import React, { useContext } from "react";
import { ThemeContext } from "../contexts/ThemeContext";

interface Props {
  level: number;
  children: React.ReactNode | string;
  className?: string;
}

function SectionHeader({
  level,
  children,
  className = "text-base font-semibold leading-6",
}: Props) {
  const { theme } = useContext(ThemeContext)!;
  const HeadingTag = `h${level}` as keyof JSX.IntrinsicElements;
  return (
    <HeadingTag className={`${className} ${theme.labelTextColor}`}>
      {children}
    </HeadingTag>
  );
}

export default SectionHeader;
