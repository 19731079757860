import React, { useContext, useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import LoadingButton from "../../components/buttons/LoadingButton";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import LabelledInput from "../../components/inputs/LabelledInput";
import useReacaptcha from "../../hooks/useReacaptcha";
import WhiteLabelLogo from "./WhiteLabelLogo";
import { useNavigate } from "react-router-dom";
import OTPInput from "../../components/inputs/OTPInput";
import { ThemeContext } from "../../contexts/ThemeContext";
import SectionHeader from "../../components/SectionHeader";
import Label from "../../components/Label";
import Typography from "../../components/Typography";
import Card from "../../components/Card";

type Inputs = {
  code1?: string;
  code2?: string;
  code3?: string;
  code4?: string;
  code5?: string;
  code6?: string;
  otp?: string;
};

type ValueNames = "code1" | "code2" | "code3" | "code4" | "code5" | "code6";

function VerifyOTP() {
  const [useBackupCode, setUseBackupCode] = useState(false);

  const loginSchema = yup.object().shape({
    code1: yup
      .string()
      .when("$useBackupCode", ([useBackupCode], schema) =>
        useBackupCode ? schema : schema.required("Code is required")
      ),
    code2: yup
      .string()
      .when("$useBackupCode", ([useBackupCode], schema) =>
        useBackupCode ? schema : schema.required("Code is required")
      ),
    code3: yup
      .string()
      .when("$useBackupCode", ([useBackupCode], schema) =>
        useBackupCode ? schema : schema.required("Code is required")
      ),
    code4: yup
      .string()
      .when("$useBackupCode", ([useBackupCode], schema) =>
        useBackupCode ? schema : schema.required("Code is required")
      ),
    code5: yup
      .string()
      .when("$useBackupCode", ([useBackupCode], schema) =>
        useBackupCode ? schema : schema.required("Code is required")
      ),
    code6: yup
      .string()
      .when("$useBackupCode", ([useBackupCode], schema) =>
        useBackupCode ? schema : schema.required("Code is required")
      ),
    otp: yup
      .string()
      .when("$useBackupCode", ([useBackupCode], schema) =>
        useBackupCode ? schema.required("Please enter code") : schema
      ),
  });

  const defaultValues = {
    otp: "",
    code1: "",
    code2: "",
    code3: "",
    code4: "",
    code5: "",
    code6: "",
  };

  const methods = useForm<Inputs>({
    mode: "all",
    resolver: yupResolver(loginSchema),
    defaultValues,
    context: { useBackupCode },
  });

  const {
    handleSubmit,
    setError,
    formState: { errors },
    watch,
    setValue,
  } = methods;

  const navigate = useNavigate();
  const { isProcessing, verifyOTP, MFA } = useAuth();
  const [errMessage, setErrMessage] = useState("");
  const { recaptchaVerify } = useReacaptcha("login");
  const [autoSubmit, setAutoSubmit] = useState(true);
  const values = watch();
  const { theme } = useContext(ThemeContext)!;

  useEffect(() => {
    if (!useBackupCode) {
      const { otp, ...rest } = values;
      if (
        Object.values(rest).every((value) => parseInt(value) >= 0) &&
        autoSubmit
      ) {
        setAutoSubmit(false);
        onSubmit(values);
      }
    }
  }, [values]);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const token = await recaptchaVerify();

    if (token.length) {
      try {
        const {
          otp,
          code1 = "",
          code2 = "",
          code3 = "",
          code4 = "",
          code5 = "",
          code6 = "",
        } = data;
        const code: any = useBackupCode
          ? otp
          : code1 + code2 + code3 + code4 + code5 + code6;
        await verifyOTP(code, token, useBackupCode);
      } catch (e: any) {
        setAutoSubmit(true);
        useBackupCode &&
          setError("otp", { type: "custom", message: e.message });
        setError("code1", { type: "custom", message: "" });
        setError("code2", { type: "custom", message: "" });
        setError("code3", { type: "custom", message: "" });
        setError("code4", { type: "custom", message: "" });
        setError("code5", { type: "custom", message: "" });
        setError("code6", { type: "custom", message: "" });
        !useBackupCode && setErrMessage(e.message);
        setValue("code1", "");
        setValue("code2", "");
        setValue("code3", "");
        setValue("code4", "");
        setValue("code5", "");
        setValue("code6", "");
        setValue("otp", "");
      }
    }
  };

  useEffect(() => {
    if (!MFA || !MFA.mfa_hash) {
      navigate("/auth/login");
    }
  }, [MFA]);

  return (
    <div className="flex h-full-vh flex-1 flex-col justify-center sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <WhiteLabelLogo />
        <SectionHeader
          level={2}
          className={`mt-4 text-center text-xl leading-9 tracking-tight`}
        >
          Two-Factor authentication
        </SectionHeader>
        <div
          className={`mt-2 ${theme.descriptionTextColor} text-sm text-center`}
        >
          {useBackupCode
            ? "Enter the 6-digit backup code saved by you to confirm your action."
            : "Enter the 6-digit code generated by your app to confirm your action."}{" "}
          (Do not refresh this page)
        </div>
      </div>

      <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <Card className={`px-6 py-12 shadow sm:rounded-lg sm:px-12`}>
          <FormProvider {...methods}>
            <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
              {useBackupCode ? (
                <LabelledInput
                  name="otp"
                  placeholder=""
                  label={"Enter Backup Code"}
                  type="text"
                  enableFocus={true}
                />
              ) : (
                <div>
                  <Label className={`block text-sm font-medium leading-6`}>
                    Enter OTP
                  </Label>
                  <div className="flex gap-x-4">
                    {Object.keys(defaultValues)
                      .filter((name) => name != "otp")
                      .map((name, index) => (
                        <OTPInput
                          key={name}
                          name={`code${index + 1}` as ValueNames}
                          placeholder="-"
                          type="text"
                          enableFocus={index === 0}
                          setErrMessage={setErrMessage}
                          fieldNameArray={[
                            "code1",
                            "code2",
                            "code3",
                            "code4",
                            "code5",
                            "code6",
                          ]}
                        />
                      ))}
                  </div>
                  {(errMessage ||
                    errors.code1 ||
                    errors.code2 ||
                    errors.code3 ||
                    errors.code4 ||
                    errors.code5 ||
                    errors.code6) && (
                    <span className={`${theme.inputErrorTextColor} text-sm`}>
                      {errMessage ||
                        errors.code1?.message ||
                        errors.code2?.message ||
                        errors.code3?.message ||
                        errors.code4?.message ||
                        errors.code5?.message ||
                        errors.code6?.message}
                    </span>
                  )}
                </div>
              )}
              <div>
                <LoadingButton
                  type="submit"
                  className="flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm"
                  buttonText="Verify"
                  loading={isProcessing}
                />
              </div>
            </form>
          </FormProvider>
        </Card>
        <Typography className="mt-10 text-center text-sm">
          {useBackupCode ? `Found your device?` : `Can't find your device?`}{" "}
          <a
            href="#!"
            onClick={() => setUseBackupCode(!useBackupCode)}
            className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
          >
            {useBackupCode ? "Use OTP" : "Use backup code."}
          </a>
        </Typography>
        <Typography className="mt-2 text-center text-sm">
          Not sure about anything?{" "}
          <a
            href="/auth/login"
            className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
          >
            Return to login
          </a>
        </Typography>
      </div>
    </div>
  );
}

export default VerifyOTP;
