import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Users from "../users/Users";
import { Cog8ToothIcon, LightBulbIcon } from "@heroicons/react/20/solid";
import ElementManagement from "./ElementManagement";
import { ThemeContext } from "../../../contexts/ThemeContext";
import Tabs from "../../../components/Tabs";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}
function WanAwareAdmin() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { theme } = useContext(ThemeContext)!;

  const activeTab = pathname.split("/").slice(-1);

  const [currentTab, setCurrentTab] = useState(activeTab[0]);

  const tabs = [
    {
      name: "Employees",
      value: "employees",
      component: <Users />,
      icon: <Cog8ToothIcon width={20} height={20} />,
      url: "employees",
    },
    {
      name: "Element Management",
      value: "element-management",
      component: <ElementManagement />,
      icon: <LightBulbIcon width={20} height={20} />,
      url: `element-management`,
    },
  ];
  const onTabChange = (tab: any) => {
    setCurrentTab(tab.value);
    navigate(`/super-user/wanaware-admin/${tab.url}`);
  };

  return (
    <div>
      <Tabs
        tabs={tabs}
        currentTab={currentTab}
        onClick={(tab) => onTabChange(tab)}
      />
    </div>
  );
}

export default WanAwareAdmin;
