import React, { useContext } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";

interface Props {
  id?: string;
  name?: string;
  disabled?: boolean;
  checked?: boolean;
  className: string;
  onClick?: (event: any) => void;
  onChange?: (event: any) => void;
  defaultChecked?: boolean;
}
function Checkbox({
  id,
  disabled,
  checked,
  className,
  onClick,
  onChange,
  defaultChecked,
  ...other
}: Props) {
  const { theme } = useContext(ThemeContext)!;
  return (
    <div>
      {" "}
      <input
        id={id}
        type="checkbox"
        disabled={disabled}
        checked={checked}
        defaultChecked={defaultChecked}
        className={`${className} ${theme.checkboxBackgroundColor} ${theme.inputFocusedBorderColor}`}
        onClick={onClick}
        onChange={onChange}
        {...other}
      />
    </div>
  );
}

export default Checkbox;
