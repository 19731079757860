import React, { useContext, useState } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";
import { useFormContext } from "react-hook-form";
import { ThemeContext } from "../../contexts/ThemeContext";

interface Option {
  label: string;
  value: string;
}

interface Props {
  name: string;
  options: Option[];
  label: string;
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

function LabelledSelect({ options, label, name }: Props) {
  const [query, setQuery] = useState("");
  const [selectedPerson, setSelectedPerson] = useState<Option | null>(null);
  const { theme } = useContext(ThemeContext)!;

  const {
    formState: { errors },
    setValue,
  } = useFormContext();

  const filteredOptions =
    query === ""
      ? options
      : options.filter((option) => {
          return option.label.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <Combobox
      as="div"
      value={selectedPerson}
      onChange={(e) => {
        setSelectedPerson(e);
        setValue(name, e?.value ?? "");
      }}
    >
      <Combobox.Label
        className={`block text-sm font-medium leading-6 ${theme.labelTextColor}`}
      >
        {label}
      </Combobox.Label>

      <div className="relative mt-2">
        <Combobox.Input
          className={`w-full rounded-md border-0 ${theme.cardBackgroundColor} py-1.5 outline-none pl-3 pr-10 ${theme.labelTextColor} shadow-sm ring-1 ring-inset ${theme.inputBorderColor} focus:ring-2 focus:ring-inset ${theme.inputFocusedBorderColor} sm:text-sm sm:leading-6`}
          onChange={(event) => {
            setQuery(event.target.value);
          }}
          displayValue={(option: Option) => option?.label ?? ""}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className={`h-5 w-5 ${theme.inputBorderColor} ${theme.activeLabelTextColor}`}
            aria-hidden="true"
          />
        </Combobox.Button>

        {filteredOptions.length > 0 && (
          <Combobox.Options
            className={`absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md ${theme.cardBackgroundColor} py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}
          >
            {filteredOptions.map((option) => (
              <Combobox.Option
                key={option?.value}
                value={option}
                className={({ active }) =>
                  classNames(
                    `relative cursor-default select-none py-2 pl-8 pr-4 ${theme.labelTextColor}`,
                    active ? `${theme.selectOptionFocusedBackgroundColor}` : ""
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames(
                        "block truncate",
                        selected && "font-semibold"
                      )}
                    >
                      {option?.label}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 left-0 flex items-center pl-1.5"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>

      {errors && errors[name] && (
        <span className={`${theme.inputErrorTextColor} text-sm`}>
          {errors[name]?.message as string}
        </span>
      )}
    </Combobox>
  );
}

export default LabelledSelect;
