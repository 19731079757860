import React, { ReactNode, useContext } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";

interface Props extends React.HTMLProps<HTMLButtonElement> {
  className?: string;
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
  children: ReactNode;
}

function GhostButton({ className, type, disabled, children, ...other }: Props) {
  const { theme } = useContext(ThemeContext)!;

  return (
    <button
      className={`text-sm font-semibold ring-1 items-center rounded-md px-2 py-2 ${theme.ghostButtonBorderColor} ${theme.ghostButtonBackgroundColor} ${theme.ghostButtonTextColor} ${theme.ghostButtonHoverTextColor} ${theme.ghostButtonActiveBgColor} ${theme.ghostButtonDisabledBackgroundColor} ${theme.ghostButtonDisabledTextColor} ${className}`}
      disabled={disabled}
      type={type}
      {...other}
    >
      {children}
    </button>
  );
}

export default GhostButton;
