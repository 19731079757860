import React, { useContext } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";

interface Props extends React.HTMLProps<HTMLButtonElement> {
  loading: boolean;
  className: string;
  buttonText: string;
  type: "button" | "submit" | "reset" | undefined;
  icon?: any;
  disabled?: boolean;
  iconClass?: string;
}

function LoadingButton({
  loading,
  className,
  buttonText,
  type,
  icon: Icon,
  disabled,
  iconClass,
  ...other
}: Props) {
  const { theme } = useContext(ThemeContext)!;

  return (
    <button
      className={`flex justify-center items-center ${theme.submitButtonTextColor} ${theme.submitButtonHoverBgColor} ${theme.submitButtonBgColor} ${theme.submitButtonDisabledBgColor} ${theme.submitButtonActiveBgColor} ${theme.submitButtonDisabledTextColor} ${className}`}
      disabled={loading || disabled}
      type={type}
      {...other}
    >
      <div
        className={`mr-1 h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]
        ${loading ? "" : "invisible"}`}
        role="status"
      ></div>
      {Icon && <Icon className={`h-5 w-5 ${iconClass}`} />}
      <span className="pr-5 pl-2">{buttonText}</span>
    </button>
  );
}

export default LoadingButton;
