/* eslint-disable jsx-a11y/anchor-is-valid */
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import React, { useContext } from "react";
import { ThemeContext } from "../contexts/ThemeContext";

function Pagination({
  currentPage,
  setCurrentPage,
  setCurrentLimit,
  totalPages,
  totalResults,
  currentLimit,
  defaultLimit,
  disableAll,
}: any) {
  const MAX_VISIBLE_PAGES = 3; // Adjust this as needed
  const rangeStart = Math.max(
    1,
    currentPage - Math.floor(MAX_VISIBLE_PAGES / 2)
  );
  const rangeEnd = Math.min(totalPages, rangeStart + MAX_VISIBLE_PAGES - 1);
  const { theme, mode } = useContext(ThemeContext)!;

  return (
    <div className="flex items-center justify-between py-3">
      <div className="flex flex-1 justify-between sm:hidden">
        <button
          className="relative inline-flex items-center rounded-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={disableAll ? disableAll : false && currentPage === 1}
        >
          Previous
        </button>
        <button
          className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={
            disableAll ? disableAll : false && currentPage === totalPages
          }
        >
          Next
        </button>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <nav
            className="isolate inline-flex space-x-1 rounded-md"
            aria-label="Pagination"
          >
            <button
              className="relative inline-flex items-center rounded-full px-2 py-2 text-gray-400 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={disableAll ? disableAll : false && currentPage === 1}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>

            {rangeStart !== 1 && (
              <button
                aria-current="page"
                className={`relative inline-flex items-center rounded-full px-4 py-2 text-sm font-semibold ${theme.labelTextColor} ring-1 ring-inset ring-gray-300  focus:z-20 focus:outline-offset-0`}
                onClick={() => setCurrentPage(1)}
                disabled={disableAll ? disableAll : false}
              >
                1
              </button>
            )}
            {rangeStart > 2 && (
              <button
                aria-current="page"
                className={`relative inline-flex items-center rounded-full px-3 py-2 text-sm font-semibold ${theme.labelTextColor} ring-1 ring-inset ring-gray-300  focus:z-20 focus:outline-offset-0`}
              >
                ...
              </button>
            )}
            {Array.from({ length: rangeEnd - rangeStart + 1 }, (_, index) => (
              <button
                aria-current="page"
                key={index}
                className={
                  currentPage === rangeStart + index
                    ? `relative z-10 inline-flex items-center ${
                        mode === "light"
                          ? `${theme.primaryBackgroundColor} text-white`
                          : "bg-white text-black"
                      } rounded-full  px-2 py-2 text-sm font-semibold focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2`
                    : `relative inline-flex items-center rounded-full px-2 py-2 text-sm font-semibold ${theme.labelTextColor} ring-1 ring-inset ring-gray-300  focus:z-20 focus:outline-offset-0`
                }
                onClick={() => setCurrentPage(rangeStart + index)}
                disabled={disableAll ? disableAll : false}
              >
                <span className="h-5 w-5">{rangeStart + index}</span>
              </button>
            ))}
            {rangeEnd < totalPages - 1 && (
              <button
                aria-current="page"
                className={`relative inline-flex items-center rounded-full px-3 py-2 text-sm font-semibold ${theme.labelTextColor} ring-1 ring-inset ring-gray-300  focus:z-20 focus:outline-offset-0`}
                disabled={disableAll ? disableAll : false}
              >
                ...
              </button>
            )}
            {rangeEnd !== totalPages && (
              <button
                aria-current="page"
                className={`relative inline-flex items-center rounded-full px-4 py-2 text-sm font-semibold ${theme.labelTextColor} ring-1 ring-inset ring-gray-300  focus:z-20 focus:outline-offset-0`}
                onClick={() => setCurrentPage(totalPages)}
                disabled={disableAll ? disableAll : false}
              >
                {totalPages}
              </button>
            )}
            <button
              className="relative inline-flex items-center rounded-full px-2 py-2 text-gray-400 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={
                disableAll ? disableAll : false && currentPage === totalPages
              }
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>

        <div
          className={`flex items-center justify-between text-sm ${theme.activeLabelTextColor} space-x-1`}
        >
          <p> Show </p>
          <select
            id="limit"
            name="limit"
            className={`block w-fit rounded-md border-0 ${theme.cardBackgroundColor} py-0.5 pl-2 pr-8 ${theme.labelTextColor} ring-1 ring-inset ${theme.inputBorderColor} focus:ring-2 ${theme.inputFocusedBorderColor} sm:text-sm sm:leading-6`}
            defaultValue={defaultLimit ? defaultLimit : "10"}
            value={currentLimit || "10"}
            onChange={(e) => {
              setCurrentLimit(e.target.value);
              setCurrentPage(1);
            }}
            disabled={disableAll ? disableAll : false}
          >
            <option value={5}> 5 </option>
            <option value={10}> 10 </option>
            <option value={20}> 20 </option>
            <option value={30}> 30 </option>
          </select>
          <p> of </p>
          <p className="font-medium">{totalResults}</p>
          <p> results </p>
        </div>
      </div>
    </div>
  );
}

export default Pagination;
