import { ChevronUpIcon, TrashIcon } from "@heroicons/react/24/outline";
import React, { useContext, useState } from "react";
import AssessmentTableAccordion from "./AssessmentTableAccordion";
import ToolTip from "../../../../components/ToolTip";
import { GlobalToasterContext } from "../../../../contexts/ToasterContext";
import { get, del } from "../../../../utils/httpMethods";
import TableActionDangerButton from "../../../../components/tables/TableActionDangerButton";
import TableActionGeneralButton from "../../../../components/tables/TableActionGeneralButton";
import { ThemeContext } from "../../../../contexts/ThemeContext";
import toast from "react-hot-toast";

function AssessmentTableRow({
  assessment,
  refetch,
  isSelected,
  openAccordion,
  setOpenAccordion,
}: any) {
  const { setModalProps } = useContext(GlobalToasterContext)!;
  const [deletingAssessment, setDeletingAssessment] = useState(false);
  const { theme } = useContext(ThemeContext)!;
  const { setShowErrorOverlay } = useContext(GlobalToasterContext)!;
  const deleteAssessment = async (id: any) => {
    try {
      setDeletingAssessment(true);
      const result: any = await del(`api/assessments/${id}`);
      console.log(result);
      refetch();
      toast.success(result.message);
      setDeletingAssessment(false);
    } catch (error: any) {
      setDeletingAssessment(false);
      toast.error(error.message || error.error);
      setShowErrorOverlay(true);
    }
  };

  const onDelete = async (assessmentId: string) => {
    setModalProps({
      show: true,
      title: `Delete Assessment Request`,
      message: `Are you sure you want to delete Assesment Request ${assessmentId}?`,
      deleteFunction: async () => await deleteAssessment(assessmentId),
    });
  };
  return (
    <>
      <tr
        key={assessment.id}
        className={`${theme.tableSelectedRowBackgroundColor}
        ${openAccordion.includes(assessment.id) ? "rounded-none" : "rounded-md"}
        border-spacing-4  relative ${
          isSelected ? "cursor-pointer" : ""
        } bg-button-ghost ${
          isSelected
            ? `${theme.nestedAccordionBackgroundColor} ${theme.primaryTableRowHoverColor}`
            : theme.nestedAccordionBackgroundColor
        } `}
      >
        <td
          className={`whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium ${
            openAccordion.includes(assessment.id)
              ? "rounded-tl-xl"
              : "rounded-s-xl"
          }`}
        >
          {assessment.id}
        </td>

        <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium">
          {assessment.company_name}
        </td>

        <td className="pl-7">
          {deletingAssessment ? (
            <div
              className={`mr-1 h-5 w-5 animate-spin rounded-full border-2 border-solid border-gray-800 border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]`}
              role="status"
            ></div>
          ) : (
            <ToolTip text="Delete Assessment Request">
              <TableActionDangerButton
                type="button"
                onClick={() => onDelete(assessment.id)}
              >
                <TrashIcon className="w-4 h-4" />
              </TableActionDangerButton>
            </ToolTip>
          )}
        </td>

        <td
          className={`cursor-pointer ${
            openAccordion.includes(assessment.id)
              ? "rounded-tr-xl"
              : "rounded-e-xl"
          }`}
          onClick={() =>
            openAccordion.includes(assessment.id)
              ? setOpenAccordion(
                  openAccordion.filter((item: any) => item !== assessment.id)
                )
              : setOpenAccordion([...openAccordion, assessment.id])
          }
        >
          {
            <TableActionGeneralButton className="mx-2 flex py-[2px] w-[32px] float-right mr-10">
              <ChevronUpIcon
                className={`h-6 w-6 transition-transform duration-200 ${
                  openAccordion.includes(assessment.id) ? "" : "rotate-180"
                } `}
                aria-hidden="true"
              />
            </TableActionGeneralButton>
          }
        </td>
      </tr>
      {openAccordion.includes(assessment.id) && (
        <tr className={`${theme.nestedAccordionBackgroundColor} `}>
          <td
            className={`py-3 pl-4 pr-3 text-sm font-medium ${theme.labelTextColor} rounded-b-xl`}
            colSpan={10}
          >
            <AssessmentTableAccordion assessment={assessment} />
          </td>
        </tr>
      )}
      <tr className="h-2"></tr>
    </>
  );
}

export default AssessmentTableRow;
