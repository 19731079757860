import React, { ReactNode, useContext } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";

interface Props extends React.HTMLProps<HTMLButtonElement> {
  className?: string;
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
  children: ReactNode;
}

function TableActionDangerButton({
  className,
  type,
  disabled,
  children,
  ...other
}: Props) {
  const { theme } = useContext(ThemeContext)!;

  return (
    <button
      className={`text-sm font-semibold px-2 py-1 ring-1 items-center rounded-md ${theme.tableActionDangerButtonBorderColor}  ${theme.tableActionDangerButtonTextColor} ${theme.tableActionDangerButtonBackgroundColor} ${theme.tableActionDangerButtonHoverTextColor} ${theme.tableActionDangerButtonHoverBackgroundColor} ${theme.tableActionDangerButtonDisabledBackgroundColor} ${theme.tableActionDangerButtonDisabledTextColor} ${theme.tableActionDangerButtonActiveBgColor} ${className}`}
      disabled={disabled}
      type={type}
      {...other}
    >
      {children}
    </button>
  );
}

export default TableActionDangerButton;
