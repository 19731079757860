import React, { useEffect, useRef, useState } from "react";

function useReacaptcha(action: string) {
  const badgeRef = useRef<any>(null);
  const [token, setToken] = useState("");

  //   render badge

  useEffect(() => {
    const handleLoaded = () => {
      (window as any).grecaptcha.ready(function () {
        const badge = document.body.querySelector(".grecaptcha-badge");
        badgeRef.current = badge;
      });
    };

    const script = document.createElement("script");

    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    script.addEventListener("load", handleLoaded);
    document.body.appendChild(script);

    // remove badge on dismount
    return () => {
      document?.body?.removeChild(script);
      badgeRef?.current?.classList.add("!hidden");
    };
  }, []);

  //   run callback from desired component to get token
  const recaptchaVerify = async () => {
    const resp = await (window as any).grecaptcha
      .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: action })
      .then(function (token: string) {
        // Add your logic to submit to your backend server here.
        setToken(token);
        return token;
      });

    return resp;
  };

  return { recaptchaVerify, token };
}

export default useReacaptcha;
