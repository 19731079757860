import React, { useContext } from "react";
import { ThemeContext } from "../contexts/ThemeContext";
interface Props {
  htmlFor?: string;
  className?: string;
  children: React.ReactNode;
}
function Label({ htmlFor, className, children }: Props) {
  const { theme } = useContext(ThemeContext)!;
  return (
    <label className={`${className} ${theme.labelTextColor}`} htmlFor={htmlFor}>
      {children}
    </label>
  );
}

export default Label;
