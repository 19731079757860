import React, { useContext } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";

interface Props extends React.HTMLProps<HTMLInputElement> {
  id?: any;
  type: string;
  className: string;
  onChange?: (value: any) => void;
  value?: string;
  placeholder?: string;
  autoFocus?: boolean;
  isNestedAccordionInput?: boolean;
  disabled?: boolean;
}

function SimpleInput({
  id,
  type,
  className,
  onChange,
  value,
  placeholder,
  autoFocus,
  isNestedAccordionInput = false,
  disabled,
  ...other
}: Props) {
  const { theme } = useContext(ThemeContext)!;
  return (
    <input
      id={id}
      type={type}
      className={`${className} ${theme.labelTextColor} 
                      ${theme.inputBorderColor}
                      ${theme.inputFocusedBorderColor} ${
        isNestedAccordionInput
          ? theme.nestedAccordionBackgroundColor
          : theme.cardBackgroundColor
      }`}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      autoFocus={autoFocus}
      disabled={disabled}
      {...other}
    />
  );
}

export default SimpleInput;
