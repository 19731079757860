import { useContext } from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { GlobalToasterContext } from "../contexts/ToasterContext";
import Overlay from "./Overlay";
import { ThemeContext } from "../contexts/ThemeContext";
import Label from "./Label";
import Typography from "./Typography";

export default function InfoModal() {
  const {
    infoModalProps: { show, message, title },
    setInfoModalProps,
  } = useContext(GlobalToasterContext)!;
  const { mode } = useContext(ThemeContext)!;
  const handleClose = () => {
    setInfoModalProps((prev) => ({
      ...prev,
      show: false,
    }));
  };

  return (
    <Overlay
      open={show}
      handleClose={handleClose}
      titleContent={""}
      className="sm:w-full sm:max-w-lg"
    >
      <div className="sm:flex sm:items-start">
        <div
          className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full ${
            mode === "light" ? "bg-red-100" : "bg-slate-700"
          } sm:mx-0 sm:h-10 sm:w-10`}
        >
          <ExclamationTriangleIcon
            className="h-6 w-6 text-red-600"
            aria-hidden="true"
          />
        </div>
        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <Label className="font-semibold">{title}</Label>
          <div className="mt-2">
            <Typography className="text-sm">{message}</Typography>
          </div>
        </div>
      </div>
    </Overlay>
  );
}
