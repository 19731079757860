import React, { useContext, useState } from "react";
import { ThemeColorPresets, ThemeContext } from "../../contexts/ThemeContext";
import { colorPresets } from "./getColorPreset";

const Presets = () => {
  const { mode, themePresetColor, onChangeColor } = useContext(ThemeContext)!;
  return (
    <div className="flex flex-wrap border-box gap-4">
      {colorPresets.map((preset) => {
        const isSelected = themePresetColor === preset.name;

        return (
          <button
            className={`flex items-center justify-center h-14 rounded-lg transition-colors duration-300 shadow-sm  border-2
         ${
           isSelected
             ? preset.borderColor
             : mode === "light"
             ? "border-gray-100"
             : "border-gray-700"
         } 
          `}
            style={{
              width: "70px",
            }}
            onClick={() => onChangeColor(preset.name as ThemeColorPresets)}
          >
            <div
              className={"persetIcon"}
              style={{
                backgroundColor: preset.colorCode,
                rotate: isSelected ? "180deg" : "-45deg",
              }}
            ></div>
          </button>
        );
      })}
    </div>
  );
};

export default Presets;
