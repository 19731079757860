const themes: any = {
  light: {
    orange: {
      bodyBackgroundColor: "bg-primary-1000",
      cardBackgroundColor: "bg-white",
      cardBackgroundColorCode: "#fff",
      activeLabelTextColor: "text-gray-700",
      disableLabelTextColor: "text-gray-600",
      accordianBackgroundColor: "bg-white",
      accordionHeaderTextColor: "text-gray-800",
      selectedTableRowColor: "bg-orange-200",

      //Sttings Drawer Button
      settingsButtonHoverTextColor: "hover:text-orange-500 ",
      setttingsButtonHoverBgColor: "hover:bg-orange-100",

      // Input
      inputBorderColor: "ring-gray-300",
      inputBorderColorCode: "#D1D5DB",
      inputFocusedBorderColor: "focus:ring-orange-400",
      inputFocusedBorderColorCode: "#fb923c",
      mutedInputBackgroundColor: "bg-gray-100/70",
      mutedInputBackgroundColorCode: "#e5e7ebb3",

      // Select
      selectOptionFocusedBackgroundColor: "bg-orange-200",
      selectOptionHoveredBackgroundColor: "hover:bg-orange-200",
      selectOptionFocusedBackgroundColorCode: "#fed7aa",
      inputErrorTextColor: "text-danger-900",
      inputErrorTextColorCode: "#ff6363",
      inputErrorBorderColor: "ring-danger-900",
      labelTextColor: "text-gray-900",
      labelTextColorCode: "#111827",
      selectDefaultBackgroundColor: "text-gray-900",
      selectMultiValueBackgroundColorCode: "#E5E7EB",
      // Checkbox/RB/Toggle
      checkboxBackgroundColor: "text-orange-500",
      radioButtonFocusedBorderColor: "focus:ring-orange-700",

      // Common primary colors
      primaryTextColor: "text-orange-500",
      primaryBackgroundColor: "bg-orange-500",
      primaryBorderColor: "border-orange-500",
      primaryFocusRingColor: "focus:ring-orange-500",
      primaryTextHoverColor: "hover:text-orange-500",
      primaryTextGroupHoverColor: "group-hover:text-orange-500",
      primaryTableRowHoverColor: "hover:bg-orange-100",
      // Typography colors & Tab colors
      typographyTextColor: "text-gray-500",
      typographyHoverTextColor: "hover:text-gray-700",
      tabHoverBorderColor: "hover:border-gray-300",
      tabBorderBottomColor: "border-b-gray-200",
      activeTabTextColor: "text-orange-500",
      menuIconBgColor: "bg-gray-200/60",
      menuBorderColor: "hover:ring-gray-200",
      // Common success colors
      successTextColor: "text-green-600",
      successHoverTextColor: "hover:text-green-600",
      successActiveBgColor: "active:bg-green-100",
      successBorderColor: "border-green-600",
      redBorderColor: "border-red-1000",
      redTextColor: "text-red-1000",
      redLightBackgroundColor: "bg-red-100",
      greenLightBackgroundColor: "bg-green-100",
      greenTextolor: "text-green-400",

      // Submit Button => [LoadingButton]
      submitButtonBgColor: "bg-orange-500",
      submitButtonDisabledBgColor: "disabled:bg-orange-200",
      submitButtonHoverBgColor: "hover:bg-orange-600",
      submitButtonActiveBgColor: "active:bg-orange-700/80",
      submitButtonTextColor: "text-white",
      submitButtonDisabledTextColor: "text-white",
      submitButtonHoverTextColor: "text-white",
      // Cancel Button
      cancelButtonBorderColor: "ring-gray-300",
      cancelButtonTextColor: "text-gray-900",
      cancelButtonBackgroundColor: "bg-white",
      cancelButtonHoverTextColor: "hover:text-orange-500",
      cancelButtonActiveBgColor: "active:bg-gray-100/60",
      cancelButtonDisabledTextColor: "disabled:text-gray-200",
      // Ghost Button
      ghostButtonBorderColor: "ring-gray-200",
      ghostButtonTextColor: "text-black",
      ghostButtonBackgroundColor: "bg-white",
      ghostButtonHoverTextColor: "hover:text-orange-500",
      ghostButtonDisabledBackgroundColor: "disabled:bg-gray-200",
      ghostButtonDisabledTextColor: "disabled:text-gray-300",
      ghostButtonActiveBgColor: "active:bg-orange-200/40",
      // Primary Button => [PrimaryButton]
      primaryButtonBorderColor: "ring-orange-500",
      primaryButtonTextColor: "text-white",
      primaryButtonBackgroundColor: "bg-orange-500",
      primaryButtonHoverTextColor: "hover:text-white",
      primaryButtonHoverBackgroundColor: "hover:bg-orange-600",
      primaryButtonDisabledBackgroundColor:
        "disabled:bg-orange-300 disabled:ring-orange-300",
      primaryButtonDisabledTextColor: "disabled:text-white",
      primaryButtonActiveBgColor: "active:bg-orange-700/90",
      // Success Button => [SuccessButton]
      successButtonBorderColor: "ring-green-600",
      successButtonTextColor: "text-white",
      successButtonBackgroundColor: "bg-green-600",
      successButtonHoverTextColor: "hover:text-white",
      successButtonHoverBackgroundColor: "hover:bg-green-700",
      successButtonDisabledBackgroundColor: "disabled:bg-green-200",
      successButtonDisabledTextColor: "disabled:text-white",
      successButtonActiveBgColor: "active:bg-green-800",
      // Delete operations Button => [DangerButton]
      dangerButtonBorderColor: "ring-danger-1000",
      dangerButtonTextColor: "text-white",
      dangerButtonBackgroundColor: "bg-danger-1000",
      dangerButtonHoverTextColor: "hover:text-white",
      dangerButtonHoverBackgroundColor: "hover:bg-danger-1100",
      dangerButtonDisabledBackgroundColor:
        "disabled:bg-danger-300 disabled:ring-danger-300",
      dangerButtonDisabledTextColor: "disabled:text-white",
      dangerButtonActiveBgColor: "active:bg-danger-1200",

      // Table Action Danger Button => [TableActionDangerButton]
      tableActionDangerButtonBorderColor: "ring-gray-200/80",
      tableActionDangerButtonTextColor: "text-danger-1000",
      tableActionDangerButtonBackgroundColor: "bg-gray-200/60",
      tableActionDangerButtonHoverTextColor: "hover:text-danger-1000",
      tableActionDangerButtonHoverBackgroundColor:
        "hover:bg-gray-200/70 hover:ring-danger-1000",
      tableActionDangerButtonDisabledBackgroundColor:
        "disabled:bg-gray-100 disabled:ring-gray-100",
      tableActionDangerButtonDisabledTextColor: "disabled:text-danger-800",
      tableActionDangerButtonActiveBgColor: "active:bg-gray-300/80",
      tableActionDangerButtonLoaderColor: "border-gray-800",
      // Table Action General Button => [TableActionGeneralButton]
      tableActionGeneralButtonBorderColor: "ring-gray-200/80",
      tableActionGeneralButtonTextColor: "text-gray-800",
      tableActionGeneralButtonBackgroundColor: "bg-gray-200/60",
      tableActionGeneralButtonHoverTextColor: "hover:text-gray-800",
      tableActionGeneralButtonHoverBackgroundColor:
        "hover:bg-gray-300/70 hover:ring-gray-300/70",
      tableActionGeneralButtonDisabledBackgroundColor:
        "disabled:bg-gray-100 disabled:ring-gray-100",
      tableActionGeneralButtonDisabledTextColor: "disabled:text-gray-500",
      tableActionGeneralButtonActiveBgColor:
        "active:bg-gray-400/45 active:ring-gray-400/45",

      // Simple Table
      tableHeaderBackgroundColor: "bg-table-header",
      tableBodyBackgroundColor: "bg-background",
      tableHeaderTextColor: "text-table-headerText",
      tableHoverBackgroundColor: "hover:bg-button-ghost",
      tableCellBorderLeft: "first:rounded-s-xl",
      tableFirstRowLoadingBgColor: "bg-gray-400",
      tableSecondRowLoadingBgColor: "bg-gray-300",
      tableSelectedRowBackgroundColor: "border-background",

      // Popover
      popoverHoverBackgroundColor: "hover:bg-gray-100",
      // Switch
      switchDisableBgColor: "bg-gray-200",
      tableMapSwitchDisableBgColor: "bg-gray-300",

      // Tooltip
      tooltipBackgroundColor: "bg-slate-800",
      // Divider classes
      dividerClasses: {
        gray100: "divide-y divide-gray-100",
        gray200: "divide-gray-200",
        gray300: "divide-y divide-gray-300",
        gray400: "divide-y divide-gray-400",
      },
      // Menu
      cardMenuBorderColor: "ring-1 ring-gray-900/5",
      menuItemHoverBackgroundColor: "hover:bg-gray-50",
      // Stepper
      fileUploadBackgroundColor: "bg-gray-100",
      theadBackgroundColor: "bg-gray-50",
      tableBorderColor: "border-gray-200",
      uploadBoxBorderColor: "border-gray-300",
      // Horizontal Line
      horizontalLineColor: "#F3F4F6",
      // List
      selectedListBackgroundColor: "bg-blue-50",
      // Nested accordians
      nestedAccordionBackgroundColor: "bg-primary-1000",
      nestedAccordionIconButtonBackgroundColor: "bg-white",
      nestedButtonSuccessHoverColor: "hover:bg-green-200",
      nestedTableLoadingRowColor: "bg-gray-400",
    },
    cyan: {
      bodyBackgroundColor: "bg-primary-1000",
      cardBackgroundColor: "bg-white",
      cardBackgroundColorCode: "#fff",
      activeLabelTextColor: "text-gray-700",
      disableLabelTextColor: "text-gray-600",
      accordianBackgroundColor: "bg-white",
      accordionHeaderTextColor: "text-gray-800",
      selectedTableRowColor: "bg-cyan-500/20",

      //Sttings Drawer Button
      settingsButtonHoverTextColor: "hover:text-cyan-600 ",
      setttingsButtonHoverBgColor: "hover:bg-cyan-100",
      // Input
      inputBorderColor: "ring-gray-300",
      inputBorderColorCode: "#D1D5DB",
      inputFocusedBorderColor: "focus:ring-cyan-600",
      inputFocusedBorderColorCode: "#0891b2",
      mutedInputBackgroundColor: "bg-gray-100/70",
      mutedInputBackgroundColorCode: "#e5e7ebb3",

      // Select
      selectOptionFocusedBackgroundColor: "bg-cyan-500/15",
      selectOptionHoveredBackgroundColor: "hover:bg-cyan-500/15",
      selectOptionFocusedBackgroundColorCode: "#06B6D426",
      inputErrorTextColor: "text-danger-900",
      inputErrorTextColorCode: "#ff6363",
      inputErrorBorderColor: "ring-danger-900",
      labelTextColor: "text-gray-900",
      labelTextColorCode: "#111827",
      selectDefaultBackgroundColor: "text-gray-900",
      selectMultiValueBackgroundColorCode: "#E5E7EB",
      // Checkbox/RB/Toggle
      checkboxBackgroundColor: "text-cyan-600",
      radioButtonFocusedBorderColor: "focus:ring-cyan-600",

      // Common primary colors
      primaryTextColor: "text-cyan-600",
      primaryBackgroundColor: "bg-cyan-600",
      primaryBorderColor: "border-cyan-600",
      primaryFocusRingColor: "focus:ring-cyan-600",
      primaryTextHoverColor: "hover:text-cyan-600",
      primaryTextGroupHoverColor: "group-hover:text-cyan-600",
      primaryTableRowHoverColor: "hover:bg-cyan-500/10",
      // Typography colors & Tab colors
      typographyTextColor: "text-gray-500",
      typographyHoverTextColor: "hover:text-gray-700",
      tabHoverBorderColor: "hover:border-gray-300",
      tabBorderBottomColor: "border-b-gray-200",
      activeTabTextColor: "text-cyan-600",
      menuIconBgColor: "bg-gray-200/60",
      menuBorderColor: "hover:ring-gray-200",
      // Common success colors
      successTextColor: "text-green-600",
      successHoverTextColor: "hover:text-green-600",
      successActiveBgColor: "active:bg-green-100",
      successBorderColor: "border-green-600",
      redBorderColor: "border-red-1000",
      redTextColor: "text-red-1000",
      redLightBackgroundColor: "bg-red-100",
      greenLightBackgroundColor: "bg-green-100",
      greenTextolor: "text-green-400",

      // Submit Button => [LoadingButton]
      submitButtonBgColor: "bg-cyan-600",
      submitButtonDisabledBgColor: "disabled:bg-cyan-500/50",
      submitButtonHoverBgColor: "hover:bg-cyan-600",
      submitButtonActiveBgColor: "active:bg-cyan-700/80",
      submitButtonTextColor: "text-white",
      submitButtonDisabledTextColor: "text-white",
      submitButtonHoverTextColor: "text-white",
      // Cancel Button
      cancelButtonBorderColor: "ring-gray-300",
      cancelButtonTextColor: "text-gray-900",
      cancelButtonBackgroundColor: "bg-white",
      cancelButtonHoverTextColor: "hover:text-cyan-600",
      cancelButtonActiveBgColor: "active:bg-gray-100/60",
      cancelButtonDisabledTextColor: "disabled:text-gray-200",
      // Ghost Button
      ghostButtonBorderColor: "ring-gray-200",
      ghostButtonTextColor: "text-black",
      ghostButtonBackgroundColor: "bg-white",
      ghostButtonHoverTextColor: "hover:text-cyan-600",
      ghostButtonDisabledBackgroundColor: "disabled:bg-gray-200",
      ghostButtonDisabledTextColor: "disabled:text-gray-300",
      ghostButtonActiveBgColor: "active:bg-cyan-500/10",
      // Primary Button => [PrimaryButton]
      primaryButtonBorderColor: "ring-cyan-600",
      primaryButtonTextColor: "text-white",
      primaryButtonBackgroundColor: "bg-cyan-600",
      primaryButtonHoverTextColor: "hover:text-white",
      primaryButtonHoverBackgroundColor: "hover:bg-cyan-700",
      primaryButtonDisabledBackgroundColor:
        "disabled:bg-cyan-300 disabled:ring-cyan-300",
      primaryButtonDisabledTextColor: "disabled:text-white",
      primaryButtonActiveBgColor: "active:bg-cyan-800",
      // Success Button => [SuccessButton]
      successButtonBorderColor: "ring-green-600",
      successButtonTextColor: "text-white",
      successButtonBackgroundColor: "bg-green-600",
      successButtonHoverTextColor: "hover:text-white",
      successButtonHoverBackgroundColor: "hover:bg-green-700",
      successButtonDisabledBackgroundColor: "disabled:bg-green-200",
      successButtonDisabledTextColor: "disabled:text-white",
      successButtonActiveBgColor: "active:bg-green-800",
      // Delete operations Button => [DangerButton]
      dangerButtonBorderColor: "ring-danger-1000",
      dangerButtonTextColor: "text-white",
      dangerButtonBackgroundColor: "bg-danger-1000",
      dangerButtonHoverTextColor: "hover:text-white",
      dangerButtonHoverBackgroundColor: "hover:bg-danger-1100",
      dangerButtonDisabledBackgroundColor:
        "disabled:bg-danger-300 disabled:ring-danger-300",
      dangerButtonDisabledTextColor: "disabled:text-white",
      dangerButtonActiveBgColor: "active:bg-danger-1200",

      // Table Action Danger Button => [TableActionDangerButton]
      tableActionDangerButtonBorderColor: "ring-gray-200/80",
      tableActionDangerButtonTextColor: "text-danger-1000",
      tableActionDangerButtonBackgroundColor: "bg-gray-200/60",
      tableActionDangerButtonHoverTextColor: "hover:text-danger-1000",
      tableActionDangerButtonHoverBackgroundColor:
        "hover:bg-gray-200/70 hover:ring-danger-1000",
      tableActionDangerButtonDisabledBackgroundColor:
        "disabled:bg-gray-100 disabled:ring-gray-100",
      tableActionDangerButtonDisabledTextColor: "disabled:text-danger-800",
      tableActionDangerButtonActiveBgColor: "active:bg-gray-300/80",
      tableActionDangerButtonLoaderColor: "border-gray-800",
      // Table Action General Button => [TableActionGeneralButton]
      tableActionGeneralButtonBorderColor: "ring-gray-200/80",
      tableActionGeneralButtonTextColor: "text-gray-800",
      tableActionGeneralButtonBackgroundColor: "bg-gray-200/60",
      tableActionGeneralButtonHoverTextColor: "hover:text-gray-800",
      tableActionGeneralButtonHoverBackgroundColor:
        "hover:bg-gray-300/70 hover:ring-gray-300/70",
      tableActionGeneralButtonDisabledBackgroundColor:
        "disabled:bg-gray-100 disabled:ring-gray-100",
      tableActionGeneralButtonDisabledTextColor: "disabled:text-gray-500",
      tableActionGeneralButtonActiveBgColor:
        "active:bg-gray-400/45 active:ring-gray-400/45",

      // Simple Table
      tableHeaderBackgroundColor: "bg-table-header",
      tableBodyBackgroundColor: "bg-background",
      tableHeaderTextColor: "text-table-headerText",
      tableHoverBackgroundColor: "hover:bg-button-ghost",
      tableCellBorderLeft: "first:rounded-s-xl",
      tableFirstRowLoadingBgColor: "bg-gray-400",
      tableSecondRowLoadingBgColor: "bg-gray-300",
      tableSelectedRowBackgroundColor: "border-background",

      // Popover
      popoverHoverBackgroundColor: "hover:bg-gray-100",
      // Switch
      switchDisableBgColor: "bg-gray-200",
      tableMapSwitchDisableBgColor: "bg-gray-300",
      // Tooltip
      tooltipBackgroundColor: "bg-slate-800",
      // Divider classes
      dividerClasses: {
        gray100: "divide-y divide-gray-100",
        gray200: "divide-gray-200",
        gray300: "divide-y divide-gray-300",
        gray400: "divide-y divide-gray-400",
      },
      // Menu
      cardMenuBorderColor: "ring-1 ring-gray-900/5",
      menuItemHoverBackgroundColor: "hover:bg-gray-50",
      // Stepper
      fileUploadBackgroundColor: "bg-gray-100",
      theadBackgroundColor: "bg-gray-50",
      tableBorderColor: "border-gray-200",
      uploadBoxBorderColor: "border-gray-300",
      // Horizontal Line
      horizontalLineColor: "#F3F4F6",
      // List
      selectedListBackgroundColor: "bg-blue-50",
      // Nested accordians
      nestedAccordionBackgroundColor: "bg-primary-1000",
      nestedAccordionIconButtonBackgroundColor: "bg-white",
      nestedButtonSuccessHoverColor: "hover:bg-green-200",
      nestedTableLoadingRowColor: "bg-gray-400",
    },
    teal: {
      bodyBackgroundColor: "bg-primary-1000",
      cardBackgroundColor: "bg-white",
      cardBackgroundColorCode: "#fff",
      activeLabelTextColor: "text-gray-700",
      disableLabelTextColor: "text-gray-600",
      accordianBackgroundColor: "bg-white",
      accordionHeaderTextColor: "text-gray-800",
      selectedTableRowColor: "bg-teal-500/20",

      //Sttings Drawer Button
      settingsButtonHoverTextColor: "hover:text-teal-700 ",
      setttingsButtonHoverBgColor: "hover:bg-teal-100",

      // Input
      inputBorderColor: "ring-gray-300",
      inputBorderColorCode: "#D1D5DB",
      inputFocusedBorderColor: "focus:ring-teal-600",
      inputFocusedBorderColorCode: "#0d9488",
      mutedInputBackgroundColor: "bg-gray-100/70",
      mutedInputBackgroundColorCode: "#e5e7ebb3",

      // Select
      selectOptionFocusedBackgroundColor: "bg-teal-500/20",
      selectOptionHoveredBackgroundColor: "hover:bg-teal-500/20",
      selectOptionFocusedBackgroundColorCode: "#14B8A633",
      inputErrorTextColor: "text-danger-900",
      inputErrorTextColorCode: "#ff6363",
      inputErrorBorderColor: "ring-danger-900",
      labelTextColor: "text-gray-900",
      labelTextColorCode: "#111827",
      selectDefaultBackgroundColor: "text-gray-900",
      selectMultiValueBackgroundColorCode: "#E5E7EB",
      // Checkbox/RB/Toggle
      checkboxBackgroundColor: "text-teal-700",
      radioButtonFocusedBorderColor: "focus:ring-teal-800",

      // Common primary colors
      primaryTextColor: "text-teal-700",
      primaryBackgroundColor: "bg-teal-700",
      primaryBorderColor: "border-teal-700",
      primaryFocusRingColor: "focus:ring-teal-700",
      primaryTextHoverColor: "hover:text-teal-700",
      primaryTextGroupHoverColor: "group-hover:text-teal-700",
      primaryTableRowHoverColor: "hover:bg-teal-500/10",
      // Typography colors & Tab colors
      typographyTextColor: "text-gray-500",
      typographyHoverTextColor: "hover:text-gray-700",
      tabHoverBorderColor: "hover:border-gray-300",
      tabBorderBottomColor: "border-b-gray-200",
      activeTabTextColor: "text-teal-700",
      menuIconBgColor: "bg-gray-200/60",
      menuBorderColor: "hover:ring-gray-200",
      // Common success colors
      successTextColor: "text-green-600",
      successHoverTextColor: "hover:text-green-600",
      successActiveBgColor: "active:bg-green-100",
      successBorderColor: "border-green-600",
      redBorderColor: "border-red-1000",
      redTextColor: "text-red-1000",
      redLightBackgroundColor: "bg-red-100",
      greenLightBackgroundColor: "bg-green-100",
      greenTextolor: "text-green-400",

      // Submit Button => [LoadingButton]
      submitButtonBgColor: "bg-teal-700",
      submitButtonDisabledBgColor: "disabled:bg-teal-500/40",
      submitButtonHoverBgColor: "hover:bg-teal-800",
      submitButtonActiveBgColor: "active:bg-teal-800/80",
      submitButtonTextColor: "text-white",
      submitButtonDisabledTextColor: "text-white",
      submitButtonHoverTextColor: "text-white",
      // Cancel Button
      cancelButtonBorderColor: "ring-gray-300",
      cancelButtonTextColor: "text-gray-900",
      cancelButtonBackgroundColor: "bg-white",
      cancelButtonHoverTextColor: "hover:text-teal-700",
      cancelButtonActiveBgColor: "active:bg-gray-100/60",
      cancelButtonDisabledTextColor: "disabled:text-gray-200",
      // Ghost Button
      ghostButtonBorderColor: "ring-gray-200",
      ghostButtonTextColor: "text-black",
      ghostButtonBackgroundColor: "bg-white",
      ghostButtonHoverTextColor: "hover:text-teal-700",
      ghostButtonDisabledBackgroundColor: "disabled:bg-gray-200",
      ghostButtonDisabledTextColor: "disabled:text-gray-300",
      ghostButtonActiveBgColor: "active:bg-teal-500/20",
      // Primary Button => [PrimaryButton]
      primaryButtonBorderColor: "ring-teal-700",
      primaryButtonTextColor: "text-white",
      primaryButtonBackgroundColor: "bg-teal-700",
      primaryButtonHoverTextColor: "hover:text-white",
      primaryButtonHoverBackgroundColor: "hover:bg-teal-800",
      primaryButtonDisabledBackgroundColor:
        "disabled:bg-red-300 disabled:ring-red-300",
      primaryButtonDisabledTextColor: "disabled:text-white",
      primaryButtonActiveBgColor: "active:bg-teal-800",
      // Success Button => [SuccessButton]
      successButtonBorderColor: "ring-green-600",
      successButtonTextColor: "text-white",
      successButtonBackgroundColor: "bg-green-600",
      successButtonHoverTextColor: "hover:text-white",
      successButtonHoverBackgroundColor: "hover:bg-green-700",
      successButtonDisabledBackgroundColor: "disabled:bg-green-200",
      successButtonDisabledTextColor: "disabled:text-white",
      successButtonActiveBgColor: "active:bg-green-800",
      // Delete operations Button => [DangerButton]
      dangerButtonBorderColor: "ring-danger-1000",
      dangerButtonTextColor: "text-white",
      dangerButtonBackgroundColor: "bg-danger-1000",
      dangerButtonHoverTextColor: "hover:text-white",
      dangerButtonHoverBackgroundColor: "hover:bg-danger-1100",
      dangerButtonDisabledBackgroundColor:
        "disabled:bg-danger-300 disabled:ring-danger-300",
      dangerButtonDisabledTextColor: "disabled:text-white",
      dangerButtonActiveBgColor: "active:bg-danger-1200",

      // Table Action Danger Button => [TableActionDangerButton]
      tableActionDangerButtonBorderColor: "ring-gray-200/80",
      tableActionDangerButtonTextColor: "text-danger-1000",
      tableActionDangerButtonBackgroundColor: "bg-gray-200/60",
      tableActionDangerButtonHoverTextColor: "hover:text-danger-1000",
      tableActionDangerButtonHoverBackgroundColor:
        "hover:bg-gray-200/70 hover:ring-danger-1000",
      tableActionDangerButtonDisabledBackgroundColor:
        "disabled:bg-gray-100 disabled:ring-gray-100",
      tableActionDangerButtonDisabledTextColor: "disabled:text-danger-800",
      tableActionDangerButtonActiveBgColor: "active:bg-gray-300/80",
      tableActionDangerButtonLoaderColor: "border-gray-800",
      // Table Action General Button => [TableActionGeneralButton]
      tableActionGeneralButtonBorderColor: "ring-gray-200/80",
      tableActionGeneralButtonTextColor: "text-gray-800",
      tableActionGeneralButtonBackgroundColor: "bg-gray-200/60",
      tableActionGeneralButtonHoverTextColor: "hover:text-gray-800",
      tableActionGeneralButtonHoverBackgroundColor:
        "hover:bg-gray-300/70 hover:ring-gray-300/70",
      tableActionGeneralButtonDisabledBackgroundColor:
        "disabled:bg-gray-100 disabled:ring-gray-100",
      tableActionGeneralButtonDisabledTextColor: "disabled:text-gray-500",
      tableActionGeneralButtonActiveBgColor:
        "active:bg-gray-400/45 active:ring-gray-400/45",

      // Simple Table
      tableHeaderBackgroundColor: "bg-table-header",
      tableBodyBackgroundColor: "bg-background",
      tableHeaderTextColor: "text-table-headerText",
      tableHoverBackgroundColor: "hover:bg-button-ghost",
      tableCellBorderLeft: "first:rounded-s-xl",
      tableFirstRowLoadingBgColor: "bg-gray-400",
      tableSecondRowLoadingBgColor: "bg-gray-300",
      tableSelectedRowBackgroundColor: "border-background",

      // Popover
      popoverHoverBackgroundColor: "hover:bg-gray-100",
      // Switch
      switchDisableBgColor: "bg-gray-200",
      tableMapSwitchDisableBgColor: "bg-gray-300",
      // Tooltip
      tooltipBackgroundColor: "bg-slate-800",
      // Divider classes
      dividerClasses: {
        gray100: "divide-y divide-gray-100",
        gray200: "divide-gray-200",
        gray300: "divide-y divide-gray-300",
        gray400: "divide-y divide-gray-400",
      },
      // Menu
      cardMenuBorderColor: "ring-1 ring-gray-900/5",
      menuItemHoverBackgroundColor: "hover:bg-gray-50",
      // Stepper
      fileUploadBackgroundColor: "bg-gray-100",
      theadBackgroundColor: "bg-gray-50",
      tableBorderColor: "border-gray-200",
      uploadBoxBorderColor: "border-gray-300",
      // Horizontal Line
      horizontalLineColor: "#F3F4F6",
      // List
      selectedListBackgroundColor: "bg-blue-50",
      // Nested accordians
      nestedAccordionBackgroundColor: "bg-primary-1000",
      nestedAccordionIconButtonBackgroundColor: "bg-white",
      nestedButtonSuccessHoverColor: "hover:bg-green-200",
      nestedTableLoadingRowColor: "bg-gray-400",
    },
    blue: {
      bodyBackgroundColor: "bg-primary-1000",
      cardBackgroundColor: "bg-white",
      cardBackgroundColorCode: "#fff",
      activeLabelTextColor: "text-gray-700",
      disableLabelTextColor: "text-gray-600",
      accordianBackgroundColor: "bg-white",
      accordionHeaderTextColor: "text-gray-800",
      selectedTableRowColor: "bg-blue-500/30",

      //Sttings Drawer Button
      settingsButtonHoverTextColor: "hover:text-blue-600 ",
      setttingsButtonHoverBgColor: "hover:bg-blue-100",

      // Input
      inputBorderColor: "ring-gray-300",
      inputBorderColorCode: "#D1D5DB",
      inputFocusedBorderColor: "focus:ring-blue-500",
      inputFocusedBorderColorCode: "#3b82f6",
      mutedInputBackgroundColor: "bg-gray-100/70",
      mutedInputBackgroundColorCode: "#e5e7ebb3",

      // Select
      selectOptionFocusedBackgroundColor: "bg-blue-500/40",
      selectOptionHoveredBackgroundColor: "hover:bg-blue-500/40",
      selectOptionFocusedBackgroundColorCode: "#3B82F666",
      inputErrorTextColor: "text-danger-900",
      inputErrorTextColorCode: "#ff6363",
      inputErrorBorderColor: "ring-danger-900",
      labelTextColor: "text-gray-900",
      labelTextColorCode: "#111827",
      selectDefaultBackgroundColor: "text-gray-900",
      selectMultiValueBackgroundColorCode: "#E5E7EB",
      // Checkbox/RB/Toggle
      checkboxBackgroundColor: "text-blue-600",
      radioButtonFocusedBorderColor: "focus:ring-blue-700",

      // Common primary colors
      primaryTextColor: "text-blue-600",
      primaryBackgroundColor: "bg-blue-600",
      primaryBorderColor: "border-blue-600",
      primaryFocusRingColor: "focus:ring-blue-600",
      primaryTextHoverColor: "hover:text-blue-600",
      primaryTextGroupHoverColor: "group-hover:text-blue-600",
      primaryTableRowHoverColor: "hover:bg-blue-500/20",
      // Typography colors & Tab colors
      typographyTextColor: "text-gray-500",
      typographyHoverTextColor: "hover:text-gray-700",
      tabHoverBorderColor: "hover:border-gray-300",
      tabBorderBottomColor: "border-b-gray-200",
      activeTabTextColor: "text-blue-600",
      menuIconBgColor: "bg-gray-200/60",
      menuBorderColor: "hover:ring-gray-200",
      // Common success colors
      successTextColor: "text-green-600",
      successHoverTextColor: "hover:text-green-600",
      successActiveBgColor: "active:bg-green-100",
      successBorderColor: "border-green-600",
      redBorderColor: "border-red-1000",
      redTextColor: "text-red-1000",
      redLightBackgroundColor: "bg-red-100",
      greenLightBackgroundColor: "bg-green-100",
      greenTextolor: "text-green-400",

      // Submit Button => [LoadingButton]
      submitButtonBgColor: "bg-blue-600",
      submitButtonDisabledBgColor: "disabled:bg-blue-500/40",
      submitButtonHoverBgColor: "hover:bg-blue-700",
      submitButtonActiveBgColor: "active:bg-blue-800/80",
      submitButtonTextColor: "text-white",
      submitButtonDisabledTextColor: "text-white",
      submitButtonHoverTextColor: "text-white",
      // Cancel Button
      cancelButtonBorderColor: "ring-gray-300",
      cancelButtonTextColor: "text-gray-900",
      cancelButtonBackgroundColor: "bg-white",
      cancelButtonHoverTextColor: "hover:text-blue-600",
      cancelButtonActiveBgColor: "active:bg-gray-100/60",
      cancelButtonDisabledTextColor: "disabled:text-gray-200",
      // Ghost Button
      ghostButtonBorderColor: "ring-gray-200",
      ghostButtonTextColor: "text-black",
      ghostButtonBackgroundColor: "bg-white",
      ghostButtonHoverTextColor: "hover:text-blue-600",
      ghostButtonDisabledBackgroundColor: "disabled:bg-gray-200",
      ghostButtonDisabledTextColor: "disabled:text-gray-300",
      ghostButtonActiveBgColor: "active:bg-blue-500/5",
      // Primary Button => [PrimaryButton]
      primaryButtonBorderColor: "ring-blue-600",
      primaryButtonTextColor: "text-white",
      primaryButtonBackgroundColor: "bg-blue-600",
      primaryButtonHoverTextColor: "hover:text-white",
      primaryButtonHoverBackgroundColor: "hover:bg-blue-700",
      primaryButtonDisabledBackgroundColor:
        "disabled:bg-red-300 disabled:ring-blue-300",
      primaryButtonDisabledTextColor: "disabled:text-white",
      primaryButtonActiveBgColor: "active:bg-blue-800",
      // Success Button => [SuccessButton]
      successButtonBorderColor: "ring-green-600",
      successButtonTextColor: "text-white",
      successButtonBackgroundColor: "bg-green-600",
      successButtonHoverTextColor: "hover:text-white",
      successButtonHoverBackgroundColor: "hover:bg-green-700",
      successButtonDisabledBackgroundColor: "disabled:bg-green-200",
      successButtonDisabledTextColor: "disabled:text-white",
      successButtonActiveBgColor: "active:bg-green-800",
      // Delete operations Button => [DangerButton]
      dangerButtonBorderColor: "ring-danger-1000",
      dangerButtonTextColor: "text-white",
      dangerButtonBackgroundColor: "bg-danger-1000",
      dangerButtonHoverTextColor: "hover:text-white",
      dangerButtonHoverBackgroundColor: "hover:bg-danger-1100",
      dangerButtonDisabledBackgroundColor:
        "disabled:bg-danger-300 disabled:ring-danger-300",
      dangerButtonDisabledTextColor: "disabled:text-white",
      dangerButtonActiveBgColor: "active:bg-danger-1200",

      // Table Action Danger Button => [TableActionDangerButton]
      tableActionDangerButtonBorderColor: "ring-gray-200/80",
      tableActionDangerButtonTextColor: "text-danger-1000",
      tableActionDangerButtonBackgroundColor: "bg-gray-200/60",
      tableActionDangerButtonHoverTextColor: "hover:text-danger-1000",
      tableActionDangerButtonHoverBackgroundColor:
        "hover:bg-gray-200/70 hover:ring-danger-1000",
      tableActionDangerButtonDisabledBackgroundColor:
        "disabled:bg-gray-100 disabled:ring-gray-100",
      tableActionDangerButtonDisabledTextColor: "disabled:text-danger-800",
      tableActionDangerButtonActiveBgColor: "active:bg-gray-300/80",
      tableActionDangerButtonLoaderColor: "border-gray-800",
      // Table Action General Button => [TableActionGeneralButton]
      tableActionGeneralButtonBorderColor: "ring-gray-200/80",
      tableActionGeneralButtonTextColor: "text-gray-800",
      tableActionGeneralButtonBackgroundColor: "bg-gray-200/60",
      tableActionGeneralButtonHoverTextColor: "hover:text-gray-800",
      tableActionGeneralButtonHoverBackgroundColor:
        "hover:bg-gray-300/70 hover:ring-gray-300/70",
      tableActionGeneralButtonDisabledBackgroundColor:
        "disabled:bg-gray-100 disabled:ring-gray-100",
      tableActionGeneralButtonDisabledTextColor: "disabled:text-gray-500",
      tableActionGeneralButtonActiveBgColor:
        "active:bg-gray-400/45 active:ring-gray-400/45",

      // Simple Table
      tableHeaderBackgroundColor: "bg-table-header",
      tableBodyBackgroundColor: "bg-background",
      tableHeaderTextColor: "text-table-headerText",
      tableHoverBackgroundColor: "hover:bg-button-ghost",
      tableCellBorderLeft: "first:rounded-s-xl",
      tableFirstRowLoadingBgColor: "bg-gray-400",
      tableSecondRowLoadingBgColor: "bg-gray-300",
      tableSelectedRowBackgroundColor: "border-background",

      // Popover
      popoverHoverBackgroundColor: "hover:bg-gray-100",
      // Switch
      switchDisableBgColor: "bg-gray-200",
      tableMapSwitchDisableBgColor: "bg-gray-300",
      // Tooltip
      tooltipBackgroundColor: "bg-slate-800",
      // Divider classes
      dividerClasses: {
        gray100: "divide-y divide-gray-100",
        gray200: "divide-gray-200",
        gray300: "divide-y divide-gray-300",
        gray400: "divide-y divide-gray-400",
      },
      // Menu
      cardMenuBorderColor: "ring-1 ring-gray-900/5",
      menuItemHoverBackgroundColor: "hover:bg-gray-50",
      // Stepper
      fileUploadBackgroundColor: "bg-gray-100",
      theadBackgroundColor: "bg-gray-50",
      tableBorderColor: "border-gray-200",
      uploadBoxBorderColor: "border-gray-300",
      // Horizontal Line
      horizontalLineColor: "#F3F4F6",
      // List
      selectedListBackgroundColor: "bg-blue-50",
      // Nested accordians
      nestedAccordionBackgroundColor: "bg-primary-1000",
      nestedAccordionIconButtonBackgroundColor: "bg-white",
      nestedButtonSuccessHoverColor: "hover:bg-green-200",
      nestedTableLoadingRowColor: "bg-gray-400",
    },
    green: {
      bodyBackgroundColor: "bg-primary-1000",
      cardBackgroundColor: "bg-white",
      cardBackgroundColorCode: "#fff",
      activeLabelTextColor: "text-gray-700",
      disableLabelTextColor: "text-gray-600",
      accordianBackgroundColor: "bg-white",
      accordionHeaderTextColor: "text-gray-800",
      selectedTableRowColor: "bg-green-200",

      //Sttings Drawer Button
      settingsButtonHoverTextColor: "hover:text-green-700 ",
      setttingsButtonHoverBgColor: "hover:bg-green-100",

      // Input
      inputBorderColor: "ring-gray-300",
      inputBorderColorCode: "#D1D5DB",
      inputFocusedBorderColor: "focus:ring-green-600",
      inputFocusedBorderColorCode: "#16a34a",
      mutedInputBackgroundColor: "bg-gray-100/70",
      mutedInputBackgroundColorCode: "#e5e7ebb3",

      // Select
      selectOptionFocusedBackgroundColor: "bg-green-500/50",
      selectOptionHoveredBackgroundColor: "hover:bg-green-500/50",
      selectOptionFocusedBackgroundColorCode: "#22C55E80",
      inputErrorTextColor: "text-danger-900",
      inputErrorTextColorCode: "#ff6363",
      inputErrorBorderColor: "ring-danger-900",
      labelTextColor: "text-gray-900",
      labelTextColorCode: "#111827",
      selectDefaultBackgroundColor: "text-gray-900",
      selectMultiValueBackgroundColorCode: "#E5E7EB",
      // Checkbox/RB/Toggle
      checkboxBackgroundColor: "text-green-700",
      radioButtonFocusedBorderColor: "focus:ring-green-800",

      // Common primary colors
      primaryTextColor: "text-green-700",
      primaryBackgroundColor: "bg-green-700",
      primaryBorderColor: "border-green-700",
      primaryFocusRingColor: "focus:ring-green-700",
      primaryTextHoverColor: "hover:text-green-700",
      primaryTextGroupHoverColor: "group-hover:text-green-700",
      primaryTableRowHoverColor: "hover:bg-green-100",
      // Typography colors & Tab colors
      typographyTextColor: "text-gray-500",
      typographyHoverTextColor: "hover:text-gray-700",
      tabHoverBorderColor: "hover:border-gray-300",
      tabBorderBottomColor: "border-b-gray-200",
      activeTabTextColor: "text-green-700",
      menuIconBgColor: "bg-gray-200/60",
      menuBorderColor: "hover:ring-gray-200",
      // Common success colors
      successTextColor: "text-green-600",
      successHoverTextColor: "hover:text-green-600",
      successActiveBgColor: "active:bg-green-100",
      successBorderColor: "border-green-600",
      redBorderColor: "border-red-1000",
      redTextColor: "text-red-1000",
      redLightBackgroundColor: "bg-red-100",
      greenLightBackgroundColor: "bg-green-100",
      greenTextolor: "text-green-400",

      // Submit Button => [LoadingButton]
      submitButtonBgColor: "bg-green-700",
      submitButtonDisabledBgColor: "disabled:bg-green-500/50",
      submitButtonHoverBgColor: "hover:bg-green-800",
      submitButtonActiveBgColor: "active:bg-green-800/80",
      submitButtonTextColor: "text-white",
      submitButtonDisabledTextColor: "text-white",
      submitButtonHoverTextColor: "text-white",
      // Cancel Button
      cancelButtonBorderColor: "ring-gray-300",
      cancelButtonTextColor: "text-gray-900",
      cancelButtonBackgroundColor: "bg-white",
      cancelButtonHoverTextColor: "hover:text-green-700",
      cancelButtonActiveBgColor: "active:bg-gray-100/60",
      cancelButtonDisabledTextColor: "disabled:text-gray-200",
      // Ghost Button
      ghostButtonBorderColor: "ring-gray-200",
      ghostButtonTextColor: "text-black",
      ghostButtonBackgroundColor: "bg-white",
      ghostButtonHoverTextColor: "hover:text-green-700",
      ghostButtonDisabledBackgroundColor: "disabled:bg-gray-200",
      ghostButtonDisabledTextColor: "disabled:text-gray-300",
      ghostButtonActiveBgColor: "active:bg-green-500/20",
      // Primary Button => [PrimaryButton]
      primaryButtonBorderColor: "ring-green-700",
      primaryButtonTextColor: "text-white",
      primaryButtonBackgroundColor: "bg-green-700",
      primaryButtonHoverTextColor: "hover:text-white",
      primaryButtonHoverBackgroundColor: "hover:bg-green-800",
      primaryButtonDisabledBackgroundColor:
        "disabled:bg-red-300 disabled:ring-red-300",
      primaryButtonDisabledTextColor: "disabled:text-white",
      primaryButtonActiveBgColor: "active:bg-green-800",
      // Success Button => [SuccessButton]
      successButtonBorderColor: "ring-green-600",
      successButtonTextColor: "text-white",
      successButtonBackgroundColor: "bg-green-600",
      successButtonHoverTextColor: "hover:text-white",
      successButtonHoverBackgroundColor: "hover:bg-green-700",
      successButtonDisabledBackgroundColor: "disabled:bg-green-200",
      successButtonDisabledTextColor: "disabled:text-white",
      successButtonActiveBgColor: "active:bg-green-800",
      // Delete operations Button => [DangerButton]
      dangerButtonBorderColor: "ring-danger-1000",
      dangerButtonTextColor: "text-white",
      dangerButtonBackgroundColor: "bg-danger-1000",
      dangerButtonHoverTextColor: "hover:text-white",
      dangerButtonHoverBackgroundColor: "hover:bg-danger-1100",
      dangerButtonDisabledBackgroundColor:
        "disabled:bg-danger-300 disabled:ring-danger-300",
      dangerButtonDisabledTextColor: "disabled:text-white",
      dangerButtonActiveBgColor: "active:bg-danger-1200",

      // Table Action Danger Button => [TableActionDangerButton]
      tableActionDangerButtonBorderColor: "ring-gray-200/80",
      tableActionDangerButtonTextColor: "text-danger-1000",
      tableActionDangerButtonBackgroundColor: "bg-gray-200/60",
      tableActionDangerButtonHoverTextColor: "hover:text-danger-1000",
      tableActionDangerButtonHoverBackgroundColor:
        "hover:bg-gray-200/70 hover:ring-danger-1000",
      tableActionDangerButtonDisabledBackgroundColor:
        "disabled:bg-gray-100 disabled:ring-gray-100",
      tableActionDangerButtonDisabledTextColor: "disabled:text-danger-800",
      tableActionDangerButtonActiveBgColor: "active:bg-gray-300/80",
      tableActionDangerButtonLoaderColor: "border-gray-800",
      // Table Action General Button => [TableActionGeneralButton]
      tableActionGeneralButtonBorderColor: "ring-gray-200/80",
      tableActionGeneralButtonTextColor: "text-gray-800",
      tableActionGeneralButtonBackgroundColor: "bg-gray-200/60",
      tableActionGeneralButtonHoverTextColor: "hover:text-gray-800",
      tableActionGeneralButtonHoverBackgroundColor:
        "hover:bg-gray-300/70 hover:ring-gray-300/70",
      tableActionGeneralButtonDisabledBackgroundColor:
        "disabled:bg-gray-100 disabled:ring-gray-100",
      tableActionGeneralButtonDisabledTextColor: "disabled:text-gray-500",
      tableActionGeneralButtonActiveBgColor:
        "active:bg-gray-400/45 active:ring-gray-400/45",

      // Simple Table
      tableHeaderBackgroundColor: "bg-table-header",
      tableBodyBackgroundColor: "bg-background",
      tableHeaderTextColor: "text-table-headerText",
      tableHoverBackgroundColor: "hover:bg-button-ghost",
      tableCellBorderLeft: "first:rounded-s-xl",
      tableFirstRowLoadingBgColor: "bg-gray-400",
      tableSecondRowLoadingBgColor: "bg-gray-300",
      tableSelectedRowBackgroundColor: "border-background",

      // Popover
      popoverHoverBackgroundColor: "hover:bg-gray-100",
      // Switch
      switchDisableBgColor: "bg-gray-200",
      tableMapSwitchDisableBgColor: "bg-gray-300",
      // Tooltip
      tooltipBackgroundColor: "bg-slate-800",
      // Divider classes
      dividerClasses: {
        gray100: "divide-y divide-gray-100",
        gray200: "divide-gray-200",
        gray300: "divide-y divide-gray-300",
        gray400: "divide-y divide-gray-400",
      },
      // Menu
      cardMenuBorderColor: "ring-1 ring-gray-900/5",
      menuItemHoverBackgroundColor: "hover:bg-gray-50",
      // Stepper
      fileUploadBackgroundColor: "bg-gray-100",
      theadBackgroundColor: "bg-gray-50",
      tableBorderColor: "border-gray-200",
      uploadBoxBorderColor: "border-gray-300",
      // Horizontal Line
      horizontalLineColor: "#F3F4F6",
      // List
      selectedListBackgroundColor: "bg-blue-50",
      // Nested accordians
      nestedAccordionBackgroundColor: "bg-primary-1000",
      nestedAccordionIconButtonBackgroundColor: "bg-white",
      nestedButtonSuccessHoverColor: "hover:bg-green-200",
      nestedTableLoadingRowColor: "bg-gray-400",
    },
    default: {
      bodyBackgroundColor: "bg-primary-1000",
      cardBackgroundColor: "bg-white",
      cardBackgroundColorCode: "#fff",
      activeLabelTextColor: "text-gray-700",
      disableLabelTextColor: "text-gray-600",
      accordianBackgroundColor: "bg-white",
      accordionHeaderTextColor: "text-gray-800",
      selectedTableRowColor: "bg-button-ghost",

      //Sttings Drawer Button
      settingsButtonHoverTextColor: "hover:text-red-700 ",
      setttingsButtonHoverBgColor: "hover:bg-red-100",
      // Input
      inputBorderColor: "ring-gray-300",
      inputBorderColorCode: "#D1D5DB",
      inputFocusedBorderColor: "focus:ring-red-500",
      inputFocusedBorderColorCode: "#da8180",
      mutedInputBackgroundColor: "bg-gray-100/70",
      mutedInputBackgroundColorCode: "#e5e7ebb3",

      // Select
      selectOptionFocusedBackgroundColor: "bg-red-200",
      selectOptionHoveredBackgroundColor: "hover:bg-red-200",
      selectOptionFocusedBackgroundColorCode: "#f0cdcc",
      inputErrorTextColor: "text-danger-900",
      inputErrorTextColorCode: "#ff6363",
      inputErrorBorderColor: "ring-danger-900",
      labelTextColor: "text-gray-900",
      labelTextColorCode: "#111827",
      selectDefaultBackgroundColor: "text-gray-900",
      selectMultiValueBackgroundColorCode: "#E5E7EB",
      // Checkbox/RB/Toggle
      checkboxBackgroundColor: "text-red-1000",
      radioButtonFocusedBorderColor: "focus:ring-red-700",
      // Common primary colors
      primaryTextColor: "text-red-1000",
      primaryBackgroundColor: "bg-red-1000",
      primaryBorderColor: "border-red-1000",
      primaryFocusRingColor: "focus:ring-red-1000",
      primaryTextHoverColor: "hover:text-red-1000",
      primaryTextGroupHoverColor: "group-hover:text-red-1000",
      primaryTableRowHoverColor: "hover:bg-red-100",
      // Typography colors & Tab colors
      typographyTextColor: "text-gray-500",
      typographyHoverTextColor: "hover:text-gray-700",
      tabHoverBorderColor: "hover:border-gray-300",
      tabBorderBottomColor: "border-b-gray-200",
      activeTabTextColor: "text-red-1000",
      menuIconBgColor: "bg-gray-200/60",
      menuBorderColor: "hover:ring-gray-200",
      // Common success colors
      successTextColor: "text-green-600",
      successHoverTextColor: "hover:text-green-600",
      successActiveBgColor: "active:bg-green-100",
      successBorderColor: "border-green-600",
      redBorderColor: "border-red-1000",
      redTextColor: "text-red-1000",
      redLightBackgroundColor: "bg-red-100",
      greenLightBackgroundColor: "bg-green-100",
      greenTextolor: "text-green-400",

      // Submit Button => [LoadingButton]
      submitButtonBgColor: "bg-red-1000",
      submitButtonDisabledBgColor: "disabled:bg-red-200",
      submitButtonHoverBgColor: "hover:bg-red-1100",
      submitButtonActiveBgColor: "active:bg-red-1200",
      submitButtonTextColor: "text-white",
      submitButtonDisabledTextColor: "text-white",
      submitButtonHoverTextColor: "text-white",
      // Cancel Button
      cancelButtonBorderColor: "ring-gray-300",
      cancelButtonTextColor: "text-gray-900",
      cancelButtonBackgroundColor: "bg-white",
      cancelButtonHoverTextColor: "hover:text-red-1000",
      cancelButtonActiveBgColor: "active:bg-gray-100/60",
      cancelButtonDisabledTextColor: "disabled:text-gray-200",
      // Ghost Button
      ghostButtonBorderColor: "ring-gray-200",
      ghostButtonTextColor: "text-black",
      ghostButtonBackgroundColor: "bg-white",
      ghostButtonHoverTextColor: "hover:text-red-1000",
      ghostButtonDisabledBackgroundColor: "disabled:bg-gray-200",
      ghostButtonDisabledTextColor: "disabled:text-gray-300",
      ghostButtonActiveBgColor: "active:bg-red-100/60",
      // Primary Button => [PrimaryButton]
      primaryButtonBorderColor: "ring-red-1000",
      primaryButtonTextColor: "text-white",
      primaryButtonBackgroundColor: "bg-red-1000",
      primaryButtonHoverTextColor: "hover:text-white",
      primaryButtonHoverBackgroundColor: "hover:bg-red-1100",
      primaryButtonDisabledBackgroundColor:
        "disabled:bg-red-300 disabled:ring-red-300",
      primaryButtonDisabledTextColor: "disabled:text-white",
      primaryButtonActiveBgColor: "active:bg-red-1200",
      // Success Button => [SuccessButton]
      successButtonBorderColor: "ring-green-600",
      successButtonTextColor: "text-white",
      successButtonBackgroundColor: "bg-green-600",
      successButtonHoverTextColor: "hover:text-white",
      successButtonHoverBackgroundColor: "hover:bg-green-700",
      successButtonDisabledBackgroundColor: "disabled:bg-green-200",
      successButtonDisabledTextColor: "disabled:text-white",
      successButtonActiveBgColor: "active:bg-green-800",
      // Delete operations Button => [DangerButton]
      dangerButtonBorderColor: "ring-danger-1000",
      dangerButtonTextColor: "text-white",
      dangerButtonBackgroundColor: "bg-danger-1000",
      dangerButtonHoverTextColor: "hover:text-white",
      dangerButtonHoverBackgroundColor: "hover:bg-danger-1100",
      dangerButtonDisabledBackgroundColor:
        "disabled:bg-danger-300 disabled:ring-danger-300",
      dangerButtonDisabledTextColor: "disabled:text-white",
      dangerButtonActiveBgColor: "active:bg-danger-1200",

      // Table Action Danger Button => [TableActionDangerButton]
      tableActionDangerButtonBorderColor: "ring-gray-200/80",
      tableActionDangerButtonTextColor: "text-danger-1000",
      tableActionDangerButtonBackgroundColor: "bg-gray-200/60",
      tableActionDangerButtonHoverTextColor: "hover:text-danger-1000",
      tableActionDangerButtonHoverBackgroundColor:
        "hover:bg-gray-200/70 hover:ring-danger-1000",
      tableActionDangerButtonDisabledBackgroundColor:
        "disabled:bg-gray-100 disabled:ring-gray-100",
      tableActionDangerButtonDisabledTextColor: "disabled:text-danger-800",
      tableActionDangerButtonActiveBgColor: "active:bg-gray-300/80",
      tableActionDangerButtonLoaderColor: "border-gray-800",
      // Table Action General Button => [TableActionGeneralButton]
      tableActionGeneralButtonBorderColor: "ring-gray-200/80",
      tableActionGeneralButtonTextColor: "text-gray-800",
      tableActionGeneralButtonBackgroundColor: "bg-gray-200/60",
      tableActionGeneralButtonHoverTextColor: "hover:text-gray-800",
      tableActionGeneralButtonHoverBackgroundColor:
        "hover:bg-gray-300/70 hover:ring-gray-300/70",
      tableActionGeneralButtonDisabledBackgroundColor:
        "disabled:bg-gray-100 disabled:ring-gray-100",
      tableActionGeneralButtonDisabledTextColor: "disabled:text-gray-500",
      tableActionGeneralButtonActiveBgColor:
        "active:bg-gray-400/45 active:ring-gray-400/45",

      // Simple Table
      tableHeaderBackgroundColor: "bg-table-header",
      tableBodyBackgroundColor: "bg-background",
      tableHeaderTextColor: "text-table-headerText",
      tableHoverBackgroundColor: "hover:bg-button-ghost",
      tableCellBorderLeft: "first:rounded-s-xl",
      tableFirstRowLoadingBgColor: "bg-gray-400",
      tableSecondRowLoadingBgColor: "bg-gray-300",
      tableSelectedRowBackgroundColor: "border-background",

      // Popover
      popoverHoverBackgroundColor: "hover:bg-gray-100",
      // Switch
      switchDisableBgColor: "bg-gray-200",
      tableMapSwitchDisableBgColor: "bg-gray-300",
      // Tooltip
      tooltipBackgroundColor: "bg-slate-800",
      // Divider classes
      dividerClasses: {
        gray100: "divide-y divide-gray-100",
        gray200: "divide-gray-200",
        gray300: "divide-y divide-gray-300",
        gray400: "divide-y divide-gray-400",
      },
      // Menu
      cardMenuBorderColor: "ring-1 ring-gray-900/5",
      menuItemHoverBackgroundColor: "hover:bg-gray-50",
      // Stepper
      fileUploadBackgroundColor: "bg-gray-100",
      theadBackgroundColor: "bg-gray-50",
      tableBorderColor: "border-gray-200",
      uploadBoxBorderColor: "border-gray-300",
      // Horizontal Line
      horizontalLineColor: "#F3F4F6",
      // List
      selectedListBackgroundColor: "bg-blue-50",
      // Nested accordians
      nestedAccordionBackgroundColor: "bg-primary-1000",
      nestedAccordionIconButtonBackgroundColor: "bg-white",
      nestedButtonSuccessHoverColor: "hover:bg-green-200",
      nestedTableLoadingRowColor: "bg-gray-400",
    },
  },
  dark: {
    orange: {
      // Newly added colors
      bodyBackgroundColor: "bg-slate-900",
      cardBackgroundColor: "bg-slate-800",
      cardBackgroundColorCode: "#1e293b",
      activeLabelTextColor: "text-gray-200",
      disableLabelTextColor: "text-gray-400",
      accordianBackgroundColor: "bg-slate-800",
      accordionHeaderTextColor: "text-gray-100",
      selectedTableRowColor: "bg-slate-500/80",

      //Sttings Drawer Button
      settingsButtonHoverTextColor: "hover:text-orange-400",
      // Input
      inputBorderColor: "ring-gray-600",
      inputBorderColorCode: "#4a5568",
      inputFocusedBorderColor: "focus:ring-orange-500",
      inputFocusedBorderColorCode: "#f97316",
      inputBackgroundColor: "bg-slate-800",
      mutedInputBackgroundColor: "bg-gray-600/70",
      mutedInputBackgroundColorCode: "#4b5563b3",

      // Description text
      descriptionTextColor: "text-slate-400",

      // Select
      selectOptionFocusedBackgroundColor: "bg-gray-700",
      selectOptionHoveredBackgroundColor: "hover:bg-gray-700",
      selectOptionFocusedBackgroundColorCode: "#363f52",
      inputErrorTextColor: "text-red-600",
      inputErrorBorderColor: "ring-red-800",
      labelTextColor: "text-white",
      labelTextColorCode: "#fff",
      selectMultiValueBackgroundColorCode: "#374151",
      // Checkbox/RB/Toggle
      checkboxBackgroundColor: "text-orange-500",
      radioButtonFocusedBorderColor: "focus:ring-orange-500",
      // Common primary colors
      primaryTextColor: "text-white",
      primaryBackgroundColor: "bg-orange-500",
      primaryBorderColor: "border-white",
      primaryFocusRingColor: "focus:ring-orange-500",
      primaryTextHoverColor: "hover:text-white",
      primaryTextGroupHoverColor: "group-hover:text-orange-500",
      primaryTableRowHoverColor: "",
      // Typography colors & Tab colors
      typographyTextColor: "text-gray-300",
      typographyHoverTextColor: "hover:text-gray-200",
      tabHoverBorderColor: "hover:border-gray-300",
      tabBorderBottomColor: "border-b-gray-600",
      activeTabTextColor: "text-white font-extrabold",
      menuIconBgColor: "bg-gray-600",
      menuBorderColor: "hover:ring-gray-200",
      // Common success colors
      successTextColor: "text-green-600",
      successHoverTextColor: "hover:text-green-600",
      successActiveBgColor: "active:bg-green-100",
      successBorderColor: "border-green-600",
      redBorderColor: "border-red-600",
      redTextColor: "text-red-1000",
      redLightBackgroundColor: "bg-gray-800",
      greenLightBackgroundColor: "bg-slate-700",
      greenBorderColor: "border-green-400",
      // Submit Button => [LoadingButton]
      submitButtonBgColor: "bg-orange-500",
      submitButtonDisabledBgColor: "disabled:bg-gray-700",
      submitButtonHoverBgColor: "hover:bg-orange-600",
      submitButtonActiveBgColor: "active:bg-orange-600/90",
      submitButtonTextColor: "text-white",
      submitButtonDisabledTextColor: "disabled:text-gray-400",
      submitButtonHoverTextColor: "text-black",
      // Cancel Button
      cancelButtonBorderColor: "ring-gray-500",
      cancelButtonTextColor: "text-white",
      cancelButtonBackgroundColor: "bg-slate-800",
      cancelButtonHoverTextColor: "hover:text-orange-500",
      cancelButtonActiveBgColor: "active:bg-gray-700/30",
      cancelButtonDisabledTextColor: "disabled:text-gray-200",
      // Ghost Button
      ghostButtonBorderColor: "ring-gray-500",
      ghostButtonTextColor: "text-white",
      ghostButtonBackgroundColor: "bg-slate-800",
      ghostButtonHoverTextColor: "hover:text-orange-500",
      ghostButtonDisabledBackgroundColor: "disabled:bg-gray-700",
      ghostButtonDisabledTextColor: "disabled:text-gray-400",
      ghostButtonActiveBgColor: "active:bg-gray-700/30",
      // Primary Button => [PrimaryButton]
      primaryButtonBorderColor: "ring-orange-500",
      primaryButtonTextColor: "text-white",
      primaryButtonBackgroundColor: "bg-orange-500",
      primaryButtonHoverTextColor: "hover:text-white",
      primaryButtonHoverBackgroundColor: "hover:bg-orange-600",
      primaryButtonDisabledBackgroundColor:
        "disabled:bg-orange-300 disabled:ring-orange-300",
      primaryButtonDisabledTextColor: "disabled:text-white",
      primaryButtonActiveBgColor: "active:bg-orange-600/90",

      // Success Button => [SuccessButton]
      successButtonBorderColor: "ring-green-600",
      successButtonTextColor: "text-black",
      successButtonBackgroundColor: "bg-green-700",
      successButtonHoverTextColor: "hover:text-white",
      successButtonHoverBackgroundColor: "hover:bg-green-600",
      successButtonDisabledBackgroundColor: "disabled:bg-gray-600",
      successButtonDisabledTextColor: "disabled:text-gray-400",
      successButtonActiveBgColor: "active:bg-green-800",
      // Delete operations Button => [DangerButton]
      dangerButtonBorderColor: "ring-danger-1000",
      dangerButtonTextColor: "text-white",
      dangerButtonBackgroundColor: "bg-danger-1000",
      dangerButtonHoverTextColor: "hover:text-white",
      dangerButtonHoverBackgroundColor: "hover:bg-danger-1100",
      dangerButtonDisabledBackgroundColor:
        "disabled:bg-danger-300 disabled:ring-danger-300",
      dangerButtonDisabledTextColor: "disabled:text-white",
      dangerButtonActiveBgColor: "active:bg-danger-1200",

      // Table header
      tableHeaderBackgroundColor: "bg-slate-800/50",
      tableHeaderTextColor: "text-gray-50",
      tableRowHoverBorder: "hover:ring-1 hover:ring-gray-400",
      tableFirstRowLoadingBgColor: "bg-gray-700/50",
      tableSecondRowLoadingBgColor: "bg-gray-600/50",
      tableCellBorderLeft:
        "first:group-hover:border-l first:group-hover:border-gray-400",
      tableCellBorderRight: "group-hover:border-r group-hover:border-gray-400",
      // Table Action Danger Button => [TableActionDangerButton]
      tableActionDangerButtonBorderColor: "ring-red-400/20",
      tableActionDangerButtonTextColor: "hover:text-danger-1000/90",
      tableActionDangerButtonBackgroundColor: "bg-red-400/10",
      tableActionDangerButtonHoverTextColor: "text-danger-1000/90",
      tableActionDangerButtonHoverBackgroundColor: "hover:bg-gray-700/50",
      tableActionDangerButtonDisabledBackgroundColor:
        "disabled:bg-gray-100 disabled:ring-gray-100",
      tableActionDangerButtonDisabledTextColor: "disabled:text-danger-800",
      tableActionDangerButtonActiveBgColor: "active:bg-gray-600/80",
      tableActionDangerButtonLoaderColor: "border-gray-200",
      // Table Action General Button => [TableActionGeneralButton]
      tableActionGeneralButtonBorderColor: "ring-gray-500/80",
      tableActionGeneralButtonTextColor: "text-white",
      tableActionGeneralButtonBackgroundColor: "bg-gray-500",
      tableActionGeneralButtonHoverTextColor: "hover:text-gray-800",
      tableActionGeneralButtonHoverBackgroundColor:
        "hover:bg-gray-300/70 hover:ring-gray-300/70",
      tableActionGeneralButtonDisabledBackgroundColor:
        "disabled:bg-gray-500 disabled:ring-gray-400/80",
      tableActionGeneralButtonDisabledTextColor: "disabled:text-gray-200/80",
      tableActionGeneralButtonActiveBgColor:
        "active:bg-gray-400/45 active:ring-gray-400/45",

      // Simple Table
      tableBodyBackgroundColor: "bg-slate-800/50",
      tableSelectedRowBackgroundColor: "bg-gray-500",
      // Popover
      popoverHoverBackgroundColor: "hover:bg-gray-500",
      // Switch
      switchDisableBgColor: "bg-gray-500",
      tableMapSwitchDisableBgColor: "bg-gray-500",
      // Tooltip
      tooltipBackgroundColor: "bg-slate-600",
      // Divider classes
      dividerClasses: {
        gray100: " divide-gray-600",
        gray200: " divide-gray-700",
        gray300: " divide-gray-800",
        gray400: "divide-gray-900",
      },
      // Menu
      cardMenuBorderColor: "ring-1 ring-gray-700",
      menuItemHoverBackgroundColor: "hover:bg-gray-700/50",
      // Stepper
      fileUploadBackgroundColor: "bg-gray-700",
      theadBackgroundColor: "bg-gray-700",
      tableBorderColor: "border-gray-700",
      uploadBoxBorderColor: "border-gray-500",
      // Horizontal Line
      horizontalLineColor: "#4B5563",
      // Chips
      chipTextColor: "text-neutral-300",
      chipBorderColor: "ring-gray-400/20",
      chipRemoveButtonTextColor: "text-neutral-400",
      chipRemoveButtonHoverColor: "hover:text-neutral-500",

      // List
      selectedListBackgroundColor: "bg-slate-700",
      //Nested accordian
      nestedAccordionBackgroundColor: "bg-slate-700",
      nestedAccordionIconButtonBackgroundColor: "bg-gray-300",
      nestedButtonSuccessHoverColor: "hover:bg-green-700",
      nestedTableLoadingRowColor: "bg-gray-600",
    },
    cyan: {
      // Newly added colors
      bodyBackgroundColor: "bg-slate-900",
      cardBackgroundColor: "bg-slate-800",
      cardBackgroundColorCode: "#1e293b",
      activeLabelTextColor: "text-gray-200",
      disableLabelTextColor: "text-gray-400",
      accordianBackgroundColor: "bg-slate-800",
      accordionHeaderTextColor: "text-gray-100",
      selectedTableRowColor: "bg-slate-500/80",

      //Sttings Drawer Button
      settingsButtonHoverTextColor: "hover:text-cyan-400",
      // Input
      inputBorderColor: "ring-gray-600",
      inputBorderColorCode: "#4a5568",
      inputFocusedBorderColor: "focus:ring-cyan-500",
      inputFocusedBorderColorCode: "#06b6d4",
      inputBackgroundColor: "bg-slate-800",
      mutedInputBackgroundColor: "bg-gray-600/70",
      mutedInputBackgroundColorCode: "#4b5563b3",

      // Description text
      descriptionTextColor: "text-slate-400",

      // Select
      selectOptionFocusedBackgroundColor: "bg-gray-700",
      selectOptionHoveredBackgroundColor: "hover:bg-gray-700",
      selectOptionFocusedBackgroundColorCode: "#363f52",
      inputErrorTextColor: "text-red-600",
      inputErrorBorderColor: "ring-red-800",
      labelTextColor: "text-white",
      labelTextColorCode: "#fff",
      selectMultiValueBackgroundColorCode: "#374151",
      // Checkbox/RB/Toggle
      checkboxBackgroundColor: "text-cyan-500",
      radioButtonFocusedBorderColor: "focus:ring-cyan-500",
      // Common primary colors
      primaryTextColor: "text-white",
      primaryBackgroundColor: "bg-cyan-500",
      primaryBorderColor: "border-white",
      primaryFocusRingColor: "focus:ring-cyan-500",
      primaryTextHoverColor: "hover:text-white",
      primaryTextGroupHoverColor: "group-hover:text-cyan-500",
      primaryTableRowHoverColor: "",
      // Typography colors & Tab colors
      typographyTextColor: "text-gray-300",
      typographyHoverTextColor: "hover:text-gray-200",
      tabHoverBorderColor: "hover:border-gray-300",
      tabBorderBottomColor: "border-b-gray-600",
      activeTabTextColor: "text-white font-extrabold",
      menuIconBgColor: "bg-gray-600",
      menuBorderColor: "hover:ring-gray-200",
      // Common success colors
      successTextColor: "text-green-600",
      successHoverTextColor: "hover:text-green-600",
      successActiveBgColor: "active:bg-green-100",
      successBorderColor: "border-green-600",
      redBorderColor: "border-red-600",
      redTextColor: "text-red-1000",
      redLightBackgroundColor: "bg-gray-800",
      greenLightBackgroundColor: "bg-slate-700",
      greenBorderColor: "border-green-400",
      // Submit Button => [LoadingButton]
      submitButtonBgColor: "bg-cyan-600",
      submitButtonDisabledBgColor: "disabled:bg-gray-700",
      submitButtonHoverBgColor: "hover:bg-cyan-700",
      submitButtonActiveBgColor: "active:bg-cyan-700/90",
      submitButtonTextColor: "text-white",
      submitButtonDisabledTextColor: "disabled:text-gray-400",
      submitButtonHoverTextColor: "text-black",
      // Cancel Button
      cancelButtonBorderColor: "ring-gray-500",
      cancelButtonTextColor: "text-white",
      cancelButtonBackgroundColor: "bg-slate-800",
      cancelButtonHoverTextColor: "hover:text-cyan-500",
      cancelButtonActiveBgColor: "active:bg-gray-700/30",
      cancelButtonDisabledTextColor: "disabled:text-gray-200",
      // Ghost Button
      ghostButtonBorderColor: "ring-gray-500",
      ghostButtonTextColor: "text-white",
      ghostButtonBackgroundColor: "bg-slate-800",
      ghostButtonHoverTextColor: "hover:text-cyan-500",
      ghostButtonDisabledBackgroundColor: "disabled:bg-gray-700",
      ghostButtonDisabledTextColor: "disabled:text-gray-400",
      ghostButtonActiveBgColor: "active:bg-gray-700/30",
      // Primary Button => [PrimaryButton]
      primaryButtonBorderColor: "ring-cyan-600",
      primaryButtonTextColor: "text-white",
      primaryButtonBackgroundColor: "bg-cyan-600",
      primaryButtonHoverTextColor: "hover:text-white",
      primaryButtonHoverBackgroundColor: "hover:bg-cyan-700",
      primaryButtonDisabledBackgroundColor:
        "disabled:bg-cyan-300 disabled:ring-cyan-300",
      primaryButtonDisabledTextColor: "disabled:text-white",
      primaryButtonActiveBgColor: "active:bg-cyan-600/90",

      // Success Button => [SuccessButton]
      successButtonBorderColor: "ring-green-600",
      successButtonTextColor: "text-black",
      successButtonBackgroundColor: "bg-green-700",
      successButtonHoverTextColor: "hover:text-white",
      successButtonHoverBackgroundColor: "hover:bg-green-600",
      successButtonDisabledBackgroundColor: "disabled:bg-gray-600",
      successButtonDisabledTextColor: "disabled:text-gray-400",
      successButtonActiveBgColor: "active:bg-green-800",
      // Delete operations Button => [DangerButton]
      dangerButtonBorderColor: "ring-danger-1000",
      dangerButtonTextColor: "text-white",
      dangerButtonBackgroundColor: "bg-danger-1000",
      dangerButtonHoverTextColor: "hover:text-white",
      dangerButtonHoverBackgroundColor: "hover:bg-danger-1100",
      dangerButtonDisabledBackgroundColor:
        "disabled:bg-danger-300 disabled:ring-danger-300",
      dangerButtonDisabledTextColor: "disabled:text-white",
      dangerButtonActiveBgColor: "active:bg-danger-1200",

      // Table header
      tableHeaderBackgroundColor: "bg-slate-800/50",
      tableHeaderTextColor: "text-gray-50",
      tableRowHoverBorder: "hover:ring-1 hover:ring-gray-400",
      tableFirstRowLoadingBgColor: "bg-gray-700/50",
      tableSecondRowLoadingBgColor: "bg-gray-600/50",
      tableCellBorderLeft:
        "first:group-hover:border-l first:group-hover:border-gray-400",
      tableCellBorderRight: "group-hover:border-r group-hover:border-gray-400",
      // Table Action Danger Button => [TableActionDangerButton]
      tableActionDangerButtonBorderColor: "ring-red-400/20",
      tableActionDangerButtonTextColor: "hover:text-danger-1000/90",
      tableActionDangerButtonBackgroundColor: "bg-red-400/10",
      tableActionDangerButtonHoverTextColor: "text-danger-1000/90",
      tableActionDangerButtonHoverBackgroundColor: "hover:bg-gray-700/50",
      tableActionDangerButtonDisabledBackgroundColor:
        "disabled:bg-gray-100 disabled:ring-gray-100",
      tableActionDangerButtonDisabledTextColor: "disabled:text-danger-800",
      tableActionDangerButtonActiveBgColor: "active:bg-gray-600/80",
      tableActionDangerButtonLoaderColor: "border-gray-200",
      // Table Action General Button => [TableActionGeneralButton]
      tableActionGeneralButtonBorderColor: "ring-gray-500/80",
      tableActionGeneralButtonTextColor: "text-white",
      tableActionGeneralButtonBackgroundColor: "bg-gray-500",
      tableActionGeneralButtonHoverTextColor: "hover:text-gray-800",
      tableActionGeneralButtonHoverBackgroundColor:
        "hover:bg-gray-300/70 hover:ring-gray-300/70",
      tableActionGeneralButtonDisabledBackgroundColor:
        "disabled:bg-gray-500 disabled:ring-gray-400/80",
      tableActionGeneralButtonDisabledTextColor: "disabled:text-gray-200/80",
      tableActionGeneralButtonActiveBgColor:
        "active:bg-gray-400/45 active:ring-gray-400/45",

      // Simple Table
      tableBodyBackgroundColor: "bg-slate-800/50",
      tableSelectedRowBackgroundColor: "bg-gray-500",
      // Popover
      popoverHoverBackgroundColor: "hover:bg-gray-500",
      // Switch
      switchDisableBgColor: "bg-gray-500",
      tableMapSwitchDisableBgColor: "bg-gray-500",
      // Tooltip
      tooltipBackgroundColor: "bg-slate-600",
      // Divider classes
      dividerClasses: {
        gray100: " divide-gray-600",
        gray200: " divide-gray-700",
        gray300: " divide-gray-800",
        gray400: "divide-gray-900",
      },
      // Menu
      cardMenuBorderColor: "ring-1 ring-gray-700",
      menuItemHoverBackgroundColor: "hover:bg-gray-700/50",
      // Stepper
      fileUploadBackgroundColor: "bg-gray-700",
      theadBackgroundColor: "bg-gray-700",
      tableBorderColor: "border-gray-700",
      uploadBoxBorderColor: "border-gray-500",
      // Horizontal Line
      horizontalLineColor: "#4B5563",
      // Chips
      chipTextColor: "text-neutral-300",
      chipBorderColor: "ring-gray-400/20",
      chipRemoveButtonTextColor: "text-neutral-400",
      chipRemoveButtonHoverColor: "hover:text-neutral-500",

      // List
      selectedListBackgroundColor: "bg-slate-700",
      //Nested accordian
      nestedAccordionBackgroundColor: "bg-slate-700",
      nestedAccordionIconButtonBackgroundColor: "bg-gray-300",
      nestedButtonSuccessHoverColor: "hover:bg-green-700",
      nestedTableLoadingRowColor: "bg-gray-600",
    },
    teal: {
      // Newly added colors
      bodyBackgroundColor: "bg-slate-900",
      cardBackgroundColor: "bg-slate-800",
      cardBackgroundColorCode: "#1e293b",
      activeLabelTextColor: "text-gray-200",
      disableLabelTextColor: "text-gray-400",
      accordianBackgroundColor: "bg-slate-800",
      accordionHeaderTextColor: "text-gray-100",
      selectedTableRowColor: "bg-slate-500/80",

      //Sttings Drawer Button
      settingsButtonHoverTextColor: "hover:text-teal-700",
      // Input
      inputBorderColor: "ring-gray-600",
      inputBorderColorCode: "#4a5568",
      inputFocusedBorderColor: "focus:ring-teal-700",
      inputFocusedBorderColorCode: "#0f766e",
      inputBackgroundColor: "bg-slate-800",
      mutedInputBackgroundColor: "bg-gray-600/70",
      mutedInputBackgroundColorCode: "#4b5563b3",

      // Description text
      descriptionTextColor: "text-slate-400",

      // Select
      selectOptionFocusedBackgroundColor: "bg-gray-700",
      selectOptionHoveredBackgroundColor: "hover:bg-gray-700",
      selectOptionFocusedBackgroundColorCode: "#363f52",
      inputErrorTextColor: "text-red-600",
      inputErrorBorderColor: "ring-red-800",
      labelTextColor: "text-white",
      labelTextColorCode: "#fff",
      selectMultiValueBackgroundColorCode: "#374151",
      // Checkbox/RB/Toggle
      checkboxBackgroundColor: "text-teal-600",
      radioButtonFocusedBorderColor: "focus:ring-teal-600",
      // Common primary colors
      primaryTextColor: "text-white",
      primaryBackgroundColor: "bg-teal-600",
      primaryBorderColor: "border-white",
      primaryFocusRingColor: "focus:ring-teal-600",
      primaryTextHoverColor: "hover:text-white",
      primaryTextGroupHoverColor: "group-hover:text-teal-600",
      primaryTableRowHoverColor: "",
      // Typography colors & Tab colors
      typographyTextColor: "text-gray-300",
      typographyHoverTextColor: "hover:text-gray-200",
      tabHoverBorderColor: "hover:border-gray-300",
      tabBorderBottomColor: "border-b-gray-600",
      activeTabTextColor: "text-white font-extrabold",
      menuIconBgColor: "bg-gray-600",
      menuBorderColor: "hover:ring-gray-200",
      // Common success colors
      successTextColor: "text-green-600",
      successHoverTextColor: "hover:text-green-600",
      successActiveBgColor: "active:bg-green-100",
      successBorderColor: "border-green-600",
      redBorderColor: "border-red-600",
      redTextColor: "text-red-1000",
      redLightBackgroundColor: "bg-gray-800",
      greenLightBackgroundColor: "bg-slate-700",
      greenBorderColor: "border-green-400",
      // Submit Button => [LoadingButton]
      submitButtonBgColor: "bg-teal-700",
      submitButtonDisabledBgColor: "disabled:bg-gray-700",
      submitButtonHoverBgColor: "hover:bg-teal-800",
      submitButtonActiveBgColor: "active:bg-teal-900/90",
      submitButtonTextColor: "text-white",
      submitButtonDisabledTextColor: "disabled:text-gray-400",
      submitButtonHoverTextColor: "text-black",
      // Cancel Button
      cancelButtonBorderColor: "ring-gray-500",
      cancelButtonTextColor: "text-white",
      cancelButtonBackgroundColor: "bg-slate-800",
      cancelButtonHoverTextColor: "hover:text-teal-600",
      cancelButtonActiveBgColor: "active:bg-gray-700/30",
      cancelButtonDisabledTextColor: "disabled:text-gray-200",
      // Ghost Button
      ghostButtonBorderColor: "ring-gray-500",
      ghostButtonTextColor: "text-white",
      ghostButtonBackgroundColor: "bg-slate-800",
      ghostButtonHoverTextColor: "hover:text-teal-600",
      ghostButtonDisabledBackgroundColor: "disabled:bg-gray-700",
      ghostButtonDisabledTextColor: "disabled:text-gray-400",
      ghostButtonActiveBgColor: "active:bg-gray-700/30",
      // Primary Button => [PrimaryButton]
      primaryButtonBorderColor: "ring-teal-700",
      primaryButtonTextColor: "text-white",
      primaryButtonBackgroundColor: "bg-teal-700",
      primaryButtonHoverTextColor: "hover:text-white",
      primaryButtonHoverBackgroundColor: "hover:bg-teal-800",
      primaryButtonDisabledBackgroundColor:
        "disabled:bg-teal-300 disabled:ring-teal-300",
      primaryButtonDisabledTextColor: "disabled:text-white",
      primaryButtonActiveBgColor: "active:bg-teal-800/90",

      // Success Button => [SuccessButton]
      successButtonBorderColor: "ring-green-600",
      successButtonTextColor: "text-black",
      successButtonBackgroundColor: "bg-green-700",
      successButtonHoverTextColor: "hover:text-white",
      successButtonHoverBackgroundColor: "hover:bg-green-600",
      successButtonDisabledBackgroundColor: "disabled:bg-gray-600",
      successButtonDisabledTextColor: "disabled:text-gray-400",
      successButtonActiveBgColor: "active:bg-green-800",
      // Delete operations Button => [DangerButton]
      dangerButtonBorderColor: "ring-danger-1000",
      dangerButtonTextColor: "text-white",
      dangerButtonBackgroundColor: "bg-danger-1000",
      dangerButtonHoverTextColor: "hover:text-white",
      dangerButtonHoverBackgroundColor: "hover:bg-danger-1100",
      dangerButtonDisabledBackgroundColor:
        "disabled:bg-danger-300 disabled:ring-danger-300",
      dangerButtonDisabledTextColor: "disabled:text-white",
      dangerButtonActiveBgColor: "active:bg-danger-1200",

      // Table header
      tableHeaderBackgroundColor: "bg-slate-800/50",
      tableHeaderTextColor: "text-gray-50",
      tableRowHoverBorder: "hover:ring-1 hover:ring-gray-400",
      tableFirstRowLoadingBgColor: "bg-gray-700/50",
      tableSecondRowLoadingBgColor: "bg-gray-600/50",
      tableCellBorderLeft:
        "first:group-hover:border-l first:group-hover:border-gray-400",
      tableCellBorderRight: "group-hover:border-r group-hover:border-gray-400",
      // Table Action Danger Button => [TableActionDangerButton]
      tableActionDangerButtonBorderColor: "ring-red-400/20",
      tableActionDangerButtonTextColor: "hover:text-danger-1000/90",
      tableActionDangerButtonBackgroundColor: "bg-red-400/10",
      tableActionDangerButtonHoverTextColor: "text-danger-1000/90",
      tableActionDangerButtonHoverBackgroundColor: "hover:bg-gray-700/50",
      tableActionDangerButtonDisabledBackgroundColor:
        "disabled:bg-gray-100 disabled:ring-gray-100",
      tableActionDangerButtonDisabledTextColor: "disabled:text-danger-800",
      tableActionDangerButtonActiveBgColor: "active:bg-gray-600/80",
      tableActionDangerButtonLoaderColor: "border-gray-200",
      // Table Action General Button => [TableActionGeneralButton]
      tableActionGeneralButtonBorderColor: "ring-gray-500/80",
      tableActionGeneralButtonTextColor: "text-white",
      tableActionGeneralButtonBackgroundColor: "bg-gray-500",
      tableActionGeneralButtonHoverTextColor: "hover:text-gray-800",
      tableActionGeneralButtonHoverBackgroundColor:
        "hover:bg-gray-300/70 hover:ring-gray-300/70",
      tableActionGeneralButtonDisabledBackgroundColor:
        "disabled:bg-gray-500 disabled:ring-gray-400/80",
      tableActionGeneralButtonDisabledTextColor: "disabled:text-gray-200/80",
      tableActionGeneralButtonActiveBgColor:
        "active:bg-gray-400/45 active:ring-gray-400/45",

      // Simple Table
      tableBodyBackgroundColor: "bg-slate-800/50",
      tableSelectedRowBackgroundColor: "bg-gray-500",
      // Popover
      popoverHoverBackgroundColor: "hover:bg-gray-500",
      // Switch
      switchDisableBgColor: "bg-gray-500",
      tableMapSwitchDisableBgColor: "bg-gray-500",
      // Tooltip
      tooltipBackgroundColor: "bg-slate-600",
      // Divider classes
      dividerClasses: {
        gray100: " divide-gray-600",
        gray200: " divide-gray-700",
        gray300: " divide-gray-800",
        gray400: "divide-gray-900",
      },
      // Menu
      cardMenuBorderColor: "ring-1 ring-gray-700",
      menuItemHoverBackgroundColor: "hover:bg-gray-700/50",
      // Stepper
      fileUploadBackgroundColor: "bg-gray-700",
      theadBackgroundColor: "bg-gray-700",
      tableBorderColor: "border-gray-700",
      uploadBoxBorderColor: "border-gray-500",
      // Horizontal Line
      horizontalLineColor: "#4B5563",
      // Chips
      chipTextColor: "text-neutral-300",
      chipBorderColor: "ring-gray-400/20",
      chipRemoveButtonTextColor: "text-neutral-400",
      chipRemoveButtonHoverColor: "hover:text-neutral-500",

      // List
      selectedListBackgroundColor: "bg-slate-700",
      //Nested accordian
      nestedAccordionBackgroundColor: "bg-slate-700",
      nestedAccordionIconButtonBackgroundColor: "bg-gray-300",
      nestedButtonSuccessHoverColor: "hover:bg-green-700",
      nestedTableLoadingRowColor: "bg-gray-600",
    },
    blue: {
      // Newly added colors
      bodyBackgroundColor: "bg-slate-900",
      cardBackgroundColor: "bg-slate-800",
      cardBackgroundColorCode: "#1e293b",
      activeLabelTextColor: "text-gray-200",
      disableLabelTextColor: "text-gray-400",
      accordianBackgroundColor: "bg-slate-800",
      accordionHeaderTextColor: "text-gray-100",
      selectedTableRowColor: "bg-slate-500/80",

      //Sttings Drawer Button
      settingsButtonHoverTextColor: "hover:text-blue-600",
      // Input
      inputBorderColor: "ring-gray-600",
      inputBorderColorCode: "#4a5568",
      inputFocusedBorderColor: "focus:ring-blue-700",
      inputFocusedBorderColorCode: "#1d4ed8",
      inputBackgroundColor: "bg-slate-800",
      mutedInputBackgroundColor: "bg-gray-600/70",
      mutedInputBackgroundColorCode: "#4b5563b3",

      // Description text
      descriptionTextColor: "text-slate-400",

      // Select
      selectOptionFocusedBackgroundColor: "bg-gray-700",
      selectOptionHoveredBackgroundColor: "hover:bg-gray-700",
      selectOptionFocusedBackgroundColorCode: "#363f52",
      inputErrorTextColor: "text-red-600",
      inputErrorBorderColor: "ring-red-800",
      labelTextColor: "text-white",
      labelTextColorCode: "#fff",
      selectMultiValueBackgroundColorCode: "#374151",
      // Checkbox/RB/Toggle
      checkboxBackgroundColor: "text-blue-600",
      radioButtonFocusedBorderColor: "focus:ring-blue-600",
      // Common primary colors
      primaryTextColor: "text-white",
      primaryBackgroundColor: "bg-blue-500",
      primaryBorderColor: "border-white",
      primaryFocusRingColor: "focus:ring-blue-500",
      primaryTextHoverColor: "hover:text-white",
      primaryTextGroupHoverColor: "group-hover:text-blue-500",
      primaryTableRowHoverColor: "",
      // Typography colors & Tab colors
      typographyTextColor: "text-gray-300",
      typographyHoverTextColor: "hover:text-gray-200",
      tabHoverBorderColor: "hover:border-gray-300",
      tabBorderBottomColor: "border-b-gray-600",
      activeTabTextColor: "text-white font-extrabold",
      menuIconBgColor: "bg-gray-600",
      menuBorderColor: "hover:ring-gray-200",
      // Common success colors
      successTextColor: "text-green-600",
      successHoverTextColor: "hover:text-green-600",
      successActiveBgColor: "active:bg-green-100",
      successBorderColor: "border-green-600",
      redBorderColor: "border-red-600",
      redTextColor: "text-red-1000",
      redLightBackgroundColor: "bg-gray-800",
      greenLightBackgroundColor: "bg-slate-700",
      greenBorderColor: "border-green-400",
      // Submit Button => [LoadingButton]
      submitButtonBgColor: "bg-blue-700",
      submitButtonDisabledBgColor: "disabled:bg-gray-700",
      submitButtonHoverBgColor: "hover:bg-blue-800",
      submitButtonActiveBgColor: "active:bg-blue-800/90",
      submitButtonTextColor: "text-white",
      submitButtonDisabledTextColor: "disabled:text-gray-400",
      submitButtonHoverTextColor: "text-black",
      // Cancel Button
      cancelButtonBorderColor: "ring-gray-500",
      cancelButtonTextColor: "text-white",
      cancelButtonBackgroundColor: "bg-slate-800",
      cancelButtonHoverTextColor: "hover:text-blue-600",
      cancelButtonActiveBgColor: "active:bg-gray-700/30",
      cancelButtonDisabledTextColor: "disabled:text-gray-200",
      // Ghost Button
      ghostButtonBorderColor: "ring-gray-500",
      ghostButtonTextColor: "text-white",
      ghostButtonBackgroundColor: "bg-slate-800",
      ghostButtonHoverTextColor: "hover:text-blue-600",
      ghostButtonDisabledBackgroundColor: "disabled:bg-gray-700",
      ghostButtonDisabledTextColor: "disabled:text-gray-400",
      ghostButtonActiveBgColor: "active:bg-gray-700/30",
      // Primary Button => [PrimaryButton]
      primaryButtonBorderColor: "ring-blue-700",
      primaryButtonTextColor: "text-white",
      primaryButtonBackgroundColor: "bg-blue-700",
      primaryButtonHoverTextColor: "hover:text-white",
      primaryButtonHoverBackgroundColor: "hover:bg-blue-800",
      primaryButtonDisabledBackgroundColor:
        "disabled:bg-blue-300 disabled:ring-blue-300",
      primaryButtonDisabledTextColor: "disabled:text-white",
      primaryButtonActiveBgColor: "active:bg-blue-800/90",

      // Success Button => [SuccessButton]
      successButtonBorderColor: "ring-green-600",
      successButtonTextColor: "text-black",
      successButtonBackgroundColor: "bg-green-700",
      successButtonHoverTextColor: "hover:text-white",
      successButtonHoverBackgroundColor: "hover:bg-green-600",
      successButtonDisabledBackgroundColor: "disabled:bg-gray-600",
      successButtonDisabledTextColor: "disabled:text-gray-400",
      successButtonActiveBgColor: "active:bg-green-800",
      // Delete operations Button => [DangerButton]
      dangerButtonBorderColor: "ring-danger-1000",
      dangerButtonTextColor: "text-white",
      dangerButtonBackgroundColor: "bg-danger-1000",
      dangerButtonHoverTextColor: "hover:text-white",
      dangerButtonHoverBackgroundColor: "hover:bg-danger-1100",
      dangerButtonDisabledBackgroundColor:
        "disabled:bg-danger-300 disabled:ring-danger-300",
      dangerButtonDisabledTextColor: "disabled:text-white",
      dangerButtonActiveBgColor: "active:bg-danger-1200",

      // Table header
      tableHeaderBackgroundColor: "bg-slate-800/50",
      tableHeaderTextColor: "text-gray-50",
      tableRowHoverBorder: "hover:ring-1 hover:ring-gray-400",
      tableFirstRowLoadingBgColor: "bg-gray-700/50",
      tableSecondRowLoadingBgColor: "bg-gray-600/50",
      tableCellBorderLeft:
        "first:group-hover:border-l first:group-hover:border-gray-400",
      tableCellBorderRight: "group-hover:border-r group-hover:border-gray-400",
      // Table Action Danger Button => [TableActionDangerButton]
      tableActionDangerButtonBorderColor: "ring-red-400/20",
      tableActionDangerButtonTextColor: "hover:text-danger-1000/90",
      tableActionDangerButtonBackgroundColor: "bg-red-400/10",
      tableActionDangerButtonHoverTextColor: "text-danger-1000/90",
      tableActionDangerButtonHoverBackgroundColor: "hover:bg-gray-700/50",
      tableActionDangerButtonDisabledBackgroundColor:
        "disabled:bg-gray-100 disabled:ring-gray-100",
      tableActionDangerButtonDisabledTextColor: "disabled:text-danger-800",
      tableActionDangerButtonActiveBgColor: "active:bg-gray-600/80",
      tableActionDangerButtonLoaderColor: "border-gray-200",
      // Table Action General Button => [TableActionGeneralButton]
      tableActionGeneralButtonBorderColor: "ring-gray-500/80",
      tableActionGeneralButtonTextColor: "text-white",
      tableActionGeneralButtonBackgroundColor: "bg-gray-500",
      tableActionGeneralButtonHoverTextColor: "hover:text-gray-800",
      tableActionGeneralButtonHoverBackgroundColor:
        "hover:bg-gray-300/70 hover:ring-gray-300/70",
      tableActionGeneralButtonDisabledBackgroundColor:
        "disabled:bg-gray-500 disabled:ring-gray-400/80",
      tableActionGeneralButtonDisabledTextColor: "disabled:text-gray-200/80",
      tableActionGeneralButtonActiveBgColor:
        "active:bg-gray-400/45 active:ring-gray-400/45",

      // Simple Table
      tableBodyBackgroundColor: "bg-slate-800/50",
      tableSelectedRowBackgroundColor: "bg-gray-500",
      // Popover
      popoverHoverBackgroundColor: "hover:bg-gray-500",
      // Switch
      switchDisableBgColor: "bg-gray-500",
      tableMapSwitchDisableBgColor: "bg-gray-500",
      // Tooltip
      tooltipBackgroundColor: "bg-slate-600",
      // Divider classes
      dividerClasses: {
        gray100: " divide-gray-600",
        gray200: " divide-gray-700",
        gray300: " divide-gray-800",
        gray400: "divide-gray-900",
      },
      // Menu
      cardMenuBorderColor: "ring-1 ring-gray-700",
      menuItemHoverBackgroundColor: "hover:bg-gray-700/50",
      // Stepper
      fileUploadBackgroundColor: "bg-gray-700",
      theadBackgroundColor: "bg-gray-700",
      tableBorderColor: "border-gray-700",
      uploadBoxBorderColor: "border-gray-500",
      // Horizontal Line
      horizontalLineColor: "#4B5563",
      // Chips
      chipTextColor: "text-neutral-300",
      chipBorderColor: "ring-gray-400/20",
      chipRemoveButtonTextColor: "text-neutral-400",
      chipRemoveButtonHoverColor: "hover:text-neutral-500",

      // List
      selectedListBackgroundColor: "bg-slate-700",
      //Nested accordian
      nestedAccordionBackgroundColor: "bg-slate-700",
      nestedAccordionIconButtonBackgroundColor: "bg-gray-300",
      nestedButtonSuccessHoverColor: "hover:bg-green-700",
      nestedTableLoadingRowColor: "bg-gray-600",
    },
    green: {
      // Newly added colors
      bodyBackgroundColor: "bg-slate-900",
      cardBackgroundColor: "bg-slate-800",
      cardBackgroundColorCode: "#1e293b",
      activeLabelTextColor: "text-gray-200",
      disableLabelTextColor: "text-gray-400",
      accordianBackgroundColor: "bg-slate-800",
      accordionHeaderTextColor: "text-gray-100",
      selectedTableRowColor: "bg-slate-500/80",

      //Sttings Drawer Button
      settingsButtonHoverTextColor: "hover:text-green-700",
      // Input
      inputBorderColor: "ring-gray-600",
      inputBorderColorCode: "#4a5568",
      inputFocusedBorderColor: "focus:ring-green-700",
      inputFocusedBorderColorCode: "#15803d",
      inputBackgroundColor: "bg-slate-800",
      mutedInputBackgroundColor: "bg-gray-600/70",
      mutedInputBackgroundColorCode: "#4b5563b3",

      // Description text
      descriptionTextColor: "text-slate-400",

      // Select
      selectOptionFocusedBackgroundColor: "bg-gray-700",
      selectOptionHoveredBackgroundColor: "hover:bg-gray-700",
      selectOptionFocusedBackgroundColorCode: "#363f52",
      inputErrorTextColor: "text-red-600",
      inputErrorBorderColor: "ring-red-800",
      labelTextColor: "text-white",
      labelTextColorCode: "#fff",
      selectMultiValueBackgroundColorCode: "#374151",
      // Checkbox/RB/Toggle
      checkboxBackgroundColor: "text-green-700",
      radioButtonFocusedBorderColor: "focus:ring-green-700",
      // Common primary colors
      primaryTextColor: "text-white",
      primaryBackgroundColor: "bg-green-700",
      primaryBorderColor: "border-white",
      primaryFocusRingColor: "focus:ring-green-700",
      primaryTextHoverColor: "hover:text-white",
      primaryTextGroupHoverColor: "group-hover:text-green-700",
      primaryTableRowHoverColor: "",
      // Typography colors & Tab colors
      typographyTextColor: "text-gray-300",
      typographyHoverTextColor: "hover:text-gray-200",
      tabHoverBorderColor: "hover:border-gray-300",
      tabBorderBottomColor: "border-b-gray-600",
      activeTabTextColor: "text-white font-extrabold",
      menuIconBgColor: "bg-gray-600",
      menuBorderColor: "hover:ring-gray-200",
      // Common success colors
      successTextColor: "text-green-600",
      successHoverTextColor: "hover:text-green-600",
      successActiveBgColor: "active:bg-green-100",
      successBorderColor: "border-green-600",
      redBorderColor: "border-red-600",
      redTextColor: "text-red-1000",
      redLightBackgroundColor: "bg-gray-800",
      greenLightBackgroundColor: "bg-slate-700",
      greenBorderColor: "border-green-400",
      // Submit Button => [LoadingButton]
      submitButtonBgColor: "bg-green-800",
      submitButtonDisabledBgColor: "disabled:bg-gray-700",
      submitButtonHoverBgColor: "hover:bg-green-900",
      submitButtonActiveBgColor: "active:bg-green-900/90",
      submitButtonTextColor: "text-white",
      submitButtonDisabledTextColor: "disabled:text-gray-400",
      submitButtonHoverTextColor: "text-black",
      // Cancel Button
      cancelButtonBorderColor: "ring-gray-500",
      cancelButtonTextColor: "text-white",
      cancelButtonBackgroundColor: "bg-slate-800",
      cancelButtonHoverTextColor: "hover:text-green-600",
      cancelButtonActiveBgColor: "active:bg-gray-700/30",
      cancelButtonDisabledTextColor: "disabled:text-gray-200",
      // Ghost Button
      ghostButtonBorderColor: "ring-gray-500",
      ghostButtonTextColor: "text-white",
      ghostButtonBackgroundColor: "bg-slate-800",
      ghostButtonHoverTextColor: "hover:text-green-600",
      ghostButtonDisabledBackgroundColor: "disabled:bg-gray-700",
      ghostButtonDisabledTextColor: "disabled:text-gray-400",
      ghostButtonActiveBgColor: "active:bg-gray-700/30",
      // Primary Button => [PrimaryButton]
      primaryButtonBorderColor: "ring-green-800",
      primaryButtonTextColor: "text-white",
      primaryButtonBackgroundColor: "bg-green-700",
      primaryButtonHoverTextColor: "hover:text-white",
      primaryButtonHoverBackgroundColor: "hover:bg-green-900",
      primaryButtonDisabledBackgroundColor:
        "disabled:bg-green-300 disabled:ring-green-300",
      primaryButtonDisabledTextColor: "disabled:text-white",
      primaryButtonActiveBgColor: "active:bg-green-900/90",

      // Success Button => [SuccessButton]
      successButtonBorderColor: "ring-green-600",
      successButtonTextColor: "text-black",
      successButtonBackgroundColor: "bg-green-700",
      successButtonHoverTextColor: "hover:text-white",
      successButtonHoverBackgroundColor: "hover:bg-green-600",
      successButtonDisabledBackgroundColor: "disabled:bg-gray-600",
      successButtonDisabledTextColor: "disabled:text-gray-400",
      successButtonActiveBgColor: "active:bg-green-800",
      // Delete operations Button => [DangerButton]
      dangerButtonBorderColor: "ring-danger-1000",
      dangerButtonTextColor: "text-white",
      dangerButtonBackgroundColor: "bg-danger-1000",
      dangerButtonHoverTextColor: "hover:text-white",
      dangerButtonHoverBackgroundColor: "hover:bg-danger-1100",
      dangerButtonDisabledBackgroundColor:
        "disabled:bg-danger-300 disabled:ring-danger-300",
      dangerButtonDisabledTextColor: "disabled:text-white",
      dangerButtonActiveBgColor: "active:bg-danger-1200",

      // Table header
      tableHeaderBackgroundColor: "bg-slate-800/50",
      tableHeaderTextColor: "text-gray-50",
      tableRowHoverBorder: "hover:ring-1 hover:ring-gray-400",
      tableFirstRowLoadingBgColor: "bg-gray-700/50",
      tableSecondRowLoadingBgColor: "bg-gray-600/50",
      tableCellBorderLeft:
        "first:group-hover:border-l first:group-hover:border-gray-400",
      tableCellBorderRight: "group-hover:border-r group-hover:border-gray-400",
      // Table Action Danger Button => [TableActionDangerButton]
      tableActionDangerButtonBorderColor: "ring-red-400/20",
      tableActionDangerButtonTextColor: "hover:text-danger-1000/90",
      tableActionDangerButtonBackgroundColor: "bg-red-400/10",
      tableActionDangerButtonHoverTextColor: "text-danger-1000/90",
      tableActionDangerButtonHoverBackgroundColor: "hover:bg-gray-700/50",
      tableActionDangerButtonDisabledBackgroundColor:
        "disabled:bg-gray-100 disabled:ring-gray-100",
      tableActionDangerButtonDisabledTextColor: "disabled:text-danger-800",
      tableActionDangerButtonActiveBgColor: "active:bg-gray-600/80",
      tableActionDangerButtonLoaderColor: "border-gray-200",
      // Table Action General Button => [TableActionGeneralButton]
      tableActionGeneralButtonBorderColor: "ring-gray-500/80",
      tableActionGeneralButtonTextColor: "text-white",
      tableActionGeneralButtonBackgroundColor: "bg-gray-500",
      tableActionGeneralButtonHoverTextColor: "hover:text-gray-800",
      tableActionGeneralButtonHoverBackgroundColor:
        "hover:bg-gray-300/70 hover:ring-gray-300/70",
      tableActionGeneralButtonDisabledBackgroundColor:
        "disabled:bg-gray-500 disabled:ring-gray-400/80",
      tableActionGeneralButtonDisabledTextColor: "disabled:text-gray-200/80",
      tableActionGeneralButtonActiveBgColor:
        "active:bg-gray-400/45 active:ring-gray-400/45",

      // Simple Table
      tableBodyBackgroundColor: "bg-slate-800/50",
      tableSelectedRowBackgroundColor: "bg-gray-500",
      // Popover
      popoverHoverBackgroundColor: "hover:bg-gray-500",
      // Switch
      switchDisableBgColor: "bg-gray-500",
      tableMapSwitchDisableBgColor: "bg-gray-500",
      // Tooltip
      tooltipBackgroundColor: "bg-slate-600",
      // Divider classes
      dividerClasses: {
        gray100: " divide-gray-600",
        gray200: " divide-gray-700",
        gray300: " divide-gray-800",
        gray400: "divide-gray-900",
      },
      // Menu
      cardMenuBorderColor: "ring-1 ring-gray-700",
      menuItemHoverBackgroundColor: "hover:bg-gray-700/50",
      // Stepper
      fileUploadBackgroundColor: "bg-gray-700",
      theadBackgroundColor: "bg-gray-700",
      tableBorderColor: "border-gray-700",
      uploadBoxBorderColor: "border-gray-500",
      // Horizontal Line
      horizontalLineColor: "#4B5563",
      // Chips
      chipTextColor: "text-neutral-300",
      chipBorderColor: "ring-gray-400/20",
      chipRemoveButtonTextColor: "text-neutral-400",
      chipRemoveButtonHoverColor: "hover:text-neutral-500",

      // List
      selectedListBackgroundColor: "bg-slate-700",
      //Nested accordian
      nestedAccordionBackgroundColor: "bg-slate-700",
      nestedAccordionIconButtonBackgroundColor: "bg-gray-300",
      nestedButtonSuccessHoverColor: "hover:bg-green-700",
      nestedTableLoadingRowColor: "bg-gray-600",
    },

    default: {
      // Newly added colors
      bodyBackgroundColor: "bg-slate-900",
      cardBackgroundColor: "bg-slate-800",
      cardBackgroundColorCode: "#1e293b",
      activeLabelTextColor: "text-gray-200",
      disableLabelTextColor: "text-gray-400",
      accordianBackgroundColor: "bg-slate-800",
      accordionHeaderTextColor: "text-gray-100",
      selectedTableRowColor: "bg-slate-500/80",

      // Input
      inputBorderColor: "ring-gray-600",
      inputBorderColorCode: "#4a5568",
      inputFocusedBorderColor: "focus:ring-red-500",
      inputFocusedBorderColorCode: "#da8180",
      inputBackgroundColor: "bg-slate-800",
      mutedInputBackgroundColor: "bg-gray-600/70",
      mutedInputBackgroundColorCode: "#4b5563b3",

      // Description text
      descriptionTextColor: "text-slate-400",

      // Select
      selectOptionFocusedBackgroundColor: "bg-gray-700",
      selectOptionHoveredBackgroundColor: "hover:bg-gray-700",
      selectOptionFocusedBackgroundColorCode: "#363f52",
      inputErrorTextColor: "text-red-600",
      inputErrorBorderColor: "ring-red-800",
      labelTextColor: "text-white",
      labelTextColorCode: "#fff",
      selectMultiValueBackgroundColorCode: "#374151",
      // Checkbox/RB/Toggle
      checkboxBackgroundColor: "text-red-1000",
      radioButtonFocusedBorderColor: "focus:ring-red-700",
      // Common primary colors
      primaryTextColor: "text-white",
      primaryBackgroundColor: "bg-red-1000",
      primaryBorderColor: "border-white",
      primaryFocusRingColor: "focus:ring-red-1000",
      primaryTextHoverColor: "hover:text-white",
      primaryTextGroupHoverColor: "group-hover:text-red-1000",
      primaryTableRowHoverColor: "",
      // Typography colors & Tab colors
      typographyTextColor: "text-gray-300",
      typographyHoverTextColor: "hover:text-gray-200",
      tabHoverBorderColor: "hover:border-gray-300",
      tabBorderBottomColor: "border-b-gray-600",
      activeTabTextColor: "text-white font-extrabold",
      menuIconBgColor: "bg-gray-600",
      menuBorderColor: "hover:ring-gray-200",
      // Common success colors
      successTextColor: "text-green-600",
      successHoverTextColor: "hover:text-green-600",
      successActiveBgColor: "active:bg-green-100",
      successBorderColor: "border-green-600",
      redBorderColor: "border-red-600",
      redTextColor: "text-red-1000",
      redLightBackgroundColor: "bg-gray-800",
      greenLightBackgroundColor: "bg-slate-700",
      greenBorderColor: "border-green-400",
      // Submit Button => [LoadingButton]
      submitButtonBgColor: "bg-red-1000",
      submitButtonDisabledBgColor: "disabled:bg-gray-700",
      submitButtonHoverBgColor: "hover:bg-red-1100",
      submitButtonActiveBgColor: "active:bg-red-1200",
      submitButtonTextColor: "text-white",
      submitButtonDisabledTextColor: "disabled:text-gray-400",
      submitButtonHoverTextColor: "text-black",
      // Cancel Button
      cancelButtonBorderColor: "ring-gray-500",
      cancelButtonTextColor: "text-white",
      cancelButtonBackgroundColor: "bg-slate-800",
      cancelButtonHoverTextColor: "hover:text-red-900",
      cancelButtonActiveBgColor: "active:bg-gray-700/30",
      cancelButtonDisabledTextColor: "disabled:text-gray-200",
      // Ghost Button
      ghostButtonBorderColor: "ring-gray-500",
      ghostButtonTextColor: "text-white",
      ghostButtonBackgroundColor: "bg-slate-800",
      ghostButtonHoverTextColor: "hover:text-red-900",
      ghostButtonDisabledBackgroundColor: "disabled:bg-gray-700",
      ghostButtonDisabledTextColor: "disabled:text-gray-400",
      ghostButtonActiveBgColor: "active:bg-gray-700/30",
      // Primary Button => [PrimaryButton]
      primaryButtonBorderColor: "ring-red-1000",
      primaryButtonTextColor: "text-white",
      primaryButtonBackgroundColor: "bg-red-1000",
      primaryButtonHoverTextColor: "hover:text-white",
      primaryButtonHoverBackgroundColor: "hover:bg-red-1100",
      primaryButtonDisabledBackgroundColor:
        "disabled:bg-red-300 disabled:ring-red-300",
      primaryButtonDisabledTextColor: "disabled:text-white",
      primaryButtonActiveBgColor: "active:bg-red-1200",

      // Success Button => [SuccessButton]
      successButtonBorderColor: "ring-green-600",
      successButtonTextColor: "text-black",
      successButtonBackgroundColor: "bg-green-700",
      successButtonHoverTextColor: "hover:text-white",
      successButtonHoverBackgroundColor: "hover:bg-green-600",
      successButtonDisabledBackgroundColor: "disabled:bg-gray-600",
      successButtonDisabledTextColor: "disabled:text-gray-400",
      successButtonActiveBgColor: "active:bg-green-800",
      // Delete operations Button => [DangerButton]
      dangerButtonBorderColor: "ring-danger-1000",
      dangerButtonTextColor: "text-white",
      dangerButtonBackgroundColor: "bg-danger-1000",
      dangerButtonHoverTextColor: "hover:text-white",
      dangerButtonHoverBackgroundColor: "hover:bg-danger-1100",
      dangerButtonDisabledBackgroundColor:
        "disabled:bg-danger-300 disabled:ring-danger-300",
      dangerButtonDisabledTextColor: "disabled:text-white",
      dangerButtonActiveBgColor: "active:bg-danger-1200",

      // Table header
      tableHeaderBackgroundColor: "bg-slate-800/50",
      tableHeaderTextColor: "text-gray-50",
      tableRowHoverBorder: "hover:ring-1 hover:ring-gray-400",
      tableFirstRowLoadingBgColor: "bg-gray-700/50",
      tableSecondRowLoadingBgColor: "bg-gray-600/50",
      tableCellBorderLeft:
        "first:group-hover:border-l first:group-hover:border-gray-400",
      tableCellBorderRight: "group-hover:border-r group-hover:border-gray-400",
      // Table Action Danger Button => [TableActionDangerButton]
      tableActionDangerButtonBorderColor: "ring-red-400/20",
      tableActionDangerButtonTextColor: "hover:text-danger-1000/90",
      tableActionDangerButtonBackgroundColor: "bg-red-400/10",
      tableActionDangerButtonHoverTextColor: "text-danger-1000/90",
      tableActionDangerButtonHoverBackgroundColor: "hover:bg-gray-700/50",
      tableActionDangerButtonDisabledBackgroundColor:
        "disabled:bg-gray-100 disabled:ring-gray-100",
      tableActionDangerButtonDisabledTextColor: "disabled:text-danger-800",
      tableActionDangerButtonActiveBgColor: "active:bg-gray-600/80",
      tableActionDangerButtonLoaderColor: "border-gray-200",
      // Table Action General Button => [TableActionGeneralButton]
      tableActionGeneralButtonBorderColor: "ring-gray-500/80",
      tableActionGeneralButtonTextColor: "text-white",
      tableActionGeneralButtonBackgroundColor: "bg-gray-500",
      tableActionGeneralButtonHoverTextColor: "hover:text-gray-800",
      tableActionGeneralButtonHoverBackgroundColor:
        "hover:bg-gray-300/70 hover:ring-gray-300/70",
      tableActionGeneralButtonDisabledBackgroundColor:
        "disabled:bg-gray-500 disabled:ring-gray-400/80",
      tableActionGeneralButtonDisabledTextColor: "disabled:text-gray-200/80",
      tableActionGeneralButtonActiveBgColor:
        "active:bg-gray-400/45 active:ring-gray-400/45",

      // Simple Table
      tableBodyBackgroundColor: "bg-slate-800/50",
      tableSelectedRowBackgroundColor: "bg-gray-500",
      // Popover
      popoverHoverBackgroundColor: "hover:bg-gray-500",
      // Switch
      switchDisableBgColor: "bg-gray-500",
      tableMapSwitchDisableBgColor: "bg-gray-500",
      // Tooltip
      tooltipBackgroundColor: "bg-slate-600",
      // Divider classes
      dividerClasses: {
        gray100: " divide-gray-600",
        gray200: " divide-gray-700",
        gray300: " divide-gray-800",
        gray400: "divide-gray-900",
      },
      // Menu
      cardMenuBorderColor: "ring-1 ring-gray-700",
      menuItemHoverBackgroundColor: "hover:bg-gray-700/50",
      // Stepper
      fileUploadBackgroundColor: "bg-gray-700",
      theadBackgroundColor: "bg-gray-700",
      tableBorderColor: "border-gray-700",
      uploadBoxBorderColor: "border-gray-500",
      // Horizontal Line
      horizontalLineColor: "#4B5563",
      // Chips
      chipTextColor: "text-neutral-300",
      chipBorderColor: "ring-gray-400/20",
      chipRemoveButtonTextColor: "text-neutral-400",
      chipRemoveButtonHoverColor: "hover:text-neutral-500",

      // List
      selectedListBackgroundColor: "bg-slate-700",
      //Nested accordian
      nestedAccordionBackgroundColor: "bg-slate-700",
      nestedAccordionIconButtonBackgroundColor: "bg-gray-300",
      nestedButtonSuccessHoverColor: "hover:bg-green-700",
      nestedTableLoadingRowColor: "bg-gray-600",
    },
  },
};

export default themes;
