import React, {
  createContext,
  ReactNode,
  useContext,
  useRef,
  useState,
} from "react";

export interface ToasterContextType {
  show: boolean;
  message: string;
  severity: "success" | "error";
}

interface ModalPropsType {
  show: boolean;
  title: string;
  message: any;
  deleteFunction: any;
  deleteButtonText?: string;
}

interface InfoModalPropsType {
  show: boolean;
  title: string;
  message: string;
}

interface AlertMFAModalPropsType {
  show: boolean;
}

export interface ExportedContextValues {
  modalProps: ModalPropsType;
  infoModalProps: InfoModalPropsType;
  alertMFAModalProps: AlertMFAModalPropsType;
  setModalProps: React.Dispatch<React.SetStateAction<ModalPropsType>>;
  setAlertMFAModalProps: React.Dispatch<
    React.SetStateAction<AlertMFAModalPropsType>
  >;
  setInfoModalProps: React.Dispatch<React.SetStateAction<InfoModalPropsType>>;
  showErrorOverlay: boolean;
  setShowErrorOverlay: React.Dispatch<React.SetStateAction<boolean>>;
}

export const GlobalToasterContext = createContext<ExportedContextValues | null>(
  null
);

function ToasterProvider({ children }: { children: ReactNode }) {
  const [modalProps, setModalProps] = useState<ModalPropsType>({
    show: false,
    title: "",
    message: "",
    deleteFunction: "",
    deleteButtonText: "",
  });

  const [infoModalProps, setInfoModalProps] = useState({
    show: false,
    title: "",
    message: "",
  });

  const [alertMFAModalProps, setAlertMFAModalProps] = useState({
    show: false,
  });

  const [showErrorOverlay, setShowErrorOverlay] = useState(false);

  return (
    <GlobalToasterContext.Provider
      value={{
        modalProps,
        infoModalProps,
        alertMFAModalProps,
        setAlertMFAModalProps,
        setModalProps,
        setInfoModalProps,
        showErrorOverlay,
        setShowErrorOverlay, // add this new state variable and set it in your error handling function  // setShowErrorOverlay((prev) => !prev)  // example usage in your error handling function  // this will toggle the state and show the error overlay on error  // you can customize the overlay as needed by modifying the styles and content inside the GlobalToaster.tsx file  // remember to import the GlobalToaster.tsx file in your app.tsx file and wrap your app component
      }}
    >
      {children}
    </GlobalToasterContext.Provider>
  );
}

export default ToasterProvider;
