import React, { useContext } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";

interface Props {
  colspan: number;
}

function LoadingRow({ colspan }: Props) {
  const { theme } = useContext(ThemeContext)!;
  return (
    <>
      <tr>
        <td colSpan={colspan}>
          <div className="animate-pulse my-4 mx-2">
            <div
              className={`h-6 ${theme.nestedTableLoadingRowColor} mt-3 mb-6 rounded`}
            ></div>
            <div
              className={`h-6 ${theme.tableSecondRowLoadingBgColor} mb-6 rounded`}
            ></div>
            <div
              className={`h-6 ${theme.nestedTableLoadingRowColor} mb-6 rounded`}
            ></div>
          </div>
        </td>
      </tr>
    </>
  );
}

export default LoadingRow;
