import { get } from "./httpMethods";

// ----------------------------------------------------------------------

const handleTokenExpired = (exp: number) => {
  const currentTime = Date.now();
  // Test token expires after 10s
  // const timeLeft = currentTime + 100000 - currentTime // ~100s for testing timer logic

  // default token expiry is 60 minutes from firebase, we will refresh 5 minutes before the token expires to refresh the accessToken and keep the user logged in!
  const timeLeft = exp - currentTime - 300000;

  setTimeout(() => {
    get(`/api/auth/refresh-token`)
      .then((res: any) => {
        setAccessTokenExpiry(res?.accessTokenExpiry);
      })
      .catch((error) => {
        localStorage.removeItem("currentUser");
        localStorage.removeItem("selectedTenant");
        window.location.reload();
      });
  }, timeLeft);

};

const setAccessTokenExpiry = (expiryInSeconds: number | null) => {
  if (expiryInSeconds) {
    handleTokenExpired(expiryInSeconds);
  } else {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("selectedTenant");
  }
};


export { setAccessTokenExpiry };
