import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

import TenantList from "./TenantList";
import { TenantsContext } from "../../../contexts/TenantsContext";
import CancelButton from "../../../components/buttons/CancelButton";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import { ThemeContext } from "../../../contexts/ThemeContext";
import Overlay from "../../../components/Overlay";
import SimpleInput from "../../../components/inputs/SimpleInput";
interface modalProps {
  modalProps: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  };
  listProps: {
    list: any[];
    setList: React.Dispatch<any>;
  };
}

function SelectTenantModal({
  modalProps: { open, setOpen },
  listProps: { list, setList },
}: modalProps) {
  const { setSelectedTenant, setSelectedTenantDetails } =
    useContext(TenantsContext)!;
  const cancelButtonRef = useRef(null);
  const [selected, setSelected] = useState<any>();
  const [search, setSearch] = useState("");
  const { theme } = useContext(ThemeContext)!;

  const handleSelect = () => {
    setSelectedTenantDetails(selected);
    setSelectedTenant(selected?.id);
    handleClose();
  };

  const handleClose = () => {
    setSearch("");
    setOpen(false);
  };

  return (
    <div>
      <Overlay
        open={open}
        handleClose={handleClose}
        titleContent="Select Tenant"
        className="sm:my-8 sm:w-2/6 sm:p-6"
        cancelButtonRef={cancelButtonRef}
      >
        <div className="relative mt-5">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <MagnifyingGlassIcon
              className="pointer-events-none absolute inset-y-0 left-3 h-full w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <SimpleInput
            type="text"
            id="table-search-users"
            className={`block p-3 pl-10  text-sm border-0 outline-none shadow-sm ring-1 ring-inset focus:ring-2 rounded-md w-full outline-none`}
            placeholder="Search for Tenants"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <TenantList
          listProps={{ list: list, setList: setList }}
          setSelected={setSelected}
          searchTerm={search}
        />
        <div className="whitespace-nowrap py-4 pl-4 text-sm font-medium text-gray-900 flex space-x-4 justify-end">
          <CancelButton type="button" onClick={() => setOpen(false)}>
            Cancel
          </CancelButton>
          <PrimaryButton
            type="button"
            className="px-2.5 py-1.5"
            onClick={() => handleSelect()}
          >
            Select
          </PrimaryButton>
        </div>
      </Overlay>
    </div>
  );
}

export default SelectTenantModal;
