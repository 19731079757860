import React, { useContext } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";

interface Props {
  tableColumns: string[];
  children: React.ReactNode; //Table body
  isNestedTable?: boolean;
}
function TableAccordian({
  tableColumns,
  children,
  isNestedTable = true,
}: Props) {
  const { theme } = useContext(ThemeContext)!;
  return (
    <table className="min-w-full overflow-y-auto border-collapse">
      <thead
        className={`${
          isNestedTable
            ? theme.nestedAccordionBackgroundColor
            : theme.tableHeaderBackgroundColor
        } ${theme.tableHeaderTextColor} text-opacity-70 mb-2 rounded-2xl`}
      >
        <tr>
          {tableColumns.map((col) => (
            <th
              key={col}
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold first:rounded-s-xl last:rounded-e-xl"
            >
              {col}
            </th>
          ))}
        </tr>
        <tr className="h-2"></tr>
      </thead>

      <tbody className={theme.tableBodyBackgroundColor}>{children}</tbody>
    </table>
  );
}

export default TableAccordian;
