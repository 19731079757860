import React, { useContext } from "react";
import { ThemeContext } from "../contexts/ThemeContext";

interface Props {
  icon: any;
  className: string;
  areaHidden?: string;
  variant?: string;
}
function IconWrapper({ icon, className, areaHidden, variant }: Props) {
  const Icon = icon;
  const { theme } = useContext(ThemeContext)!;
  function getColorClass(variant: any) {
    switch (variant) {
      case "success":
        return theme.successTextColor;
      case "muted":
        return theme.disableLabelTextColor;
      case "error":
        return theme.redTextColor;
      default:
        return theme.primaryTextColor;
    }
  }

  const iconColorClass = getColorClass(variant);
  return (
    <Icon
      className={`${className} ${iconColorClass}`}
      area-hidden={areaHidden}
    />
  );
}

export default IconWrapper;
