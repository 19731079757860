import toast, { ToastBar, Toaster } from "react-hot-toast";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../contexts/ThemeContext";
import { GlobalToasterContext } from "../contexts/ToasterContext";

function GlobalToaster() {
  const { mode } = useContext(ThemeContext)!;
  const { showErrorOverlay, setShowErrorOverlay } =
    useContext(GlobalToasterContext)!;
  const [show, setShow] = useState(false);

  useEffect(() => {
    let errorTimer: any;

    if (showErrorOverlay) {
      setShow(true);
    }

    errorTimer = showErrorOverlay
      ? setTimeout(() => {
          setTimeout(() => {
            setShow(false);
          }, 100);
          setTimeout(() => {
            setShow(true);
          }, 200);
          setTimeout(() => {
            setShow(false);
            setShowErrorOverlay(false);
          }, 300);
        })
      : false;

    return () => {
      clearTimeout(errorTimer);
    };
  }, [showErrorOverlay, setShowErrorOverlay]);

  return (
    <div
      className={`${
        show ? "fixed inset-0 bg-red-400 bg-opacity-50 transition-opacity" : ""
      }`}
      style={{ zIndex: "10000" }}
    >
      <Toaster
        position="top-right"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "text-sm font-medium",
          duration: 3000,
          style: {
            background: mode === "dark" ? "#1E293B" : "#fff", //"#363636"
            color: mode === "dark" ? "#fff" : "#000",
            margin: "4px 2px 4px 2px",
          },

          // Default options for specific types
          success: {
            duration: 3000,
            icon: (
              <CheckCircleIcon
                height={20}
                width={20}
                className="text-green-400"
              />
            ),
          },
          error: {
            duration: 10000,
            icon: (
              <ExclamationCircleIcon
                height={20}
                width={20}
                className="text-red-400"
              />
            ),
          },
        }}
      >
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <>
                <div className="flex justify-center max-h-48 ">
                  <span className="flex-shrink-0">{icon}</span>
                  <span className="max-w-72 overflow-y-auto overflow-x-hidden text-wrap word-wrap">
                    {message}
                  </span>
                  <span>
                    <button onClick={() => toast.dismiss(t.id)}>
                      <XMarkIcon
                        height={20}
                        width={20}
                        className="text-red-400"
                      />
                    </button>
                  </span>
                </div>
              </>
            )}
          </ToastBar>
        )}
      </Toaster>
    </div>
  );
}

export default GlobalToaster;
