import React, { useContext, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import LabelledInput from "../../../components/inputs/LabelledInput";

import CreatableSelect from "react-select/creatable";
import { get, post } from "../../../utils/httpMethods";
import useAuth from "../../../hooks/useAuth";
import SelectWrapper from "../../../components/selects/SelectWrapper";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import { ThemeContext } from "../../../contexts/ThemeContext";
import Label from "../../../components/Label";
import LabelledCreatableSelect from "../../../components/selects/LabelledCreatableSelect";

export interface RefetchProps {
  refetchProps: {
    refetch: string;
    setRefetch: React.Dispatch<React.SetStateAction<string>>;
  };
}

type Inputs = {
  element_deployment: string;
  element_type: string;
  element_manufacturer: string;
  element_model: string;
};

const addGeneralSchema = yup.object({
  element_deployment: yup
    .string()
    .required("Element Deployment type is required!"),
  element_type: yup.string().required("Element type is required!"),
  element_manufacturer: yup
    .string()
    .required("Element Manufacturer is required"),
  element_model: yup.string().required("Model name is requried"),
});

function GeneralForm({ refetchProps: { setRefetch } }: RefetchProps) {
  const deploymentOptions = [
    { value: "On-Premise", label: "On-Premise" },
    { value: "Cloud", label: "Cloud" },
    { value: "IoT", label: "IoT" },
  ];

  const typeOptions = [
    { value: "Router", label: "Router" },
    { value: "Firewall", label: "Firewall" },
    { value: "SD-WAN Appliance", label: "SD-WAN Appliance" },
    { value: "Switch", label: "Switch" },
    { value: "Wireless Access Point", label: "Wireless Access Point" },
    { value: "Server", label: "Server" },
    { value: "Website", label: "Website" },
    { value: "API", label: "API" },
    { value: "Load Balancer", label: "Load Balancer" },
  ];

  const defaultValues = {
    element_deployment: "",
    element_type: "",
    element_manufacturer: "",
    element_model: "",
  };

  const methods = useForm<Inputs>({
    resolver: yupResolver(addGeneralSchema) as any,
    defaultValues: { ...defaultValues },
  });

  const {
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    control,
  } = methods;

  const {
    element_deployment,
    element_type,
    element_manufacturer,
    element_model,
  } = watch();
  const [manufacturerOptions, setManufacturerOptions] = useState<any>([
    { value: "abc", label: "ABC" },
    { value: "xyz", label: "XYZ" },
  ]);
  const [allData, setAllData] = useState<any>([]);
  const { organization } = useAuth();
  const { theme } = useContext(ThemeContext)!;

  useEffect(() => {
    get(`/api/element-management/${element_deployment}`)
      .then((res: any) => setAllData(res.data))
      .catch((e) => console.log(e));
  }, [element_deployment]);

  useEffect(() => {
    allData?.length &&
      setManufacturerOptions([
        ...allData
          .filter((data: any) => data.element_type === element_type)
          .map((data: any) => ({
            value: data.element_manufacturer,
            label: data.element_manufacturer,
          }))
          .filter(
            (v: any, i: any, a: any) =>
              a.findIndex((v2: any) => v2.label === v.label) === i
          ),
      ]);
  }, [allData, element_deployment, element_type]);

  const onSubmit = async (data: Inputs) => {
    post("/api/element-management", data);
    setRefetch(data.element_deployment);
    reset(defaultValues);
  };

  return (
    <div className="flex flex-col space-y-10">
      <div>
        <Label className="block text-md font-medium leading-6 mb-4">
          Add Element Deployment Type
        </Label>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex space-x-4 w-full">
              <div className="w-1/5">
                <Label className="block text-sm font-medium leading-6">
                  Element Deployment
                </Label>

                <Controller
                  name="element_deployment"
                  control={control}
                  render={({ field }) => {
                    return (
                      <>
                        <SelectWrapper
                          name="element_deployment"
                          className="mt-2 outline-none"
                          onChange={({ value }: any) => {
                            field.onChange(value);
                          }}
                          placeholder="Deployment Type"
                          options={deploymentOptions as any}
                          value={
                            field.value.length
                              ? { ...field, label: field.value }
                              : ""
                          }
                          errors={errors}
                        />
                      </>
                    );
                  }}
                />
              </div>
              <div className="w-1/5">
                <Label className="block text-sm font-medium leading-6">
                  Element Type
                </Label>

                <Controller
                  name="element_type"
                  control={control}
                  render={({ field }) => {
                    return (
                      <SelectWrapper
                        name="element_type"
                        className="mt-2 outline-none"
                        onChange={({ value }: any) => {
                          field.onChange(value);
                        }}
                        placeholder="Element Type"
                        options={typeOptions as any}
                        value={
                          field.value.length
                            ? { ...field, label: field.value }
                            : ""
                        }
                        errors={errors}
                      />
                    );
                  }}
                />
              </div>

              <div className="w-1/5">
                <LabelledCreatableSelect
                  label="Element Manufacturer"
                  name="element_manufacturer"
                  options={manufacturerOptions as any}
                  placeholder={`Select Manufacturer`}
                  isDisabled={!(element_deployment && element_type)}
                  isMulti={false}
                  isGrouped={true}
                  defaultValue={null}
                />
              </div>
              <div className="w-1/5">
                <LabelledInput
                  label="Element Model"
                  name="element_model"
                  type="text"
                  placeholder="Enter Element Model"
                />
              </div>

              <PrimaryButton
                type="submit"
                className={`${
                  Object.keys(errors).length ? "self-center mt-1" : ""
                } self-end mb-1 block px-3 py-2`}
                disabled={!organization?.is_active}
              >
                + Add
              </PrimaryButton>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
}

export default GeneralForm;
