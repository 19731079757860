import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import LoadingButton from "../../../components/buttons/LoadingButton";
import Loader from "../../../components/loaders/Loader";
import { get, post } from "../../../utils/httpMethods";
import { GlobalToasterContext } from "../../../contexts/ToasterContext";
import { CheckIcon } from "@heroicons/react/24/outline";
import useAuth from "../../../hooks/useAuth";
import { ArrowDownTrayIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import OTPInput from "../../../components/inputs/OTPInput";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import SelectWrapper from "../../../components/selects/SelectWrapper";
import CancelButton from "../../../components/buttons/CancelButton";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import Typography from "../../../components/Typography";
import Label from "../../../components/Label";
import Overlay from "../../../components/Overlay";
import IconWrapper from "../../../components/IconWrapper";
import toast from "react-hot-toast";

interface props {
  modalProps: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  };
}

type Inputs = {
  // otp: string;
  code1: string;
  code2: string;
  code3: string;
  code4: string;
  code5: string;
  code6: string;
};

type ValueNames = "code1" | "code2" | "code3" | "code4" | "code5" | "code6";

const authAppMethods = [
  {
    label: "Google Authenticator",
    value: "Google_Authenticator",
  },
  {
    label: "Duo (Coming soon)",
    value: "Duo",
    isDisabled: true,
  },
];

function MfaSettings({ modalProps }: props) {
  const { open, setOpen } = modalProps;
  const cancelButtonRef = useRef(null);
  const [authApp, setAuthApp] = useState("Google_Authenticator");
  const [otp, setOTP] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [backupCodes, setBackupCodes] = useState([]);
  const { logout, fetchUser } = useAuth();
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("currentUser") as any)
  );
  const navigate = useNavigate();
  const { setShowErrorOverlay } = useContext(GlobalToasterContext)!;
  const [errMessage, setErrMessage] = useState("");
  const otpSchema = yup
    .object({
      // otp: yup.string().required("Please enter code"),
      code1: yup.string().required("Code is required"),
      code2: yup.string().required("Code is required"),
      code3: yup.string().required("Code is required"),
      code4: yup.string().required("Code is required"),
      code5: yup.string().required("Code is required"),
      code6: yup.string().required("Code is required"),
    })
    .required();
  const defaultValues = {
    code1: "",
    code2: "",
    code3: "",
    code4: "",
    code5: "",
    code6: "",
  };

  const methods = useForm<Inputs>({
    mode: "all",
    resolver: yupResolver(otpSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setError,
    formState: { errors },
    watch,
    control,
    setValue,
  } = methods;

  const values = watch();
  const [autoSubmit, setAutoSubmit] = useState(true);

  const [qrData, setQrData] = useState({
    url: "",
    secret: "",
  });
  const handleClose = () => {
    setIsSuccess(false);
    setMessage("");
    setQrData({
      url: "",
      secret: "",
    });
  };

  useEffect(() => {
    if (
      Object.values(values).every((value) => parseInt(value) >= 0) &&
      autoSubmit
    ) {
      setAutoSubmit(false);
      onSubmit(values);
    }
  }, [values]);

  const onSubmit = async (data: Inputs) => {
    setIsConfirming(true);
    setMessage("");

    try {
      const { code1, code2, code3, code4, code5, code6 } = data;
      const otp = code1 + code2 + code3 + code4 + code5 + code6;
      const result: any = await post(`api/users/MFA/setup`, {
        mfa_app: authApp,
        otp: otp,
        secret_code: qrData.secret,
      });
      setIsConfirming(false);
      setIsSuccess(true);
      setMessage(result.message);
      setBackupCodes(result.backup_codes);
      fetchUser();
    } catch (e: any) {
      setAutoSubmit(true);
      setError("code1", { type: "custom", message: "" });
      setError("code2", { type: "custom", message: "" });
      setError("code3", { type: "custom", message: "" });
      setError("code4", { type: "custom", message: "" });
      setError("code5", { type: "custom", message: "" });
      setError("code6", { type: "custom", message: "" });
      setValue("code1", "");
      setValue("code2", "");
      setValue("code3", "");
      setValue("code4", "");
      setValue("code5", "");
      setValue("code6", "");
      setIsConfirming(false);
      setMessage(e.message);
      setIsSuccess(false);
      setErrMessage(e.message);
    }
  };

  const DownloadButton = ({ textOutput }: { textOutput: string }) => {
    const file = new Blob([textOutput], { type: "text/plain" });
    return (
      <a
        download="WanAware.BackupCodes.txt"
        target="_blank"
        rel="noreferrer"
        href={URL.createObjectURL(file)}
        style={{
          textDecoration: "inherit",
          color: "inherit",
        }}
      >
        <span className="text-primary">
          <ArrowDownTrayIcon className="h-4 inline" /> Download codes
        </span>
      </a>
    );
  };

  const fetchQRAndCode = async () => {
    setIsLoading(true);
    try {
      const data: any = await get(`api/users/MFA/setup`);
      setQrData({ url: data.qr_url, secret: data.secret_code });
      setIsLoading(false);
    } catch (e: any) {
      setIsLoading(false);
      toast.error(e.message || e.error);
      setShowErrorOverlay(true);
    }
  };
  useEffect(() => {
    open && fetchQRAndCode();
  }, [open]);
  return (
    <div>
      <Overlay
        open={open}
        cancelButtonRef={cancelButtonRef}
        handleClose={handleClose}
        titleContent={
          !isSuccess ? (
            "Multi-Factor Authentication"
          ) : (
            <>
              <div className="mb-2 text-center mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                <IconWrapper
                  icon={CheckIcon}
                  className="h-6 w-6"
                  variant="success"
                  areaHidden="true"
                />
              </div>
              <div className="text-center">You're all set!</div>
            </>
          )
        }
        className={
          !isSuccess
            ? "pt-5 sm:my-8 sm:w-full sm:max-w-3xl sm:p-6"
            : "overflow-hidden pt-5 sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
        }
      >
        {!isSuccess ? (
          <FormProvider {...methods}>
            <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <Typography
                  className="flex flex-col gap-4 text-sm leading-6 mt-5"
                  variant="subHeading"
                >
                  <span className="">
                    Multi-factor authentication (MFA) has been enabled on your
                    account. With MFA enabled, you will be asked to provide a
                    one-time password (OTP) in addition to your username and
                    password.
                  </span>
                  <span>
                    You will need an authenticator app installed on your device
                    to proceed.{" "}
                  </span>
                </Typography>
                <Label className="block text-sm font-medium leading-6 mt-3">
                  Select Authenticator
                </Label>
                <div className="grid grid-cols-3 mt-2">
                  <SelectWrapper
                    name=""
                    className={`outline-none self-end col-span-2`}
                    onChange={(e: any) => setAuthApp(e.value)}
                    placeholder="Select MFA APP"
                    options={authAppMethods}
                    defaultValue={authAppMethods[0]}
                  />
                </div>

                <Typography className="leading-6 mt-5" variant="subHeading">
                  1. Scan the barcode with your authenticator app :
                </Typography>
                <div className="grid grid-cols-2 m-4">
                  <div className="w-60 h-60 flex  justify-center">
                    {!isLoading ? (
                      <img src={qrData.url} alt="" />
                    ) : (
                      <Loader className="h-10 w-10 border-4 self-center" />
                    )}
                  </div>
                  <div className="flex flex-col justify-items-start self-center">
                    <Typography
                      className="text-sm leading-6"
                      as="span"
                      variant="subHeading"
                    >
                      Or enter this code into the Authentication App:
                    </Typography>
                    <Typography variant="valueLabel" className="mt-5">
                      {qrData.secret}
                    </Typography>
                  </div>
                </div>

                <Typography className="leading-6 mt-3" variant="subHeading">
                  2. Enter the OTP generated by your authenticator app:
                </Typography>

                <div className="mt-2 flex gap-x-4">
                  {Object.keys(values).map((name, index) => (
                    <OTPInput
                      key={name}
                      name={`code${index + 1}` as ValueNames}
                      placeholder="-"
                      type="text"
                      enableFocus={index === 0}
                      setErrMessage={setErrMessage}
                      fieldNameArray={[
                        "code1",
                        "code2",
                        "code3",
                        "code4",
                        "code5",
                        "code6",
                      ]}
                    />
                  ))}
                </div>
                {(errMessage ||
                  errors.code1 ||
                  errors.code2 ||
                  errors.code3 ||
                  errors.code4 ||
                  errors.code5 ||
                  errors.code6) && (
                  <Typography as="span" variant="error" className={`text-sm`}>
                    {errMessage ||
                      errors.code1?.message ||
                      errors.code2?.message ||
                      errors.code3?.message ||
                      errors.code4?.message ||
                      errors.code5?.message ||
                      errors.code6?.message}
                  </Typography>
                )}
              </div>
              <div className="flex mt-9 space-x-2 justify-end">
                <CancelButton type="button" onClick={() => logout()}>
                  Logout
                </CancelButton>

                <LoadingButton
                  type="submit"
                  className="rounded-md px-2.5 py-1.5 text-sm font-semibold shadow-sm"
                  buttonText={"Confirm"}
                  loading={isConfirming}
                />
              </div>
            </form>
          </FormProvider>
        ) : (
          <div>
            {" "}
            <div>
              <div className="mt-3 text-center sm:mt-5">
                <div className="mt-2">
                  <Typography className="text-sm">{message}</Typography>
                </div>
                <div className="mt-2">
                  <div className="grid grid-cols-2 gap-x-4 gap-y-5 py-6">
                    {backupCodes.map((code) => (
                      <Typography
                        className="font-medium"
                        variant="valueLabel"
                        as="div"
                      >
                        {code}
                      </Typography>
                    ))}
                  </div>
                </div>
                <div className="mt-2">
                  <DownloadButton textOutput={backupCodes.join(", ")} />
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <CancelButton
                type="button"
                className="mt-3 inline-flex w-full justify-center"
                onClick={() => logout()}
              >
                Logout now
              </CancelButton>
              <PrimaryButton
                type="button"
                className="inline-flex w-full justify-center mt-3 py-2"
                onClick={() => {
                  handleClose();
                  setOpen(false);
                  navigate(
                    [
                      "WanAware_Finance",
                      "Reseller_Finance",
                      "Customer_Finance",
                    ].includes(user.role)
                      ? "/billing"
                      : ["WanAware_Super_User", "Reseller_Admin"].includes(
                          user.role
                        )
                      ? "/super-user/customers"
                      : "/assets",
                    { replace: true }
                  );
                }}
                ref={cancelButtonRef}
              >
                Stay logged in
              </PrimaryButton>
            </div>
          </div>
        )}
      </Overlay>
    </div>
  );
}

export default MfaSettings;
