import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useContext } from "react";
import { ThemeContext } from "../contexts/ThemeContext";

interface Props {
  open: boolean;
  cancelButtonRef?: any;
  modalRef?: any;
  handleClose: () => void;
  children: React.ReactNode;
  style?: any;
  titleContent: any;
  className?: string;
}

function Overlay({
  open,
  cancelButtonRef,
  modalRef,
  handleClose,
  children,
  style,
  titleContent,
  className = "sm:w-10/12 md:w-9/12 lg:w-3/6 xl:w-5/12",
}: Props) {
  const { theme } = useContext(ThemeContext)!;
  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={handleClose}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto" ref={modalRef}>
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className={`relative z-10 transform rounded-lg ${theme.cardBackgroundColor} px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6 ${className}`}
                  style={style}
                >
                  <Dialog.Title
                    as="h2"
                    className={`text-lg font-bold leading-6 ${
                      theme.labelTextColor
                    } ${titleContent ? "mb-2" : ""}`}
                  >
                    {titleContent}
                  </Dialog.Title>
                  {children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default Overlay;
