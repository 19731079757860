import React, { useCallback, useContext, useEffect, useState } from "react";
import { TrashIcon } from "@heroicons/react/20/solid";
import { del, get } from "../../../utils/httpMethods";
import { RefetchProps } from "./GeneralForm";
import { tableCols } from "./OnPremiseSection";
import Pagination from "../../../components/Pagination";
import { GlobalToasterContext } from "../../../contexts/ToasterContext";
import NoDataFound from "../../../components/nodataPlaceholders/NoDataFound";
import TableActionDangerButton from "../../../components/tables/TableActionDangerButton";
import Label from "../../../components/Label";
import { ThemeContext } from "../../../contexts/ThemeContext";

function CloudSection({ refetchProps: { refetch, setRefetch } }: RefetchProps) {
  const { setModalProps } = useContext(GlobalToasterContext)!;
  const { theme } = useContext(ThemeContext)!;
  const [allData, setAllData] = useState<any>([]);
  //   pagination States
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLimit, setCurrentLimit] = useState(10);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(() => {
    setLoading(true);
    get(
      `/api/element-management/Cloud?limit=${currentLimit}&page=${currentPage}`
    )
      .then((res: any) => {
        setAllData(res);
        setRefetch("");
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }, [currentLimit, currentPage, setRefetch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (refetch === "Cloud") {
      fetchData();
    }
  }, [refetch, fetchData]);

  const handleDeleteManagement = async (id: string) => {
    await del(`/api/element-management/${id}`);
    fetchData();
  };

  return (
    <div>
      <Label className="block text-md font-medium leading-6 mb-4">Cloud</Label>

      <div className="flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <>
                <table className="min-w-full border-separate border-spacing-y-2">
                  <thead
                    className={`${theme.tableHeaderBackgroundColor} ${theme.tableHeaderTextColor} text-opacity-70 mb-2 rounded-2xl`}
                  >
                    <tr>
                      {tableCols.map((col) => (
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold first:rounded-s-xl last:rounded-e-xl"
                        >
                          {col.label}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className={theme.tableBodyBackgroundColor}>
                    {!loading && !allData.data?.length ? (
                      <>
                        <NoDataFound
                          name="Elements"
                          colspan={tableCols.length}
                        />
                      </>
                    ) : (
                      allData.data?.map((data: any) => (
                        <tr
                          className={`cursor-pointer ${theme.tableSelectedRowBackgroundColor} border-spacing-4 rounded-md ${theme.cardBackgroundColor} ${theme.labelTextColor}`}
                        >
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium rounded-s-xl">
                            {data.element_type}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium">
                            {data.element_manufacturer}
                          </td>{" "}
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium">
                            {data.element_model}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-left rounded-e-xl">
                            <TableActionDangerButton
                              onClick={() => {
                                setModalProps({
                                  show: true,
                                  title: "Delete Element Model",
                                  message: `Are you sure you want to delete Cloud ${data.element_type} ${data.element_model}?`,
                                  deleteFunction: async () =>
                                    await handleDeleteManagement(data.id),
                                });
                              }}
                              type="button"
                            >
                              <TrashIcon height={20} width={20} />
                            </TableActionDangerButton>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </>
            </div>
          </div>
        </div>
      </div>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setCurrentLimit={setCurrentLimit}
        totalPages={allData?.totalPages}
        totalResults={allData?.totalResults}
        defaultLimit={currentLimit}
      />
    </div>
  );
}

export default CloudSection;
