import axios from "axios";
import { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { socket } from "./socket";
import { get } from "./httpMethods";

/**
 * --------------------------------------------------------------------------
 *  Axios Instantiation
 * --------------------------------------------------------------------------
 *  Description: Create an Axios instance with dynamic baseURL based on the current subdomain.
 *  HTTP Method: Any (GET/POST/PUT/DELETE)
 * --------------------------------------------------------------------------
 * --------------------------------------------------------------------------
 *  Notes:
 *      - Dynamically constructs the baseURL from the current subdomain.
 *      - Uses `withCredentials` to support cross-origin authentication.
 * --------------------------------------------------------------------------
 */

/* Extract the current hostname (subdomain.wanaware.com) */
const hostname = window.location.hostname; // e.g., subdomain.wanaware.com

let baseURL: any;

/* Construct the baseURL dynamically */
if (hostname === 'localhost') {
  baseURL = process.env.REACT_APP_HOST_API_URL; // Local dev API
} else {
  baseURL = `https://${hostname}`; // Production API
}

/* Axios Instance */
const axiosInstance = axios.create({
  baseURL,
  withCredentials: true,
});

/**
 * --------------------------------------------------------------------------
 *  Interceptors
 * --------------------------------------------------------------------------
 */

/*
 * Handle pre request
 * 1. Return response
 */
export function handlePreRequest(config: AxiosRequestConfig & any) {
  return config;
}

/*
 * Handle request error while sending any data to API
 */
export function handleRequestError(error: AxiosError) {
  return Promise.reject(error);
}

/*
 * Handle response Success
 */
export function handleResponseSuccess(response: AxiosResponse) {
  return response.data;
}


/**
 * Delete the cookie
 * @param key 
 */
const deleteCookie = (key: string) => {
  try {
    document.cookie = `${key}=; Max-Age=0; path=/; domain=${window.location.hostname};`;
  } catch (e) {

  }
};

/**
 * Non component logout function to handle request 401 errors
 * @param error 
 * @param message 
 */
const logout = async (error: string, message: string) => {
  // Logout from Firebase Auth
  const user = JSON.parse(localStorage.getItem("currentUser") as any);
  localStorage.removeItem("currentUser");
  localStorage.removeItem("selectedTenant");
  if (user) {
    socket.emit("leaveMessageRoom", user.id);
    await get("/api/auth/logout");
    deleteCookie("accessTokenExpiry");
  }

  deleteCookie("accessTokenExpiry");
  setTimeout(() => {
    if (error === "Session Invalidated") {
      window.location.href = `${window.location.origin}/auth/message/${error}/${message}`
    } else {
      window.location.href = `${window.location.origin}/auth/login`
    }
  }, 1000);
};

/**
 * Function to check multiple routes against window.location.href
 * @param routes 
 * @returns 
 */
function checkMultipleRoutes(routes: string[]) {
  const currentURL = window.location.href;

  // Use .some to efficiently check if any route matches
  return routes.some(route => currentURL.includes(route));
}


// Routes / Pages on which 403 error will be handled and 403 page will be displayed.
const routesToHandle403 = ["/assets", "/elements", "/workers/customer", "/workers/wanaware", "/events", "/administration/general/company", "/administration/general/incident-management", "/administration/users", "/administration/integrations", "/administration/logging/user-log", "/administration/logging/system-log", "/profile/general", "/profile/security", "/profile/notifications", "/profile/referrals"];

/*
 * Handle response Error
 * 1. Check for 401 and 403 response code from server and logout
 */
export async function handleResponseError(error: AxiosError | any) {
  if (error.response && error.response.status === 401) {
    if (!window.location.href.includes("auth")) {
      await logout(error.response.data?.error, error.response.data?.message);
    }
  } else if (error.response && error.response.status === 403) {
    const isRouteMatched = checkMultipleRoutes(routesToHandle403);
    if (!window.location.href.includes("auth") && isRouteMatched) {
      window.location.href = `${window.location.origin}/403`
    }
  }
  return Promise.reject(
    (error && error.response && error.response.data) || error
  );
}

/*
 * Interceptors for request and response
 * 1. Handle pre request data
 * 2. Handle request error if any
 * 3. Handle response success
 * 4. Handle response error
 */
axiosInstance.interceptors.request.use(handlePreRequest, handleRequestError);
axiosInstance.interceptors.response.use(
  handleResponseSuccess,
  handleResponseError
);

export default axiosInstance;
