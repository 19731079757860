import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Menu, Transition } from "@headlessui/react";
import useAuth from "../../hooks/useAuth";
import Avatar from "react-avatar";
import { socket } from "../../utils/socket";

import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/20/solid";
import {
  ArrowUturnRightIcon,
  Bars3Icon,
  BuildingOffice2Icon,
  UserIcon,
} from "@heroicons/react/24/outline";
import TenantDropdown from "./tenants/TenantDropdown";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LayoutContext } from "../../contexts/LayoutContext";
import { TenantsContext } from "../../contexts/TenantsContext";
import { get, put } from "../../utils/httpMethods";
import { GlobalToasterContext } from "../../contexts/ToasterContext";
import NotificationsPopover from "../../features/messages/NotificationsPopover";
import { ThemeContext } from "../../contexts/ThemeContext";
import AdjustmentsHorizontalIcon from "@heroicons/react/24/solid/AdjustmentsHorizontalIcon";
import ToolTip from "../../components/ToolTip";
import SettingsDrawer from "../../components/settings/SettingsDrawer";
import toast from "react-hot-toast";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const employees = [
  "WanAware_Engineer",
  "WanAware_Report_User",
  "WanAware_Support",
  "WanAware_Finance",
  "Reseller_Engineer",
  "Reseller_Report_User",
  "Reseller_Support",
  "Reseller_Finance",
  "Customer_Engineer",
  "Customer_Report_User",
  "Customer_Support",
  "Customer_Finance",
];

const tenantAllowedRoles = [
  "WanAware_Super_User",
  "WanAware_Engineer",
  "WanAware_Report_User",
  "WanAware_Support",
  "WanAware_Finance",
  "Reseller_Admin",
  "Reseller_Engineer",
  "Reseller_Report_User",
  "Reseller_Support",
  "Reseller_Finance",
];

function Navbar() {
  const { logout, user, isFetchingUser, organization } = useAuth();
  const {
    selectedTenant,
    selectedTenantDetails,
    setSelectedTenantDetails,
    setSelectedTenant,
  } = useContext(TenantsContext)!;
  const { setSidebarOpen } = useContext(LayoutContext)!;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [isAdmPage, setIsAdmPage] = useState(false);
  const [openThemeSettings, setOpenThemeSettings] = useState(false);
  const [navItemList, setNavItemList] = useState<any>([]);
  const { theme, mode } = useContext(ThemeContext)!;
  const userNavigation = [
    {
      href: "/profile/general",
      label: "My Profile",
      icon: <UserIcon className="inline h-4" />,
    },
    {
      href: "/auth/login",
      label: "Logout",
      icon: <ArrowUturnRightIcon className="inline h-4" />,
    },
  ];

  useEffect(() => {
    const finalList = employees.includes(user?.role)
      ? userNavigation.filter(
          (item) => item.label !== "Users" && item.label !== "Company"
        )
      : user?.role === "WanAware_Super_User" || user?.role === "Customer_Admin"
      ? userNavigation.filter((item) => item.label !== "Company")
      : [...userNavigation];
    setNavItemList(finalList);
    setSelectedTenant(
      JSON.parse(localStorage.getItem("selectedTenant") as any) ||
        organization?.id
    );
  }, [user, organization]);

  useEffect(() => {
    setIsAdmPage(pathname.split("/").includes("administration"));
  }, [pathname]);

  const [messages, setMessages] = useState<any>([]);
  const [unreadCount, setUnreadCount] = useState<any>(0);

  const getMessages = useCallback(() => {
    get(`/api/messages/all`)
      .then((res: any) => {
        setMessages(res.messages);
        setUnreadCount(res.unread);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    socket.emit("joinMessageRoom", user.id);
    socket.on("new_message", (data: any) => {
      toast.success("Received a new message");

      if (
        user.id === data.to_user_id ||
        (user.role === data.to_user_role &&
          organization.id === data.to_organization_id)
      ) {
        socket.emit("fetch_messages", {
          userId: user.id,
          userRole: user.role,
          organizationId: organization.id,
        });
      }
    });

    socket.on("receive_messages", (data: any) => {
      setMessages(data.messages);
      setUnreadCount(data.unread);
    });

    getMessages();
  }, []);

  const markAllMessagesAsReadBySender = (senderId: string) => {
    put(`/api/messages/read/${senderId}`)
      .then((res) => {
        getMessages();
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div
        className={`flex flex-col h-16 shrink-0 items-center gap-x-4 mt-3 px-4 sm:gap-x-6 sm:px-6 lg:px-0  ${theme.bodyBackgroundColor} ${theme.activeLabelTextColor} `}
      >
        <div
          className={`flex flex-1 gap-x-4 self-stretch lg:gap-x-6 justify-between lg:mx-8 border-b-2 ${theme.bodyBackgroundColor} ${theme.tabBorderBottomColor}`}
        >
          {/* <form className="relative flex flex-1" action="#" method="GET">
      <label htmlFor="search-field" className="sr-only">
        Search
      </label>
      <MagnifyingGlassIcon
        className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
        aria-hidden="true"
      />
      <input
        id="search-field"
        className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
        placeholder="Search..."
        type="search"
        name="search"
      />
    </form> */}
          <div className="flex">
            <button
              type="button"
              className={`-m-2.5 mr-2 p-2.5 ${theme.activeLabelTextColor} lg:hidden`}
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            {tenantAllowedRoles.includes(user?.role) ? (
              <>
                <TenantDropdown />
              </>
            ) : (
              <>
                <div className="self-center flex flex-1 gap-x-2">
                  {/* <BuildingOffice2Icon className="h-6 w-6 font-semibold" /> */}
                  <div className="font-bold text-3xl">{organization?.name}</div>
                </div>
              </>
            )}

            {selectedTenantDetails?.name &&
              selectedTenant !== organization?.id && (
                <div
                  onClick={() => {
                    setSelectedTenantDetails(organization);
                    setSelectedTenant(organization?.id);
                    if (isAdmPage || pathname === "/structures")
                      navigate("/dashboard");
                  }}
                  className={`ml-2 flex items-center hover:underline cursor-pointer text-gray-400 ${
                    mode === "light"
                      ? "hover:text-gray-800"
                      : "hover:text-gray-200"
                  }`}
                >
                  <XMarkIcon className="h-5 w-5 " aria-hidden="true" />
                </div>
              )}
          </div>

          <div className="flex items-center gap-x-4 lg:gap-x-6">
            <div>
              <ToolTip text="Theme Settings">
                <button
                  onClick={() => setOpenThemeSettings(!openThemeSettings)}
                  className={` text-gray-400 ${theme.typographyHoverTextColor} font-black transition-transform duration-300 transform  
           `}
                >
                  <AdjustmentsHorizontalIcon width={20} height={20} />
                </button>
              </ToolTip>
              <SettingsDrawer
                open={openThemeSettings}
                setOpen={setOpenThemeSettings}
              />
            </div>
            <NotificationsPopover
              markAllMessagesAsReadBySender={markAllMessagesAsReadBySender}
              messages={messages}
              unreadCount={unreadCount}
            />
            {/* Separator */}
            {/* Separator */}
            <div
              className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
              aria-hidden="true"
            />
            {/* Profile dropdown */}
            <Menu as="div" className="relative">
              <Menu.Button className="-m-1.5 flex items-center p-1.5">
                <span className="sr-only">Open user menu</span>
                {!isFetchingUser ? (
                  <Avatar
                    src={user?.avatar}
                    name={user?.first_name + " " + user?.last_name}
                    size="30"
                    round={true}
                  />
                ) : (
                  <></>
                )}
                <span className="hidden lg:flex lg:items-center">
                  <span
                    className={`ml-4 text-sm font-semibold leading-6 ${theme.labelTextColor}`}
                    aria-hidden="true"
                  >
                    {user?.first_name} {user?.last_name}
                  </span>
                  <ChevronDownIcon
                    className={`ml-2 h-5 w-5 ${theme.disableLabelTextColor}`}
                    aria-hidden="true"
                  />
                </span>
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  className={`absolute right-0 z-10 mt-2.5 w-44 origin-top-right rounded-md  py-2 shadow-lg ${theme.cardBackgroundColor} ${theme.cardMenuBorderColor} `}
                >
                  {navItemList.map((item: any) => (
                    <Menu.Item key={item.label}>
                      {item.label === "Logout"
                        ? ({ active }) => (
                            <Link
                              to={item.href}
                              onClick={() => logout()}
                              className={classNames(
                                `block px-4 py-2 text-sm ${theme.activeLabelTextColor} capitalize ${theme.menuItemHoverBackgroundColor}`
                              )}
                            >
                              {item.icon} {item.label}
                            </Link>
                          )
                        : ({ active }) => (
                            <Link
                              to={item.href}
                              className={classNames(
                                `block px-4 py-2 text-sm ${theme.activeLabelTextColor} capitalize ${theme.menuItemHoverBackgroundColor}`
                              )}
                            >
                              {item.icon} {item.label}
                            </Link>
                          )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
