import React, { Fragment, useContext } from "react";
import {
  EllipsisHorizontalIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/24/outline";
import { Menu, Transition } from "@headlessui/react";
import { ThemeContext } from "../contexts/ThemeContext";

interface MenuItem {
  label: string;
  icon?: any;
  onClick: () => void;
  loading?: boolean;
  variant?: "default" | "delete" | string;
  render?: (active: boolean) => React.ReactNode; // Optional custom render function
}
interface MenuButtonProps {
  className?: string;
  children: React.ReactNode | string;
}
interface Props {
  show?: boolean; // Show the menu on hover if no menu button is provided.
  menuItems: MenuItem[]; // List of menu items to display
  menuClassName?: string; // CSS classes to be applied to the menu component container
  menuItemsClassName?: string; //Additional CSS classes to be applied to the menu items container
  menuButton?: MenuButtonProps; // The button node to toggle menu visibility
  menuIcon?: any; // Icon to be displayed as menu button
  disabled?: boolean; // Disable the menu button if set to true
  menuItemClassName?: string; //Additional CSS class to be applied to the menu item.
}

function MenuComponent({
  show,
  menuItems,
  menuClassName,
  menuItemsClassName,
  menuButton,
  menuIcon,
  disabled,
  menuItemClassName,
}: Props) {
  const { theme } = useContext(ThemeContext)!;
  return (
    <Menu as="div" className={`${menuClassName}`}>
      {menuButton && (
        <Menu.Button
          className={`${menuButton.className} ${theme.cardBackgroundColor}
          ${disabled ? theme.ghostButtonDisabledBackgroundColor : ""}
          `}
          disabled={disabled}
        >
          {menuButton.children}
        </Menu.Button>
      )}
      {menuIcon && (
        <Menu.Button>
          <button
            id="dropdownMenuIconButton"
            data-dropdown-toggle="dropdownDots"
            type="button"
          >
            {menuIcon}
          </button>
        </Menu.Button>
      )}
      <Transition
        as={Fragment}
        show={show}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`${menuItemsClassName} ${theme.submitButtonDisabledTextColor} ${theme.cardBackgroundColor}   ${theme.cardMenuBorderColor} `}
        >
          {menuItems.map((item: MenuItem, index: number) => (
            <Menu.Item key={index}>
              {({ active }) => (
                <div
                  className={`flex px-2 py-2 ${
                    theme.menuItemHoverBackgroundColor
                  } ${menuItemClassName} ${
                    item.variant === "delete"
                      ? theme.primaryTextColor
                      : theme.labelTextColor
                  }`}
                  onClick={item.onClick}
                >
                  {item.render ? (
                    item.render(active)
                  ) : item.loading ? (
                    <div
                      className={`mr-1 mt-1 h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite] ${
                        item.loading ? "" : "invisible"
                      }`}
                      role="status"
                    ></div>
                  ) : (
                    item.icon && (
                      <item.icon height={20} width={20} className="mr-2" />
                    )
                  )}
                  {!item.render && item.label}
                </div>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default MenuComponent;
