/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */
import React, { useContext, useEffect, useState } from "react";
import LabelledInput from "../../../components/inputs/LabelledInput";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { get, put } from "../../../utils/httpMethods";
import LoadingButton from "../../../components/buttons/LoadingButton";
import LabelledTextArea from "../../../components/inputs/LabelledTextArea";
import useAuth from "../../../hooks/useAuth";
import { GlobalToasterContext } from "../../../contexts/ToasterContext";
import Avatar from "react-avatar";
import FileInput from "../../../components/inputs/FileInput";
import { TenantsContext } from "../../../contexts/TenantsContext";
import { useLocation } from "react-router-dom";
import ImageCropOverlay from "./ImageCropOverlay";
import LabelledSuffixInput from "../../../components/inputs/LabelledSuffixInput";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import Loader from "../../../components/loaders/Loader";
import CancelButton from "../../../components/buttons/CancelButton";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import { ThemeContext } from "../../../contexts/ThemeContext";
import SectionHeader from "../../../components/SectionHeader";
import Typography from "../../../components/Typography";
import Card from "../../../components/Card";
import toast from "react-hot-toast";

type Inputs = {
  name: string;
  // email: string;
  // address_line1: string;
  // address_line2: string;
  // address_city: string;
  // address_state: string;
  // address_country: string;
  // address_zip: string;
  // website: string;
  // tax_id: string;
  subdomain: string;
  terms_and_conditions: string;
  light_logo: string;
  dark_logo: string;
};

const logoTypes = ["Light", "Dark"];
const companySchema = yup
  .object({
    name: yup.string().required("name is required"),
  })
  .required();

function Company() {
  const methods = useForm<Inputs>({
    resolver: yupResolver(companySchema) as any,
  });

  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const { selectedTenant } = useContext(TenantsContext)!;
  const [organization, setOrganization] = useState<any>();
  const [imagePreview, setImagePreview] = useState<any>();
  const [imagePreviewLight, setImagePreviewLight] = useState("");
  const [imagePreviewDark, setImagePreviewDark] = useState("");
  const [imageCropOverlay, setImageCropOverlay] = useState(false);
  const [crop, setCrop] = useState<any>();
  const [lightLogo, setLightLogo] = useState();
  const [darkLogo, setDarkLogo] = useState();
  const [OriginalImage, setOriginalImage] = useState<any>();
  const [logoType, setLogoType] = useState<string>("");
  const { user, organization: loggedUserOrg, fetchUser } = useAuth();
  const [logoError, setLogoError] = useState<any>("");
  const { theme, mode } = useContext(ThemeContext)!;
  const { setShowErrorOverlay } = useContext(GlobalToasterContext)!;

  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = methods;
  const fetchCustomer = async () => {
    try {
      if (!selectedTenant) return;
      const apiUrl =
        pathname.includes("/administration/general/company") &&
        user?.role === "Customer_Admin"
          ? `/api/auth/self`
          : `/api/customers/${selectedTenant}`;
      let response = (await get(apiUrl)) as any;
      response =
        pathname.includes("/administration/general/company") &&
        user?.role === "Customer_Admin"
          ? response.organization
          : response;
      if (Object.keys(response).length !== 0) setOrganization(response);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetchCustomer();
  }, [selectedTenant, pathname]);

  useEffect(() => {
    setValue("name", organization?.name);
    // setValue("email", organization?.email as any);
    // setValue("address_line1", organization?.address_line1);
    // setValue("address_line2", organization?.address_line2);
    // setValue("address_city", organization?.address_city);
    // setValue("address_state", organization?.address_state);
    // setValue("address_country", organization?.address_country);
    // setValue("address_zip", organization?.address_zip);
    // setValue("website", organization?.website);
    // setValue("tax_id", organization?.tax_id);
    setValue("subdomain", organization?.subdomain);
    setValue("terms_and_conditions", organization?.terms_and_conditions);
    setValue("light_logo", organization?.light_logo);
    setValue("dark_logo", organization?.dark_logo);
  }, [organization]);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const formdata = new FormData();

    for (const [key, value] of Object.entries<any>(data)) {
      if (value !== null) {
        if (key === "light_logo" && lightLogo) {
          formdata.append("light_logo", lightLogo);
        }
        if (key === "dark_logo" && darkLogo) {
          formdata.append("dark_logo", darkLogo);
        }
        if (key !== "light_logo" && key !== "dark_logo") {
          formdata.append(key, value);
        }
      }
    }
    setLoading(true);
    const subURL =
      user?.role === "Reseller_Admin" ? "resellers/customers" : "customers";
    try {
      const result: any =
        pathname.includes("/administration/general/company") &&
        user?.role === "Customer_Admin"
          ? await put(`/api/auth/organization/self`, formdata)
          : await put(`/api/${subURL}/${organization.id}`, formdata);
      toast.success(result.message);
      fetchUser();
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      toast.error(e.message || e.error);
      setShowErrorOverlay(true);
    }
  };

  const onImageChange = (event: any, type: string) => {
    if (event.target.files && event.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      setOriginalImage(event.target.files[0]);
      setLogoType(type);
      setImageCropOverlay(true);
    }
  };
  useEffect(() => {
    if (imagePreview) {
      if (logoType === "Light") {
        setImagePreviewLight(URL.createObjectURL(imagePreview) as any);
        setLightLogo(imagePreview);
      } else {
        setImagePreviewDark(URL.createObjectURL(imagePreview) as any);
        setDarkLogo(imagePreview);
      }
    }
  }, [imagePreview]);
  useEffect(() => {
    if (logoError) setImageCropOverlay(false);
  }, [logoError]);

  return (
    <div className="flex flex-col gap-6 px-4 py-5 sm:px-6 mt-6">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex justify-between">
            <div>
              <SectionHeader
                level={2}
                className="text-base font-semibold leading-7"
              >
                Company Information
              </SectionHeader>
              <Typography className="mt-1 text-sm leading-6">
                Update Your Company Information
              </Typography>
            </div>
            {((pathname === "/settings/company" &&
              user?.role === "Reseller_Admin") ||
              (pathname === "/administration/general/company" &&
                user?.role === "Customer_Admin")) && (
              <div className="flex gap-1.5 h-10">
                <PrimaryButton
                  className="flex items-center gap-2 rounded-lg py-2.5 px-4 font-medium"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? (
                    <Loader className="h-4 w-4 border-white" />
                  ) : (
                    <CheckCircleIcon height={16} width={16} />
                  )}
                  Save
                </PrimaryButton>
                <CancelButton
                  className="flex items-center gap-2 rounded-lg px-4 font-medium"
                  type="button"
                >
                  <XCircleIcon
                    className={theme.primaryTextColor}
                    height={16}
                    width={16}
                  />
                  Cancel
                </CancelButton>
              </div>
            )}
          </div>

          <div className="flex flex-col gap-6">
            <div className="flex gap-4 w-full sm:flex-col md:flex-row lg:flex-row flex-col">
              <div
                className={`flex flex-col ${
                  mode === "dark" ? theme.cardBackgroundColor : "bg-slate-900"
                } rounded-3xl p-7 md:w-1/2 min-h-52 gap-5 lg:w-1/2 sm:w-full w-full`}
              >
                <div className="flex gap-5">
                  <div className="avatar-container flex flex-col w-20 h-20 border border-gray-600">
                    <div className="relative">
                      <Avatar
                        src={imagePreviewLight || organization?.light_logo}
                        name={organization?.name}
                        round="8px"
                        size="65"
                        unstyled={!!organization?.light_logo}
                      />

                      {imagePreviewLight ? (
                        <small className="text-gray-400 text-xs avatar-text">
                          Preview
                        </small>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  {((pathname === "/settingsself-end/company" &&
                    user?.role === "Reseller_Admin") ||
                    (pathname === "/administration/general/company" &&
                      user?.role === "Customer_Admin")) && (
                    <div className="mt-1.5 h-10">
                      <FileInput
                        fieldName={"light_logo"}
                        label="Upload New Logo"
                        instructions="JPG, GIF or PNG. 1MB max."
                        onFileChange={(e: any) => onImageChange(e, "Light")}
                        accept="image/*"
                        maxSizeInMB={Number(
                          process.env.REACT_APP_IMAGE_TO_UPLOAD_MAX_SIZE_IN_MB
                        )}
                        isOrgUpdate={true}
                        setLogoError={setLogoError}
                        icon={"ArrowDownIcon"}
                      />
                    </div>
                  )}
                </div>

                <div className="flex flex-col gap-2.5">
                  {logoError && "Light" === logoType && (
                    <Typography as="span" variant="error" className={`text-sm`}>
                      {logoError}
                    </Typography>
                  )}
                  <label className="text-base font-semibold text-white">
                    {"Light"} logo
                  </label>

                  <label className="text-sm font-normal text-white">
                    {`This logo will be displayed on ${"dark"} backgrounds`}
                  </label>
                </div>
              </div>
              <div className="flex flex-col bg-white rounded-3xl p-7 md:w-1/2 min-h-52 gap-5 lg:w-1/2 sm:w-full w-full">
                <div className="flex gap-5">
                  <div className="avatar-container flex flex-col w-20 h-20 border border-gray-300">
                    <div className="relative">
                      <Avatar
                        src={imagePreviewDark || organization?.dark_logo}
                        name={organization?.name}
                        round="8px"
                        size="65"
                        unstyled={!!organization?.dark_logo}
                      />

                      {imagePreviewDark ? (
                        <small className="text-gray-400 text-xs avatar-text">
                          Preview
                        </small>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  {((pathname === "/settings/company" &&
                    user?.role === "Reseller_Admin") ||
                    (pathname === "/administration/general/company" &&
                      user?.role === "Customer_Admin")) && (
                    <div className="mt-1.5 h-10">
                      <FileInput
                        fieldName={"dark_logo"}
                        label="Upload New Logo"
                        instructions="JPG, GIF or PNG. 1MB max."
                        onFileChange={(e: any) => onImageChange(e, "Dark")}
                        accept="image/*"
                        maxSizeInMB={Number(
                          process.env.REACT_APP_IMAGE_TO_UPLOAD_MAX_SIZE_IN_MB
                        )}
                        isOrgUpdate={true}
                        setLogoError={setLogoError}
                        icon={"ArrowDownIcon"}
                      />
                    </div>
                  )}
                </div>

                <div className="flex flex-col gap-2.5">
                  {logoError && "Dark" === logoType && (
                    <Typography as="span" variant="error" className={`text-sm`}>
                      {logoError}
                    </Typography>
                  )}
                  <label className="text-black font-base font-semibold">
                    {"Dark"} logo
                  </label>

                  <label className="text-black text-sm font-normal ">
                    {`This logo will be displayed on ${"light"} backgrounds`}
                  </label>
                </div>
              </div>
            </div>
            <Card className="flex flex-col p-7 rounded-3xl gap-5">
              <div className="flex flex-col lg:flex-row md:flex-row gap-5">
                <div className="w-full lg:w-1/2 md:w-1/2">
                  <LabelledInput
                    disabled={
                      !(
                        (pathname === "/settings/company" &&
                          user?.role === "Reseller_Admin") ||
                        (pathname === "/administration/general/company" &&
                          user?.role === "Customer_Admin")
                      )
                    }
                    name="name"
                    placeholder="Enter name"
                    label="Name"
                    type="text"
                  />
                </div>

                {/* <div className="sm:col-span-3">
                <LabelledInput
                  disabled={
                    !(
                      (pathname === "/settings/company" &&
                        user?.role === "Reseller_Admin") ||
                      (pathname === "/administration/general/company" &&
                        user?.role === "Customer_Admin")
                    )
                  }
                  name="email"
                  placeholder="Enter email"
                  label="Email"
                  type="email"
                />
              </div> */}

                {/* <div className="sm:col-span-3">
                <LabelledInput
                  disabled={
                    !(
                      (pathname === "/settings/company" &&
                        user?.role === "Reseller_Admin") ||
                      (pathname === "/administration/general/company" &&
                        user?.role === "Customer_Admin")
                    )
                  }
                  name="address_line1"
                  placeholder="Enter address"
                  label="Address line 1"
                  type="text"
                />
              </div> */}

                {/* <div className="sm:col-span-3">
                <LabelledInput
                  disabled={
                    !(
                      (pathname === "/settings/company" &&
                        user?.role === "Reseller_Admin") ||
                      (pathname === "/administration/general/company" &&
                        user?.role === "Customer_Admin")
                    )
                  }
                  name="address_line2"
                  placeholder="Enter address"
                  label="Address line 2"
                  type="text"
                />
              </div> */}

                {/* <div className="sm:col-span-3">
                <LabelledInput
                  disabled={
                    !(
                      (pathname === "/settings/company" &&
                        user?.role === "Reseller_Admin") ||
                      (pathname === "/administration/general/company" &&
                        user?.role === "Customer_Admin")
                    )
                  }
                  name="address_city"
                  placeholder="Enter city"
                  label="City"
                  type="text"
                />
              </div> */}

                {/* <div className="sm:col-span-3">
                <LabelledInput
                  disabled={
                    !(
                      (pathname === "/settings/company" &&
                        user?.role === "Reseller_Admin") ||
                      (pathname === "/administration/general/company" &&
                        user?.role === "Customer_Admin")
                    )
                  }
                  name="address_state"
                  placeholder="Enter state"
                  label="State"
                  type="text"
                />
              </div> */}

                {/* <div className="sm:col-span-3">
                <LabelledInput
                  disabled={
                    !(
                      (pathname === "/settings/company" &&
                        user?.role === "Reseller_Admin") ||
                      (pathname === "/administration/general/company" &&
                        user?.role === "Customer_Admin")
                    )
                  }
                  name="address_country"
                  placeholder="Enter country"
                  label="Country"
                  type="text"
                />
              </div> */}

                {/* <div className="sm:col-span-3">
                <LabelledInput
                  disabled={
                    !(
                      (pathname === "/settings/company" &&
                        user?.role === "Reseller_Admin") ||
                      (pathname === "/administration/general/company" &&
                        user?.role === "Customer_Admin")
                    )
                  }
                  name="address_zip"
                  placeholder="Enter zip"
                  label="ZIP code"
                  type="text"
                />
              </div> */}

                {/* <div className="sm:col-span-3">
                <LabelledInput
                  disabled={
                    !(
                      (pathname === "/settings/company" &&
                        user?.role === "Reseller_Admin") ||
                      (pathname === "/administration/general/company" &&
                        user?.role === "Customer_Admin")
                    )
                  }
                  name="website"
                  placeholder="Enter website"
                  label="Website"
                  type="text"
                />
              </div> */}

                {/* <div className="sm:col-span-3">
                <LabelledInput
                  disabled={
                    !(
                      (pathname === "/settings/company" &&
                        user?.role === "Reseller_Admin") ||
                      (pathname === "/administration/general/company" &&
                        user?.role === "Customer_Admin")
                    )
                  }
                  name="tax_id"
                  placeholder="Enter tax id"
                  label="Tax Id"
                  type="text"
                />
                </div> */}
                <div className="w-full lg:w-1/2 md:w-1/2">
                  <LabelledSuffixInput
                    // disabled={!((pathname === "/settings/company" && user?.role === "Reseller_Admin") || (pathname === "/administration/general/company" && user?.role === "Customer_Admin"))}
                    disabled={true}
                    name="subdomain"
                    placeholder="Enter subdoomain"
                    label="Subdomain"
                    type="textbox"
                    suffix={`${process.env.REACT_APP_DNS}`}
                  />
                </div>
              </div>

              <LabelledTextArea
                disabled={
                  !(
                    (pathname === "/settings/company" &&
                      user?.role === "Reseller_Admin") ||
                    (pathname === "/administration/general/company" &&
                      user?.role === "Customer_Admin")
                  )
                }
                name="terms_and_conditions"
                placeholder="Enter terms and conditions"
                label="Terms and Conditions"
                type="text"
              />

              {/* {((pathname === "/settings/company" &&
                user?.role === "Reseller_Admin") ||
                (pathname === "/administration/general/company" &&
                  user?.role === "Customer_Admin")) && (
                <div className="items-end">
                  <LoadingButton
                    type="submit"
                    className="rounded-md px-2.5 py-1.5 text-sm font-semibold shadow-sm"
                    buttonText="Save"
                    loading={loading}
                  />
                </div>
              )} */}
            </Card>
          </div>
        </form>
      </FormProvider>
      <ImageCropOverlay
        modalProps={{ open: imageCropOverlay, setOpen: setImageCropOverlay }}
        OriginalImage={OriginalImage}
        setPreviewImage={setImagePreview}
        fileInputId={logoType === "Light" ? "light_logo" : "dark_logo"}
      />
    </div>
  );
}

export default Company;
