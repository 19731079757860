import React, { useContext, useState } from "react";
import { ThemeContext } from "../../../contexts/ThemeContext";
import Card from "../../../components/Card";
import SectionHeader from "../../../components/SectionHeader";

interface Props {
  listProps: {
    list: any[];
    setList: React.Dispatch<any>;
  };
  setSelected: any;
  searchTerm: any;
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

function TenantList({
  listProps: { list, setList },
  setSelected,
  searchTerm,
}: Props) {
  const [active, setActive] = useState<any>();
  const { theme } = useContext(ThemeContext)!;

  return (
    <div>
      <Card className="w-full h-[22rem] mt-6 overflow-y-auto">
        {list.length ? (
          list
            .filter((item) =>
              item.name.toLowerCase().includes(searchTerm.toLowerCase().trim())
            )
            .map((item, i) => (
              <button
                key={i} // Added key prop for each button
                onClick={() => {
                  setActive(i);
                  setSelected(item);
                }}
                type="button"
                className={classNames(
                  active === i ? theme.selectOptionFocusedBackgroundColor : "",
                  `relative inline-flex rounded-sm items-center w-full px-4 py-2 text-sm ${theme.selectOptionHoveredBackgroundColor}`
                )}
              >
                {item.name}
              </button>
            ))
        ) : (
          <div>
            <div className="text-center">
              <SectionHeader level={3} className="mt-2 text-sm font-semibold">
                No data found
              </SectionHeader>
            </div>
          </div>
        )}
      </Card>
    </div>
  );
}

export default TenantList;
