import React, { useContext } from "react";
import { Controller, useFormContext } from "react-hook-form";
import CreatableSelect from "react-select/creatable";
import { ThemeContext } from "../../contexts/ThemeContext";

export interface InputProps {
  name: string;
  options:
    | { value: string; label: string }[]
    | {
        label: string;
        options: {
          value: string;
          label: string;
        }[];
      }[];
  label: string;
  placeholder: string;
  isMulti?: boolean;
  isGrouped?: boolean;
  modalRef?: React.RefObject<HTMLDivElement>;
  value?: any;
  onChangeHandler?: (newValue: any) => void;
  isDisabled?: boolean;
  defaultValue?: any;
}

function LabelledCreatableSelect({
  name,
  options,
  label,
  placeholder,
  isMulti,
  isGrouped,
  modalRef,
  value,
  onChangeHandler,
  isDisabled,
  defaultValue,
}: InputProps) {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();
  const { theme } = useContext(ThemeContext)!;

  let multipleInput = true;
  if (isMulti === false) {
    multipleInput = false;
  }
  return (
    <>
      <div>
        <label
          className={`block text-sm font-medium leading-6 ${theme.labelTextColor}`}
        >
          {label}
        </label>
        <div className="mt-2">
          <Controller
            name={name}
            control={control}
            defaultValue={null} // Set the default value as needed
            rules={{ required: "Please select an option" }} // Add validation rules
            render={({ field, formState }) => (
              <>
                <CreatableSelect
                  {...field.value}
                  isMulti={multipleInput}
                  placeholder={placeholder}
                  className={
                    errors[name]
                      ? theme.inputErrorBorderColor
                      : theme.primaryButtonBorderColor
                  }
                  isClearable // Allow clearing the selection
                  options={options}
                  defaultValue={{
                    label: isGrouped ? field?.value?.value : field.value,
                    value: isGrouped ? field?.value?.value : field.value,
                  }}
                  isDisabled={isDisabled}
                  value={
                    isGrouped
                      ? field?.value?.value
                      : value
                      ? value
                      : field.value
                  }
                  onChange={(newValue: any) => {
                    if (onChangeHandler) {
                      onChangeHandler(newValue);
                    } else if (isGrouped && newValue?.value) {
                      field.onChange(newValue.value);
                    } else {
                      field.onChange(newValue);
                    }
                  }}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: state.isFocused
                        ? `${theme.inputFocusedBorderColorCode} !important`
                        : `${theme.inputBorderColorCode} !important`,
                      WebkitBoxShadow: state.isFocused
                        ? `0 0 0 1px ${theme.inputFocusedBorderColorCode} !important`
                        : `none !important`,
                      backgroundColor: theme.cardBackgroundColor,
                      color: theme.labelTextColorCode,
                    }),
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                    option: (base, state) => ({
                      ...base,
                      backgroundColor: state.isFocused
                        ? `${theme.selectOptionFocusedBackgroundColorCode} !important`
                        : theme.cardBackgroundColorCode,
                      color: theme.labelTextColorCode,
                    }),
                    singleValue: (base, state) => ({
                      ...base,
                      color: theme.labelTextColor,
                    }),
                    multiValue: (base, state) => ({
                      ...base,
                      backgroundColor:
                        theme.selectMultiValueBackgroundColorCode,
                    }),
                    multiValueLabel: (base, state) => ({
                      ...base,
                      color: theme.labelTextColorCode,
                    }),
                    input: (base) => ({
                      ...base,
                      color: theme.labelTextColorCode,
                    }),
                    menu: (base) => ({
                      ...base,
                      backgroundColor: theme.cardBackgroundColorCode,
                      border: `0.5px solid ${theme.inputBorderColorCode}`,
                    }),
                  }}
                  menuPortalTarget={modalRef ? modalRef.current : document.body}
                  menuPlacement="auto"
                  menuPosition="fixed"
                />
              </>
            )}
          />
          {errors && errors[name] && (
            <span className={`${theme.inputErrorTextColor} text-sm`}>
              {errors[name]?.message as string}
            </span>
          )}
        </div>
      </div>
    </>
  );
}

export default LabelledCreatableSelect;
