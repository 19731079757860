import { XMarkIcon } from '@heroicons/react/20/solid'
import useAuth from '../hooks/useAuth';
import { useEffect, useState } from 'react';

interface Props {
    text: string;
}

export default function Banner() {
    const { user, organization } = useAuth();
    const [daysDifference, setDaysDifference] = useState<any>(null);

    useEffect(() => {
        organization && timeDifference()
    }, [organization, user]);


    function timeDifference() {
        const today = new Date();
        const updatedAt: any = new Date(organization?.updated_at);
        let difference = today.getTime() - updatedAt.getTime();
        let temp = Math.floor(difference / 1000 / 60 / 60 / 24);

        setDaysDifference(temp);
    }


    return (
        <>
            {!organization?.is_active &&
                <div className="sticky self-stretch text-center w-full bg-red-600 py-1">
                    <span className="text-white">
                        {
                            ((7 - daysDifference) === 0)
                                ? `This Account is Deactivated and will be permanently deleted today`
                                : `This Account is Deactivated and will be permanently deleted in ${7 - (daysDifference)} ${((7 - daysDifference) === 1) ? "day" : "days"}.`
                        }
                    </span>
                </div>
            }
        </>

    )
}