import React, { useContext } from "react";
import { ThemeContext } from "../contexts/ThemeContext";

function HR({className}:any) {
  const { theme } = useContext(ThemeContext)!;

  return (
    <hr
    className={`${className}`}
      style={{
        backgroundColor: theme.horizontalLineColor,
        height: "1px",
        borderTopWidth: "inherit",
      }}
    />
  );
}

export default HR;
