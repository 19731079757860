import React, { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { ThemeContext } from "../../contexts/ThemeContext";

export interface InputProps {
  name: string;
  placeholder?: string;
  label: string;
  disabled?: boolean;
  type: "password" | "text" | "email" | "textarea" | string;
  disabledClass?: string;
  enableFocus?: boolean;
}

function LabelledInput({
  name,
  placeholder,
  label,
  type,
  disabled,
  disabledClass,
  enableFocus,
}: InputProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { theme } = useContext(ThemeContext)!;

  return (
    <>
      <div>
        <label
          className={`block text-sm font-medium leading-6 ${theme.labelTextColor}`}
        >
          {label}
        </label>
        <div className="mt-2">
          <input
            type={type}
            disabled={disabled}
            {...register(name)}
            className={`${disabledClass} block w-full rounded-md p-2 border-0 py-1.5 
            outline-none shadow-sm ring-1 ring-inset ${
              theme.inputBackgroundColor
            } 
            ${theme.inputBorderColor} 
            ${theme.labelTextColor}
            placeholder:text-md focus:outline-none focus:ring-2 ${
              theme.inputFocusedBorderColor
            } sm:leading-6  ${errors[name] ? theme.inputErrorBorderColor : ""}`}
            placeholder={placeholder}
            autoFocus={enableFocus}
          />
          {errors && errors[name] && (
            <span className={`${theme.inputErrorTextColor} text-sm`}>
              {errors[name]?.message as string}
            </span>
          )}
        </div>
      </div>
    </>
  );
}

export default LabelledInput;
