import { FC, ReactNode, useContext, useRef } from "react";
import { ThemeContext } from "../contexts/ThemeContext";
interface Props {
  children: ReactNode;
  text?: string;
  height?: string;
  width?: string;
  parentDiv?: any;
  show?: boolean; // Typically used to show the tooltip specifically on certain table action buttons.
  isTable?: boolean; // This helps prevent unwanted tooltip display for table rows that reuse this component.
}
const ToolTip: FC<Props> = ({
  children,
  text,
  height,
  width,
  parentDiv,
  show,
  isTable,
}): JSX.Element => {
  const tooltipRef = useRef<HTMLSpanElement>(null);
  const container = useRef<HTMLDivElement>(null);
  const { theme } = useContext(ThemeContext)!;
  return (
    <div
      hidden={!show}
      ref={container}
      onMouseEnter={({ clientX }) => {
        if (!height && !width) {
          if (!tooltipRef.current || !container.current) return;
          const { left } = container.current.getBoundingClientRect();
          const parentDivDetails = parentDiv?.current?.getBoundingClientRect();
          const tooltipRef1 = tooltipRef?.current?.getBoundingClientRect();
          if (
            parentDivDetails !== "undefined" &&
            parentDivDetails?.width < left
          )
            tooltipRef.current.style.left = clientX - left - 150 + "px";
          else if (
            parentDivDetails !== "undefined" &&
            parentDivDetails?.width - left - tooltipRef1.width < 0
          ) {
            tooltipRef.current.style.left =
              clientX - left - tooltipRef1.width + "px";
          } else if (
            (parentDivDetails !== "undefined" &&
              Math.abs(parentDivDetails?.left - left) <= 48) ||
            parentDivDetails?.left <= 48
          )
            tooltipRef.current.style.left = clientX - left + "px";
          else tooltipRef.current.style.left = clientX - left - 40 + "px";
        }
      }}
      className="group relative inline-block"
    >
      {text ? (
        <span
          ref={tooltipRef}
          className={`invisible text-xs ${
            (show || !isTable) && `group-hover:visible`
          } opacity-0 z-30 group-hover:opacity-100 transition ${
            theme.tooltipBackgroundColor
          } text-white p-1 rounded absolute bottom-full mb-1 left-0 ${
            height && width
              ? `${height} ${width} whitespace-wrap mb-2.5`
              : "whitespace-nowrap"
          }`}
        >
          {text}
        </span>
      ) : null}
      {children}
    </div>
  );
};
export default ToolTip;
