import React, { useContext, useState } from "react";
import Accordion from "../../../../components/Accordion";
import SectionHeader from "../../../../components/SectionHeader";
import Label from "../../../../components/Label";
import Typography from "../../../../components/Typography";
import { ThemeContext } from "../../../../contexts/ThemeContext";

export const AccordionTitle = ({ text }: { text: string }) => {
  return <span>{text}</span>;
};

interface Props {
  assessment: any;
}

function AssessmentTableAccordion({ assessment }: Props) {
  const { mode } = useContext(ThemeContext)!;
  const [currentOpenAccordion, setCurrentOpenAccordion] = useState<any>([]);
  return (
    <div>
      <div className="mb-6">
        <div>
          <div className="pb-1 ">
            <div>
              <div className="px-4 sm:px-0">
                <SectionHeader
                  level={3}
                  className="text-base font-semibold leading-7"
                >
                  Company Information
                </SectionHeader>
              </div>

              <div className="grid grid-cols-6 gap-x-10 gap-y-4">
                <div className="mt-2">
                  <Label className="text-sm font-medium leading-6">
                    Company Name
                  </Label>
                  <Typography
                    as="div"
                    variant="headingLabel"
                    className="mt-1 text-sm leading-6 font-normal"
                  >
                    {assessment.company_name}
                  </Typography>
                </div>
              </div>
            </div>

            <div className="mt-6">
              <div className="px-4 sm:px-0">
                <SectionHeader
                  level={3}
                  className="text-base font-semibold leading-7"
                >
                  Domain Information
                </SectionHeader>
              </div>

              <div className="grid grid-cols-5 gap-x-9 gap-y-4">
                <div className="mt-2">
                  <Label className="text-sm font-medium leading-6">
                    Domains
                  </Label>
                  <Typography
                    as="div"
                    variant="headingLabel"
                    className="mt-1 text-sm leading-6 font-normal"
                  >
                    {assessment?.domains &&
                      assessment?.domains.map((domain: any) => (
                        // <Badge theme="light" variant="secondary" text={domain} />
                        <span className="block">{domain}</span>
                      ))}
                  </Typography>
                </div>

                <div className="mt-2">
                  <Label className="text-sm font-medium leading-6">
                    Domain Hostnames
                  </Label>
                  <Typography
                    as="div"
                    variant="headingLabel"
                    className="mt-1 text-sm leading-6 font-normal"
                  >
                    {assessment?.domain_hostnames &&
                      assessment?.domain_hostnames.map(
                        (domain_hostname: any) => (
                          // <Badge theme="light" variant="secondary" text={domain_hostname} />
                          <span className="block">{domain_hostname}</span>
                        )
                      )}
                  </Typography>
                </div>
              </div>
            </div>

            <div className="mt-6">
              <div className="px-4 sm:px-0">
                <SectionHeader
                  level={3}
                  className="text-base font-semibold leading-7"
                >
                  IP Information
                </SectionHeader>
              </div>

              <div className="">
                <div className="mt-2">
                  <Label className="text-sm font-medium leading-6">
                    IP Addresses
                  </Label>
                  <Typography
                    as="div"
                    variant="headingLabel"
                    className="grid grid-cols-5 gap-x-9 gap-y-4 mt-1 text-sm leading-6 font-normal"
                  >
                    {assessment?.ip_addresses &&
                      assessment?.ip_addresses.map(
                        (ip_address: any, index: any) => (
                          // <Badge theme="light" variant="secondary" text={ip_address} />
                          <Accordion
                            background={
                              mode === "light" ? "bg-white" : "bg-gray-800"
                            }
                            border={mode === "light" ? "border" : ""}
                            uniqueKey={ip_address}
                            entity="assessment_ip_address"
                            setCurrentOpenAccordion={setCurrentOpenAccordion}
                            title={
                              <div className="flex px-2 justify-between">
                                <span>{ip_address}</span>
                              </div>
                            }
                          >
                            <>
                              <div className="">
                                <dl className="divide-y divide-gray-100">
                                  <div className="px-3 py-2">
                                    <div className="mb-2">
                                      <Typography
                                        as="dt"
                                        variant="headingLabel"
                                        className="text-sm font-medium"
                                      >
                                        ASN
                                      </Typography>
                                      <Typography
                                        as="dd"
                                        variant="valueLabel"
                                        className="text-sm leading-6 sm:mt-0"
                                      >
                                        {assessment?.ip_addresses_data[index]
                                          ? JSON.parse(
                                              assessment?.ip_addresses_data[
                                                index
                                              ]
                                            )?.asn !== ""
                                            ? JSON.parse(
                                                assessment?.ip_addresses_data[
                                                  index
                                                ]
                                              )?.asn
                                            : "---"
                                          : ""}
                                      </Typography>
                                    </div>

                                    <div className="mb-2">
                                      <Typography
                                        as="dt"
                                        variant="headingLabel"
                                        className="text-sm font-medium"
                                      >
                                        ISP
                                      </Typography>
                                      <Typography
                                        as="dd"
                                        variant="valueLabel"
                                        className="text-sm leading-6 sm:mt-0"
                                      >
                                        {assessment?.ip_addresses_data[index]
                                          ? JSON.parse(
                                              assessment?.ip_addresses_data[
                                                index
                                              ]
                                            )?.isp !== ""
                                            ? JSON.parse(
                                                assessment?.ip_addresses_data[
                                                  index
                                                ]
                                              )?.isp
                                            : "---"
                                          : ""}
                                      </Typography>
                                    </div>

                                    <div className="">
                                      <Typography
                                        as="dt"
                                        variant="headingLabel"
                                        className="text-sm font-medium"
                                      >
                                        Region
                                      </Typography>
                                      <Typography
                                        as="dd"
                                        variant="valueLabel"
                                        className="text-sm leading-6 sm:mt-0"
                                      >
                                        {assessment?.ip_addresses_data[index]
                                          ? JSON.parse(
                                              assessment?.ip_addresses_data[
                                                index
                                              ]
                                            )?.region !== ""
                                            ? JSON.parse(
                                                assessment?.ip_addresses_data[
                                                  index
                                                ]
                                              )?.region
                                            : "---"
                                          : ""}
                                      </Typography>
                                    </div>
                                  </div>
                                </dl>
                              </div>
                            </>
                          </Accordion>
                        )
                      )}
                  </Typography>
                </div>

                <div className="mt-4">
                  <Label className="text-sm font-medium leading-6 ">
                    IP Blocks
                  </Label>
                  <div className="grid grid-cols-5 gap-x-9 gap-y-1 mt-1 text-sm font-normal">
                    {assessment?.ip_blocks &&
                      assessment?.ip_blocks.map((ip_block: any, index: any) => (
                        // <Badge theme="light" variant="secondary" text={ip_block} />
                        // <span className='block'>{ip_block}</span>
                        <Accordion
                          background={
                            mode === "light" ? "bg-white" : "bg-gray-800"
                          }
                          border={mode === "light" ? "border" : ""}
                          uniqueKey={ip_block}
                          entity="assessment_ip_block"
                          setCurrentOpenAccordion={setCurrentOpenAccordion}
                          title={
                            <div className="flex px-2 justify-between">
                              {/* <span className="text-gray-950">Element Details</span> */}
                              <span>{ip_block}</span>
                            </div>
                          }
                        >
                          <>
                            <div className="">
                              <dl className="divide-y divide-gray-100">
                                <div className="px-3 py-2">
                                  <div className="mb-2">
                                    <Typography
                                      as="dt"
                                      variant="headingLabel"
                                      className="text-sm font-medium"
                                    >
                                      ASN
                                    </Typography>
                                    <Typography
                                      as="dd"
                                      variant="valueLabel"
                                      className="text-sm leading-6 sm:mt-0"
                                    >
                                      {assessment?.ip_blocks_data[index]
                                        ? JSON.parse(
                                            assessment?.ip_blocks_data[index]
                                          )?.asn !== ""
                                          ? JSON.parse(
                                              assessment?.ip_blocks_data[index]
                                            )?.asn
                                          : "---"
                                        : ""}
                                    </Typography>
                                  </div>

                                  <div className="mb-2">
                                    <Typography
                                      as="dt"
                                      variant="headingLabel"
                                      className="text-sm font-medium"
                                    >
                                      ISP
                                    </Typography>
                                    <Typography
                                      as="dd"
                                      variant="valueLabel"
                                      className="text-sm leading-6 sm:mt-0"
                                    >
                                      {assessment?.ip_blocks_data[index]
                                        ? JSON.parse(
                                            assessment?.ip_blocks_data[index]
                                          )?.isp !== ""
                                          ? JSON.parse(
                                              assessment?.ip_blocks_data[index]
                                            )?.isp
                                          : "---"
                                        : ""}
                                    </Typography>
                                  </div>

                                  <div className="">
                                    <Typography
                                      as="dt"
                                      variant="headingLabel"
                                      className="text-sm font-medium"
                                    >
                                      Region
                                    </Typography>
                                    <Typography
                                      as="dd"
                                      variant="valueLabel"
                                      className="text-sm leading-6 sm:mt-0"
                                    >
                                      {assessment?.ip_blocks_data[index]
                                        ? JSON.parse(
                                            assessment?.ip_blocks_data[index]
                                          )?.region !== ""
                                          ? JSON.parse(
                                              assessment?.ip_blocks_data[index]
                                            )?.region
                                          : "---"
                                        : ""}
                                    </Typography>
                                  </div>
                                </div>
                              </dl>
                            </div>
                          </>
                        </Accordion>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssessmentTableAccordion;
