import React, { Fragment, useContext, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import LabelledInput from "../../../components/inputs/LabelledInput";
import LabelledList from "../../../components/LabelledList";
import { get, post, put } from "../../../utils/httpMethods";
import LoadingButton from "../../../components/buttons/LoadingButton";
import useAuth from "../../../hooks/useAuth";
import { GlobalToasterContext } from "../../../contexts/ToasterContext";
import { useLocation } from "react-router-dom";
import CancelButton from "../../../components/buttons/CancelButton";
import { ThemeContext } from "../../../contexts/ThemeContext";
import SectionHeader from "../../../components/SectionHeader";
import Overlay from "../../../components/Overlay";
import toast from "react-hot-toast";

const super_user_roles = [
  {
    name: "WanAware_Super_User",
    value: "Admin",
  },
  {
    name: "WanAware_Engineer",
    value: "Engineer",
  },
  {
    name: "WanAware_Support",
    value: "Support",
  },
  {
    name: "WanAware_Report_User",
    value: "Report User",
  },
  {
    name: "WanAware_Finance",
    value: "Finance",
  },
];
const reseller_admin_roles = [
  {
    name: "Reseller_Admin",
    value: "Admin",
  },
  {
    name: "Reseller_Engineer",
    value: "Engineer",
  },
  {
    name: "Reseller_Support",
    value: "Support",
  },
  {
    name: "Reseller_Report_User",
    value: "Report User",
  },
  {
    name: "Reseller_Finance",
    value: "Finance",
  },
];
const customer_admin_roles = [
  {
    name: "Customer_Admin",
    value: "Admin",
  },

  {
    name: "Customer_Engineer",
    value: "Engineer",
  },
  {
    name: "Customer_Support",
    value: "Support",
  },
  {
    name: "Customer_Report_User",
    value: "Report User",
  },
  {
    name: "Customer_Finance",
    value: "Finance",
  },
];
interface addUserModalProps {
  modalProps: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  };
  user: any;
  fetchUsers: any;
  getUserAssignedCompanyAndRoles: any;
}

type Inputs = {
  first_name: string;
  last_name: string;
  email: string;
  role: string;
};

const addUserSchema = yup
  .object({
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required"),
    email: yup.string().required("Email is required"),
    role: yup.string().required("Role is required"),
  })
  .required();

export default function EditUserModal({
  modalProps: { open, setOpen },
  user,
  fetchUsers,
  getUserAssignedCompanyAndRoles,
}: addUserModalProps) {
  const cancelButtonRef = useRef(null);
  const { user: loggedUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { theme } = useContext(ThemeContext)!;
  const { setShowErrorOverlay } = useContext(GlobalToasterContext)!;

  const methods = useForm<Inputs>({
    resolver: yupResolver(addUserSchema),
    defaultValues: {
      first_name: user.first_name || "",
      last_name: user.last_name || "",
      email: user.email || "",
      role: user.role || "",
    },
  });

  const {
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = methods;
  const { role } = watch();
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      setLoading(true);
      const result: any = await put(`/api/users/${user.id}`, data);
      const userParentCompanyRole =
        data.role.includes("Admin") || data.role.includes("Super_User")
          ? "Admin"
          : data.role.includes("Engineer")
          ? "Engineer"
          : data.role.includes("Support")
          ? "Support"
          : data.role.includes("Report_User")
          ? "Report_User"
          : "Finance";
      const userAssignedCompanyAndRoles: any = await get(
        `/api/company-users/${user.id}`
      );
      const userAssignedParentCompany = userAssignedCompanyAndRoles.find(
        (each: any) => each.is_parent_company_role
      );
      await put(`/api/company-users/${userAssignedParentCompany.id}`, {
        role: userParentCompanyRole,
      });
      toast.success(result.message);
      setLoading(false);
      setOpen(false);
      fetchUsers();
      getUserAssignedCompanyAndRoles();
    } catch (error: any) {
      toast.error(error.message);
      setShowErrorOverlay(true);
      setLoading(false);
      setOpen(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setInterval(() => {
      reset();
    }, 1000);
  };

  return (
    <Overlay
      open={open}
      titleContent="Update User"
      handleClose={handleClose}
      className="sm:w-3/6"
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-2 gap-x-6 gap-y-4">
            <LabelledInput
              name="first_name"
              placeholder=""
              label="First name"
              type="text"
            />
            <LabelledInput
              name="last_name"
              placeholder=""
              label="Last name"
              type="text"
            />

            <LabelledInput
              name="email"
              placeholder=""
              label="Email"
              type="text"
              disabled={true}
            />
            <LabelledList
              options={
                location.pathname === "/administration/users" ||
                location.pathname.includes("/super-user/customers")
                  ? customer_admin_roles
                  : location.pathname.includes("/super-user/resellers")
                  ? reseller_admin_roles
                  : loggedUser.role === "Reseller_Admin"
                  ? reseller_admin_roles
                  : loggedUser.role === "WanAware_Super_User"
                  ? super_user_roles
                  : customer_admin_roles
              }
              label="Role"
              name="role"
              error={errors.role}
              defaultValue={role}
            />
          </div>

          <div className="flex mt-8 space-x-2 justify-end">
            <CancelButton type="button" onClick={() => handleClose()}>
              Cancel
            </CancelButton>

            <LoadingButton
              type="submit"
              className="rounded-md px-2.5 py-1.5 text-sm font-semibold shadow-sm"
              buttonText="Save"
              loading={loading}
            />
          </div>
        </form>
      </FormProvider>
    </Overlay>
  );
}
