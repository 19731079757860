import React, { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { ThemeContext } from "../../contexts/ThemeContext";
import Label from "../Label";

export interface SuffixInputProps {
  name: string;
  placeholder?: string;
  label: string;
  disabled?: boolean;
  type: "password" | "text" | "email" | "textarea" | string;
  disabledClass?: string;
  suffix: string;
}

function LabelledSuffixInput({
  name,
  placeholder,
  label,
  type,
  disabled,
  disabledClass,
  suffix,
}: SuffixInputProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { theme } = useContext(ThemeContext)!;

  return (
    <>
      <div>
        <label
          className={`block text-sm font-medium leading-6 ${theme.labelTextColor}`}
        >
          {label}
        </label>
        <div className="mt-2">
          <div className="flex">
            <input
              type={type}
              disabled={disabled}
              {...register(name)}
              className={`outline-0 border-0 ring-1 ring-gray-300 ${
                theme.labelTextColor
              } focus:ring-2 px-2 w-1/2 placeholder:text-md  rounded-md  p-2 py-1.5 focus:ring-2 focus:outline-none focus:border-${
                theme.inputFocusedBorderColor
              }
                ${theme.inputBorderColor}
              ${theme.inputBackgroundColor}
              ${theme.inputFocusedBorderColor} sm:leading-6  ${
                errors[name] ? `${theme.inputErrorBorderColor}` : ""
              }`}
              placeholder={placeholder}
            />
            <Label className=" px-2 mt-2">.{suffix}</Label>
          </div>
        </div>
        {errors && errors[name] && (
          <span className={`${theme.inputErrorTextColor} text-sm`}>
            {errors[name]?.message as string}
          </span>
        )}
      </div>
    </>
  );
}

export default LabelledSuffixInput;
