import React from "react";
interface Props {
  //   variant: "success" | "error" | "primary" | "warning" | "secondary" | "high";
  text: string;
}

function NotificationBadge({ text }: Props) {
  return (
    <span className="inline-flex items-center rounded-full bg-red-500 px-2 py-1 text-xs font-medium text-white ring-2 ring-inset ring-red-100 absolute -top-3">
      {text}
    </span>
  );
}

export default NotificationBadge;
