import React, { Fragment, useContext, useEffect, useState } from "react";
import LoadingButton from "../../../components/buttons/LoadingButton";
import { GlobalToasterContext } from "../../../contexts/ToasterContext";
import { Roles } from "../../../constants/Roles";
import { TrashIcon } from "@heroicons/react/24/outline";
import { post, put } from "../../../utils/httpMethods";
import { PlusIcon } from "@heroicons/react/24/outline";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import useAuth from "../../../hooks/useAuth";
import { useLocation } from "react-router-dom";
import SelectWrapper from "../../../components/selects/SelectWrapper";
import CancelButton from "../../../components/buttons/CancelButton";
import GhostButton from "../../../components/buttons/GhostButton";
import { ThemeContext } from "../../../contexts/ThemeContext";
import HR from "../../../components/HR";
import IconWrapper from "../../../components/IconWrapper";
import MenuComponent from "../../../components/MenuComponent";
import Label from "../../../components/Label";
import toast from "react-hot-toast";

export const AccordionTitle = ({ text }: { text: string }) => {
  return <span>{text}</span>;
};

function UserAccordion({
  user,
  setOpenAccordion,
  companies,
  userAssignedCompanies,
  menuListItems,
  getUserAssignedCompanyAndRoles,
  fetchUsers,
  setAssignCustomer,
  totalCustomersAssigned,
}: any) {
  const { user: loggedUser } = useAuth();
  const { pathname } = useLocation();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const existingCompanies =
    userAssignedCompanies && userAssignedCompanies.length
      ? userAssignedCompanies.map((company: any) => company.gql_structure_id)
      : [];
  const [companyUsers, setCompanyUsers] = useState<any>(
    userAssignedCompanies && userAssignedCompanies.length
      ? userAssignedCompanies.map(
          ({ created_at, ...restData }: any, index: any) => {
            return {
              ...restData,
              objId: index + 1,
            };
          }
        )
      : [
          {
            objId: 1,
            gql_structure_id: "",
            role: "",
            user_id: user.id,
          },
        ]
  );
  const [deletedRoles, setDeletedRoles] = useState<any>([]);
  const { setModalProps } = useContext(GlobalToasterContext)!;
  const { theme } = useContext(ThemeContext)!;
  const { setShowErrorOverlay } = useContext(GlobalToasterContext)!;

  const submitHandler = async () => {
    const tempUsers = companyUsers
      .map(({ objId, ...restData }: any) => restData)
      .filter(({ gql_structure_id, role }: any) => gql_structure_id && role);
    // segregate company users according to old assigned company-role update and newly assigned company-role based on "id" property in companyUsers
    const toUpdateUsers = tempUsers.filter((each: any) => each.id);
    const toAddUsers = tempUsers.filter((each: any) => !each.id);
    const toDeleteUsers = [...deletedRoles];
    try {
      if (
        toAddUsers?.length ||
        toUpdateUsers?.length ||
        toDeleteUsers?.length
      ) {
        setLoading(true);
        await post(`/api/company-users`, {
          toUpdateUsers,
          toAddUsers,
          toDeleteUsers,
        });
        if (toUpdateUsers?.length) {
          const parentCompanyRole = toUpdateUsers.find(
            (user: any) => user.is_parent_company_role
          );
          if (parentCompanyRole) {
            const primaryUserRole = user.role.includes("WanAware")
              ? parentCompanyRole.role === `Admin`
                ? `WanAware_Super_User`
                : `WanAware_${parentCompanyRole.role}`
              : user.role.includes("Reseller")
              ? `Reseller_${parentCompanyRole.role}`
              : `Customer_${parentCompanyRole.role}`;
            await put(`/api/users/${user.id}`, { role: primaryUserRole });
            fetchUsers();
          }
        }
        getUserAssignedCompanyAndRoles();
        handleClose();
        setLoading(false);
        toast.success("User Updated Successfully");
      } else {
        throw new Error("Please enter valid company and role details");
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(error.message);
      setShowErrorOverlay(true);
    }
  };

  const handleClose = () => {
    // reset();
    setOpenAccordion(false);
    // setInterval(() => {
    //   reset();
    // }, 1000);
  };

  const roles = Roles.company_user_roles.map((eachRole) => {
    return {
      value: eachRole.name,
      label: eachRole.value,
    };
  });

  const addCompanyRoleHandler = () => {
    setCompanyUsers([
      ...companyUsers,
      {
        objId: +companyUsers[companyUsers.length - 1].objId + 1,
        gql_structure_id: "",
        role: "",
        user_id: user.id,
      },
    ]);
  };

  const onCompanyRoleChangeHandler = (label: string, value: any, id: any) => {
    const temp = companyUsers.map((each: any) => {
      if (each.objId == id) {
        return {
          ...each,
          [label]: value,
        };
      } else {
        return each;
      }
    });
    setCompanyUsers(temp);
  };

  const deleteCompanyRole = (objId: any, data: any) => {
    // Find roles to be deleted
    const deletedRolesTemp = companyUsers.filter(
      (each: any) => each.objId == objId && data.id
    );
    setDeletedRoles((prev: any) => [...prev, ...deletedRolesTemp]);
    const temp = companyUsers.filter((each: any) => each.objId != objId);
    if (!temp?.length) {
      setCompanyUsers([
        {
          objId: 1,
          gql_structure_id: "",
          role: "",
          user_id: user.id,
        },
      ]);
    } else {
      setCompanyUsers(temp);
    }
  };

  const onDelete = (objId: any, data: any) => {
    setModalProps({
      show: true,
      title: "Delete Role",
      message: `Are you sure you want to delete?`,
      deleteFunction: () => deleteCompanyRole(objId, data),
    });
  };

  useEffect(() => {
    setCompanyUsers(
      userAssignedCompanies && userAssignedCompanies.length
        ? userAssignedCompanies.map(
            ({ created_at, ...restData }: any, index: any) => {
              return {
                ...restData,
                objId: index + 1,
              };
            }
          )
        : [
            {
              objId: 1,
              gql_structure_id: "",
              role: "",
              user_id: user.id,
            },
          ]
    );
  }, [userAssignedCompanies]);

  return (
    <>
      {user && (
        <div>
          <HR />
          {(pathname === "/super-user/wanaware-admin/employees" ||
            pathname === "/settings/users" ||
            pathname === "/settings/employees" ||
            (pathname === "/administration/users" &&
              loggedUser.role === "Customer_Admin") ||
            loggedUser.role === "WanAware_Super_User" ||
            loggedUser.role === "Reseller_Admin") &&
            menuListItems.length > 0 && (
              <div className="flex mr-8 mt-4 mb-4 justify-end">
                <MenuComponent
                  menuItems={menuListItems}
                  menuIcon={
                    <IconWrapper
                      className="w-5 h-5"
                      icon={EllipsisVerticalIcon}
                    />
                  }
                  menuItemsClassName="absolute right-8 -mt-10 w-44 origin-top-right rounded-lg shadow py-2 shadow-lg focus:outline-none"
                  menuClassName="absolute z-50"
                  disabled={
                    !userAssignedCompanies?.length &&
                    menuListItems.find(
                      (menu: any) => menu.title === "Edit Roles"
                    )
                      ? true
                      : false
                  }
                  menuItemClassName={`capitalize ${
                    !userAssignedCompanies?.length &&
                    menuListItems.find(
                      (menu: any) => menu.title === "Edit Roles"
                    )
                      ? "cursor-default"
                      : "cursor-pointer"
                  }`}
                />
              </div>
            )}

          {(((pathname === "/super-user/wanaware-admin/employees" ||
            pathname === "/super-user/resellers") &&
            user.role !== "WanAware_Super_User") ||
            ((pathname === "/settings/users" ||
              pathname === "/settings/employees") &&
              (loggedUser.role === "WanAware_Super_User" ||
                loggedUser.role === "Reseller_Admin"))) && (
            <div>
              <button
                className="mb-4 text-sm text-blue-600 dark:text-blue-500 hover:underline"
                onClick={() => setAssignCustomer(true)}
              >
                {user.role !== "WanAware_Super_User" &&
                user.role !== "Reseller_Admin"
                  ? ` ${totalCustomersAssigned ?? 0} ${
                      totalCustomersAssigned === 1 ? "Customer" : "Customers"
                    } Assigned`
                  : ""}
              </button>
            </div>
          )}
          {/* <div className="grid grid-cols-4 gap-x-4 gap-y-4"> */}
          {companyUsers
            .sort(
              (a: any, b: any) =>
                Number(b.is_parent_company_role) -
                Number(a.is_parent_company_role)
            )
            .map(({ objId, ...eachCompanyUser }: any, index: any) => {
              return (
                <div className="pr-6 grid grid-cols-[1fr,1fr,auto] gap-x-4 mb-8 items-center">
                  <div className="flex justify-between items-center">
                    <Label className="block text-sm font-medium text-left pr-3 pt-1 leading-6">
                      Company
                    </Label>
                    <SelectWrapper
                      name=""
                      className="flex-grow mt-2 outline-none self-end text-gray-900 "
                      // isMulti
                      isDisabled={
                        (eachCompanyUser.id &&
                          eachCompanyUser.is_parent_company_role) ||
                        pathname.includes("/super-user/resellers") ||
                        pathname.includes("/super-user/customers") ||
                        (pathname === "/administration/users" &&
                          loggedUser.role !== "Customer_Admin")
                      }
                      onChange={(e: any) => {
                        onCompanyRoleChangeHandler(
                          "gql_structure_id",
                          e.value,
                          objId
                        );
                      }}
                      placeholder="Select Company"
                      value={
                        (user?.organization_name && {
                          label: user?.organization_name,
                          value: user?.organization_id,
                        }) ??
                        companies.find(
                          (option: any) =>
                            option.value ===
                            companyUsers[index].gql_structure_id
                        ) ??
                        ""
                      }
                      options={companies.filter(
                        (company: any) =>
                          !existingCompanies.includes(company.value)
                      )}
                    />
                  </div>
                  <div className="flex justify-between items-center">
                    <Label className="block text-sm font-medium  text-left pl-2 pr-3 pt-1 leading-6">
                      Role
                    </Label>
                    <SelectWrapper
                      name=""
                      isDisabled={
                        pathname.includes("/super-user/resellers") ||
                        pathname.includes("/super-user/customers") ||
                        (pathname === "/administration/users" &&
                          loggedUser.role !== "Customer_Admin")
                      }
                      className={`grow mt-2 outline-none self-end text-gray-900 ${
                        index !== companyUsers.length - 1 ? "mr-0" : ""
                      }`}
                      onChange={(e: any) => {
                        onCompanyRoleChangeHandler("role", e.value, objId);
                      }}
                      placeholder="Select Role"
                      value={
                        roles.find(
                          (option: any) =>
                            option.value === companyUsers[index].role
                        ) ?? ""
                      }
                      options={roles}
                    />
                  </div>
                  {(pathname === "/super-user/wanaware-admin/employees" ||
                    pathname === "/settings/users" ||
                    pathname === "/settings/employees" ||
                    (pathname === "/administration/users" &&
                      loggedUser.role === "Customer_Admin")) && (
                    <div className="flex w-20">
                      {(index !== 0 || !eachCompanyUser.id) && (
                        <GhostButton
                          type="button"
                          disabled={
                            !eachCompanyUser.id && companyUsers?.length == 1
                          }
                          className="px-1 ml-3 mr-2 mt-2 py-1"
                          onClick={() =>
                            deleteCompanyRole(objId, eachCompanyUser)
                          }
                        >
                          <TrashIcon className="w-4 h-4" />
                        </GhostButton>
                      )}
                      {index === companyUsers.length - 1 && (
                        <GhostButton
                          type="button"
                          // disabled={!eachCompanyUser.id && companyUsers?.length == 1}
                          className={`px-3 mt-2 py-1 text-sm ${theme.primaryTextColor}`}
                          onClick={addCompanyRoleHandler}
                          disabled={
                            index !== 0 &&
                            !(
                              companyUsers[index]?.gql_structure_id &&
                              companyUsers[index]?.role
                            )
                          }
                        >
                          +
                        </GhostButton>
                      )}
                    </div>
                  )}
                </div>
              );
            })}

          {(pathname === "/super-user/wanaware-admin/employees" ||
            pathname === "/settings/users" ||
            pathname === "/settings/employees" ||
            (pathname === "/administration/users" &&
              loggedUser.role === "Customer_Admin")) && (
            <div className="flex space-x-2 justify-end pr-6">
              <CancelButton type="button" onClick={() => handleClose()}>
                Cancel
              </CancelButton>

              <LoadingButton
                type="button"
                onClick={submitHandler}
                className="rounded-md px-2.5 py-1.5 text-sm font-semibold shadow-sm"
                buttonText="Save"
                loading={loading}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default UserAccordion;
