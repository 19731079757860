import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext, useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import * as Yup from "yup";
import LabelledSelect from "../../../components/selects/LabelledSelect";
import LabelledInput from "../../../components/inputs/LabelledInput";
import LabelledTextArea from "../../../components/inputs/LabelledTextArea";
import FileInput from "../../../components/inputs/FileInput";
import { GlobalToasterContext } from "../../../contexts/ToasterContext";
import ToggleSwitchBasic from "../../../components/toggles/ToggleSwitchBasic";
import { Switch } from "@headlessui/react";
import CancelButton from "../../../components/buttons/CancelButton";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import SectionHeader from "../../../components/SectionHeader";

const messageSchema = Yup.object().shape({
  recipient: Yup.string().required("Recipient is required!"),
  title: Yup.string().required("Title is required!"),
  text: Yup.string().required("Text is required!"),
  attachment: Yup.mixed(),
});

const recipientOptions = [
  {
    label: "Global",
    value: "global",
  },
  {
    label: "Global Admin Only",
    value: "global_admin_only",
  },
  {
    label: "Reseller Admin Only",
    value: "reseller_admin_only",
  },
  {
    label: "Reseller Employees Only",
    value: "reseller_employees_only",
  },
  {
    label: "Customer Admin Only",
    value: "customer_admin_only",
  },

  {
    label: "Customer Only",
    value: "customer_only",
  },
  {
    label: "Customer User Only",
    value: "customer_user_only",
  },
];

function MessageCenter() {
  const { setInfoModalProps } = useContext(GlobalToasterContext)!;

  const methods = useForm<any>({
    resolver: yupResolver(messageSchema),
  });

  const {
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = methods;

  const { recipient } = watch();

  const onSubmit: SubmitHandler<any> = async (data) => {
    console.log(data);
  };

  const [mode, setMode] = useState("Message");
  const [enabled, setEnabled] = useState(false);
  const [attachment, setAttachment] = useState<any>();

  const onImageChange = async (event: any) => {
    if (event.target.files && event.target.files[0]) {
      setAttachment(event.target.files[0]);
    }
  };
  console.log(attachment);
  const [logoError, setLogoError] = useState<any>();

  const [showInfo, setShowInfo] = useState(false);

  //   useEffect(() => {
  //     setInfoModalProps({
  //       show: true,
  //       title: "Alert",
  //       message: `This message is being sent to ${recipient}, are you sure you would like to send it?`,
  //     });
  //   }, [recipient]);

  return (
    <div>
      <SectionHeader
        level={2}
        className="text-base font-semibold leading-7 mb-6"
      >
        {" "}
        Send Notifications{" "}
      </SectionHeader>
      <div className="form-wrapper bg-white shadow-lg p-4 rounded-lg">
        <FormProvider {...methods}>
          <div className="space-y-6">
            <LabelledSelect
              options={recipientOptions}
              name="recipient"
              label="Recipient"
            />

            {recipient ? (
              <span className="text-sm text-gray-500">
                {`This message is being sent to ${
                  recipientOptions.filter((elem) => elem.value === recipient)[0]
                    .label
                }, are you sure you
                would like to send it?`}
              </span>
            ) : (
              <></>
            )}
            <LabelledInput
              name="title"
              label="Title"
              type="text"
              placeholder={`Enter ${mode} Title`}
            />
            <div className="flex align-middle space-x-4">
              <label
                htmlFor="about"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Send Message
              </label>

              <Switch
                checked={enabled}
                onChange={setEnabled}
                className={`${
                  enabled ? "bg-primary-600" : "bg-purple-500"
                } relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span className="sr-only">Enable notifications</span>
                <span
                  className={`${
                    enabled ? "translate-x-6" : "translate-x-1"
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>

              <label
                htmlFor="about"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Send Notification
              </label>
            </div>

            <LabelledTextArea label={`${mode} Body`} name="text" type="text" />

            <div className="flex">
              <FileInput
                fieldName={"avatar"}
                label="Add Attachment"
                instructions="Attach a file with the message (10MB max.)"
                onFileChange={onImageChange}
                accept="image/*, .pdf"
                maxSizeInMB={10}
                setLogoError={setLogoError}
              />

              {attachment ? (
                <span className="text-sm text-gray-600">{attachment.name}</span>
              ) : (
                <></>
              )}
            </div>
            <div className="flex space-x-4 ">
              <CancelButton type="button">Cancel</CancelButton>
              <PrimaryButton type="button" className="px-2.5 py-1.5">
                Send
              </PrimaryButton>
            </div>
          </div>
        </FormProvider>
      </div>
    </div>
  );
}

export default MessageCenter;
