import React, { useContext, useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { XMarkIcon } from "@heroicons/react/20/solid";
import Scrollbar from "../Scrollbar";
import SettingMode from "./SettingMode";
import { ThemeContext } from "../../contexts/ThemeContext";
import Presets from "./Presets";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
function SettingsDrawer({ open, setOpen }: Props) {
  const drawerRef = useRef<any>(null);
  const { theme, mode } = useContext(ThemeContext)!;

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOutside = (event: any) => {
    if (drawerRef.current && !drawerRef.current?.contains(event.target)) {
      handleClose();
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);
  return (
    <div>
      <AnimatePresence>
        {open && (
          <>
            <div
              className="fixed inset-0 z-40 bg-opacity-50"
              onClick={handleClose}
            ></div>
            <motion.div
              ref={drawerRef}
              className={`fixed top-4 right-3 bottom-4 flex flex-col w-72  shadow-lg z-50 rounded-2xl ${theme.cardBackgroundColor} ${theme.labelTextColor}`}
              initial={{ x: "100%" }}
              animate={{ x: "0%" }}
              exit={{ x: "100%" }}
              transition={{ duration: 0.32 }}
            >
              <div className="flex items-center justify-between py-4 m-1 p-4 ">
                <h2 className="flex-grow text-md font-semibold">Settings</h2>
                <div className={`flex space-x-2`}>
                  <button
                    className={`p-2 rounded-full ${
                      mode === "dark"
                        ? "hover:bg-gray-600"
                        : "hover:bg-gray-200"
                    } dark:hover:bg-gray-700`}
                    onClick={handleClose}
                  >
                    <XMarkIcon width={20} height={20} />
                  </button>
                </div>
              </div>
              <hr
                className={`border-dashed ${
                  mode === "dark" && "border-gray-600"
                }`}
              />

              <Scrollbar className="flex-grow overflow-y-auto">
                <div className="space-y-6 p-4 pl-6">
                  <div className="space-y-2 my-3">
                    <h3 className="text-sm font-semibold pb-2">Mode</h3>
                    <SettingMode />
                  </div>
                  <div className="space-y-2 my-3">
                    <h3 className="text-sm font-semibold pb-2">Presets</h3>
                    <Presets />
                  </div>
                </div>
              </Scrollbar>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
}

export default SettingsDrawer;
