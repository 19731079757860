import React, { useContext, useEffect } from "react";
import "./App.css";
import Router from "./routes";
import useAuth from "./hooks/useAuth";
import LoadingScreen from "./components/loaders/LoadingScreen";
import { useLocation } from "react-router-dom";
import ThemeSettings from "./components/settings";
import { ThemeContext } from "./contexts/ThemeContext";
import GlobalToaster from "./components/GlobalToaster";

function App() {
  const { user } = useAuth();
  const { pathname } = useLocation();
  const { theme, mode } = useContext(ThemeContext)!;

  useEffect(() => {
    document.body.className = `${mode} ${theme.bodyBackgroundColor}`;
  }, [theme]);
  return (
    <>
      <GlobalToaster />
      <ThemeSettings>
        {!user &&
        !pathname.includes("auth") &&
        !pathname.includes("attacksurface") ? (
          <LoadingScreen />
        ) : (
          <Router />
        )}
      </ThemeSettings>
    </>
  );
}

export default App;
