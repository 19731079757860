import React, { useContext } from "react";
import { InputProps } from "./LabelledInput";
import { useFormContext } from "react-hook-form";
import { ThemeContext } from "../../contexts/ThemeContext";

function LabelledTextArea({ name, placeholder, label, disabled }: InputProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { theme } = useContext(ThemeContext)!;

  return (
    <div className="col-span-full">
      <label
        htmlFor="about"
        className={`block text-sm font-medium leading-6 ${theme.labelTextColor}`}
      >
        {label}
      </label>
      <div className="mt-2">
        <textarea
          id="token"
          disabled={disabled}
          placeholder={placeholder}
          rows={3}
          {...register(name)}
          className={`block w-full rounded-md p-2 border-0 py-1.5 ${
            theme.labelTextColor
          } outline-none shadow-sm ring-1 ring-inset bg-transparent ${
            theme.inputBorderColor
          } placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:outline-none focus:border-${
            theme.inputFocusedBorderColor
          } ${theme.inputFocusedBorderColor} sm:leading-6  ${
            errors[name] ? `${theme.inputErrorBorderColor}` : ""
          }`}
          defaultValue={""}
        />
      </div>
      {errors && errors[name] && (
        <span className={`${theme.inputErrorTextColor} text-sm`}>
          {errors[name]?.message as string}
        </span>
      )}
    </div>
  );
}

export default LabelledTextArea;
