import React, { useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "../contexts/ThemeContext";

interface Props {
  variant: "success" | "error" | "primary" | "warning" | "secondary" | "high";
  text: string;
  isTableRowHover?: boolean;
}
type Theme = "dark" | "light" | "darkSolid";

function Badge({ variant, text, isTableRowHover }: Props) {
  const { mode } = useContext(ThemeContext)!;
  const theme: Theme = mode as Theme;
  const badgeElipsis = !isTableRowHover ? "badge-elipsis" : "";
  const themeMapper = {
    dark: () => {
      const variantMapper = {
        success: () => {
          return (
            <span
              className={`${badgeElipsis} inline-flex items-center rounded-2xl bg-green-400/10 px-2 py-1 text-xs font-medium text-status-normal ring-1 ring-green-400/20`}
            >
              {text}
            </span>
          );
        },
        error: () => {
          return (
            <span
              className={`${badgeElipsis} inline-flex items-center rounded-2xl bg-red-400/10 px-2 py-1 text-xs font-medium text-red-600 ring-1 ring-red-400/20`}
            >
              {text}
            </span>
          );
        },
        warning: () => {
          return (
            <span
              className={`${badgeElipsis} inline-flex items-center rounded-2xl bg-amber-400/10 px-2 py-1 text-xs font-medium text-status-medium ring-1 ring-amber-400/20`}
            >
              {text}
            </span>
          );
        },
        primary: () => {
          return (
            <span
              className={`${badgeElipsis} inline-flex items-center rounded-2xl bg-primary-400/10 px-2 py-1 text-xs font-medium text-primary-400 ring-1 ring-primary-400/20`}
            >
              {text}
            </span>
          );
        },
        high: () => {
          return (
            <span
              className={`${badgeElipsis} inline-flex items-center rounded-2xl bg-red-100 px-2 py-1 text-xs font-medium text-status-high ring-1 ring-red-600/10`}
            >
              {text}
            </span>
          );
        },
        secondary: () => {
          return (
            <span
              className={`${badgeElipsis} inline-flex items-center rounded-2xl bg-gray-300/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-gray-400/20`}
            >
              {text}
            </span>
          );
        },
      };

      if (variant) {
        return variantMapper[variant]();
      } else {
        return variantMapper["secondary"]();
      }
    },
    light: () => {
      const variantMapper = {
        success: () => {
          return (
            <span
              className={`${badgeElipsis} inline-flex items-center rounded-2xl bg-green-100 px-2 py-1 text-xs font-medium text-status-normal ring-1 ring-green-600/10`}
            >
              {text}
            </span>
          );
        },
        error: () => {
          return (
            <span
              className={`${badgeElipsis} inline-flex items-center rounded-2xl bg-red-100 px-2 py-1 text-xs font-medium text-status-critical ring-1 ring-red-600/10`}
            >
              {text}
            </span>
          );
        },
        warning: () => {
          return (
            <span
              className={`${badgeElipsis} inline-flex items-center rounded-2xl bg-amber-100 px-2 py-1 text-xs font-medium text-status-medium ring-1 ring-amber-600/10`}
            >
              {text}
            </span>
          );
        },
        primary: () => {
          return (
            <span
              className={`${badgeElipsis} inline-flex items-center rounded-2xl bg-primary-100 px-2 py-1 text-xs font-medium text-primary-700 ring-1 ring-primary-600/10`}
            >
              {text}
            </span>
          );
        },
        high: () => {
          return (
            <span
              className={`${badgeElipsis} inline-flex items-center rounded-2xl bg-red-100 px-2 py-1 text-xs font-medium text-status-high ring-1 ring-red-600/10`}
            >
              {text}
            </span>
          );
        },
        secondary: () => {
          return (
            <span
              className={`${badgeElipsis} inline-flex items-center rounded-2xl bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-gray-600/10`}
            >
              {text}
            </span>
          );
        },
      };

      if (variant) {
        return variantMapper[variant]();
      } else {
        return variantMapper["secondary"]();
      }
    },
    darkSolid: () => {
      const variantMapper = {
        success: () => {
          return (
            <span
              className={`${badgeElipsis} inline-flex items-center rounded-2xl bg-green-600/90 px-2 py-1 text-xs font-medium text-white ring-1 ring-green-400/20`}
            >
              {text}
            </span>
          );
        },
        error: () => {
          return (
            <span
              className={`${badgeElipsis} inline-flex items-center rounded-2xl bg-red-600/90 px-2 py-1 text-xs font-medium text-white ring-1 ring-red-400/20`}
            >
              {text}
            </span>
          );
        },
        warning: () => {
          return (
            <span
              className={`${badgeElipsis} inline-flex items-center rounded-2xl bg-[#FD8C00] px-3 py-1 text-xs font-medium text-white ring-1 ring-amber-400/20`}
            >
              {text}
            </span>
          );
        },
        primary: () => {
          return (
            <span
              className={`${badgeElipsis} inline-flex items-center rounded-2xl bg-primary-600/90 px-2 py-1 text-xs font-medium text-white ring-1 ring-primary-400/20`}
            >
              {text}
            </span>
          );
        },
        high: () => {
          return (
            <span
              className={`${badgeElipsis} inline-flex items-center rounded-2xl bg-red-600/90 px-2 py-1 text-xs font-medium text-white ring-1 ring-red-600/10`}
            >
              {text}
            </span>
          );
        },
        secondary: () => {
          return (
            <span
              className={`${badgeElipsis} inline-flex items-center rounded-2xl bg-gray-600/90 px-2 py-1 text-xs font-medium text-white ring-1 ring-gray-400/20`}
            >
              {text}
            </span>
          );
        },
      };

      if (variant) {
        return variantMapper[variant]();
      } else {
        return variantMapper["secondary"]();
      }
    },
  };

  if (theme) {
    return themeMapper[theme]();
  } else {
    return themeMapper["light"]();
  }
}

export default Badge;
