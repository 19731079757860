import React, { useContext } from "react";
import { PlusIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../buttons/PrimaryButton";
import GhostButton from "../buttons/GhostButton";
import SectionHeader from "../SectionHeader";
import IconWrapper from "../IconWrapper";
import Typography from "../Typography";

interface Props {
  name: string;
  openForm?: any;
  hideActionButton?: boolean;
  isActionButtonSoft?: boolean;
  descriptionText?: string;
  redirectTo?: string;
  isActionButtonDisabled?: boolean;
}

function NoDataFull({
  name,
  openForm,
  hideActionButton,
  descriptionText,
  redirectTo,
  isActionButtonSoft,
  isActionButtonDisabled,
}: Props) {
  const navigate = useNavigate();

  return (
    <div className="text-center my-16">
      <svg
        className={`mx-auto h-12 w-12 text-gray-400 ${
          redirectTo && "cursor-pointer hover:text-gray-500"
        }`}
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
        onClick={() => redirectTo && navigate(redirectTo)}
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>
      <SectionHeader level={3} className={`mt-2 text-sm font-semibold`}>
        No {name}s
      </SectionHeader>
      <Typography className={`mt-1 text-sm`} variant="headingLabel">
        {descriptionText ? (
          <span>{descriptionText}</span>
        ) : (
          <span>Get Started by Creating a {name}.</span>
        )}
      </Typography>
      {!hideActionButton ? (
        <div className="mt-6">
          {isActionButtonSoft ? (
            <PrimaryButton
              type="button"
              onClick={() => openForm(true)}
              disabled={isActionButtonDisabled}
              className="inline-flex items-center"
            >
              <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
              Add {name}
            </PrimaryButton>
          ) : (
            <GhostButton
              type="button"
              onClick={() => openForm(true)}
              disabled={isActionButtonDisabled}
              className="inline-flex items-center"
            >
              <IconWrapper
                className="-ml-0.5 mr-1.5 h-5 w-5"
                icon={PlusIcon}
                aria-hidden="true"
              />
              Add {name}
            </GhostButton>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default NoDataFull;
