import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { GlobalToasterContext } from "../../../contexts/ToasterContext";
import useAuth from "../../../hooks/useAuth";
import MfaSettings from "./MfaSettings";
import CancelButton from "../../../components/buttons/CancelButton";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import Overlay from "../../../components/Overlay";
import Typography from "../../../components/Typography";

function AlertMFASettings() {
  const {
    alertMFAModalProps: { show },
    setAlertMFAModalProps,
  } = useContext(GlobalToasterContext)!;
  const { logout } = useAuth();
  const cancelButtonRef = useRef(null);
  const [openMfaSettings, setOpenMfaSettings] = useState(false);

  const handleClose = () => {
    setAlertMFAModalProps((prev) => ({
      ...prev,
      show: false,
    }));
  };

  return (
    <div>
      <Overlay
        open={show}
        handleClose={() => {}}
        titleContent={"Multi-Factor Authentication (MFA) Required"}
        className="w-5/6 sm:w-10/12 md:w-8/12 lg:w-4/6 xl:w-3/6"
        cancelButtonRef={cancelButtonRef}
      >
        <div>
          <Typography
            as="div"
            className="flex flex-col gap-4 text-sm leading-6 mt-5"
          >
            <span className="">
              Multi-Factor Authentication (MFA) is required to be enabled for
              all user accounts in order to utilize this portal. We currently
              support Google Authenticator which can be enabled from the users
              My Profile {`>>`} Security and then enable Multi-Factor
              Authentication.
            </span>
          </Typography>
        </div>

        <div className="flex mt-9 space-x-2 justify-end">
          <CancelButton type="button" onClick={() => logout()}>
            Logout
          </CancelButton>
          <PrimaryButton
            type="button"
            className="rounded-md px-2.5 py-1.5 text-sm font-semibold shadow-sm"
            onClick={() => {
              handleClose();
              setOpenMfaSettings(true);
            }}
          >
            Setup MFA
          </PrimaryButton>
        </div>
      </Overlay>

      <MfaSettings
        modalProps={{ open: openMfaSettings, setOpen: setOpenMfaSettings }}
      />
    </div>
  );
}

export default AlertMFASettings;
