import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../navBar/Navbar";
import DeleteModal from "../../components/DeleteModal";
import Sidebar from "../sidebar/Sidebar";
import InfoModal from "../../components/InfoModal";
import AlertMFASettings from "../../features/profile/mfa/AlertMFASettings";
import Banner from "../../components/Banner";

function index() {
  return (
    <div className="h-full-vh">
      <Sidebar />
      <DeleteModal />
      <InfoModal />
      <AlertMFASettings />
      <div className="lg:pl-64">
        <div className="sticky top-0 z-10">
          <Banner />

          <Navbar />
        </div>
        <main className="py-10 relative">
          <div className="px-4 sm:px-6 lg:px-8 ">
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
}

export default index;
