import React, { Fragment, useContext, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import LabelledInput from "../../../components/inputs/LabelledInput";
import LabelledList from "../../../components/LabelledList";
import { post } from "../../../utils/httpMethods";
import LoadingButton from "../../../components/buttons/LoadingButton";
import useAuth from "../../../hooks/useAuth";
import { GlobalToasterContext } from "../../../contexts/ToasterContext";
import CancelButton from "../../../components/buttons/CancelButton";
import { ThemeContext } from "../../../contexts/ThemeContext";
import SectionHeader from "../../../components/SectionHeader";
import Overlay from "../../../components/Overlay";
import toast from "react-hot-toast";

interface addUserModalProps {
  refetch: () => Promise<void>;
  modalProps: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  };
  url: string;
  roles: Array<any>;
  orgId: string;
}

type Inputs = {
  first_name: string;
  last_name: string;
  email: string;
  role: string;
};

const addUserSchema = yup
  .object({
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required"),
    email: yup.string().required("Email is required"),
    role: yup.string().required("Role is required"),
  })
  .required();

function CreateUserModal({
  refetch,
  modalProps: { open, setOpen },
  url,
  roles,
  orgId,
}: addUserModalProps) {
  const cancelButtonRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const { organization } = useAuth();
  const { theme } = useContext(ThemeContext)!;
  const methods = useForm<Inputs>({
    resolver: yupResolver(addUserSchema),
  });
  const { setShowErrorOverlay } = useContext(GlobalToasterContext)!;

  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      setLoading(true);
      await post(url || `/api/users/${orgId}`, data);
      reset();
      refetch();
      handleClose();
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(error.error);
      setShowErrorOverlay(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    reset();
    // setInterval(() => {
    //   reset();
    // }, 1000);
  };

  return (
    <Overlay
      titleContent={
        organization.access_tier === "WanAware" ||
        organization.access_tier === "Reseller"
          ? "Create Employee"
          : "Create User"
      }
      handleClose={handleClose}
      open={open}
      className="w-5/6 sm:w-10/12 md:w-8/12 lg:w-4/6 xl:w-3/6"
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-2 gap-x-6 gap-y-4">
            <LabelledInput
              name="first_name"
              placeholder="Enter First Name"
              label="First name"
              type="text"
            />
            <LabelledInput
              name="last_name"
              placeholder="Enter Last Name"
              label="Last name"
              type="text"
            />
            <LabelledInput
              name="email"
              placeholder="Enter Email"
              label="Email"
              type="text"
            />
            <LabelledList
              options={roles}
              label="Role"
              name="role"
              error={errors.role}
            />
          </div>

          <div className="flex mt-8 space-x-2 justify-end">
            <CancelButton type="button" onClick={() => handleClose()}>
              Cancel
            </CancelButton>

            <LoadingButton
              type="submit"
              className="rounded-md px-2.5 py-1.5 text-sm font-semibold shadow-sm"
              buttonText={
                organization.access_tier === "WanAware" ||
                organization.access_tier === "Reseller"
                  ? "Invite Employee"
                  : "Invite User"
              }
              loading={loading}
            />
          </div>
        </form>
      </FormProvider>
    </Overlay>
  );
}

export default CreateUserModal;
