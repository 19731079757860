import React, { ReactNode, createContext, useEffect, useState } from "react";
import themes from "../themes";

export interface Theme {
  bodyBackgroundColor: string;
  cardBackgroundColor: string;
  cardBackgroundColorCode: string;
  activeLabelTextColor: string;
  disableLabelTextColor: string;
  accordianBackgroundColor: string;
  accordionHeaderTextColor: string;
  selectedTableRowColor: string;

  //Sttings Drawer Button
  settingsButtonHoverTextColor: string;
  setttingsButtonHoverBgColor: string;

  // Input
  inputBorderColor: string;
  inputBorderColorCode: string;
  inputFocusedBorderColor: string;
  inputFocusedBorderColorCode: string;
  inputBackgroundColor: string;
  mutedInputBackgroundColor: string;
  mutedInputBackgroundColorCode: string;
  //Description text
  descriptionTextColor: string;
  // Select
  selectOptionFocusedBackgroundColor: string;
  selectOptionHoveredBackgroundColor: string;
  selectOptionFocusedBackgroundColorCode: string;
  inputErrorTextColor: string;
  inputErrorTextColorCode: string;
  inputErrorBorderColor: string;
  labelTextColor: string;
  labelTextColorCode: string;
  selectMultiValueBackgroundColorCode: string;
  // Checkbox/RB/Toggle
  checkboxBackgroundColor: string;
  radioButtonFocusedBorderColor: string;
  // Common primary colors
  primaryTextColor: string;
  primaryTextHoverColor: string;
  primaryTextGroupHoverColor: string;
  primaryBackgroundColor: string;
  primaryBorderColor: string;
  primaryFocusRingColor: string;
  // Typography colors & Tab colors
  typographyTextColor: string;
  typographyHoverTextColor: string;
  tabHoverBorderColor: string;
  tabBorderBottomColor: string;
  activeTabTextColor: string;
  menuIconBgColor: string;
  menuBorderColor: string;
  // Common success colors
  successTextColor: string;
  successHoverTextColor: string;
  successActiveBgColor: string;
  successBorderColor: string;
  greenLightBackgroundColor: string;
  greenBorderColor: string;
  // Common error colors
  redBorderColor: string;
  redLightBackgroundColor: string;
  redTextColor: string;
  // Submit Button => [LoadingButton]
  submitButtonBgColor: string;
  submitButtonDisabledBgColor: string;
  submitButtonHoverBgColor: string;
  submitButtonActiveBgColor: string;
  submitButtonTextColor: string;
  submitButtonDisabledTextColor: string;
  submitButtonHoverTextColor: string;
  // Cancel Button
  cancelButtonBorderColor: string;
  cancelButtonTextColor: string;
  cancelButtonBackgroundColor: string;
  cancelButtonHoverTextColor: string;
  cancelButtonActiveBgColor: string;
  cancelButtonDisabledTextColor: string;
  // Ghost Button
  ghostButtonBorderColor: string;
  ghostButtonTextColor: string;
  ghostButtonBackgroundColor: string;
  ghostButtonHoverTextColor: string;
  ghostButtonDisabledBackgroundColor: string;
  ghostButtonDisabledTextColor: string;
  ghostButtonActiveBgColor: string;
  // Primary Button => [PrimaryButton]
  primaryButtonBorderColor: string;
  primaryButtonTextColor: string;
  primaryButtonBackgroundColor: string;
  primaryButtonHoverTextColor: string;
  primaryButtonHoverBackgroundColor: string;
  primaryButtonDisabledBackgroundColor: string;
  primaryButtonDisabledTextColor: string;
  primaryButtonActiveBgColor: string;
  primaryTableRowHoverColor: string;
  // Success Button => [SuccessButton]
  successButtonBorderColor: string;
  successButtonTextColor: string;
  successButtonBackgroundColor: string;
  successButtonHoverTextColor: string;
  successButtonHoverBackgroundColor: string;
  successButtonDisabledBackgroundColor: string;
  successButtonDisabledTextColor: string;
  successButtonActiveBgColor: string;
  // Delete operations Button => [DangerButton]
  dangerButtonBorderColor: string;
  dangerButtonTextColor: string;
  dangerButtonBackgroundColor: string;
  dangerButtonHoverTextColor: string;
  dangerButtonHoverBackgroundColor: string;
  dangerButtonDisabledBackgroundColor: string;
  dangerButtonDisabledTextColor: string;
  dangerButtonActiveBgColor: string;

  // Table Action Danger Button => [TableActionDangerButton]
  tableActionDangerButtonBorderColor: string;
  tableActionDangerButtonTextColor: string;
  tableActionDangerButtonBackgroundColor: string;
  tableActionDangerButtonHoverTextColor: string;
  tableActionDangerButtonHoverBackgroundColor: string;
  tableActionDangerButtonDisabledBackgroundColor: string;
  tableActionDangerButtonDisabledTextColor: string;
  tableActionDangerButtonActiveBgColor: string;
  tableActionDangerButtonLoaderColor: string;
  // Table Action General Button => [TableActionGeneralButton]
  tableActionGeneralButtonBorderColor: string;
  tableActionGeneralButtonTextColor: string;
  tableActionGeneralButtonBackgroundColor: string;
  tableActionGeneralButtonHoverTextColor: string;
  tableActionGeneralButtonHoverBackgroundColor: string;
  tableActionGeneralButtonDisabledBackgroundColor: string;
  tableActionGeneralButtonDisabledTextColor: string;
  tableActionGeneralButtonActiveBgColor: string;

  //Table Header
  tableHeaderBackgroundColor: string;
  tableBodyBackgroundColor: string;
  tableHeaderTextColor: string;
  tableHoverBackgroundColor: string;
  tableRowHoverBorder: string;
  tableFirstRowLoadingBgColor: string;
  tableSecondRowLoadingBgColor: string;
  tableCellBorderLeft: string;
  tableCellBorderRight: string;
  tableSelectedRowBackgroundColor: string;

  //Popover
  popoverHoverBackgroundColor: string;
  //switch
  switchDisableBgColor: string;
  tableMapSwitchDisableBgColor: string;
  //tooltip
  tooltipBackgroundColor: string;
  //divider classes
  dividerClasses: {
    gray100: string;
    gray200: string;
    gray300: string;
    gray400: string;
  };
  //menu
  cardMenuBorderColor: string;
  menuItemHoverBackgroundColor: string;
  //stepper
  fileUploadBackgroundColor: string;
  theadBackgroundColor: string;
  tableBorderColor: string;
  uploadBoxBorderColor: string;

  //horizontal line
  horizontalLineColor: string;
  //Chips
  chipTextColor: string;
  chipBorderColor: string;
  chipRemoveButtonTextColor: string;
  chipRemoveButtonHoverColor: string;

  //list
  selectedListBackgroundColor: string;

  //Nested Accordian
  nestedAccordionBackgroundColor: string;
  nestedAccordionIconButtonBackgroundColor: string;
  nestedButtonSuccessHoverColor: string;
  nestedTableLoadingRowColor: string;
}

export type ThemeColorPresets =
  | "default"
  | "teal"
  | "cyan"
  | "blue"
  | "orange"
  | "green";
interface ThemeContextType {
  theme: Theme;
  toggleTheme: (theme: string) => void;
  mode: string;
  themePresetColor: ThemeColorPresets;
  onChangeColor: (theme: ThemeColorPresets) => void;
}

export const ThemeContext = createContext<ThemeContextType | null>(null);

function ThemeProvider({ children }: { children: ReactNode }) {
  const user = JSON.parse(localStorage.getItem("currentUser") as any);

  const initialThemeSettings = JSON.parse(
    localStorage.getItem("themeSettings")!
  );
  const [theme, setTheme] = useState<string>(
    user?.theme_mode || initialThemeSettings?.themeMode || "light"
  );
  const [themePresetColor, setThemePresetColor] = useState<ThemeColorPresets>(
    initialThemeSettings?.themePresetColor || "default"
  );

  const toggleTheme = (newTheme: string) => {
    setTheme(newTheme);
  };

  const onChangeColor = (color: ThemeColorPresets) => {
    setThemePresetColor(color);
  };

  // Update local storage whenever the theme changes and user is logged out
  useEffect(() => {
    if (!user) {
      localStorage.setItem(
        "themeSettings",
        JSON.stringify({ themeMode: theme, themePresetColor: themePresetColor })
      );
    }
    const themeSettings = JSON.parse(localStorage.getItem("themeSettings")!);
    localStorage.setItem(
      "themeSettings",
      JSON.stringify({
        ...themeSettings,
        themePresetColor: themePresetColor,
      })
    );
  }, [theme, themePresetColor]);

  return (
    <ThemeContext.Provider
      value={{
        theme: themes[theme as any][themePresetColor as any],
        toggleTheme,
        mode: theme,
        themePresetColor: themePresetColor,
        onChangeColor: onChangeColor,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
}

export default ThemeProvider;
