import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Company from "./Company";
import IncidentManagement from "./IncidentManagement";
import useAuth from "../../../hooks/useAuth";
import { ThemeContext } from "../../../contexts/ThemeContext";
import Tabs from "../../../components/Tabs";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}
function CustomerGeneralNavigator() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const activeTab = pathname.split("/").slice(-1);
  const { user: loggedUser } = useAuth();
  const [currentTab, setCurrentTab] = useState(activeTab[0]);
  const { theme } = useContext(ThemeContext)!;

  let tabs = [
    {
      name: "Company Information",
      value: "company",
      url: "/administration/general/company",
      component: <Company />,
    },
    // {
    //   name: "Incident Management",
    //   value: "incident-management",
    //   url: "/administration/general/incident-management",
    //   component: <IncidentManagement />,
    // },
  ];
  tabs =
    loggedUser.role !== "Customer_Admin"
      ? tabs.filter((tab) => tab.name !== "Incident Management")
      : tabs;

  const onTabChange = (tab: any) => {
    setCurrentTab(tab.value);
    navigate(tab.url);
  };
  return (
    <div>
      <Tabs
        tabs={tabs}
        currentTab={currentTab}
        onClick={(tab) => onTabChange(tab)}
      />
    </div>
  );
}

export default CustomerGeneralNavigator;
