import React, { Fragment, useContext, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { BellIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import NotificationBadge from "../../components/NotificationBadge";
import Avatar from "react-avatar";
import moment from "moment-timezone";
import DateInFormat from "../../components/DateInFormat";
import { ThemeContext } from "../../contexts/ThemeContext";
import Typography from "../../components/Typography";
import IconWrapper from "../../components/IconWrapper";

const tabs = [
  {
    name: "Messages",
    value: "messages",
  },
  {
    name: "Alerts",
    value: "alerts",
  },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

interface Props {
  unreadCount: number;
  messages: any[];
  markAllMessagesAsReadBySender: React.Dispatch<React.SetStateAction<any>>;
}

function ContactIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M17 18a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2" />
      <rect width="18" height="18" x="3" y="4" rx="2" />
      <circle cx="12" cy="10" r="2" />
      <line x1="8" x2="8" y1="2" y2="4" />
      <line x1="16" x2="16" y1="2" y2="4" />
    </svg>
  );
}

function NotificationsPopover({
  unreadCount,
  messages,
  markAllMessagesAsReadBySender,
}: Props) {
  const [currentTab, setCurrentTab] = useState("messages");
  const onTabChange = (tab: any) => {
    setCurrentTab(tab.value);
  };
  const [selectedMessage, setSelectedMessage] = useState<any>();
  const { theme } = useContext(ThemeContext)!;

  return (
    <Popover className="relative">
      <Popover.Button className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
        <span className="sr-only">View notifications</span>
        <BellIcon className="h-6 w-6" aria-hidden="true" />
        {unreadCount ? <NotificationBadge text={unreadCount as any} /> : <></>}
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Popover.Panel className="absolute right-0 z-10 mt-2.5 w-[60vw] h-[75vh] origin-top-right rounded-md bg-gray-50 shadow-lg ring-1 ring-gray-900/5 focus:outline-none dark:bg-gray-700 dark:text-gray-300">
          <nav className="-mb-px flex" aria-label="Tabs">
            {tabs.map((tab) => (
              <div
                key={tab.name}
                // href={tab.href}
                className={classNames(
                  tab.value === currentTab
                    ? `${theme.primaryBorderColor} ${theme.primaryTextColor}`
                    : `border-transparent ${theme.typographyTextColor} ${theme.tabHoverBorderColor} ${theme.typographyHoverTextColor}`,
                  "w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium cursor-pointer"
                )}
                onClick={() => onTabChange(tab)}
              >
                {tab.name}
              </div>
            ))}
          </nav>

          <div className="flex mt-4 justify-between relative h-[90%]">
            {/* Messages Starts here */}
            {currentTab === "messages" ? (
              <div
                className={`w-[100%] relative ${theme.cardBackgroundColor} shadow-lg`}
              >
                {messages?.length ? (
                  <div
                    className={`flex overflow-y-auto h-[100%] w-full max-w-4xl border ${theme.tableBorderColor} ${theme.cardBackgroundColor} shadow-lg`}
                  >
                    <div
                      className={`flex flex-col border-r ${theme.tableBorderColor}`}
                    >
                      <div className="flex-1 overflow-auto">
                        <div
                          className={`divide-y ${theme.dividerClasses.gray200}`}
                        >
                          {messages?.map((item: any) => (
                            <div
                              onClick={() => {
                                setSelectedMessage(item);
                                markAllMessagesAsReadBySender(item.sender_id);
                              }}
                              className={`flex items-center gap-4 px-4 py-2 ${theme.popoverHoverBackgroundColor} cursor-pointer`}
                            >
                              <Avatar
                                name={
                                  item.sender_first_name +
                                  " " +
                                  item.sender_last_name
                                }
                                size="30"
                                round={true}
                              ></Avatar>
                              <div className="flex-1">
                                <div className="font-medium">
                                  {item.sender_first_name}{" "}
                                  {item.sender_last_name}
                                </div>
                                {item?.messages?.length ? (
                                  <p
                                    className={
                                      item?.read
                                        ? `text-sm ${theme.typographyTextColor}`
                                        : `text-sm font-semibold ${theme.activeLabelTextColor}`
                                    }
                                  >
                                    {item?.messages[0].title}
                                  </p>
                                ) : (
                                  <></>
                                )}
                              </div>
                              <Typography as="div" className="text-sm">
                                {item?.messages?.length
                                  ? moment
                                      .utc(item?.messages[0].created_at)
                                      .local()
                                      .fromNow()
                                  : ""}
                              </Typography>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col flex-1">
                      {selectedMessage ? (
                        <>
                          <div
                            className={`flex items-center justify-between border-b ${theme.tableBorderColor} px-4 py-2`}
                          >
                            <div className="flex items-center gap-4">
                              <Avatar
                                name={
                                  selectedMessage?.sender_first_name +
                                  " " +
                                  selectedMessage?.sender_last_name
                                }
                                size="30"
                                round={true}
                              ></Avatar>
                              <div>
                                <div className="font-medium">
                                  {selectedMessage?.sender_first_name}{" "}
                                  {selectedMessage?.sender_last_name}
                                </div>
                                {/* <p className="text-sm text-gray-500 dark:text-gray-400">
                          Online
                        </p> */}
                              </div>
                            </div>
                          </div>
                          <div className="flex-1 overflow-auto p-4">
                            <div className="grid gap-4">
                              {selectedMessage?.messages?.map(
                                (message: any) => (
                                  <div className="flex items-start gap-4">
                                    <div className="flex-1">
                                      <div className="mb-1 font-medium">
                                        {message.title}
                                      </div>
                                      <div
                                        className={`rounded-lg ${theme.fileUploadBackgroundColor} p-3 text-sm shadow-sm  dark:text-gray-50`}
                                      >
                                        {message.body}
                                      </div>
                                      <Typography as="div" className="text-sm">
                                        <DateInFormat
                                          date={message.created_at}
                                          format="DD MMM YYYY HH:mm"
                                        />
                                      </Typography>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="flex-1 overflow-auto p-4">
                          <div className="flex flex-col items-center justify-center h-full">
                            <ContactIcon className="h-12 w-12 text-gray-400 dark:text-gray-600" />
                            <Typography className="mt-4 text-lg font-medium">
                              No messages to display
                            </Typography>
                            <Typography as="p" className="mt-2">
                              Please click on the sender to view the messages.
                            </Typography>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="flex-1 overflow-auto p-4 pt-28">
                    <div className="flex flex-col items-center justify-center h-full">
                      <IconWrapper
                        icon={ContactIcon}
                        className="h-12 w-12"
                        variant="muted"
                      />
                      <Typography as="div" className="mt-4 text-lg font-medium">
                        No messages to display
                      </Typography>
                      <Typography as="p" className="mt-2 text-sm">
                        Start a conversation with your team.
                      </Typography>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <></>
            )}

            {/* Messages ends here */}

            {/* Alerts starts here */}
            {currentTab === "alerts" ? (
              <div className={`w-[100%] relative ${theme.cardBackgroundColor}`}>
                {false ? (
                  <div
                    className={`flex overflow-y-auto h-[100%] w-full max-w-4xl border ${theme.tableBorderColor} ${theme.cardBackgroundColor} shadow-lg`}
                  >
                    <div
                      className={`flex flex-col border-r ${theme.tableBorderColor}`}
                    >
                      <div className="flex-1 overflow-auto">
                        <div
                          className={`divide-y ${theme.dividerClasses.gray200}`}
                        >
                          <div
                            className={`flex items-center gap-4 px-4 py-3 ${theme.popoverHoverBackgroundColor} cursor-pointer`}
                          >
                            <div className="flex h-8 w-8 items-center justify-center rounded-full bg-red-500 text-white">
                              <ExclamationTriangleIcon className="h-5 w-5" />
                            </div>
                            <div className="flex-1">
                              <div className="font-medium text-red-500">
                                Critical
                              </div>
                              <Typography as="p" className="text-sm">
                                Server down, immediate action required.
                              </Typography>
                            </div>
                          </div>
                          <div className="flex items-center gap-4 px-4 py-3 hover:bg-gray-100 cursor-pointer dark:hover:bg-gray-800">
                            <div className="flex h-8 w-8 items-center justify-center rounded-full bg-orange-500 text-white">
                              <ExclamationTriangleIcon className="h-5 w-5" />
                            </div>
                            <div className="flex-1">
                              <div className="font-medium text-orange-500">
                                High
                              </div>
                              <Typography as="p" className="text-sm">
                                Disk space running low, please free up space.
                              </Typography>
                            </div>
                          </div>
                          <div
                            className={`flex items-center gap-4 px-4 py-3 ${theme.popoverHoverBackgroundColor} cursor-pointer`}
                          >
                            <div className="flex h-8 w-8 items-center justify-center rounded-full bg-yellow-500 text-white">
                              <ExclamationTriangleIcon className="h-5 w-5" />
                            </div>
                            <div className="flex-1">
                              <div className="font-medium text-yellow-500">
                                Medium
                              </div>
                              <Typography as="p" className="text-sm">
                                Backup failed, please check backup settings.
                              </Typography>
                            </div>
                          </div>
                          <div
                            className={`flex items-center gap-4 px-4 py-3 ${theme.popoverHoverBackgroundColor} cursor-pointer`}
                          >
                            <div className="flex h-8 w-8 items-center justify-center rounded-full bg-green-500 text-white">
                              <ExclamationTriangleIcon className="h-5 w-5" />
                            </div>
                            <div className="flex-1">
                              <div className="font-medium text-green-500">
                                Low
                              </div>
                              <Typography as="p" className="text-sm">
                                Disk space available, no action required.
                              </Typography>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col flex-1">
                      <div
                        className={`flex items-center justify-between border-b ${theme.tableBorderColor} px-4 py-2`}
                      >
                        <div className="flex items-center gap-4">
                          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-yellow-500 text-white">
                            <ExclamationTriangleIcon className="h-5 w-5" />
                          </div>
                          <div className="flex-1">
                            <div className="font-medium text-red-500">High</div>
                          </div>
                        </div>
                      </div>
                      <div className="flex-1 overflow-auto p-4">
                        <div className="grid gap-4">
                          <div className="flex items-start gap-4">
                            <div className="flex-1">
                              <div className="mb-1 font-medium">
                                Server Down
                              </div>
                              <div
                                className={`rounded-lg ${theme.fileUploadBackgroundColor} p-3 text-sm shadow-sm dark:text-gray-50`}
                              >
                                The server is currently down and immediate
                                action is required to restore service. The
                                engineering team has been notified and is
                                working to resolve the issue as soon as
                                possible.
                              </div>

                              <Typography as="div" className="text-xs mt-1">
                                2:30 PM
                              </Typography>
                            </div>
                          </div>
                          <div className="flex items-start gap-4 justify-end">
                            <div className="flex-1">
                              <div className="mb-1 text-right font-medium">
                                Disk Space Low
                              </div>
                              <div
                                className={`rounded-lg ${theme.fileUploadBackgroundColor} p-3 text-sm shadow-sm dark:text-gray-50`}
                              >
                                The disk space on the primary storage volume is
                                running low. Please free up some space by
                                deleting unused files or applications. This
                                issue should be addressed as soon as possible to
                                prevent service disruptions.
                              </div>
                              <Typography
                                as="div"
                                className="text-xs mt-1 text-right"
                              >
                                2:31 PM
                              </Typography>
                            </div>
                          </div>
                          <div className="flex items-start gap-4">
                            <div className="flex-1">
                              <div className="mb-1 font-medium">
                                Backup Failure
                              </div>
                              <div
                                className={`rounded-lg ${theme.fileUploadBackgroundColor} p-3 text-sm shadow-sm dark:text-gray-50`}
                              >
                                The scheduled backup process has failed. Please
                                check the backup settings and ensure that the
                                backup destination is accessible and has
                                sufficient space. Resolving this issue is
                                important to maintain data integrity.
                              </div>
                              <Typography as="div" className="text-xs mt-1">
                                2:32 PM
                              </Typography>
                            </div>
                          </div>
                          <div className="flex items-start gap-4 justify-end">
                            <div className="flex-1">
                              <div className="mb-1 text-right font-medium">
                                Disk Space Available
                              </div>
                              <div
                                className={`rounded-lg ${theme.fileUploadBackgroundColor} p-3 text-sm shadow-sm dark:text-gray-50`}
                              >
                                The disk space on the primary storage volume is
                                currently sufficient, and no immediate action is
                                required. The engineering team will continue to
                                monitor the disk usage and proactively address
                                any future capacity issues.
                              </div>
                              <Typography
                                as="div"
                                className="text-xs mt-1 text-right"
                              >
                                2:33 PM
                              </Typography>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex-1 overflow-auto p-4 pt-28">
                    <div className="flex flex-col items-center justify-center h-full">
                      <IconWrapper
                        icon={ExclamationTriangleIcon}
                        className="h-12 w-12"
                        variant="muted"
                      />
                      <Typography className="mt-4 text-lg font-medium">
                        No alerts to display
                      </Typography>
                      <Typography as="p" className="mt-2 text-sm">
                        When you have important alerts, they will be shown here.
                      </Typography>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

export default NotificationsPopover;
