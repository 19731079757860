import React, { useContext, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { ThemeContext } from "../../contexts/ThemeContext";

export interface InputProps {
  name: string;
  placeholder?: string;
  disabled?: boolean;
  type: "password" | "text" | "email" | "textarea" | string;
  disabledClass?: string;
  enableFocus?: boolean;
  setErrMessage: React.Dispatch<React.SetStateAction<string>>;
  fieldNameArray: string[];
}

function OTPInput({
  name,
  placeholder,
  type,
  disabled,
  disabledClass,
  enableFocus,
  setErrMessage,
  fieldNameArray,
}: InputProps) {
  const {
    register,
    setValue,
    setFocus,
    formState: { errors },
    clearErrors,
  } = useFormContext();
  const { theme } = useContext(ThemeContext)!;

  const handlePaste = (event: any) => {
    let data = event.clipboardData.getData("text");

    data = data.split("");
    [].forEach.call(
      document.querySelectorAll(".field-code"),
      (node: any, index) => {
        node.value = data[index];

        const fieldIndex = `code${index + 1}`;

        setValue(fieldIndex, data[index]);
        // setFocus(fieldIndex)
        setErrMessage("");
        clearErrors([...fieldNameArray]);
      }
    );

    event.preventDefault();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const inputElement = event.target as HTMLInputElement;
    const { name } = inputElement;

    const fieldIndex = name.replace("code", "");

    const fieldIntIndex = Number(fieldIndex);

    if (event.key === "Backspace") {
      const currentField = document.querySelector(
        `input[name=code${fieldIntIndex}]`
      ) as HTMLInputElement;

      const prevField = document.querySelector(
        `input[name=code${fieldIntIndex - 1}]`
      );

      if (prevField !== null && !currentField?.value.length) {
        (prevField as HTMLElement).focus();
      }
    }
    if (event.keyCode === 37) {
      const currentField = document.querySelector(
        `input[name=code${fieldIntIndex}]`
      ) as HTMLInputElement;

      const prevField = document.querySelector(
        `input[name=code${fieldIntIndex - 1}]`
      );

      if (prevField !== null) {
        (prevField as HTMLElement).focus();
      }
    } else if (event.keyCode === 39) {
      const currentField = document.querySelector(
        `input[name=code${fieldIntIndex}]`
      ) as HTMLInputElement;

      const nextFeild = document.querySelector(
        `input[name=code${fieldIntIndex + 1}]`
      );

      if (nextFeild !== null) {
        (nextFeild as HTMLElement).focus();
      }
    }
  };

  const handleChangeWithNextField = (
    event: React.ChangeEvent<HTMLInputElement>,
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  ) => {
    setErrMessage("");
    const { maxLength, value, name } = event.target;

    const fieldIndex = name.replace("code", "");

    const fieldIntIndex = Number(fieldIndex);

    if (value.length >= maxLength) {
      if (fieldIntIndex < fieldNameArray.length) {
        const nextfield = document.querySelector(
          `input[name=code${fieldIntIndex + 1}]`
        );

        if (nextfield !== null) {
          (nextfield as HTMLElement).focus();
        }
      }
    }

    handleChange(event);
  };

  useEffect(() => {
    const target = document.querySelector("input.field-code");

    target?.addEventListener("paste", handlePaste);

    return () => {
      target?.removeEventListener("paste", handlePaste);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div>
        <div className={`w-12 h-12`}>
          <input
            type={type}
            disabled={disabled}
            {...register(name)}
            className={`
                            field-code
                            ${disabledClass} 
                            block w-full h-full flex flex-col items-center justify-center text-center px-2 rounded-md border-0 py-0 ${
                              theme.labelTextColor
                            } outline-none shadow-sm ring-1 ring-inset ${
              theme.inputBorderColor
            } ${theme.inputBackgroundColor} placeholder:text-md ${
              theme.inputFocusedBorderColor
            } ${
              errors[name] ? theme.inputErrorBorderColor : ""
            } focus:ring-2 focus:ring-inset sm:leading-6
                        `}
            placeholder={placeholder}
            autoFocus={enableFocus}
            maxLength={1}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleChangeWithNextField(event, register(name).onChange)
            }
            onPaste={handlePaste}
            onKeyDown={handleKeyDown}
          />
        </div>
      </div>
    </>
  );
}

export default OTPInput;
