import {
  DocumentMagnifyingGlassIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/outline";
import Typography from "../Typography";

interface Props {
  name: string;
  colspan: number;
}

const iconSize = 120;

function NoDataFound({ name, colspan }: Props) {
  return (
    <tr>
      <td colSpan={colspan}>
        <Typography
          as="div"
          variant="valueLabel"
          className="my-4 mx-4 flex items-center justify-center py-20 space-x-10"
        >
          <EyeSlashIcon height={iconSize} width={iconSize} />
          <Typography
            variant="valueLabel"
            className="font-bold  text-3xl"
          >{`No ${name} found`}</Typography>
        </Typography>
      </td>
    </tr>
  );
}

export default NoDataFound;
