import React, { useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import moment from "moment";
import { USCanedaTimezones } from "../features/profile/AllTimeZones";

const replacements = [
  ["DD", "dd"],
  ["YY", "yy"],
  ["YYYY", "yyyy"],
  ["A", "a"],
];

const getFormat = (
  time_format: any,
  timezone: string,
  USCanadaTimezones: Array<string>
) => {
  const timeStandard = localStorage.getItem("timeStandard");
  if (timeStandard === "ISO") {
    return time_format === "24hr"
      ? "YYYY-MM-DD HH:mm:ss"
      : "YYYY-MM-DD hh:mm:ss A";
  } else if (USCanadaTimezones.includes(timezone)) {
    return time_format === "24hr"
      ? "MMMM DD YYYY, HH:mm:ss"
      : "MMMM DD YYYY, hh:mm:ss A";
  } else {
    return time_format === "24hr" ? "YY-MM-DD HH:mm:ss" : "YY-MM-DD hh:mm:ss A";
  }
};

// convert the date format into valid format of datepicker component
export const ConvertToDatePickerFormat = (inputFormat: any) => {
  const format = replacements.reduce((acc, [oldStr, newStr]) => {
    return acc.replaceAll(oldStr, newStr);
  }, inputFormat);
  return format;
};

//function to get the conditional date format
export function SetDateFormat({ setFormat, notUsingDatePicker }: any) {
  const { user } = useAuth();
  useEffect(() => {
    const format = getFormat(
      user.time_format,
      user.timezone,
      USCanedaTimezones
    );
    notUsingDatePicker
      ? setFormat(format)
      : setFormat(ConvertToDatePickerFormat(format));
  }, [user]);

  return null;
}

//function to convert the date in timezone date format
function DateInFormat({ date }: any) {
  const { user } = useAuth();
  const [format, setFormat] = useState("");
  return (
    <>
      <SetDateFormat setFormat={setFormat} notUsingDatePicker={true} />
      {date ? (
        <span>
          {user?.timezone
            ? moment.utc(date).tz(user.timezone).format(format)
            : moment(moment.utc(date).toDate()).local().format(format)}
        </span>
      ) : (
        <span>-</span>
      )}
    </>
  );
}

export default DateInFormat;
