import React, { useContext } from "react";
import WhiteLabelLogo from "./WhiteLabelLogo";
import { ThemeContext } from "../../contexts/ThemeContext";

function DeactivatedAccNotification() {
  const { theme } = useContext(ThemeContext)!;

  return (
    <>
      <main
        className={`grid h-[100vh] place-items-center ${theme.cardBackgroundColor} px-6 py-24 sm:py-32 lg:px-8`}
      >
        <div className="text-center">
          <WhiteLabelLogo />
          <h1
            className={`mt-4 text-3xl font-bold tracking-tight ${theme.labelTextColor} sm:text-3xl`}
          >
            Account is deactivated.
          </h1>
          <p
            className={`mt-6 text-base leading-7 ${theme.disableLabelTextColor}`}
          >
            Your account has been deactivated by an administrator.
          </p>
          <p
            className={`mt-2 text-base leading-7 ${theme.disableLabelTextColor}`}
          >
            Please reach out to your account administrator for further
            instructions.
          </p>
          <div className="mt-6 flex items-center justify-center gap-x-6">
            <a
              href="/auth/login"
              className={`rounded-md ${theme.submitButtonBgColor} px-3.5 py-2.5 text-sm font-semibold ${theme.submitButtonTextColor} shadow-sm ${theme.submitButtonHoverBgColor} focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${theme.submitButtonActiveBgColor}`}
            >
              Back to Login
            </a>
          </div>
        </div>
      </main>
    </>
  );
}

export default DeactivatedAccNotification;
