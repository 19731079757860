import React, { ReactNode, useContext } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";

interface Props extends React.HTMLProps<HTMLButtonElement> {
  className?: string;
  type: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
  children: ReactNode;
}

function CancelButton({
  className,
  type,
  disabled,
  children,
  ...other
}: Props) {
  const { theme } = useContext(ThemeContext)!;

  return (
    <button
      className={`text-sm font-semibold ring-1 items-center rounded-md px-3 py-[0.45rem] ${theme.cancelButtonBorderColor} ${theme.cancelButtonTextColor} ${theme.cancelButtonBackgroundColor} ${theme.cancelButtonHoverTextColor} ${theme.cancelButtonActiveBgColor} ${theme.cancelButtonDisabledTextColor} ${className}`}
      disabled={disabled}
      type={type}
      {...other}
    >
      {children}
    </button>
  );
}

export default CancelButton;
