import React, {
  createContext,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router";
// import useAuth from "../hooks/useAuth";
import { CustomerInterface } from "../features/administration/customer/Customers";
import { get } from "../utils/httpMethods";
import useAuth from "../hooks/useAuth";
import { useParams } from "react-router-dom";

interface CustomerContextType {
  loading: boolean;
  customers: CustomerInterface[];
  currentCompany: CustomerInterface;
  changeCompany: React.Dispatch<React.SetStateAction<CustomerInterface>>;
  reloadCustomers: React.Dispatch<React.SetStateAction<string>>;
  fetchCustomer: any;
  setCustomers: any;
}

export const CustomersContext = createContext<CustomerContextType | null>(null);

function CustomersProvider({ children }: { children: ReactNode }) {
  const { pathname } = useLocation();
  const [customerList, setCustomerList] = useState([] as any);
  const [selectedCompany, setSelectedCompany] = useState({} as any);
  const [loading, setLoading] = useState(false);
  const { user, organization } = useAuth();
  const [isCustomerPage, setIsCustomerPage] = useState(false);

  const [selectedCompanyId, setSelectedCompanyId] = useState(
    pathname.split("/").includes("customers") ? pathname.split("/")[3] : ""
  );

  useEffect(() => {
    const iscustomer = pathname.split("/").includes("customers");
    setIsCustomerPage(() => (!!user && iscustomer ? true : false));
    setSelectedCompanyId(
      pathname.split("/").includes("customers") ? pathname.split("/")[3] : ""
    );
  }, [pathname, user]);

  const fetchCustomers = (term = "") => {
    if (!isCustomerPage) return;
    const URL =
      // user.role === "Reseller_Admin"
      organization.access_tier === "Reseller"
        ? `/api/resellers/customers`
        : "/api/customers";
    get(`${URL}?search=${term}`)
      .then((response: any) => {
        setCustomerList(response.data);

        setLoading(false);
        if (response.data?.length) {
          if (selectedCompanyId) {
            fetchCustomer(selectedCompanyId);
          } else {
            setSelectedCompany(response.data[0]);
          }
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };
  const fetchCustomer = async (id: string) => {
    try {
      if (!isCustomerPage) return;
      const URL =
        organization.access_tier === "Reseller"
          ? `/api/resellers/customers/${id}`
          : `/api/customers/${id}`;
      const response = (await get(URL)) as any;

      if (Object.keys(response).length !== 0) {
        setSelectedCompany(response);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetchCustomers();
  }, [isCustomerPage]);

  return (
    <CustomersContext.Provider
      value={{
        loading: loading,
        customers: customerList,
        setCustomers: setCustomerList,
        currentCompany: selectedCompany,
        changeCompany: (selectedCustomer: any) => {
          setSelectedCompany(selectedCustomer);
        },
        reloadCustomers: (term) => {
          fetchCustomers(term as any);
        },
        fetchCustomer: (id: string) => {
          fetchCustomer(id);
        },
      }}
    >
      {children}
    </CustomersContext.Provider>
  );
}

export default CustomersProvider;
