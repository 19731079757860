import { Fragment, useContext, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { GlobalToasterContext } from "../contexts/ToasterContext";
import CancelButton from "./buttons/CancelButton";
import DangerButton from "./buttons/DangerButton";
import { ThemeContext } from "../contexts/ThemeContext";

export default function DeleteModal() {
  const {
    modalProps: { show, message, title, deleteFunction, deleteButtonText },
    setModalProps,
  } = useContext(GlobalToasterContext)!;

  const cancelButtonRef = useRef(null);
  const { theme, mode } = useContext(ThemeContext)!;
  const handleClose = () => {
    setModalProps((prev) => ({
      ...prev,
      show: false,
    }));
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={handleClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform overflow-hidden rounded-lg ${theme.cardBackgroundColor} px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6`}
              >
                <div className="sm:flex sm:items-start">
                  <div
                    className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full ${
                      mode === "light" ? "bg-red-100" : "bg-slate-700"
                    } sm:mx-0 sm:h-10 sm:w-10`}
                  >
                    <ExclamationTriangleIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className={`text-base font-semibold leading-6 ${theme.labelTextColor}`}
                    >
                      {title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p
                        className={`text-sm ${theme.typographyTextColor} whitespace-pre-line`}
                      >
                        {message}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <DangerButton
                    type="button"
                    className="inline-flex w-full justify-center sm:ml-3 sm:w-auto"
                    onClick={() =>
                      setModalProps((prev) => ({
                        ...prev,
                        show: false,
                        deleteFunction: deleteFunction(),
                      }))
                    }
                  >
                    {deleteButtonText ||
                      (title == "Remove Target" ? "Remove" : "Delete")}
                  </DangerButton>
                  <CancelButton
                    type="button"
                    className="mt-3 inline-flex w-full justify-center sm:mt-0 sm:w-auto"
                    onClick={() =>
                      setModalProps((prev) => ({
                        ...prev,
                        show: false,
                      }))
                    }
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </CancelButton>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
