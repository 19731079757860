import { Switch } from "@headlessui/react";
import React, { useContext, useEffect, useState } from "react";
import AssessmentsTable from "./AssessmentsTable";
import ExternalAttackSurfaceFormModal from "./ExternalAttackSurfaceForm";
import { get, put } from "../../../../utils/httpMethods";
import useAuth from "../../../../hooks/useAuth";
import { GlobalToasterContext } from "../../../../contexts/ToasterContext";
import Loader from "../../../../components/loaders/Loader";
import { ThemeContext } from "../../../../contexts/ThemeContext";
import SectionHeader from "../../../../components/SectionHeader";
import Label from "../../../../components/Label";
import Card from "../../../../components/Card";
import ToggleSwitchBasic from "../../../../components/toggles/ToggleSwitchBasic";
import toast from "react-hot-toast";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

function Assessment() {
  const { organization, fetchUser } = useAuth();
  const [openFormModal, setOpenFormModal] = useState(
    organization?.external_attack_surface ?? false
  );
  const [assesments, setAssessments] = useState<any>([]);
  const [assesmentsLoading, setAssessmentsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const { theme } = useContext(ThemeContext)!;
  const { setShowErrorOverlay } = useContext(GlobalToasterContext)!;

  const getAllAssessmentRequests = async () => {
    try {
      setAssessmentsLoading(true);
      const response: any = await get(
        `api/assessments/organization/${organization.id}`
      );
      setAssessments(response);
      setAssessmentsLoading(false);
    } catch (error: any) {
      setAssessmentsLoading(false);
      toast.error(error.message || error.error);
      setShowErrorOverlay(true);
    }
  };

  const switchChangeHandler = async () => {
    try {
      setLoading(true);
      const resp: any = await put(`/api/auth/organization/self`, {
        external_attack_surface: !organization.external_attack_surface,
      });
      await fetchUser();
      setLoading(false);
      toast.success(resp.message);
    } catch (error: any) {
      setLoading(false);
      toast.error(error.message || error.error);
      setShowErrorOverlay(true);
    }
  };

  const copyToClipboard = async (e: any) => {
    e.preventDefault();
    let subdomain = window.location.host.split(".")[0];
    // Create a temporary textarea element
    const textarea = document.createElement("textarea");

    // Set the text to be copied as the textarea's value
    textarea.value = `https://${subdomain}.${process.env.REACT_APP_DNS}/assessments/attacksurface`;

    // Append the textarea to the document
    document.body.appendChild(textarea);

    // Select the text in the textarea
    textarea.select();

    // Copy the selected text to the clipboard
    document.execCommand("copy");

    // Remove the temporary textarea
    document.body.removeChild(textarea);
    toast.success("Assessment Request Form link copied to your clipboard");
  };

  useEffect(() => {
    setOpenFormModal(organization?.external_attack_surface ?? false);
  }, [organization]);

  return (
    <div>
      <Card className="overflow-visible border border-gray-200 rounded-2xl shadow p-4 my-4">
        <div className="pb-3 mt-3">
          <SectionHeader
            level={3}
            className="text-base font-semibold leading-6"
          >
            Assessments
          </SectionHeader>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-1  flex space-x-4 items-center">
            <Label className="block text-sm font-medium leading-6">
              External Attack Surface
            </Label>

            <div className="mt-2">
              {!loading ? (
                <div className="flex items-center space-x-3">
                  <Switch
                    checked={openFormModal}
                    onChange={switchChangeHandler}
                    className={classNames(
                      openFormModal
                        ? theme.primaryBackgroundColor
                        : "bg-gray-300",
                      `relative inline-flex h-6 w-12 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 ${theme.inputFocusedBorderColor} focus:ring-offset-2`
                    )}
                  >
                    <span
                      className={classNames(
                        openFormModal
                          ? "translate-x-6 bg-white"
                          : "translate-x-0 " + theme.primaryBackgroundColor,
                        "pointer-events-none relative inline-block h-5 w-5 transform rounded-full  shadow ring-0 transition duration-200 ease-in-out"
                      )}
                    ></span>
                  </Switch>

                  {openFormModal && (
                    <a
                      onClick={(e) => copyToClipboard(e)}
                      href="#!"
                      className="text-sm font-medium leading-7 text-indigo-600"
                    >
                      Copy Assessment Form Link
                    </a>
                  )}
                </div>
              ) : (
                <Loader className="h-8 w-8 border-3 border-primary" />
              )}
            </div>
          </div>
        </div>
      </Card>

      <Card className="overflow-visible border border-gray-200 rounded-2xl shadow p-4 my-4">
        <div className="pb-3 mt-3">
          <SectionHeader
            level={3}
            className="text-base font-semibold leading-6"
          >
            Assessment Requests
          </SectionHeader>
        </div>
        <div className="w-full">
          <AssessmentsTable
            getAllAssessmentRequests={getAllAssessmentRequests}
            setOpenFormModal={setOpenFormModal}
            assesmentsLoading={assesmentsLoading}
            assesments={assesments}
          />
        </div>
      </Card>
    </div>
  );
}

export default Assessment;
