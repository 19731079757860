import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import LabelledInput from "../../../components/inputs/LabelledInput";
import LabelledList from "../../../components/LabelledList";
import { get, post, put } from "../../../utils/httpMethods";
import LoadingButton from "../../../components/buttons/LoadingButton";
import useAuth from "../../../hooks/useAuth";
import { GlobalToasterContext } from "../../../contexts/ToasterContext";
import { useLocation } from "react-router-dom";
import RHFCheckbox from "../../../components/inputs/RHFCheckbox";
import Scrollbar from "../../../components/Scrollbar";
import CancelButton from "../../../components/buttons/CancelButton";
import Overlay from "../../../components/Overlay";
import Typography from "../../../components/Typography";
import Label from "../../../components/Label";
import Checkbox from "../../../components/inputs/Checkbox";
import toast from "react-hot-toast";

interface AssignCustomerModalProps {
  modalProps: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  };
  user: any;
  fetchUsers: any;
  getCustomersAssignedToUsers: any;
  allCustomers: any;
}

export default function AssignCustomerModal({
  modalProps: { open, setOpen },
  user,
  fetchUsers,
  getCustomersAssignedToUsers,
  allCustomers,
}: AssignCustomerModalProps) {
  const cancelButtonRef = useRef(null);
  const { user: loggedUser, organization } = useAuth();
  const [loading, setLoading] = useState(false);
  const [tenants, setTenants] = useState(allCustomers);
  const { pathname } = useLocation();
  const { setShowErrorOverlay } = useContext(GlobalToasterContext)!;

  const onSubmit = async () => {
    let toDeleteUsers;
    let toSaveUsers: any = [];
    tenants.forEach((tenant: any) => {
      if (!tenant.id && tenant.checked) {
        const tempObj = {
          user_id: user.id,
          customer_id: tenant.value,
          customer_name: tenant.label,
          role: user.role,
          organization_id: organization.id,
        };
        toSaveUsers.push(tempObj);
      }
    });
    toDeleteUsers = tenants.filter(
      (tenant: any) => tenant.id && !tenant.checked
    );
    try {
      setLoading(true);
      await post(`/api/employee-customers`, { toSaveUsers, toDeleteUsers });
      toast.success(
        `Tenants for ${
          user.first_name + " " + user.last_name ?? "user"
        } changed`
      );
      setLoading(false);
      setOpen(false);
      // fetchUsers();
      getCustomersAssignedToUsers();
    } catch (error: any) {
      toast.error(error.message);
      setShowErrorOverlay(true);
      setLoading(false);
      setOpen(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setInterval(() => {
      // reset();
    }, 1000);
  };

  const handleFieldCheck = (e: any, field: any) => {
    // setSelectionError("");
    // setSelectAll(false);
    let tempFields = tenants.map((eachField: any) => {
      if (eachField.value === field.value) {
        return {
          ...field,
          checked: !eachField.checked,
        };
      } else {
        return eachField;
      }
    });
    setTenants(tempFields);
  };

  useEffect(() => {
    setTenants(allCustomers);
  }, [allCustomers]);

  return (
    <Overlay
      titleContent={
        pathname === "/super-user/resellers"
          ? ` Assigned to ${user.first_name} ${user.last_name}`
          : ` Assign to ${user.first_name} ${user.last_name}?`
      }
      open={open}
      handleClose={handleClose}
      className="sm:w-3/6"
    >
      <Scrollbar sx={{ maxHeight: "70vh" }}>
        {pathname === "/super-user/resellers" ? (
          tenants &&
          tenants.length &&
          tenants?.filter((field: any) => field.checked)?.length ? (
            tenants
              ?.filter((field: any) => field.checked)
              .map((field: any) => (
                <div
                  className={`relative mb-4 items-start pb-1 flex ${
                    pathname === "/super-user/resellers"
                      ? "cursor-default"
                      : "cursor-pointer"
                  }`}
                >
                  <div className="flex h-6 items-center focus:ring-0">
                    <Checkbox
                      id={field.value}
                      aria-describedby="comments-description"
                      disabled={pathname === "/super-user/resellers"}
                      checked={field.checked}
                      className={`h-4 w-4 ${
                        pathname === "/super-user/resellers"
                          ? "cursor-default"
                          : "cursor-pointer"
                      } rounded border-gray-300 rounded border-gray-300`}
                      onClick={(e) => handleFieldCheck(e, field)}
                    />
                  </div>
                  <div
                    className={`ml-3 text-sm leading-6 ${
                      pathname === "/super-user/resellers"
                        ? "cursor-default"
                        : "cursor-pointer"
                    }`}
                  >
                    <Label
                      htmlFor={field.value}
                      className={`font-medium text-gray-900 ${
                        pathname === "/super-user/resellers"
                          ? "cursor-default"
                          : "cursor-pointer"
                      }`}
                    >
                      {field.label} absamnmmmmmmmbm
                    </Label>
                  </div>
                </div>
              ))
          ) : (
            <Typography as="div" variant="valueLabel">
              No Customers Assigned
            </Typography>
          )
        ) : tenants && tenants.length ? (
          tenants?.map((field: any) => (
            <div
              className={`relative mb-4 items-start pb-1 ml-2 flex ${
                pathname === "/super-user/resellers"
                  ? "cursor-default"
                  : "cursor-pointer"
              }`}
            >
              <div className="flex h-6 items-center focus:ring-0">
                <Checkbox
                  id={field.value}
                  aria-describedby="comments-description"
                  disabled={pathname === "/super-user/resellers"}
                  checked={field.checked}
                  onClick={(e) => handleFieldCheck(e, field)}
                  className={`h-4 w-4 ${
                    pathname === "/super-user/resellers"
                      ? "cursor-default"
                      : "cursor-pointer"
                  } rounded border-gray-300`}
                />
              </div>
              <div
                className={`ml-3 text-sm leading-6 ${
                  pathname === "/super-user/resellers"
                    ? "cursor-default"
                    : "cursor-pointer"
                }`}
              >
                <Label
                  htmlFor={field.value}
                  className={`font-medium text-gray-900 ${
                    pathname === "/super-user/resellers"
                      ? "cursor-default"
                      : "cursor-pointer"
                  }`}
                >
                  {field.label}
                </Label>
              </div>
            </div>
          ))
        ) : (
          <Typography as="div" variant="valueLabel">
            No Customers Found
          </Typography>
        )}
      </Scrollbar>

      <div className="flex mt-4 space-x-2 justify-end">
        <CancelButton type="button" onClick={() => handleClose()}>
          Cancel
        </CancelButton>

        {pathname !== "/super-user/resellers" && (
          <LoadingButton
            onClick={onSubmit}
            type="submit"
            className="rounded-md px-2.5 py-1.5 text-sm font-semibold shadow-sm"
            buttonText="Save"
            loading={loading}
          />
        )}
      </div>
    </Overlay>
  );
}
