import React, { useContext } from "react";
import { ThemeContext } from "../contexts/ThemeContext";
interface Props extends React.HTMLAttributes<HTMLDivElement> {
  key?: string;
  className: string;
  children?: React.ReactNode;
  ref?: any;
  isHighlighted?: any;
}

const Card = React.forwardRef<HTMLDivElement, Props>(
  ({ key, className = "", children, isHighlighted, ...other }: Props, ref) => {
    const { theme } = useContext(ThemeContext)!;
    return (
      <div
        key={key}
        className={`${className} ${
          isHighlighted ? isHighlighted : theme.cardBackgroundColor
        } ${theme.labelTextColor} ${theme.tabBorderBottomColor} ${
          theme.tableBorderColor
        }`}
        {...other}
        ref={ref}
      >
        {children}
      </div>
    );
  }
);

export default Card;
