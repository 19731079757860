import React, { ReactNode, useContext } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";

interface Props extends React.HTMLProps<HTMLButtonElement> {
  className?: string;
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
  children: ReactNode;
}

function DangerButton({
  className,
  type,
  disabled,
  children,
  ...other
}: Props) {
  const { theme } = useContext(ThemeContext)!;

  return (
    <button
      className={`text-sm font-semibold items-center rounded-md px-3 py-[0.45rem] ${theme.dangerButtonBorderColor}  ${theme.dangerButtonTextColor} ${theme.dangerButtonBackgroundColor} ${theme.dangerButtonHoverTextColor} ${theme.dangerButtonHoverBackgroundColor} ${theme.dangerButtonDisabledBackgroundColor} ${theme.dangerButtonDisabledTextColor} ${theme.dangerButtonActiveBgColor} ${className}`}
      disabled={disabled}
      type={type}
      {...other}
    >
      {children}
    </button>
  );
}

export default DangerButton;
