import React, { useContext, useEffect, useState } from "react";
import NoDataFull from "../../../../components/nodataPlaceholders/NoDataFull";
import LoadingRow from "../../../../components/loaders/LoadingRow";
import AssessmentTableRow from "./AssessmentTableRow";
import NoDataFound from "../../../../components/nodataPlaceholders/NoDataFound";

import TableAccordian from "../../../../components/tables/TableAccordian";

const tableCols = ["ID", "Company Name", "Actions", ""];

function AssessmentsTable({
  getAllAssessmentRequests,
  setOpenFormModal,
  assesmentsLoading,
  assesments,
}: any) {
  const [selectedRow, setSelectdRow] = useState<any>();
  const [openAccordion, setOpenAccordion] = useState([]);

  useEffect(() => {
    getAllAssessmentRequests();
  }, []);

  return (
    <div>
      <>
        <div className="mt-5 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                {!assesmentsLoading && !assesments?.length ? (
                  <NoDataFull
                    openForm={setOpenFormModal}
                    name={"Assessment Request"}
                    hideActionButton={true}
                    //   descriptionText={}
                  />
                ) : (
                  <>
                    <TableAccordian
                      tableColumns={tableCols}
                      isNestedTable={true}
                    >
                      {assesmentsLoading ? (
                        <LoadingRow colspan={tableCols?.length || 6} />
                      ) : assesments?.length ? (
                        assesments.map((assessment: any) => (
                          <AssessmentTableRow
                            key={assessment.id}
                            assessment={assessment}
                            refetch={getAllAssessmentRequests}
                            isSelected={assessment.id === selectedRow}
                            openAccordion={openAccordion}
                            setOpenAccordion={setOpenAccordion}
                          />
                        ))
                      ) : (
                        <>
                          <NoDataFound
                            name="Assessment Request"
                            colspan={tableCols?.length || 6}
                          />
                        </>
                      )}
                    </TableAccordian>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default AssessmentsTable;
