import { Dialog, Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import OTPInput from "../../../components/inputs/OTPInput";
import { del, get, post } from "../../../utils/httpMethods";
import useReacaptcha from "../../../hooks/useReacaptcha";
import { CustomersContext } from "../../../contexts/CustomersContext";
import { AuthContext } from "../../../contexts/JWTContext";
import useAuth from "../../../hooks/useAuth";
import Loader from "../../../components/loaders/Loader";
import { GlobalToasterContext } from "../../../contexts/ToasterContext";
import LoadingButton from "../../../components/buttons/LoadingButton";
import CancelButton from "../../../components/buttons/CancelButton";
import DangerButton from "../../../components/buttons/DangerButton";
import { ThemeContext } from "../../../contexts/ThemeContext";
import SectionHeader from "../../../components/SectionHeader";
import Typography from "../../../components/Typography";
import Overlay from "../../../components/Overlay";
import toast from "react-hot-toast";

interface props {
  modalProps: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    userDetails?: any;
    fetchUsers?: any;
  };
}

type Inputs = {
  code1: string;
  code2: string;
  code3: string;
  code4: string;
  code5: string;
  code6: string;
};

type ValueNames = "code1" | "code2" | "code3" | "code4" | "code5" | "code6";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

function DeleteCustomerModal({ modalProps }: props) {
  const { open, setOpen, userDetails, fetchUsers } = modalProps;
  const cancelButtonRef = useRef(null);
  const { user } = useAuth();

  const [proceedDelete, setProceedDelete] = useState(false);
  const [proceedMFA, setProceedMFA] = useState(false);

  const [errMessage, setErrMessage] = useState("");
  const [otpVerified, setOtpVerified] = useState(false);

  //to get customer name and its users
  const { customers, currentCompany, reloadCustomers } =
    useContext(CustomersContext)!;
  const [customer, setCustomer] = useState(currentCompany);

  useEffect(() => {
    setCustomer(currentCompany);
  }, [customers, currentCompany]);

  let { name } = customer;

  const [allUsers, setAllUsers] = useState([]);
  const { organization } = useContext(AuthContext)!;
  const { recaptchaVerify } = useReacaptcha("login");
  const [callVerifyOTP, setCallVerifyOTP] = useState(false);
  const [iconLoading, setIconLoading] = useState(false);
  const [mfaCode, setMfaCode] = useState("");
  const [loading, setLoading] = useState(false);
  const { setShowErrorOverlay } = useContext(GlobalToasterContext)!;

  useEffect(() => {
    if (open) {
      fetchResellerCustomerUsers();
    }
  }, [open]);

  const fetchResellerCustomerUsers = async () => {
    try {
      if (["Reseller", "Super_user"].includes(organization?.access_tier)) {
        let result: any = await get(
          `/api/customers/${currentCompany.id}/users`
        );
        setAllUsers(result?.data);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const otpSchema = yup
    .object({
      code1: yup.string().required("Code is required"),
      code2: yup.string().required("Code is required"),
      code3: yup.string().required("Code is required"),
      code4: yup.string().required("Code is required"),
      code5: yup.string().required("Code is required"),
      code6: yup.string().required("Code is required"),
    })
    .required();

  const defaultValues = {
    code1: "",
    code2: "",
    code3: "",
    code4: "",
    code5: "",
    code6: "",
  };

  const methods = useForm<Inputs>({
    mode: "all",
    resolver: yupResolver(otpSchema) as any,
    defaultValues,
  });

  const {
    handleSubmit,
    setError,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = methods;

  const values = watch();
  const { theme, mode } = useContext(ThemeContext)!;

  const handleClose = () => {
    setProceedDelete(false);
    setProceedMFA(false);
    setOpen(false);
    setOtpVerified(false);
    reset();
    setCallVerifyOTP(false);
    setErrMessage("");
  };

  const onSubmit = async (data: any) => {
    setIconLoading(true);
    const token = await recaptchaVerify();
    if (token.length) {
      try {
        const {
          code1 = "",
          code2 = "",
          code3 = "",
          code4 = "",
          code5 = "",
          code6 = "",
        } = data;
        const otp: any = code1 + code2 + code3 + code4 + code5 + code6;
        await post("/api/auth/mfa/code", {
          otp,
          recaptchaToken: token,
          email: user?.email,
        });
        setOtpVerified(true);
        setIconLoading(false);
        setMfaCode(otp);
      } catch (e: any) {
        setOtpVerified(false);
        setCallVerifyOTP(false);
        setIconLoading(false);

        setErrMessage(e.message);
        setValue("code1", "");
        setValue("code2", "");
        setValue("code3", "");
        setValue("code4", "");
        setValue("code5", "");
        setValue("code6", "");
      }
    }
  };

  useEffect(() => {
    if (
      Object.values(values).every((value: any) => parseInt(value) >= 0) &&
      !callVerifyOTP
    ) {
      onSubmit(values);
      setCallVerifyOTP(true);
    }
  }, [values]);

  const onProceedAgainAndDelete = async () => {
    try {
      setLoading(true);
      const url = userDetails
        ? `/api/users/${userDetails?.id}?mfa_code=${mfaCode}`
        : `/api/resellers/${currentCompany.id}?mfa_code=${mfaCode}`;

      const result: any = await del(url);

      setLoading(false);
      toast.success(result.message);
      userDetails ? fetchUsers() : reloadCustomers("");
      handleClose();
    } catch (error: any) {
      toast.error(error.error);
      setShowErrorOverlay(true);
    }
  };

  return (
    <Overlay
      open={open}
      handleClose={handleClose}
      titleContent={""}
      className="sm:w-full sm:max-w-lg"
    >
      {proceedMFA ? (
        <FormProvider {...methods}>
          <form className="px-10 pt-10" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex justify-between gap-4">
              <div>
                <Typography className="leading-6 mt-3">
                  Enter the OTP
                </Typography>

                <div className="mt-2 flex gap-x-4 items-center">
                  {Object.keys(defaultValues)
                    .filter((name) => name != "otp")
                    .map((name, index) => (
                      <OTPInput
                        key={name}
                        name={`code${index + 1}` as ValueNames}
                        placeholder="-"
                        type="text"
                        enableFocus={index === 0}
                        setErrMessage={setErrMessage}
                        disabled={otpVerified}
                        fieldNameArray={[
                          "code1",
                          "code2",
                          "code3",
                          "code4",
                          "code5",
                          "code6",
                        ]}
                      />
                    ))}

                  {otpVerified ? (
                    <CheckCircleIcon className="h-6 w-6" color="green" />
                  ) : iconLoading ? (
                    <Loader className="h-6 w-6 border-3 border-primary" />
                  ) : (
                    <XCircleIcon className="h-6 w-6" color="#B40301" />
                  )}
                </div>

                {(errMessage ||
                  errors.code1 ||
                  errors.code2 ||
                  errors.code3 ||
                  errors.code4 ||
                  errors.code5 ||
                  errors.code6) && (
                  <span className={`${theme.inputErrorTextColor} text-sm`}>
                    {errMessage ||
                      errors.code1?.message ||
                      errors.code2?.message ||
                      errors.code3?.message ||
                      errors.code4?.message ||
                      errors.code5?.message ||
                      errors.code6?.message}
                  </span>
                )}
              </div>
            </div>
          </form>
        </FormProvider>
      ) : (
        <div className="sm:flex sm:items-start">
          <div
            className={`${
              mode === "dark" ? "bg-slate-700" : "bg-red-100"
            } mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full  sm:mx-0 sm:h-10 sm:w-10`}
          >
            <ExclamationTriangleIcon
              className={`h-6 w-6 ${
                mode === "dark" ? "text-danger-900" : "text-red-600"
              } `}
              aria-hidden="true"
            />
          </div>
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            {proceedDelete ? (
              <Typography className="text-sm">
                {`Are you sure you want to delete ${allUsers.length || 1}${
                  allUsers.length > 1 ? " accounts" : " account"
                }? Deleting this account requires the
                            multi-factor authentication for this account to
                            proceed.`}{" "}
              </Typography>
            ) : (
              <>
                <SectionHeader
                  level={3}
                  className="text-base font-semibold leading-6"
                >
                  Delete Account
                </SectionHeader>
                <div className="mt-2">
                  <Typography className="text-sm">
                    {" "}
                    {`You are about to permanently delete the account
                                ${
                                  userDetails
                                    ? `${userDetails.first_name} ${
                                        userDetails.last_name ?? ""
                                      }`
                                    : name || organization?.name
                                }. This will delete the account, and all
                                records associated with it. This operation is
                                non-reversible.`}
                  </Typography>
                  <br></br>
                  <Typography className="text-sm">
                    Are you sure you would like to proceed?
                  </Typography>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      <div
        className={`mt-5 sm:mt-6 flex flex-col gap-y-4 gap-x-4 sm:flex-col lg:flex-row md:flex-row sm:gap-y-2 ${
          proceedMFA ? "px-10 pb-10" : ""
        }`}
      >
        {!proceedMFA && (
          <CancelButton
            type="button"
            className={`mt-3 inline-flex w-full justify-center px-3 sm:col-start-1 sm:mt-0`}
            onClick={() => {
              setOpen(false);
              setProceedDelete(false);
            }}
          >
            Cancel
          </CancelButton>
        )}
        <DangerButton
          type={"button"}
          className={`inline-flex w-full justify-center sm:col-start-2 ${theme.submitButtonDisabledBgColor} ${theme.submitButtonDisabledTextColor}`}
          onClick={() => {
            if (!proceedMFA && user.is_mfa_enabled) {
              if (!proceedDelete) setProceedDelete(true);
              else setProceedMFA(true);
            } else {
              onProceedAgainAndDelete();
            }
          }}
          disabled={(proceedMFA && !otpVerified) || loading}
        >
          <div
            className={`mr-1 h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]
        ${loading ? "" : "invisible"}`}
            role="status"
          ></div>
          <span className="pr-5 pl-2">
            {proceedMFA ? "Proceed again and delete the user" : "Proceed"}
          </span>
        </DangerButton>
      </div>
    </Overlay>
  );
}

export default DeleteCustomerModal;
