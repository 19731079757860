import React, { useContext } from "react";
import { ThemeContext } from "../contexts/ThemeContext";

interface Props {
  children: React.ReactNode | string;
  className?: string;
  as?: "p" | "div" | "span" | "dt" | "dd"; // Optional prop to specify the element type
  variant?:
    | "error"
    | "success"
    | "valueLabel"
    | "headingLabel"
    | "subHeading"
    | "default";
  onClick?: () => void;
}

function Typography({
  children,
  className,
  as,
  variant = "default",
  onClick = () => {},
}: Props) {
  const { theme } = useContext(ThemeContext)!;
  const Element = as || "p"; // Determine which element to render
  function getColorClass(variant: any) {
    switch (variant) {
      case "error":
        return theme.inputErrorTextColor;
      case "success":
        return theme.successTextColor;
      case "valueLabel":
        return theme.activeLabelTextColor;
      case "headingLabel":
        return theme.labelTextColor;
      case "subHeading":
        return theme.disableLabelTextColor;
      default:
        return theme.typographyTextColor;
    }
  }

  const textColorClass = getColorClass(variant);
  return (
    <Element className={`${className} ${textColorClass}`} onClick={onClick}>
      {children}
    </Element>
  );
}

export default Typography;
