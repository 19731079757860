export const colorPresets = [
  // DEFAULT
  {
    name: "default",
    borderColor: "border-red-1000",
    backgroundColor: "bg-red-100",
    colorCode: "#b40301",
    boxShadow: "rgba(180, 3, 1, 0.3)",
  },
  // TEAL
  {
    name: "teal",
    borderColor: "border-teal-700",
    backgroundColor: "bg-teal-100",
    colorCode: "#0f766e",
    boxShadow: "rgba(13, 148, 136, 0.3)",
  },
  // CYAN
  {
    name: "cyan",
    borderColor: "border-cyan-500",
    backgroundColor: "bg-cyan-100/50",
    colorCode: "#0891b2",
    boxShadow: "rgba(8, 145, 178, 0.3)",
  },
  // BLUE
  {
    name: "blue",
    borderColor: "border-blue-500",
    backgroundColor: "bg-blue-100",
    colorCode: "#2563eb",
    boxShadow: "rgba(59, 130, 246, 0.3)",
  },
  // ORANGE
  {
    name: "orange",
    borderColor: "border-orange-500",
    backgroundColor: "bg-orange-100",
    colorCode: "#fb923c",
    boxShadow: "rgba(249, 115, 22, 0.3)",
  },
  // GREEN
  {
    name: "green",
    borderColor: "border-green-700",
    backgroundColor: "bg-green-100",
    colorCode: "#15803d",
    boxShadow: "rgba(34, 197, 94, 0.3)",
  },
];
