import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { get, post, put } from "../utils/httpMethods";
import { JWTContextType } from "../@types/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { setAccessTokenExpiry } from "../utils/jwt";
import { GlobalToasterContext } from "./ToasterContext";
import { socket } from "../utils/socket";
import { ThemeContext } from "./ThemeContext";
import { useIdleTimer, workerTimers } from "react-idle-timer";
import toast from "react-hot-toast";
export const AuthContext = createContext<JWTContextType | null>(null);

// MFA Bypass time in milliseconds
const MFABypassTimeInMS = 86400000; // 24 Hours

function JWTContext({ children }: any) {
  const navigate = useNavigate();
  const { theme, mode, toggleTheme } = useContext(ThemeContext)!;
  const [isProcessing, setIsProcessing] = useState(false);
  const [isFetchingUser, setIsFetchingUser] = useState(false);
  const [user, setUser] = useState<any>(null);
  const [MFA, setMFADetails] = useState({} as any);
  const [organization, setOrganization] = useState(null);
  const [autoLogout, setAutoLogout] = useState<any>({});
  const skipThemeUpdate = useRef(false);
  const prevUserData = useRef<any>(null);
  const { setShowErrorOverlay, setAlertMFAModalProps } =
    useContext(GlobalToasterContext)!;

  /**
   * Function to execute after timeout is reached limit of no activity
   */
  const onIdle = () => {
    logout();
  };

  /**
   * ---------------------------------------------------------------
   * IDLE TIMER: LOGOUT USER IF NO ACTIVITY BASED ON TIMEOUT VALUE
   * ---------------------------------------------------------------
   * CROSS TAB IS SUPPORTED: TIMER IS SYNCED BETWEEN OPEN TABS
   * ---------------------------------------------------------------
   * RUN TIMER ONLY WHEN USER IS LOGGED IN
   * ---------------------------------------------------------------
   */
  const isRunningOnLocal =
    window.location.host.split(".")[0] === "localhost:3000";
  useIdleTimer({
    disabled: !user || isRunningOnLocal,
    crossTab: true,
    syncTimers: 1,
    timeout: Number(process.env.REACT_APP_NO_ACTIVITY_TIMEOUT_IN_MS),
    onIdle,
    timers: workerTimers,
  });

  const { pathname } = useLocation();

  useEffect(() => {
    prevUserData.current = user;
    if (!user?.mfa_disabled_at) return;
    const MFABypassTimeRemainingInMS =
      parseInt(user?.mfa_disabled_at) + MFABypassTimeInMS - Date.now();

    setAutoLogout((prev: any) => ({
      ...prev,
      counter: MFABypassTimeRemainingInMS,
    }));
  }, [user]);

  useEffect(() => {
    let to: any;

    if (!isNaN(autoLogout.counter)) {
      if (Math.sign(autoLogout.counter) !== -1) {
        to = setTimeout(() => {
          logout();
        }, autoLogout.counter);
      }
    }

    return () => {
      clearTimeout(to);
    };
  }, [autoLogout.counter]);

  /**
   * Fetch the logged in user
   * @returns
   */
  const fetchUser = async () => {
    try {
      setIsFetchingUser(true);
      const userData = (await get("/api/auth/self")) as any;
      const { user, organization } = userData;
      if (!user.timezone) {
        user.timezone = "America/Chicago"; // Default timezone
      }
      setUser(user);
      setAutoLogout((prev: any) => ({
        ...prev,
        is_mfa_enabled: user.is_mfa_enabled,
      }));
      localStorage.setItem("currentUser", JSON.stringify(user));
      setOrganization(organization);
      setIsFetchingUser(false);
      return userData;
    } catch (e) {
      setIsFetchingUser(false);
      localStorage.removeItem("currentUser");
      localStorage.removeItem("selectedTenant");
      if (
        ![process.env.REACT_APP_ROOT_SUB_DOMAIN].includes(
          window.location.host.split(".")[0]
        ) &&
        window.location.host.split(".")[0] !== "localhost:3000"
      ) {
        !pathname.includes("auth") && navigate("/auth/login");
      } else {
        !pathname.includes("auth") && navigate("/auth/company/login");
      }
    }
  };

  useEffect(() => {
    const accessTokenExpiry = getCookie("accessTokenExpiry");
    const user = JSON.parse(localStorage.getItem("currentUser") as any);

    /* Enforce MFA */
    const isRunningOnLocal =
      window.location.host.split(".")[0] === "localhost:3000";
    if (!isRunningOnLocal) {
      setAlertMFAModalProps({
        show: user?.show_enable_mfa_advise && !user.is_mfa_enabled,
      });
    }

    const timeStandard = localStorage.getItem("timeStandard");
    localStorage.setItem("timeStandard", timeStandard || "Date");
    const initialize = () => {
      if (!accessTokenExpiry) {
        localStorage.removeItem("currentUser");
        localStorage.removeItem("selectedTenant");
        if (
          ![process.env.REACT_APP_ROOT_SUB_DOMAIN].includes(
            window.location.host.split(".")[0]
          ) &&
          window.location.host.split(".")[0] !== "localhost:3000"
        ) {
          !pathname.includes("auth") &&
            !pathname.includes("attacksurface") &&
            navigate("/auth/login");
        } else {
          !pathname.includes("auth") &&
            !pathname.includes("attacksurface") &&
            navigate("/auth/company/login");
        }
      } else {
        if (!pathname.includes("/auth/accept-invite")) {
          setAccessTokenExpiry(Number(accessTokenExpiry));
          if (pathname.includes("auth")) {
            navigate(
              [
                "WanAware_Finance",
                "Reseller_Finance",
                "Customer_Finance",
              ].includes(user.role)
                ? "/administration/billing"
                : ["WanAware_Super_User", "Reseller_Admin"].includes(user.role)
                ? "/super-user/customers"
                : "/assets",
              { replace: true }
            );
          }
          fetchUser();
        }
      }
    };

    initialize();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Login with sub domain
   * @param email
   * @param password
   * @param recaptchaToken
   * @returns
   */
  const loginWithDomain = async (
    email: string,
    password: string,
    recaptchaToken: string
  ) => {
    setIsProcessing(true);
    try {
      const loginRes: any = await post(
        "/api/auth/login",
        {
          email: email,
          password: password,
          recaptchaToken: recaptchaToken,
        },
        {
          headers: {
            subdomain:
              window.location.host.split(".")[0] !== "localhost:3000"
                ? window.location.host.split(".")[0]
                : "",
          },
        }
      );

      const { is_mfa_enabled, mfa_hash } = loginRes;

      // Navigate to verify OTP screen if MFA is enabled
      if (is_mfa_enabled) {
        setMFADetails({ password, mfa_hash, email });
        setIsProcessing(false);
        navigate("/auth/verify-otp");
        setAlertMFAModalProps({
          show: false,
        });
        return loginRes;
      }

      const { user, organization } = loginRes;
      const accessTokenExpiry = getCookie("accessTokenExpiry");
      setAccessTokenExpiry(Number(accessTokenExpiry));
      setUser(user);
      localStorage.setItem("currentUser", JSON.stringify(user));
      localStorage.setItem("selectedTenant", JSON.stringify(organization?.id));
      toggleTheme(user.theme_mode || "light");
      setOrganization(organization);
      navigate(
        ["WanAware_Finance", "Reseller_Finance", "Customer_Finance"].includes(
          user.role
        )
          ? "/administration/billing"
          : ["WanAware_Super_User", "Reseller_Admin"].includes(user.role)
          ? "/super-user/customers"
          : "/assets",
        { replace: true }
      );

      /* Enforce MFA */
      const isRunningOnLocal =
        window.location.host.split(".")[0] === "localhost:3000";

      if (!isRunningOnLocal) {
        setAlertMFAModalProps({
          show: user?.show_enable_mfa_advise && !user.is_mfa_enabled,
        });
      }

      setIsProcessing(false);
      return loginRes;
    } catch (e: any) {
      setIsProcessing(false);
      if (e.is_deactivated) {
        navigate("/auth/deactivated");
      } else {
        toast.error(e.message || e.error || e);
        setShowErrorOverlay(true);
      }
      return e;
    }
  };

  /**
   * Verify OTP
   * @param otp
   * @param recaptchaToken
   * @param useBackupCode
   * @returns
   */
  const verifyOTP = async (
    otp: string,
    recaptchaToken: string,
    useBackupCode: boolean
  ) => {
    setIsProcessing(true);
    try {
      const loginRes: any = await post("/api/auth/mfa", {
        otp,
        recaptchaToken: recaptchaToken,
        mfa_hash: MFA.mfa_hash,
        email: MFA.email,
        password: MFA.password,
        use_backup_code: useBackupCode,
      });

      const { user, organization } = loginRes;

      const accessTokenExpiry = getCookie("accessTokenExpiry");
      setAccessTokenExpiry(Number(accessTokenExpiry));
      setUser(user);

      localStorage.setItem("currentUser", JSON.stringify(user));
      localStorage.setItem("selectedTenant", JSON.stringify(organization?.id));
      toggleTheme(user.theme_mode || "light");
      setOrganization(organization);
      setMFADetails(null);
      navigate(
        ["WanAware_Finance", "Reseller_Finance", "Customer_Finance"].includes(
          user.role
        )
          ? "/administration/billing"
          : ["WanAware_Super_User", "Reseller_Admin"].includes(user.role)
          ? "/super-user/customers"
          : "/assets",
        { replace: true }
      );
      setIsProcessing(false);
      return loginRes;
    } catch (e: any) {
      setIsProcessing(false);
      throw new Error(e.message);
    }
  };

  /**
   * Forgot password
   * @param email
   * @returns
   */
  const forgotPassword = async (email: string, recaptchaToken: string) => {
    setIsProcessing(true);
    try {
      const forgotPasswordResult: any = await post(
        "/api/auth/forgot-password",
        {
          email: email,
          recaptchaToken,
        }
      );
      setIsProcessing(false);

      return forgotPasswordResult;
    } catch (e: any) {
      setIsProcessing(false);
      return e;
    }
  };

  const resetPassword = async (
    password: string,
    confirm_password: string,
    token: string
  ) => {
    setIsProcessing(true);
    try {
      const resetPasswordResult: any = await post(
        "/api/auth/password/self",
        {
          password,
          confirm_password,
        },
        {
          headers: { Authorization: token },
        }
      );
      setIsProcessing(false);

      toast.success(resetPasswordResult.message);

      return resetPasswordResult;
    } catch (e: any) {
      setIsProcessing(false);
      throw new Error(e.message);
      toast.error(e.message);
      setShowErrorOverlay(true);
      return e;
    }
  };

  /**
   * Accept invite and signup the user
   * @param first_name
   * @param last_name
   * @param email
   * @param password
   * @param subdomain
   * @param verificationToken
   * @param isFirstUser
   * @param recaptchaToken
   * @returns
   */
  const acceptInviteAndRegister = async (
    first_name: string,
    last_name: string,
    email: string,
    password: string,
    confirm_password: string,
    subdomain: string | undefined,
    verificationToken: string,
    isFirstUser: any = "true",
    recaptchaToken: string
  ) => {
    setIsProcessing(true);
    try {
      const acceptInviteResult: any = await post(
        isFirstUser === "true"
          ? `/api/auth/users/self?token=${verificationToken}`
          : `/api/auth/accept-user-invite?token=${verificationToken}`,
        {
          first_name,
          last_name,
          email,
          password,
          confirm_password,
          subdomain,
          recaptchaToken,
        }
      );
      setIsProcessing(false);

      toast.success(
        acceptInviteResult.message ||
          "Your registration is successful, you can login to explore the WanAware"
      );

      navigate("/auth/login");
      return acceptInviteResult;
    } catch (e: any) {
      setIsProcessing(false);
      toast.error(e.message || e.response);
      setShowErrorOverlay(true);
      return e;
    }
  };

  /**
   * Customer signup
   * @param first_name
   * @param last_name
   * @param email
   * @param company_name
   * @param subdomain
   * @param password
   * @param confirm_password
   * @param token
   * @returns
   */
  const signup = async (
    first_name: string,
    last_name: string,
    email: string,
    company_name: string,
    subdomain: string,
    password: string,
    confirm_password: string,
    token: string
  ) => {
    setIsProcessing(true);
    try {
      const signupResult: any = await post(
        "/api/auth/signup",
        {
          first_name,
          last_name,
          email,
          company_name,
          subdomain,
          password,
          confirm_password,
          recaptchaToken: token,
        },
        {
          headers: {
            subdomain:
              window.location.host.split(".")[0] !== "localhost:3000" &&
              window.location.host.split(".")[0] !==
                process.env.REACT_APP_ROOT_SUB_DOMAIN
                ? window.location.host.split(".")[0]
                : "",
          },
        }
      );
      setIsProcessing(false);

      return signupResult;
    } catch (e: any) {
      setIsProcessing(false);
      toast.error(e.message || e.error);
      setShowErrorOverlay(true);
      return e;
    }
  };

  /**
   * Logout
   */
  const logout = useCallback(async () => {
    await get("/api/auth/logout");
    deleteCookie("accessTokenExpiry");

    const user = JSON.parse(localStorage.getItem("currentUser") as any);
    if (user) socket.emit("leaveMessageRoom", user.id);

    localStorage.removeItem("currentUser");
    localStorage.removeItem("selectedTenant");
    localStorage.removeItem(`elementFilters_${prevUserData.current?.id}`);
    localStorage.removeItem(
      `elements_current_page_${prevUserData.current?.id}`
    );
    localStorage.removeItem(
      `elements_current_limit_${prevUserData.current?.id}`
    );
    setUser(null);
    setOrganization(null);
    skipThemeUpdate.current = true; // ref to skip theme update
    toggleTheme(JSON.parse(localStorage.getItem("themeSettings")!)?.themeMode);
    localStorage.removeItem("eventFilters");
    navigate("/auth/login");
  }, []);

  const getWhiteLabel = async (mode: string) => {
    // Skip white label request if its on local or portal.stage
    if (
      window.location.host.split(".")[0] === "localhost:3000" ||
      window.location.host.split(".")[0] ===
        process.env.REACT_APP_ROOT_SUB_DOMAIN
    ) {
      return;
    }
    return await get(`/api/auth/white-label`, {
      headers: {
        subdomain: window.location.host.split(".")[0],
        mode: mode,
      },
    });
  };

  /**
   * Update theme settings
   */
  useEffect(() => {
    if (skipThemeUpdate.current) {
      skipThemeUpdate.current = false;
      return;
    }
    if (user) {
      updateUser();
    }
  }, [theme]);

  const updateUser = async () => {
    const result: any = await put(`/api/auth/self`, {
      theme_mode: mode,
    });
    fetchUser();
  };

  const getCookie = (key: string) => {
    var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : "";
  };

  const deleteCookie = (key: string) => {
    try {
      document.cookie = `${key}=; Max-Age=0; path=/; domain=${window.location.hostname};`;
    } catch (e) {}
  };

  return (
    <AuthContext.Provider
      value={{
        logout,
        loginWithDomain,
        isProcessing,
        user: user as any,
        organization: organization as any,
        fetchUser,
        isFetchingUser,
        forgotPassword,
        resetPassword,
        acceptInviteAndRegister,
        signup,
        getWhiteLabel,
        verifyOTP,
        MFA: MFA as any,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default JWTContext;
