import React, { Fragment, useContext, useEffect, useState } from "react";
import { GlobalToasterContext } from "../../../contexts/ToasterContext";
import { del, get, post, put } from "../../../utils/httpMethods";
import Badge from "../../../components/Badge";
import useAuth from "../../../hooks/useAuth";
import { Menu, Transition } from "@headlessui/react";
import { ChevronUpIcon, EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import EditUserModal from "./EditUserModal";
import { Roles } from "../../../constants/Roles";
import UserAccordion from "./UserAccordion";
import { TrashIcon } from "@heroicons/react/24/outline";
import AssignCustomerModal from "./AssignCustomerModal";
import { useLocation } from "react-router-dom";
import Loader from "../../../components/loaders/Loader";
import DeleteCustomerModal from "../customer/DeleteCustomerModal";
import TableActionDangerButton from "../../../components/tables/TableActionDangerButton";
import TableActionGeneralButton from "../../../components/tables/TableActionGeneralButton";
import { ThemeContext } from "../../../contexts/ThemeContext";
import IconWrapper from "../../../components/IconWrapper";
import MenuComponent from "../../../components/MenuComponent";
import toast from "react-hot-toast";

const Reseller_Employee = [
  "Reseller_Admin",
  "Reseller_Engineer",
  "Reseller_Report_User",
  "Reseller_Support",
  "Reseller_Finance",
];

export const AccordionTitle = ({ text }: { text: string }) => {
  return <span>{text}</span>;
};

function UsersTableRow({
  user,
  fetchUsers,
  companies,
  allCustomersOrganizations,
  getAllCustomerOrganizations,
}: any) {
  const allowedRoles = [
    "WanAware_Super_User",
    "Reseller_Admin",
    "Customer_Admin",
  ];
  const { user: loggedUser } = useAuth();
  const { pathname } = useLocation();
  const [edit, setedit] = useState(false);
  const [assignCustomer, setAssignCustomer] = useState(false);
  const [editRoles, setEditRoles] = useState(false);
  const [open, setOpen] = useState(false);
  const [openAccordion, setOpenAccordion] = useState(false);
  const [userAssignedCompanies, setUserAssignedCompanies] = useState([]);
  const [allCustomers, setAllCustomers] = useState([]);
  const [totalCustomersAssigned, setTotalCustomersAssigned] = useState(0);
  const [isDeletingUser, setIsDeletingUser] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const { theme } = useContext(ThemeContext)!;
  const [selectedUsers, setSelectedUsers] = useState([]) as any;
  const { setShowErrorOverlay } = useContext(GlobalToasterContext)!;

  const menuItemsData = [
    {
      label: "Resend Invite",
      title: "Resend Invite",
      actionFunction: "handleResendInvite",
    },
    {
      label: "Edit",
      title: "Edit",
      actionFunction: "handleEdit",
    },
  ];
  let menuOptions: any = user?.is_active
    ? menuItemsData.filter((item: any) => item.label !== "Resend Invite")
    : [...menuItemsData];

  if (allowedRoles.includes(loggedUser?.role)) {
    menuOptions.splice(
      menuOptions.length,
      0,
      user?.is_active
        ? {
            label: "Deactivate",
            title: "Deactivate",
            actionFunction: "handleStatus",
          }
        : {
            label: "Activate",
            title: "Activate",
            actionFunction: "handleStatus",
          }
    );
  }

  menuOptions =
    user.id === loggedUser.id
      ? menuOptions.filter(
          (item: any) =>
            item.label !== "Deactivate" && item.label !== "Activate"
        )
      : (pathname.includes("/super-user/customers") ||
          pathname.includes("/super-user/resellers")) &&
        ["WanAware_Super_User", "Reseller_Admin"].includes(loggedUser.role)
      ? menuOptions.filter(
          (item: any) =>
            item.label === "Deactivate" || item.label === "Activate"
        )
      : [...menuOptions];

  const ResendInviteUser = async () => {
    try {
      const url = `/api/users/invite/${user.id}`;
      const result: any = await post(url);
      toast.success(result.message);
      fetchUsers();
    } catch (error: any) {
      toast.error(error.message);
      setShowErrorOverlay(true);
    }
  };

  const updateUserStatus = async () => {
    try {
      const url = `/api/users/${user.id}`;
      const result: any = await put(url, { is_active: !user?.is_active });
      toast.success(result.message);
      fetchUsers();
    } catch (error: any) {
      toast.error(error.message);
      setShowErrorOverlay(true);
    }
  };

  const globalFunction: Record<string, () => void> = {
    handleDelete: () => {
      setDeleteUser(true);
    },
    handleResendInvite: () => {
      ResendInviteUser();
    },
    handleEdit: () => {
      setedit(true);
      // handleEditUser();
    },
    handleEditRole: () => {
      setEditRoles(true);
      // handleEditUser();
    },
    handleStatus: () => {
      updateUserStatus();
    },
  };

  const handleClick = (functionName: any) => {
    globalFunction[functionName]();
  };

  //menuOptions added onClick property to use MenuComponent Component
  let menuListItems = menuOptions.map((menu: any) => {
    menu["onClick"] = () => handleClick(menu.actionFunction);
    return menu;
  });

  const getUserAssignedCompanyAndRoles = async () => {
    try {
      const result: any = await get(`/api/company-users/${user.id}`);
      setUserAssignedCompanies(result);
    } catch (error) {
      console.log(error);
    }
  };

  // Get customers assigned to users/employees
  const getCustomersAssignedToUsers = async () => {
    try {
      if (!["Reseller_Admin", "WanAware_Super_User"].includes(loggedUser?.role))
        return;
      const result: any = await get(`/api/employee-customers/${user.id}`);

      const temp = allCustomersOrganizations.map((customer: any) => {
        const customerPresent = result.find(
          (each: any) => customer.id === each.customer_id
        );
        if (customerPresent) {
          return {
            value: customer.id,
            label: customer.name,
            checked: true,
            id: customerPresent.id,
          };
        } else {
          return {
            value: customer.id,
            label: customer.name,
            checked: false,
          };
        }
      });
      const totalAssigned = temp.filter((each: any) => each.checked);
      user.totalCustomersAssigned = totalAssigned.length;
      setTotalCustomersAssigned(totalAssigned.length);
      setAllCustomers(temp);
      return result;
    } catch (error: any) {
      console.log(error);
    }
    // const customers = await getAllCustomerOrganizations();
  };

  useEffect(() => {
    getUserAssignedCompanyAndRoles();
    getCustomersAssignedToUsers();
  }, [allCustomersOrganizations]);

  menuListItems =
    user.id === loggedUser.id || !user.zendesk_user_id
      ? menuOptions.filter(
          (item: any) =>
            item.label !== "Deactivate" && item.label !== "Activate"
        )
      : (pathname.includes("/super-user/customers") ||
          pathname.includes("/super-user/resellers") ||
          pathname.includes("/administration/users")) &&
        ["WanAware_Super_User", "Reseller_Admin"].includes(loggedUser.role)
      ? menuOptions.filter(
          (item: any) =>
            item.label === "Deactivate" || item.label === "Activate"
        )
      : [...menuOptions];

  return (
    <>
      <tr
        className={`${
          allowedRoles.includes(loggedUser.role)
            ? "cursor-pointer"
            : "cursor-default"
        }
         group border-background border-spacing-4 rounded-xl ${
           theme.cardBackgroundColor
         }  ${selectedUsers.includes(user.id) ? "" : theme.tableRowHoverBorder}
        `}
        key={user.id}
        onClick={() => {
          if (allowedRoles.includes(loggedUser.role)) {
            setOpenAccordion(!openAccordion);
            if (selectedUsers.length === 0) setSelectedUsers([user.id]);
            else if (selectedUsers.includes(user.id))
              setSelectedUsers(
                selectedUsers.filter((selected: any) => selected != user.id)
              );
            else setSelectedUsers((prev: any) => [...prev, user.id]);
          } else return;
        }}
      >
        <td
          className={`${
            openAccordion ? "rounded-tl-xl" : "rounded-s-xl"
          } py-2.5 pl-4 pr-3 text-sm font-medium ${theme.labelTextColor} 
          ${theme.cardBackgroundColor}
           ${selectedUsers.includes(user.id) ? "" : theme.tableCellBorderLeft}
          `}
        >
          {user.first_name + " " + user.last_name ?? ""}
        </td>
        <td
          className={`py-2.5 pl-4 pr-3 text-sm font-medium ${theme.labelTextColor} `}
        >
          {user.email ?? ""}
        </td>
        <td
          className={`py-2.5 pl-4 pr-3 text-sm font-medium ${theme.labelTextColor} `}
        >
          {user.is_active ? (
            <Badge variant="success" text={"Active"} />
          ) : (
            <Badge variant="error" text={"Inactive"} />
          )}
        </td>
        {(pathname === "/super-user/wanaware-admin/employees" ||
          pathname === "/settings/users" ||
          pathname === "/settings/employees" ||
          (pathname === "/administration/users" &&
            loggedUser.role === "Customer_Admin")) && (
          <td
            className={`py-2.5 pl-4 pr-3 text-sm font-medium ${theme.labelTextColor} `}
          >
            {isDeletingUser ? (
              <span className="px-2 py-1.5">
                <Loader className="h-5 w-5 border-3 border-primary" />
              </span>
            ) : (
              <TableActionDangerButton
                type="button"
                onClick={() => handleClick("handleDelete")}
                hidden={user.id === loggedUser.id}
              >
                <TrashIcon className="w-4 h-4" />
              </TableActionDangerButton>
            )}
          </td>
        )}
        {!allowedRoles.includes(loggedUser.role) ? (
          pathname !== "/administration/users" ? (
            <td>
              <MenuComponent
                menuItems={menuOptions}
                menuIcon={
                  <IconWrapper
                    className="w-5 h-5"
                    icon={EllipsisVerticalIcon}
                  />
                }
                menuItemsClassName="absolute right-8 -mt-10 w-44 origin-top-right rounded-lg shadow py-2 shadow-lg focus:outline-none"
                menuClassName="absolute z-50"
              />
            </td>
          ) : (
            <td></td>
          )
        ) : (
          // pathname === "/super-user/resellers"
          //   ? <td></td>
          //   :
          <td
            className={`${
              openAccordion ? "rounded-tr-xl" : "rounded-e-xl"
            } cursor-pointer py-2.5 ${theme.cardBackgroundColor} ${
              selectedUsers.includes(user.id) ? "" : theme.tableCellBorderRight
            }`}
          >
            <TableActionGeneralButton className="flex mr-2 py-[3px] w-[32px]">
              <ChevronUpIcon
                className={`h-5 w-5 transition-transform duration-200 ${
                  openAccordion ? "" : "rotate-180"
                } `}
                aria-hidden="true"
              />
            </TableActionGeneralButton>
          </td>
        )}
        {edit && (
          <EditUserModal
            user={user}
            modalProps={{ open: edit, setOpen: setedit }}
            fetchUsers={fetchUsers}
            getUserAssignedCompanyAndRoles={getUserAssignedCompanyAndRoles}
          />
        )}

        {deleteUser && (
          <DeleteCustomerModal
            modalProps={{
              open: deleteUser,
              setOpen: setDeleteUser,
              userDetails: user,
              fetchUsers: fetchUsers,
            }}
          />
        )}

        {assignCustomer && (
          <AssignCustomerModal
            user={user}
            allCustomers={allCustomers}
            modalProps={{ open: assignCustomer, setOpen: setAssignCustomer }}
            fetchUsers={fetchUsers}
            getCustomersAssignedToUsers={getCustomersAssignedToUsers}
          />
        )}
      </tr>

      {openAccordion && (
        <tr className={`${theme.cardBackgroundColor}`}>
          <td
            className="py-2.5 pl-4 pr-3 text-sm font-medium text-gray-900 rounded-b-xl"
            colSpan={6}
          >
            <UserAccordion
              user={user}
              setOpenAccordion={setOpenAccordion}
              companies={companies}
              userAssignedCompanies={userAssignedCompanies}
              menuListItems={menuListItems}
              getUserAssignedCompanyAndRoles={getUserAssignedCompanyAndRoles}
              editRoles={editRoles}
              setEditRoles={setEditRoles}
              fetchUsers={fetchUsers}
              setAssignCustomer={setAssignCustomer}
              totalCustomersAssigned={totalCustomersAssigned}
            />
          </td>
        </tr>
      )}
      <tr className="bg-background h-2"></tr>
    </>
  );
}

export default UsersTableRow;
