import React, {
  useState,
  Fragment,
  useRef,
  useEffect,
  useContext,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";

import "react-image-crop/dist/ReactCrop.css";
import ToolTip from "../../../components/ToolTip";
import CancelButton from "../../../components/buttons/CancelButton";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import { ThemeContext } from "../../../contexts/ThemeContext";
import GhostButton from "../../../components/buttons/GhostButton";
import Overlay from "../../../components/Overlay";
import Label from "../../../components/Label";
import { colorPresets } from "../../../components/settings/getColorPreset";

interface Props {
  modalProps: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  };
  OriginalImage: any;
  setPreviewImage: any;
  fileInputId: string;
}

const TO_RADIANS = Math.PI / 180;

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

function ImageCropOverlay({
  modalProps: { open, setOpen },
  OriginalImage,
  setPreviewImage,
  fileInputId,
}: Props) {
  const [imgSrc, setImgSrc] = useState("");
  const previewCanvasRef = useRef<any>();
  const imgRef = useRef<any>(null);
  const hiddenAnchorRef = useRef<HTMLAnchorElement>(null);
  const cancelButtonRef = useRef(null);
  const [crop, setCrop] = useState<any>();
  const [completedCrop, setCompletedCrop] = useState<any>();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(16 / 9);
  const { theme, themePresetColor } = useContext(ThemeContext)!;

  useEffect(() => {
    if (OriginalImage) {
      setCrop(undefined);
      setImgSrc("");
      setImgSrc(URL.createObjectURL(OriginalImage) as any);
    }
  }, [OriginalImage]);

  const onImageLoad = (e: any) => {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  };

  const handleClose = () => {
    setOpen(false);
    clearFileInput();
    setRotate(0);
    setScale(1);
  };

  const clearFileInput = () => {
    const fileInput = document.getElementById(fileInputId) as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }
  };

  async function onDownloadCropClick() {
    const image = imgRef.current;
    const previewCanvas = previewCanvasRef.current;
    if (!image || !previewCanvas || !completedCrop) {
      console.error("Crop canvas does not exist");
      return;
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen: any = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY
    );

    const ctx = offscreen.getContext("2d");
    if (!ctx) {
      throw new Error("No 2d context");
    }
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height
    );

    const blob = await offscreen.convertToBlob({
      type: "image/png",
    });
    const uniqueFilename = `${OriginalImage?.name}`;
    const croppedFile = new File([blob], uniqueFilename, {
      type: OriginalImage?.type,
    });
    setPreviewImage(croppedFile);
    handleClose();
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  async function canvasPreview(
    image: any,
    canvas: any,
    crop: any,
    scale = 1,
    rotate = 0
  ) {
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      throw new Error("No 2d context");
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const pixelRatio = window.devicePixelRatio;

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = "high";

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;

    const rotateRads = rotate * TO_RADIANS;
    const centerX = image.naturalWidth / 2;
    const centerY = image.naturalHeight / 2;

    ctx.save();
    ctx.translate(-cropX, -cropY);
    ctx.translate(centerX, centerY);
    ctx.rotate(rotateRads);
    ctx.scale(scale, scale);
    ctx.translate(-centerX, -centerY);
    ctx.drawImage(
      image,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight
    );

    ctx.restore();
  }

  function useDebounceEffect(fn: any, waitTime: any, deps: any) {
    useEffect(() => {
      const t = setTimeout(() => {
        fn.apply(undefined, deps);
      }, waitTime);

      return () => {
        clearTimeout(t);
      };
    }, deps);
  }

  useEffect(() => {
    if (themePresetColor) {
      const selected: any = colorPresets.find(
        (preset) => preset.name === themePresetColor
      );
      updateSliderColor(selected.colorCode, selected.boxShadow);
    }
  }, [themePresetColor]);

  function updateSliderColor(color: any, boxShadow: any) {
    document.documentElement.style.setProperty("--slider-color", color);
    document.documentElement.style.setProperty(
      "--slider-shadow-color",
      boxShadow
    );
  }
  return (
    <div>
      <Overlay
        open={open}
        cancelButtonRef={cancelButtonRef}
        handleClose={handleClose}
        titleContent={"Crop Image"}
        className="sm:my-8 sm:mt-20 w-full sm:p-6 sm:w-2/3 md:w-1/2 lg:w-1/2 xl:w-1/2 h-3/4 sm:h-4/5 lg:h-3/4"
      >
        <div className="App mt-10">
          <div className="bg-gray-100/[.7] flex justify-center">
            {!!imgSrc && (
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => setCompletedCrop(c)}
                aspect={aspect}
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={imgSrc}
                  style={{
                    transform: `scale(${scale}) rotate(${rotate}deg)`,
                  }}
                  onLoad={(e) => onImageLoad(e)}
                />
              </ReactCrop>
            )}
          </div>
          {/* bg-gray-50/[.5] */}
          <div
            className={`grid grid-cols-12 mt-3 ${theme.theadBackgroundColor}/[.5] border ${theme.tableBorderColor} rounded-md py-1`}
          >
            <div
              className={`col-span-10 flex justify-center items-center gap-2 px-2  border-r-2 ${theme.tableBorderColor}`}
            >
              <Label>Scale</Label>
              <div className="w-full">
                <input
                  id="minmax-range"
                  type="range"
                  min="0.1"
                  max="3"
                  step="0.1"
                  value={scale}
                  disabled={!imgSrc}
                  className="w-full h-1.5 bg-gray-200 rounded-xl range-lg appearance-none cursor-pointer"
                  onChange={(e) => {
                    setScale(Number(e.target.value));
                  }}
                />
              </div>
              <Label htmlFor="scale-input" className="">
                {scale}
              </Label>
            </div>
            <div className="flex justify-center items-center">
              <ToolTip text="Rotate Left">
                <GhostButton
                  id="rotate-left"
                  className="flex border-0 items-center justify-center h-8"
                  onClick={() =>
                    setRotate((prevRotate) => (prevRotate - 90) % 360)
                  }
                >
                  <svg
                    className="h-4 w-4"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 12C3 13.78 3.52784 15.5201 4.51677 17.0001C5.50571 18.4802 6.91131 19.6337 8.55585 20.3149C10.2004 20.9961 12.01 21.1743 13.7558 20.8271C15.5016 20.4798 17.1053 19.6226 18.364 18.364C19.6226 17.1053 20.4798 15.5016 20.8271 13.7558C21.1743 12.01 20.9961 10.2004 20.3149 8.55585C19.6337 6.91131 18.4802 5.50571 17.0001 4.51677C15.5201 3.52784 13.78 3 12 3C9.48395 3.00947 7.06897 3.99122 5.26 5.74L3 8M3 8V3M3 8H8"
                      stroke="currentColor"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </GhostButton>
              </ToolTip>
            </div>
            <div className="flex justify-center items-center ">
              <ToolTip text="Rotate Right">
                <GhostButton
                  id="rotate-right"
                  className="flex items-center justify-center border-0 h-8"
                  onClick={() =>
                    setRotate((prevRotate) => (prevRotate + 90) % 360)
                  }
                >
                  <svg
                    className="h-4 w-4"
                    viewBox="0 0 24 24"
                    fill="none"
                    strokeWidth="5"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21 12C21 13.78 20.4722 15.5201 19.4832 17.0001C18.4943 18.4802 17.0887 19.6337 15.4442 20.3149C13.7996 20.9961 11.99 21.1743 10.2442 20.8271C8.49836 20.4798 6.89472 19.6226 5.63604 18.364C4.37737 17.1053 3.5202 15.5016 3.17294 13.7558C2.82567 12.01 3.0039 10.2004 3.68509 8.55585C4.36628 6.91131 5.51983 5.50571 6.99987 4.51677C8.47991 3.52784 10.22 3 12 3C14.52 3 16.93 4 18.74 5.74L21 8M21 8V3M21 8H16"
                      stroke="currentColor"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </GhostButton>
              </ToolTip>
            </div>
          </div>
          {!!completedCrop && (
            <>
              <div>
                <canvas
                  hidden={true}
                  ref={previewCanvasRef}
                  style={{
                    border: "1px solid black",
                    objectFit: "contain",
                    width: completedCrop.width,
                    height: completedCrop.height,
                  }}
                />
              </div>
            </>
          )}
        </div>
        <a ref={hiddenAnchorRef} style={{ display: "none" }}>
          Hidden Anchor
        </a>
        <div className="flex mt-4 space-x-2 justify-end">
          <CancelButton
            type="button"
            onClick={() => handleClose()}
            className={"block"}
          >
            Cancel
          </CancelButton>
          <PrimaryButton
            type="button"
            className="rounded-md px-2.5 py-1.5 text-sm font-semibold shadow-sm"
            onClick={onDownloadCropClick}
          >
            {"Crop Image"}
          </PrimaryButton>
        </div>
      </Overlay>
    </div>
  );
}

export default ImageCropOverlay;
