import React, { useCallback, useContext, useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import logoDark from "../../assets/WanAware_Logo_Dark.png";
import logoLight from "../../assets/WanAware_Logo_Light.png";
import { ThemeContext } from "../../contexts/ThemeContext";

function WhiteLabelLogo({ setWhiteLabelData }: any) {
  const { getWhiteLabel } = useAuth();
  const { mode } = useContext(ThemeContext)!;
  const [whiteLabel, setWhiteLabel] = useState({} as any);
  const [isLoadingWhiteLabel, setIsLoadingWhiteLabel] = useState(false);

  const fetchWhiteLabel = useCallback(async (mode: string) => {
    setIsLoadingWhiteLabel(true);
    const whiteLabel = await getWhiteLabel(mode);
    setWhiteLabel(whiteLabel as any);
    setWhiteLabelData && setWhiteLabelData(whiteLabel);
    setIsLoadingWhiteLabel(false);
  }, []);

  useEffect(() => {
    fetchWhiteLabel(mode);
  }, [mode]);

  return (
    <>
      {!isLoadingWhiteLabel ? (
        <img
          className="mx-auto w-40"
          src={whiteLabel?.logo || (mode === "light" ? logoDark : logoLight)}
          alt={whiteLabel?.name || "WanAware"}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default WhiteLabelLogo;
