import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ResellerCompany from "./ResellerCompany";
import Assessment from "./assessments/Assessment";
import Company from "../general/Company";
import { ThemeContext } from "../../../contexts/ThemeContext";
import Tabs from "../../../components/Tabs";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}
function ResellerGeneralNavigator() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const activeTab = pathname.split("/").slice(-1);
  const [currentTab, setCurrentTab] = useState(activeTab[0]);
  const { theme } = useContext(ThemeContext)!;

  const tabs = [
    {
      name: "Company Information",
      value: "company",
      url: "/settings/company",
      component: <Company />,
    },
    {
      name: "Assessments",
      value: "assessments",
      url: "/settings/assessments",
      component: <Assessment />,
    },
  ];

  const onTabChange = (tab: any) => {
    setCurrentTab(tab.value);
    navigate(tab.url);
  };
  return (
    <div>
      <Tabs
        tabs={tabs}
        currentTab={currentTab}
        onClick={(tab) => onTabChange(tab)}
      />
    </div>
  );
}

export default ResellerGeneralNavigator;
