import React from "react";
import UsersTable from "./UsersTable";
import useAuth from "../../../hooks/useAuth";
import { Roles } from "../../../constants/Roles";
import { useLocation } from "react-router-dom";

function Users({ url }: any) {
  const { user } = useAuth();
  const { pathname } = useLocation();
  const roles =
    pathname === "/administration/users"
      ? Roles.customer_roles
      : user?.role === "Reseller_Admin"
      ? Roles.reseller_admin_roles
      : user?.role === "Customer_Admin"
      ? Roles.customer_roles
      : Roles.super_user_roles;
  return (
    <div className="py-5 mt-6 rounded">
      <UsersTable url={url} roles={roles} />
    </div>
  );
}

export default Users;
