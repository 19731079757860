import { Suspense, lazy, ElementType } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import LaunchpadLayout from "../layouts/launchpad";
import LoadingScreen from "../components/loaders/LoadingScreen";
import ProtectedRoute from "./ProtectedRoutes";
import WanAwareAdmin from "../features/administration/wanAware/WanAwareAdmin";
import Billing from "../features/billing/Billing";
import useAuth from "../hooks/useAuth";
import VerifyOTP from "../features/auth/VerifyOTP";
import DeactivatedAccNotification from "../features/auth/DeactivatedAccNotification";
import ResellerGeneralNavigator from "../features/administration/reseller/ResellerGeneralNavigator";
import ResellerCompany from "../features/administration/reseller/ResellerCompany";
import Assessment from "../features/administration/reseller/assessments/Assessment";
import MessageCenter from "../features/administration/wanAware/MessageCenter";
import SendMessage from "../features/messages/SendMessage";
import CustomerGeneralNavigator from "../features/administration/general/CustomerGeneralNavigator";
import QuickStartPage from "../features/functions/quickStart/QuickStartPage";

const Loadable = (Component: ElementType) => (props: any) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { user } = useAuth();

  //Include roles that have billing as default route
  const rolesForBillingAsDefaultRoute = [
    "WanAware_Finance",
    "Reseller_Finance",
    "Customer_Finance",
  ];

  const rolesForCustomerAsDefaultRoute = [
    "WanAware_Super_User",
    "Reseller_Admin",
  ];

  return useRoutes([
    {
      path: "auth",
      element: <AuthLayout />,
      children: [
        { element: <Navigate to="/auth/login" replace />, index: true },
        {
          path: "login",
          element: <LoginWithDomain />,
        },
        {
          path: "deactivated",
          element: <DeactivatedAccNotification />,
        },
        {
          path: "company/login",
          element: <CompanyLogin />,
        },
        {
          path: "sign-up",
          element: <Register />,
        },
        {
          path: "forgot-password",
          element: <ForgotPassword />,
        },
        {
          path: "reset-password",
          element: <ResetPassword />,
        },
        {
          path: "accept-invite",
          element: <AcceptInvitation />,
        },
        {
          path: "verify-otp",
          element: <VerifyOTP />,
        },
        {
          path: "message/:heading/:text",
          element: <ShowMessage />,
        },
      ],
    },
    {
      path: "assessments",
      element: <AuthLayout />,
      children: [
        {
          element: <Navigate to="/assessments/attacksurface" replace />,
          index: true,
        },
        { path: "attacksurface", element: <ExternalAttackSurfaceForm /> },
      ],
    },

    {
      path: "/profile",
      element: <LaunchpadLayout />,
      children: [
        { element: <Navigate to="/profile/general" replace />, index: true },
        { path: "general", element: <ProfileNavigator /> },
        { path: "security", element: <ProfileNavigator /> },
        { path: "notifications", element: <ProfileNavigator /> },
        { path: "referrals", element: <ProfileNavigator /> },
      ],
    },
    {
      path: "/",
      element: <LaunchpadLayout />,
      children: [
        {
          element: (
            <Navigate
              to={
                rolesForCustomerAsDefaultRoute.includes(user?.role)
                  ? "/super-user/customers"
                  : rolesForBillingAsDefaultRoute.includes(user?.role)
                  ? "/administration/billing"
                  : "/assets"
              }
              replace
            />
          ),
          index: true,
        },
        // {
        //   path: "launchpad",
        //   element: (
        //     <ProtectedRoute
        //       element={<Launchpad />}
        //       allowedRoles={[
        //         "Customer_Admin",
        //         "WanAware_Engineer",
        //         "Reseller_Engineer",
        //         "Customer_Engineer",
        //         "WanAware_Support",
        //         "Reseller_Support",
        //         "Customer_Support",
        //         "WanAware_Report_User",
        //         "Reseller_Report_User",
        //         "Customer_Report_User",
        //       ]}
        //     />
        //   ),
        // },

        // {
        //   path: "workers", //This will change
        //   children: [
        //     {
        //       path: "customer",
        //       element: (
        //         <ProtectedRoute
        //           element={<WorkerNavigator />}
        //           allowedRoles={[
        //             "Customer_Admin",
        //             "WanAware_Engineer",
        //             "Reseller_Engineer",
        //             "Customer_Engineer",
        //             "WanAware_Support",
        //             "Reseller_Support",
        //             "Customer_Support",
        //           ]}
        //         />
        //       ),
        //     },

        //     {
        //       path: "wanAware",
        //       element: (
        //         <ProtectedRoute
        //           element={<WorkerNavigator />}
        //           allowedRoles={[
        //             "WanAware_Super_User",
        //             "Reseller_Admin",
        //             "Customer_Admin",
        //             "WanAware_Engineer",
        //             "Reseller_Engineer",
        //             "Customer_Engineer",
        //             "WanAware_Support",
        //             "Reseller_Support",
        //             "Customer_Support",
        //           ]}
        //         />
        //       ),
        //     },
        //   ],
        // },
        {
          path: "structures",
          element: (
            <ProtectedRoute
              element={<OrgStructure />}
              allowedRoles={[
                "WanAware_Super_User",
                "Reseller_Admin",
                "Customer_Admin",
              ]}
            />
          ),
        },
        // {
        //   path: "quick-start",
        //   element: (
        //     <ProtectedRoute
        //       element={<QuickStartPage />}
        //       allowedRoles={[
        //         "WanAware_Engineer",
        //         "Reseller_Engineer",
        //         "Customer_Admin",
        //         "Customer_Engineer",
        //       ]}
        //     />
        //   ),
        // },
        // {
        //   path: "discovery",
        //   children: [
        //     {
        //       path: "bulk",
        //       element: (
        //         <ProtectedRoute
        //           element={<Discovery />}
        //           allowedRoles={[
        //             "Customer_Admin",
        //             "WanAware_Engineer",
        //             "Reseller_Engineer",
        //             "Customer_Engineer",
        //             "WanAware_Support",
        //             "Reseller_Support",
        //             "Customer_Support",
        //           ]}
        //         />
        //       ),
        //     },
        //     {
        //       path: "dynamic-discovery",
        //       element: (
        //         <ProtectedRoute
        //           element={<Discovery />}
        //           allowedRoles={[
        //             "WanAware_Super_User",
        //             "Reseller_Admin",
        //             "Customer_Admin",
        //             "WanAware_Engineer",
        //             "Reseller_Engineer",
        //             "Customer_Engineer",
        //             "WanAware_Support",
        //             "Reseller_Support",
        //             "Customer_Support",
        //           ]}
        //         />
        //       ),
        //     },
        //   ],
        // },
        {
          path: "assets/:id/summary/:tabName",
          element: <AssetDetails />,
        },
        // { path: "orgstructure", element: <OrgStructure /> },
        { path: "workers", element: <Workers /> },
        // { path: "discovery", element: <Discovery /> },
        {
          path: "assets",
          element: (
            <ProtectedRoute
              element={<Discovery />}
              allowedRoles={[
                "Customer_Admin",
                "WanAware_Engineer",
                "Reseller_Engineer",
                "Customer_Engineer",
                "WanAware_Support",
                "Reseller_Support",
                "Customer_Support",
              ]}
            />
          ),
        },
        {
          path: "elements",
          element: (
            <ProtectedRoute
              element={<Element />}
              allowedRoles={[
                "Customer_Admin",
                "WanAware_Engineer",
                "Reseller_Engineer",
                "Customer_Engineer",
                "WanAware_Support",
                "Reseller_Support",
                "Customer_Support",
              ]}
            />
          ),
        },
        { path: "elements/bulk", element: <BulkElements /> },
        { path: "assets/bulk", element: <BulkAssets /> },
        { path: "elements/export", element: <ExportElements /> },
        { path: "workers/export", element: <ExportElements /> },
        { path: "assets/export", element: <ExportElements /> },
        { path: "settings/users", element: <Users /> },
        { path: "settings/employees", element: <Users /> },
        {
          path: "settings/company",
          element:
            user?.role === "Reseller_Admin" ? (
              <ResellerGeneralNavigator />
            ) : (
              <Company />
            ),
        },
        { path: "settings/assessments", element: <ResellerGeneralNavigator /> },
        // { path: "elements/:id", element: <ElementDetails /> },
        { path: "test", element: <GoogleMap /> },
        {
          path: "reseller",
          element: <Reseller />,
        },
      ],
    },
    {
      path: "/elements/:id",
      element: <LaunchpadLayout />,
      children: [
        {
          element: <Navigate to="/elements/:id/collections" replace />,
          index: true,
        },
        //  {/* Commented Code for GQL Integration */}
        // { path: "insights", element: <ElementDetails /> },
        { path: "collections", element: <ElementDetails /> },
        { path: "details", element: <ElementDetails /> },
      ],
    },
    {
      path: "/super-user",
      element: <LaunchpadLayout />,
      children: [
        {
          path: "customers",
          element: (
            <ProtectedRoute
              element={<Customers />}
              allowedRoles={["WanAware_Super_User", "Reseller_Admin"]}
            />
          ),
          children: [
            {
              element: <Navigate to="customers/:id" replace />,
              index: true,
            },
            {
              path: ":id",
              element: (
                <ProtectedRoute
                  element={<Customers />}
                  allowedRoles={["WanAware_Super_User", "Reseller_Admin"]}
                />
              ),
            },
          ],
        },
        {
          path: "notifications",
          element: (
            <ProtectedRoute
              element={<MessageCenter />}
              allowedRoles={["WanAware_Super_User", "Reseller_Admin"]}
            />
          ),
        },
        // { path: "customers/users", element: <Customers /> },
        {
          children: [
            {
              path: "resellers",
              element: (
                <ProtectedRoute
                  element={<Reseller />}
                  allowedRoles={["WanAware_Super_User"]}
                />
              ),
              children: [
                {
                  element: <Navigate to="resellers/:id" replace />,
                  index: true,
                },
                {
                  path: ":id",
                  element: (
                    <ProtectedRoute
                      element={<Reseller />}
                      allowedRoles={["WanAware_Super_User"]}
                    />
                  ),
                },
              ],
            },
            // { path: "resellers/customers", element: <Reseller /> },
            // { path: "resellers/users", element: <Reseller /> },
          ],
        },
        {
          path: "wanaware-admin",
          children: [
            {
              element: (
                <Navigate to="/super-user/wanaware-admin/employees" replace />
              ),
              index: true,
            },
            {
              path: "employees",
              element: (
                <ProtectedRoute
                  element={<WanAwareAdmin />}
                  allowedRoles={["WanAware_Super_User"]}
                />
              ),
            },
            {
              path: "element-management",
              element: (
                <ProtectedRoute
                  element={<WanAwareAdmin />}
                  allowedRoles={["WanAware_Super_User"]}
                />
              ),
            },
          ],
        },
      ],
    },
    {
      path: "/administration",
      element: <LaunchpadLayout />,
      children: [
        {
          element: <Navigate to="/general" replace />,
          index: true,
        },
        { path: "general/company", element: <CustomerGeneralNavigator /> },
        {
          path: "general/incident-management",
          element: <CustomerGeneralNavigator />,
        },
        { path: "users", element: <Users /> },
        // {
        //   path: "integrations",
        //   element: (
        //     <ProtectedRoute
        //       element={<IntegrationTable />}
        //       allowedRoles={[
        //         "WanAware_Super_User",
        //         "Reseller_Admin",
        //         "Customer_Admin",
        //         "WanAware_Engineer",
        //         "Reseller_Engineer",
        //         "Customer_Engineer",
        //         "WanAware_Support",
        //         "Reseller_Support",
        //         "Customer_Support",
        //       ]}
        //     />
        //   ),
        // },
        {
          path: "logging",
          children: [
            {
              path: "user-log",
              element: (
                <ProtectedRoute
                  element={<LoggingNavigator />}
                  allowedRoles={[
                    "WanAware_Super_User",
                    "Reseller_Admin",
                    "Customer_Admin",
                  ]}
                />
              ),
            },
            {
              path: "system-log",
              element: (
                <ProtectedRoute
                  element={<LoggingNavigator />}
                  allowedRoles={[
                    "WanAware_Super_User",
                    "Reseller_Admin",
                    "Customer_Admin",
                  ]}
                />
              ),
            },
          ],
        },

        {
          path: "workers",
          children: [{ path: "wanaware", element: <Workers /> }],
        },
        // {
        //   path: "billing",
        //   element: (
        //     <ProtectedRoute
        //       element={<Billing />}
        //       allowedRoles={[
        //         "WanAware_Super_User",
        //         "Reseller_Admin",
        //         "Customer_Admin",
        //         "WanAware_Finance",
        //         "Reseller_Finance",
        //         "Customer_Finance",
        //       ]}
        //     />
        //   ),
        // },
      ],
    },
    {
      path: "/",
      element: <LaunchpadLayout />,
      children: [
        { element: <Navigate to="/events" replace />, index: true },
        {
          path: "events",
          element: (
            <ProtectedRoute
              element={<EventNavigator />}
              allowedRoles={[
                "WanAware_Super_User",
                "Reseller_Admin",
                "Customer_Admin",
                "WanAware_Engineer",
                "Reseller_Engineer",
                "Customer_Engineer",
                "WanAware_Support",
                "Reseller_Support",
                "Customer_Support",
              ]}
            />
          ),
        },
        {
          path: "events/:id",
          element: (
            <ProtectedRoute
              element={<EventDetails />}
              allowedRoles={[
                "WanAware_Super_User",
                "Reseller_Admin",
                "Customer_Admin",
                "WanAware_Engineer",
                "Reseller_Engineer",
                "Customer_Engineer",
                "WanAware_Support",
                "Reseller_Support",
                "Customer_Support",
              ]}
            />
          ),
          children: [
            {
              element: <Navigate to="/events/:id/details" replace />,
              index: true,
            },
            { path: "details", element: <EventDetails /> },
            { path: "insights", element: <EventDetails /> },
            { path: "logs", element: <EventDetails /> },
          ],
        },
      ],
    },
    {
      path: "/",
      element: <LaunchpadLayout />,
      children: [
        { element: <Navigate to="/support" replace />, index: true },
        {
          path: "support",
          element: (
            <ProtectedRoute
              element={<TicketNavigator />}
              allowedRoles={[
                "WanAware_Super_User",
                "Reseller_Admin",
                "Customer_Admin",
                "WanAware_Engineer",
                "Reseller_Engineer",
                "Customer_Engineer",
                "WanAware_Support",
                "Reseller_Support",
                "Customer_Support",
              ]}
            />
          ),
        },
        {
          path: "support/:id",
          element: (
            <ProtectedRoute
              element={<TicketDetails />}
              allowedRoles={[
                "WanAware_Super_User",
                "Reseller_Admin",
                "Customer_Admin",
                "WanAware_Engineer",
                "Reseller_Engineer",
                "Customer_Engineer",
                "WanAware_Support",
                "Reseller_Support",
                "Customer_Support",
              ]}
            />
          ),
        },
      ],
    },
    {
      path: "/",
      element: <LaunchpadLayout />,
      children: [
        { element: <Navigate to="/knowledgebase" replace />, index: true },
        {
          path: "knowledgebase",
          element: (
            <ProtectedRoute
              element={<Knowledgebase />}
              allowedRoles={[
                "WanAware_Super_User",
                "Reseller_Admin",
                "Customer_Admin",
                "WanAware_Engineer",
                "Reseller_Engineer",
                "Customer_Engineer",
                "WanAware_Support",
                "Reseller_Support",
                "Customer_Support",
              ]}
            />
          ),
        },
      ],
    },
    {
      path: "/",
      element: <LaunchpadLayout />,
      children: [
        { element: <Navigate to="/403" replace />, index: true },
        {
          path: "403",
          element: <Forbidden />,
        },
      ],
    },
    {
      path: "*",
      children: [
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// LAZY imports

////////////////////////
////      AUTH     ////
///////////////////////

const LoginWithDomain = Loadable(
  lazy(() => import("../features/auth/LoginWithDomain"))
);
const CompanyLogin = Loadable(
  lazy(() => import("../features/auth/CompanyLogin"))
);

const Register = Loadable(lazy(() => import("../features/auth/Register")));
const ForgotPassword = Loadable(
  lazy(() => import("../features/auth/ForgotPassword"))
);
const ResetPassword = Loadable(
  lazy(() => import("../features/auth/ResetPassword"))
);
const AcceptInvitation = Loadable(
  lazy(() => import("../features/auth/AcceptInvitation"))
);
const ShowMessage = Loadable(
  lazy(() => import("../features/auth/ShowMessage"))
);
const NotFound = Loadable(lazy(() => import("../features/404/Page404")));
const Forbidden = Loadable(lazy(() => import("../features/403/Page403")));
const AuthLayout = Loadable(lazy(() => import("../layouts/auth")));

const Element = Loadable(
  lazy(() => import("../features/functions/elements/Element"))
);
const Reseller = Loadable(
  lazy(() => import("../features/administration/reseller/Reseller"))
);
const ProfileNavigator = Loadable(
  lazy(() => import("../features/profile/ProfileNavigator"))
);
const WorkerNavigator = Loadable(
  lazy(() => import("../features/functions/workers/WorkerNavigator"))
);

const Launchpad = Loadable(
  lazy(() => import("../features/launchpad/Launchpad"))
);
const Workers = Loadable(
  lazy(() => import("../features/functions/workers/Workers"))
);
const Discovery = Loadable(
  lazy(() => import("../features/functions/assets/Discovery"))
);

const BulkAssets = Loadable(
  lazy(() => import("../features/functions/assets/bulkUploadWizard/Navigator"))
);

const AssetDetails = Loadable(
  lazy(() => import("../features/functions/assets/AssetDetails"))
);
const ElementDetails = Loadable(
  lazy(() => import("../features/functions/elements/ElementDetails"))
);

const BulkElements = Loadable(
  lazy(
    () => import("../features/functions/elements/bulkUploadWizard/Navigator")
  )
);

const ExportElements = Loadable(
  lazy(() => import("../components/exportWizard/Navigator"))
);

const Customers = Loadable(
  lazy(() => import("../features/administration/customer/Customers"))
);
const GoogleMap = Loadable(
  lazy(() => import("../features/functions/elements/googleMaps/GoogleMap"))
);
const TicketNavigator = Loadable(
  lazy(() => import("../features/support/TicketNavigator"))
);
const EventNavigator = Loadable(
  lazy(() => import("../features/events/EventNavigator"))
);
const EventDetails = Loadable(
  lazy(() => import("../features/events/EventDetails"))
);

const TicketDetails = Loadable(
  lazy(() => import("../features/support/TicketDetails"))
);

const Users = Loadable(
  lazy(() => import("../features/administration/users/Users"))
);
const IntegrationTable = Loadable(
  lazy(() => import("../features/administration/integrations/IntegrationTable"))
);

const LoggingNavigator = Loadable(
  lazy(() => import("../features/administration/logging/LoggingNavigator"))
);

const Company = Loadable(
  lazy(() => import("../features/administration/general/Company"))
);

const OrgStructure = Loadable(
  lazy(() => import("../features/functions/structure/OrgStructure"))
);

const ExternalAttackSurfaceForm = Loadable(
  lazy(
    () =>
      import(
        "../features/administration/reseller/assessments/ExternalAttackSurfaceForm"
      )
  )
);

const Knowledgebase = Loadable(
  lazy(() => import("../features/knowledgebase/Knowledgebase"))
);
