import React, { useContext } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { ThemeContext } from "../../contexts/ThemeContext";

export interface InputProps {
  name: string;
  className: string;
  options:
    | { value: string; label: string }[]
    | {
        label: string;
        options: {
          value: string;
          label: string;
        }[];
      }[];
  placeholder: string;
  isMulti?: boolean;
  isDisabled?: boolean;
  isOptionDisabled?: any;
  errors?: any;
  menuPortalTarget?: any;
  menuPlacement?: string;
  defaultValue?: any;
  value?: any;
  menuPosition?: string;
  isSearchable?: boolean;
  onChange?: React.Dispatch<React.SetStateAction<any>>;
  onInputChange?: React.Dispatch<React.SetStateAction<any>>;
}

function SelectWrapper({
  className,
  options,
  placeholder,
  isMulti,
  menuPortalTarget,
  onChange,
  onInputChange,
  errors,
  name,
  isOptionDisabled,
  menuPlacement,
  defaultValue,
  isDisabled,
  value,
  menuPosition,
  isSearchable,
}: InputProps) {
  const { theme } = useContext(ThemeContext)!;
  return (
    <>
      <Select
        className={className}
        onChange={onChange}
        placeholder={placeholder}
        options={options}
        styles={{
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
            backgroundColor: theme.cardBackgroundColorCode,
          }),
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.isFocused
              ? `${theme.inputFocusedBorderColorCode} !important`
              : `${theme.inputBorderColorCode} !important`,
            WebkitBoxShadow: state.isFocused
              ? `0 0 0 1px ${theme.inputFocusedBorderColorCode} !important`
              : `none !important`,
            backgroundColor: theme.cardBackgroundColor,
            color: theme.labelTextColorCode,
          }),
          option: (base, state) => ({
            ...base,
            backgroundColor: state.isFocused
              ? `${theme.selectOptionFocusedBackgroundColorCode} !important`
              : theme.cardBackgroundColorCode,
            color: theme.labelTextColorCode,
            fontWeight: state.isSelected ? "500" : "normal",
            cursor: "pointer",
          }),
          singleValue: (base) => ({
            ...base,
            color: theme.labelTextColorCode,
          }),
          multiValue: (base, state) => ({
            ...base,
            backgroundColor: theme.selectMultiValueBackgroundColorCode,
          }),
          multiValueLabel: (base, state) => ({
            ...base,
            color: theme.labelTextColorCode,
          }),
          menu: (base) => ({
            ...base,
            backgroundColor: theme.cardBackgroundColorCode,
            border: `0.5px solid ${theme.inputBorderColorCode}`,
          }),
          input: (base) => ({
            ...base,
            color: theme.labelTextColorCode,
          }),
        }}
        menuPortalTarget={menuPortalTarget}
        isMulti={isMulti}
        isOptionDisabled={isOptionDisabled}
        menuPlacement={menuPlacement as any}
        defaultValue={
          defaultValue
            ? typeof defaultValue === "object"
              ? defaultValue
              : { label: defaultValue, value: defaultValue }
            : ""
        }
        isDisabled={isDisabled}
        value={value}
        onInputChange={onInputChange}
        menuPosition={menuPosition as any}
        isSearchable={isSearchable}
      />
      {errors && errors[name] && (
        <span className={`${theme.inputErrorTextColor} text-sm`}>
          {errors[name]?.message as string}
        </span>
      )}
    </>
  );
}

export default SelectWrapper;
