import { ArrowDownIcon } from "@heroicons/react/20/solid";
import React, { useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { ThemeContext } from "../../contexts/ThemeContext";
import themes from "../../themes";

export interface InputProps {
  fieldName: string;
  instructions?: string;
  onFileChange?: any;
  accept: string;
  label: string;
  disabled?: boolean;
  maxSizeInMB: number;
  isOrgUpdate?: boolean;
  setLogoError?: any;
  icon?: any;
}

function FileInput({
  fieldName,
  instructions,
  label,
  disabled,
  onFileChange,
  accept,
  maxSizeInMB,
  isOrgUpdate,
  setLogoError,
  icon,
}: InputProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const {
    theme: currentTheme,
    themePresetColor,
    mode,
  } = useContext(ThemeContext)!;

  const { onChange, onBlur, name, ref } = register(fieldName);
  const [sizeError, setSizeError] = useState("");
  const fileTypes = ["image/jpg", "image/jpeg", "image/gif", "image/png"];
  useEffect(() => {
    if (setLogoError) setLogoError(sizeError);
  }, [sizeError]);

  const theme =
    fieldName === "dark_logo" && mode === "dark"
      ? themes["light"][themePresetColor]
      : currentTheme;

  return (
    <>
      <div>
        <label
          htmlFor={fieldName}
          className={` flex gap-2 text-sm cursor-pointer font-semibold ring-1 items-center rounded-md px-2 py-2 ${
            theme.ghostButtonBorderColor
          } ${theme.ghostButtonBackgroundColor}  ${
            theme.ghostButtonTextColor
          } ${theme.ghostButtonHoverTextColor} ${
            theme.ghostButtonDisabledBackgroundColor
          } ${theme.ghostButtonDisabledTextColor} ${
            fieldName !== "light_logo" && theme.ghostButtonActiveBgColor
          } `}
        >
          {icon === "ArrowDownIcon" && (
            <div className="bg-button h-4 w-4">
              <ArrowDownIcon height={16} width={16} color="white" />
            </div>
          )}
          {label}
        </label>
        <input
          id={fieldName}
          disabled={disabled}
          type="file"
          accept={accept}
          onChange={(e) => {
            const file = e.target.files && e.target.files[0];
            if (!file) return;
            const fileSizeLimit = 1000000 * maxSizeInMB;

            if (file.size > fileSizeLimit) {
              setSizeError(
                `Error: File size exceeds the allowed limit (${maxSizeInMB} MB). Please choose a smaller file.`
              );
            } else if (!fileTypes.includes(file.type)) {
              setSizeError("Error: File type is not allowed.");
            } else setSizeError("");
            onChange(e);
            onFileChange(e);
          }} // assign onChange event
          onBlur={onBlur} // assign onBlur event
          name={name} // assign name prop
          ref={ref} // assign ref prop
          className={`hidden focus:ring-2 focus:ring-inset ${
            errors[fieldName]
              ? theme.inputErrorBorderColor
              : theme.inputBorderColor
          } sm:text-sm sm:leading-6 ${theme.inputFocusedBorderColor}`}
        />
        {sizeError && !isOrgUpdate && (
          <div className={`${theme.inputErrorTextColor} mt-2 text-sm`}>
            {sizeError}
          </div>
        )}
        <p className="mt-2 text-xs leading-5 text-gray-400">{instructions}</p>
        {errors && errors[fieldName] && !isOrgUpdate && (
          <span className={`${theme.inputErrorTextColor} text-sm`}>
            {errors[fieldName]?.message as string}
          </span>
        )}
      </div>
    </>
  );
}

export default FileInput;
