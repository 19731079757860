import React, { useContext } from "react";
import { ThemeContext } from "../contexts/ThemeContext";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}
interface Tab {
  name: string;
  value: string;
  component?: React.ReactNode;
  icon?: any;
  url?: string;
}

interface Props {
  tabs: Tab[];
  onClick: (value: any) => void;
  currentTab: string;
  className?: string;
}

function Tabs({ tabs, onClick, currentTab, className }: Props) {
  const { theme } = useContext(ThemeContext)!;
  return (
    <>
      {" "}
      <div className="hidden sm:block mt-5 mb-5">
        <div className={`border-b ${theme.tabBorderBottomColor}`}>
          <nav className="-mb-px flex" aria-label="Tabs">
            {tabs.map((tab) => (
              <div
                key={tab.name}
                // href={tab.href}
                className={classNames(
                  tab.value === currentTab
                    ? `${theme.primaryBorderColor} ${theme.activeTabTextColor}`
                    : `border-transparent ${theme.typographyTextColor} ${theme.tabHoverBorderColor} ${theme.typographyHoverTextColor} ${theme.tabBorderBottomColor}`,
                  `${className} w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium cursor-pointer`
                )}
                onClick={() => onClick(tab)}
              >
                <span className="inline-flex gap-2">
                  {tab.icon} <span> {tab.name}</span>
                </span>
              </div>
            ))}
          </nav>
        </div>
      </div>
      <div className="pb-4">
        {tabs.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <div key={tab.name}>{tab.component}</div>;
        })}
      </div>
    </>
  );
}

export default Tabs;
