import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import React, { ReactNode, useContext, useState } from "react";
import useAuth from "../hooks/useAuth";
import { ThemeContext } from "../contexts/ThemeContext";

interface Props {
  children: ReactNode;
  title: string | ReactNode;
  background: string;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  defaultOpen?: boolean;
  margin?: boolean;
  border?: string;
  entity?: string;
  setCurrentOpenAccordion: any;
  uniqueKey: string;
}

function Accordion({
  children,
  title,
  background,
  setOpen,
  defaultOpen,
  margin = true,
  border,
  setCurrentOpenAccordion,
  uniqueKey,
  entity = "target",
  ...other
}: Props) {
  const { user } = useAuth();
  const { theme } = useContext(ThemeContext)!;
  return (
    <Disclosure
      as="div"
      key={uniqueKey}
      className={`${margin ? "mt-2" : ""} text-gray-300 rounded-md`}
      defaultOpen={defaultOpen}
      {...other}
    >
      {({ open }) => {
        setOpen && setOpen(open);
        return (
          <>
            <dt>
              <Disclosure.Button
                className={`flex w-full items-start justify-between text-left ${
                  background == "bg-white"
                    ? `text-gray-800 border border-gray-200 rounded-md shadow `
                    : `text-gray-100`
                }  p-3 ${open ? "rounded-t-md" : "rounded-md"} ${background} `}
                onClick={(e: any) => {
                  e.stopPropagation();
                  setCurrentOpenAccordion((prevOpenAccordions: any) => {
                    const updatedAccordions = prevOpenAccordions.includes(
                      uniqueKey
                    )
                      ? prevOpenAccordions.filter(
                          (item: any) => item !== uniqueKey
                        )
                      : [...prevOpenAccordions, uniqueKey];

                    localStorage.setItem(
                      `current_opened_${entity}_accordions_${user.id}`,
                      JSON.stringify(updatedAccordions)
                    );

                    return updatedAccordions;
                  });
                }}
              >
                <span className="text-base font-semibold leading-7 flex-grow">
                  {title}
                </span>
                <span className="ml-6 flex h-7 items-center">
                  <ChevronUpIcon
                    className={`h-6 w-6 transition-transform duration-200 ${
                      open ? "" : "rotate-180"
                    } `}
                    aria-hidden="true"
                  />
                </span>
              </Disclosure.Button>
            </dt>
            <Disclosure.Panel
              as="dd"
              className={` ${theme.accordianBackgroundColor} ${
                border ? border : "border-t border-t-gray-600"
              } p-3 rounded-b-md ${
                open
                  ? "transition-transform duration-500 delay-500 translate-y-0"
                  : "transition-transform duration-300  -translate-y-40"
              }`}
            >
              {children}
            </Disclosure.Panel>
          </>
        );
      }}
    </Disclosure>
  );
}

export default Accordion;
