import React, { useContext, useEffect, useState } from "react";
import UsersTableRow from "./UsersTableRow";
import CreateUserModal from "./CreateUserModal";
import { get } from "../../../utils/httpMethods";
import useAuth from "../../../hooks/useAuth";
import LoadingRow from "../../../components/loaders/LoadingRow";
import { AuthContext } from "../../../contexts/JWTContext";
import { TenantsContext } from "../../../contexts/TenantsContext";
import { useLocation } from "react-router";
import { ResellersContext } from "../../../contexts/ResellerContext";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import { ThemeContext } from "../../../contexts/ThemeContext";
import GhostButton from "../../../components/buttons/GhostButton";
import SimpleInput from "../../../components/inputs/SimpleInput";
import Pagination from "../../../components/Pagination";

function UsersTable({ url, roles, currentOrganizationId }: any) {
  const { user: loggedUser, organization: loggedOrg } = useAuth();
  const { pathname } = useLocation();
  const tableCols =
    pathname.includes("/super-user/resellers") ||
    pathname.includes("/super-user/customers") ||
    (pathname === "/administration/users" &&
      loggedUser?.role !== "Customer_Admin")
      ? ["Name", "Email", "Status", ""]
      : ["Name", "Email", "Status", "Actions", ""];
  const [openUserModal, setOpenUserModal] = useState(false);
  const [allUsers, setAllUsers] = useState([]) as any;
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const { organization } = useContext(AuthContext)!;
  const { selectedTenant } = useContext(TenantsContext)!;
  const [companies, setCompanies] = useState([]);
  const [orgId, setOrgId] = useState<any>();
  const { currentCompany } = useContext(ResellersContext)!;
  const { theme } = useContext(ThemeContext)!;
  const [paginationObj, setPaginationObj] = useState() as any;

  useEffect(() => {
    const isTenant = pathname.split("/").includes("administration");
    const org = isTenant ? selectedTenant : organization?.id;
    setOrgId(org);
  }, [pathname, organization, selectedTenant]);
  const [allCustomersOrganizations, setAllCustomerOrganizations] = useState([]);

  const [currentPage, setCurrentPage] = useState(
    JSON.parse(
      localStorage.getItem(`users_current_page_${loggedUser?.id}`) as any
    ) || 1
  );
  const [currentLimit, setCurrentLimit] = useState(
    JSON.parse(
      localStorage.getItem(`users_current_limit_${loggedUser?.id}`) as any
    ) || 10
  );
  const [isPaginationVisible, setIsPaginationVisible] = useState(true);

  const fetchUsers = async () => {
    setLoading(true);
    setIsPaginationVisible(true);

    try {
      let result: any = await get(
        url
          ? url
          : orgId !== "All"
          ? `/api/users/organization/${orgId}`
          : loggedUser.role === "WanAware_Super_User"
          ? `/api/users?limit=${currentLimit}&page=${currentPage}`
          : `/api/resellers/${organization.id}?limit=${currentLimit}&page=${currentPage}`
      );
      setAllUsers(result?.data);
      setPaginationObj({
        totalPages: result?.totalPages,
        totalResults: result?.totalResults,
      });
      setLoading(false);
      setSearch("");
    } catch (error: any) {
      console.log(error);
      setLoading(false);
    }
  };

  // Get all comapnies within organization
  const getAllCompanies = async () => {
    try {
      const result: any = await get(
        `/api/companies/${
          currentOrganizationId ? currentOrganizationId : orgId
        }?noTree=true`
      );
      const temp = result.map((eachCompany: any) => {
        return {
          value: eachCompany.id,
          label: eachCompany.name,
        };
      });
      setCompanies(temp);
    } catch (error: any) {
      console.log(error);
    }
  };

  const getAllCustomerOrganizations = async () => {
    try {
      if (
        !["WanAware_Super_User", "Reseller_Admin"].includes(loggedUser?.role)
      ) {
        return;
      }
      const url =
        loggedUser.role === "WanAware_Super_User" &&
        pathname !== "/super-user/resellers"
          ? `/api/customers?limit=${Number.MAX_SAFE_INTEGER}`
          : loggedUser.role === "WanAware_Super_User"
          ? `/api/resellers/${
              currentCompany?.id ? currentCompany?.id : organization?.id
            }/customers?limit=${Number.MAX_SAFE_INTEGER}`
          : `/api/resellers/customers?limit=${Number.MAX_SAFE_INTEGER}`;
      const result: any = await get(url);
      setAllCustomerOrganizations(result.data);
      return result.data;
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    // organization && fetchUsers();

    if (url || orgId || orgId === "All") {
      fetchUsers();
      getAllCustomerOrganizations();
      getAllCompanies();
    }
  }, [url, orgId, currentPage, currentLimit]);

  const handleSearchUser = async () => {
    setLoading(true);
    search ? setIsPaginationVisible(false) : setIsPaginationVisible(true);
    try {
      let result: any = await get(
        url
          ? url + `?search=${search}`
          : orgId !== "All"
          ? `/api/users/organization/${orgId}?search=${search}`
          : loggedUser.role === "WanAware_Super_User"
          ? `/api/users?limit=${currentLimit}&page=${currentPage}&search=${search}`
          : `/api/resellers/${organization.id}?limit=${currentLimit}&page=${currentPage}&search=${search}`
      );
      setAllUsers(result.data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="mt-3 flow-root relative">
        <div className="flex justify-between">
          <div className="flex items-center space-x-2">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <SimpleInput
                type="text"
                className={`block p-2 pl-10 text-sm text-gray-900 border-0 outline-none shadow-sm ring-1 ring-inset focus:ring-2 ${theme.inputBorderColor} ${theme.inputFocusedBorderColor} rounded-lg w-80 outline-none`}
                placeholder={
                  loggedOrg?.access_tier === "Customer" ||
                  pathname.includes("/super-user/customers")
                    ? "Search for Users"
                    : "Search for Employees"
                }
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearchUser();
                  }
                }}
              />
            </div>

            <GhostButton
              type="button"
              className="px-2.5 py-1.5"
              onClick={handleSearchUser}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                />
              </svg>
            </GhostButton>

            <GhostButton
              type="button"
              className="px-2.5 py-1.5"
              onClick={() => {
                setSearch("");
                fetchUsers();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                />
              </svg>
            </GhostButton>
          </div>

          {(pathname === "/super-user/wanaware-admin/employees" ||
            pathname === "/settings/users" ||
            pathname === "/settings/employees" ||
            (pathname === "/administration/users" &&
              loggedUser?.role === "Customer_Admin")) && (
            <div className="flex space-x-2">
              <PrimaryButton
                type="button"
                className={`inline-flex w-full justify-center px-3 py-2`}
                disabled={
                  loggedOrg?.access_tier === "Customer" &&
                  !organization?.is_active
                }
                onClick={() => setOpenUserModal(true)}
              >
                +{" "}
                {loggedOrg?.access_tier === "Customer"
                  ? "Create User"
                  : "Create Employee"}
              </PrimaryButton>
            </div>
          )}
        </div>

        {/* table */}
        <div>
          <div className="mt-5 flow-root">
            <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <table className="min-w-full border-separate border-spacing-y-0">
                    <thead
                      className={`${theme.tableHeaderBackgroundColor} ${theme.tableHeaderTextColor} text-opacity-70 mb-2 rounded-2xl`}
                    >
                      <tr>
                        {tableCols.map((col) => (
                          <th
                            key={col}
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold first:rounded-s-xl last:rounded-e-xl"
                          >
                            {col}
                          </th>
                        ))}
                      </tr>
                      <tr className="h-2"></tr>
                    </thead>
                    <tbody className={`${theme.bodyBackgroundColor}`}>
                      {loading ? (
                        <LoadingRow colspan={tableCols.length} />
                      ) : (
                        allUsers &&
                        allUsers.map((user: any) => (
                          <UsersTableRow
                            user={user}
                            fetchUsers={fetchUsers}
                            roles={roles}
                            companies={companies}
                            allCustomersOrganizations={
                              allCustomersOrganizations
                            }
                            setAllCustomerOrganizations={
                              setAllCustomerOrganizations
                            }
                            getAllCustomerOrganizations={
                              getAllCustomerOrganizations
                            }
                          />
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {orgId === "All" && isPaginationVisible && (
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setCurrentLimit={setCurrentLimit}
          totalPages={paginationObj?.totalPages}
          totalResults={paginationObj?.totalResults}
          currentLimit={currentLimit}
        />
      )}

      <CreateUserModal
        refetch={fetchUsers}
        modalProps={{ open: openUserModal, setOpen: setOpenUserModal }}
        url={url}
        roles={roles}
        orgId={orgId}
      />
    </div>
  );
}

export default UsersTable;
