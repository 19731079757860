import React, { useContext, useState } from "react";
import { SunIcon, MoonIcon } from "@heroicons/react/20/solid";
import { ThemeContext } from "../../contexts/ThemeContext";
import { colorPresets } from "./getColorPreset";
const SettingMode = () => {
  const { mode, toggleTheme, themePresetColor, theme } =
    useContext(ThemeContext)!;

  const handleModeChange = (selectedMode: any) => {
    if (selectedMode !== mode) {
      toggleTheme(selectedMode);
    }
  };
  const selected = colorPresets.find(
    (color) => color.name === themePresetColor
  );
  return (
    <div className="flex space-x-4">
      <button
        className={`flex items-center justify-center w-28 h-18 rounded-lg transition-colors duration-300 shadow-md border-1 ${
          mode === "light"
            ? `${selected?.backgroundColor} ${theme.primaryTextColor} `
            : "text-slate-500 bg-white"
        } `}
        onClick={() => handleModeChange("light")}
      >
        <SunIcon className="w-9 h-9" />
      </button>
      <button
        className={`flex items-center justify-center w-28 h-20 rounded-lg transition-colors duration-300 shadow-md border-2 border-gray-900 ${
          mode === "dark" ? "bg-gray-900 text-blue-600" : "bg-gray-900"
        } `}
        onClick={() => handleModeChange("dark")}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="#37424D"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke={mode === "dark" ? selected?.colorCode : "#64748B"}
          className="size-8"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M21.752 15.002A9.72 9.72 0 0 1 18 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 0 0 3 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 0 0 9.002-5.998Z"
          />
        </svg>
      </button>
    </div>
  );
};

export default SettingMode;
